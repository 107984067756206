
import React from 'react';
import { Row, Col, Card, Container } from '@themesberg/react-bootstrap';

import { CommandsTable } from '../../components/Tables';
import BasePage from "../base/BasePage";

class DocsBuildPage extends BasePage{

  constructor(props) {
    super(props);

  }


  render() {
    return (
        <Container className="px-0">
          <Row>
            <Col xs={12} className="p-3">
              <Card>
                <Card.Body>
                  <article>
                    <h1 className="h2" id="build-com.numeralasia.smartsupplies.tools">Build com.numeralasia.smartsupplies.tools </h1>
                    <p className="fs-5 fw-light">Learn the commands that will help you work with Volt React Dashboard and the commands that can build the final production version of your app.</p>

                    <p className="fs-5 fw-bold">Available commands</p>
                    <CommandsTable />
                  </article>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
    );
  }

}
export default Object.assign(DocsBuildPage, {ENDPOINT : "/documentation/build-com.numeralasia.smartsupplies.tools"})
