import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faHome, faPlus} from '@fortawesome/free-solid-svg-icons';
import {Breadcrumb, Button, Card, Col, Row, Table} from '@themesberg/react-bootstrap';
import BasePage from "./../base/BasePage";
import label from "na-utilities/src/label/Label"
import DashboardPage from "../dashboard/DashboardPage";
import PaginationTable from "../../components/PaginationTable";
import {getUser} from "../../utils/StorageUtil";
import {Link} from "react-router-dom";
import {Routes} from "../../routes";
import Endpoint from "na-utilities/src/api/Endpoint";
import queryString from 'query-string';
import {navigate, parseDate} from "na-utilities/src/utils/Utilities";
import {DD_MMMM_YYYY_HH_MM_SS} from "../../utils/Global";
import SearchInput from "../../components/input/SearchInput";
import TableSortOption from "../../components/input/TableSortOption";
import SortOption from "na-utilities/src/model/SortOption";
import SpotLocationDetailPage from "./SpotLocationDetailPage";
import LocationSelectorModal from "../../components/LocationSelectorModal";

class SpotLocationPage extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      inputSearch: queryString.parse(this.props.query).search ? queryString.parse(this.props.query).search : "",
      user : getUser(),
      page: queryString.parse(props.query).page ? queryString.parse(props.query).page : 1,
      spotLocations:[],
      totalPage: 0,
      totalElement: 0,
      pageElement: 0,
      sortOption:{}
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.closePreLoader()
    this.fetchSpotLocations()
  }

  componentWillReceiveProps(props, nextContext) {
    if (props != this.props) {
      let page = queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
      let key = queryString.parse(props.query).tab ? queryString.parse(props.query).tab : label.All
      let inputSearch = queryString.parse(props.query).search ? queryString.parse(props.query).search : ""
      this.setState({
        page: page,
        key: key,
        inputSearch: inputSearch
      }, () => {
        this.fetchSpotLocations()
      })
    }
  }


  fetchSpotLocations = () => {
    let {user,spotLocations, search, sortOption} = this.state
    let company = user.company?user.company:{}
    this.get(Endpoint.API.SPOT_LOCATIONS, {
      params :{
        companyId : company.id,
        ascending: sortOption.ascending,
        sortir: sortOption.sortir,
        search: search,
        page: this.state.page - 1,
      }
    }, null, response=>{
      if(response.code===200){
        let spotLocations = response.data
        let totalPage = response.totalPage
        let totalElement = response.totalElement
        let pageElement = response.pageElement
        this.setState({spotLocations:spotLocations,totalPage, totalElement, pageElement})
      }else{
        this.props.history.goBack();
      }
    }, false, true)
  }


  render() {
    let {user, spotLocations, page, pageElement, totalPage, totalElement, sortOption} = this.state
    return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                <Breadcrumb.Item href={DashboardPage.ENDPOINT}>{label.Dashboard}</Breadcrumb.Item>
                <Breadcrumb.Item active>{label.Location}</Breadcrumb.Item>
              </Breadcrumb>
              <h4>{label.LocationList}</h4>
            </div>
          </div>

          <div className="table-settings mb-4">
            <Row className="align-items-center">
              <Col xs={8} md={6} lg={3} xl={4}>
                <SearchInput onEnter={(search) => {
                  this.setState({search}, () => {
                    this.fetchSpotLocations()
                  })
                }} onEmpty={() => {
                  this.setState({search:""},() => {
                    this.fetchSpotLocations()
                  })
                }}/>
              </Col>
              <Col xs={4} md={4} xl={2} className="ps-md-0">
                <TableSortOption
                    sortOptions={
                      [
                          SortOption.init(label.Longest,"ja.created",true),
                          SortOption.init(label.Latest,"ja.created",false),
                          SortOption.init(label.TheMost,"ja.itemCount",false),
                          SortOption.init(label.Smallest,"ja.itemCount",true),
                      ]
                    }
                    sortOption={sortOption}
                    placeholder={label.SortBy}
                    onSelect={(sortOption) => {
                      this.setState({sortOption},() => {
                        this.fetchSpotLocations()
                      })
                    }}
                    onReset={() => {
                      this.setState({sortOption:{}},() => {
                        this.fetchSpotLocations()
                      })
                    }}/>
              </Col>
              <Col xs={6} className="text-right">
                  <Button
                      variant="info"
                      size="sm"
                      onClick={event => {
                          navigate(this.props, SpotLocationDetailPage.ENDPOINT)
                      }}>
                    {label.CreateLocation}&nbsp;<FontAwesomeIcon icon={faPlus}/>
                  </Button>
              </Col>
            </Row>
          </div>
          <Card border="light" className="table-wrapper table-responsive shadow-sm">
            <Card.Body className="pt-0">
              <Table hover className="user-table align-items-center">
                <thead>
                <tr>
                  <th className="border-bottom">#</th>
                  <th className="border-bottom">{label.Name}</th>
                  <th className="border-bottom">{label.Description}</th>
                  <th className="border-bottom">{label.Regional}</th>
                  <th className="border-bottom">{label.Created}</th>
                  <th className="border-bottom">{label.Detail}</th>
                </tr>
                </thead>
                <tbody>
                {spotLocations.map((spotLocation, index) => (
                    <tr>
                      <td>
                        <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
                          {((page - 1) * pageElement) + (++index)}
                        </Card.Link>
                      </td>
                      <td>
                        <span className="fw-normal">{spotLocation.name}</span>
                      </td>
                      <td>
                        <span className="fw-normal">{spotLocation.description}</span>
                      </td>
                      <td>
                        <span className="fw-normal">{spotLocation.regional}</span>
                      </td>
                      <td>
                        <span className="fw-normal">{parseDate(spotLocation.created, DD_MMMM_YYYY_HH_MM_SS)}</span>
                      </td>
                      <td>
                        <Button
                            size="sm"
                            className="rounded-circle text-center"
                            onClick={(e) => {
                              e.preventDefault()
                              navigate(this.props, SpotLocationDetailPage.ENDPOINT+"?id="+spotLocation.id)
                            }}
                            type={Link}>
                          <FontAwesomeIcon icon={faEye}/>
                        </Button>
                      </td>
                    </tr>
                ))}
                </tbody>
              </Table>
            </Card.Body>
            <PaginationTable
                onSelect={() => {

                }}
                currentPage={page}
                pageCount={totalPage}
                totalElement={totalElement}/>
          </Card>
          {/*<TransactionsTable/>*/}
        </>
    );
  }
}
export default Object.assign(SpotLocationPage, {ENDPOINT : "/spotLocations"})

