import React from 'react'
import {SortOption} from "na-utilities/src/model/SortOption";
import PropTypes from "prop-types";
import {Button, ButtonGroup, Col, Dropdown, Card} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faSort} from "@fortawesome/free-solid-svg-icons";
import label from "na-utilities/src/label/Label";



export default class TableSortOption extends React.Component{

    state = {}
    constructor(props) {
        super(props);
        this.state = {
            sortOptions:props.sortOptions?props.sortOptions:Array(),
            sortOption:props.sortOption?props.sortOption:{},
        }
    }


    componentWillReceiveProps(props, nextContext) {
        if (props != this.props) {
            let sortOptions = props.sortOptions?props.sortOptions:Array()
            let sortOption = props.sortOption?props.sortOption:{}
            this.setState({sortOption, sortOptions})
        }
    }


    render() {
        let {sortOption, sortOptions} = this.state
        let {placeholder, onSelect, onReset} = this.props
        if(!sortOptions){
            sortOptions = Array()
        }
        return (
            <div className="clickable-input" onClick={event => {
                document.getElementById("dropdown")
                    .getElementsByClassName("dropdown-toggle")[0].click()
            }}>
                <Dropdown id={"dropdown"} as={ButtonGroup}>
                    {label.SortBy}&nbsp;&nbsp;
                    <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faSort}/>
                </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                        <Dropdown.Item
                            className="fw-bold text-dark"
                            onClick={event => {
                                this.setState({sortOption: {}},() => {
                                    if(onReset){
                                        onReset()
                                    }
                                })
                            }}>
                            {placeholder?placeholder:label.Reset}
                        </Dropdown.Item>
                        {sortOptions.map((item)=>{
                            return (
                                <Dropdown.Item
                                    className="fw-bold"
                                    onClick={event => {
                                        this.setState({sortOption: item},() => {
                                            if(onSelect){
                                                onSelect(item)
                                            }
                                        })
                                    }}>
                                    {item.label} <FontAwesomeIcon style={{visibility:(sortOption.label===item.label?'unset':'hidden')}} icon={faCheck}/>
                                </Dropdown.Item>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }

}
TableSortOption.propTypes = {
    placeholder:PropTypes.string,
    sortOptions:PropTypes.array.isRequired,
    sortOption:PropTypes.object.isRequired,
    onSelect:PropTypes.func.isRequired,
    onReset:PropTypes.func.isRequired
}