import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Button, Modal} from '@themesberg/react-bootstrap';
import PropTypes from 'prop-types';
import label from 'na-utilities/src/label/Label'
import {faCrop} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class ImageCropper extends React.Component {

  state = {}

  constructor(props) {
    super(props);
    this.state = {
      src :  this.props.src,
      crop : {
        unit: "%",
        width: 30,
        aspect: this.props.freeTransform?null:this.props.aspect?this.props.aspect:1 / 1
      },
    }
  }

  componentWillReceiveProps(props, nextContext) {
    if(props!=this.props){
      this.setState({
        src:props.src,
        crop:{
          unit: "%",
          width: 30,
          aspect: this.props.freeTransform?null:this.props.aspect?this.props.aspect:1 / 1
        }
      })
    }
  }
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
          this.imageRef,
          crop,
          "newFile.png"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // const scaleX = image.width / image.width;
    // const scaleY = image.height / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve, reject) => {
      var dataURL = canvas.toDataURL('image/png', 1.0);
      resolve(dataURL)
    });
  }


  render() {
    const { crop, croppedImageUrl, src } = this.state;
    const {show} = this.props
    return (
        <Modal
            show={show}
            centered={true}
            backdrop={false}
        >
          <Modal.Header>{label.CropImage}</Modal.Header>
          <Modal.Body className="text-center" style={{}}>
            {src && (
                <ReactCrop
                    src={src}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                />
                //   <img src={src}/>
                // </ReactCrop>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
                size="sm"
                variant="warning"
                onClick={e=>{
              e.preventDefault()
                if(this.props.cancelCallback){
                    this.props.cancelCallback()
                }
            }}>
              {label.Cancel}
            </Button>
            {' '}
            <Button
                size="sm"
                variant="success"
                color="primary" onClick={e=>{
              e.preventDefault()
              if(this.props.callback){
                this.props.callback(croppedImageUrl)
              }
            }}>
              {label.Ok}&nbsp;<FontAwesomeIcon icon={faCrop} />
            </Button>

          </Modal.Footer>
        </Modal>
    );
  }
}

ImageCropper.propTypes = {
     src:PropTypes.any.isRequired,
     show:PropTypes.bool.isRequired,
     callback:PropTypes.func.isRequired,
     cancelCallback:PropTypes.func.isRequired,
     aspect:PropTypes.number,
     freeTransform:PropTypes.bool,

}
