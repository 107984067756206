import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Col, Form, Modal, Row, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import PaginationTop from "./PaginationTop";
import Global from "na-utilities/src/utils/Global";
import SearchInput from "./input/SearchInput";

export default class UserFilterModal extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            selectedUsers:new Set([]),
            search:"",
            users:[],
            user:{},
            sortOption:{},
            page:1,
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
            show:false
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    addUserSelectedId(object){
        let {selectedUsers} = this.state
        selectedUsers.add(object)
        this.setState({selectedUsers})
    }

    removeUserSelectedId(object){
        let {selectedUsers} = this.state
        let objectToDelete = undefined ;
        selectedUsers.forEach((item)=>{
            if(item.id===object.id){
                objectToDelete = item
            }
        })
        if(objectToDelete){
            selectedUsers.delete(objectToDelete)
        }
        this.setState({selectedUsers})
    }

    exists(object){
        let {selectedUsers} = this.state
        let has = false ;
        selectedUsers.forEach((item)=>{
            if(item.id===object.id){
                has = true
            }
        })
        return has
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let show = this.props.show?this.props.show:false
            let selectedUsers = this.props.selectedUsers
            this.setState({show, selectedUsers},() => {
                if(show && !prevState.show){
                    this.fetchUsers(null)
                }
            })
        }

    }

    fetchUsers(){
        let {user, sortOption, search} = this.state
        this.get(Endpoint.API.USERS, {
            params :{
                ascending: sortOption.ascending,
                sortir: sortOption.sortir,
                page: this.state.page - 1,
                search:search
            }
        }, null, response=>{
            if(response.code===200){
                let users = response.data
                let totalPage = response.totalPage
                let totalElement = response.totalElement
                let pageElement = response.pageElement
                this.setState({users:users,totalPage, totalElement, pageElement})
            }else{
                this.props.history.goBack();
            }
        }, false, true)
    }

    onSelect(user){
        if(this.props.onSelect){
            this.props.onSelect(user);
        }
    }




    render() {
        let {users,show, selectedUsers, page,totalPage, totalElement, pageElement} = this.state
        return (
            <>
                {super.render()}
                <Modal as={Modal.Dialog} size="xl" centered show={show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.SelectUser}</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={()=>{
                            this.props.onClose()
                        }} />
                    </Modal.Header>
                    <Modal.Header>
                        <Row className="w-100">
                            <Col md={4}>
                                <SearchInput
                                    onEnter={(search) => {
                                        this.setState({search}, () => {
                                            this.fetchUsers()
                                        })
                                    }} onEmpty={() => {
                                        this.setState({search:""}, () => {
                                            this.fetchUsers()
                                        })
                                     }}/>
                            </Col>
                            <Col md={8} className="text-right">
                                <PaginationTop
                                    onSelect={(page) => {
                                        this.setState({page}, () => {
                                            this.fetchUsers()
                                        })
                                    }}
                                    currentPage={page}
                                    pageCount={totalPage}
                                    totalElement={totalElement}
                                    showingRow={page}/>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive className="table-sm align-items-center table-flush p-0">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{label.Fullname}</th>
                                <th scope="col">{label.UserCode}</th>
                                <th scope="col">{label.Status}</th>
                                <th scope="col">{label.Role}</th>
                                <th scope="col" className="text-center">{label.Select}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {users.map((item, index) => {
                                return (
                                    <tr>
                                        <th scope="row">
                                            {((page - 1) * pageElement) + (index+1)}
                                        </th>
                                        <th scope="row" >
                                            {item.fullname}
                                        </th>
                                        <td>{item.userCode}</td>
                                        <td>{item.userStatus}</td>
                                        <td>{item.roleInformation}</td>
                                        <td scope="col" className="text-center">
                                            <Form.Check
                                                size="lg"
                                                id="checkbox1"
                                                htmlFor="checkbox1"
                                                checked={this.exists(item)}
                                                onChange={event => {
                                                    if(this.exists(item)){
                                                        this.removeUserSelectedId(item)
                                                    }else{
                                                        this.addUserSelectedId(item)
                                                    }
                                                }}
                                                className="pl-3"
                                                label="" />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="success"
                            onClick={event => {
                                this.setState({
                                    selectedUsers:new Set([])
                                }, () => {
                                    this.props.onSelect(selectedUsers)
                                })
                            }}>
                            {label.ResetFilter}
                        </Button>
                        <Button
                            variant="info"
                            onClick={event => {
                                this.props.onSelect(selectedUsers)
                            }}>
                            {label.ApplyFilter}
                            &nbsp;
                            {label.Selected_Count(selectedUsers.size?selectedUsers.size:0)}
                            &nbsp;
                            <FontAwesomeIcon icon={faFilter} />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

}
UserFilterModal.propTypes = {
    show:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onSelect:PropTypes.func.isRequired,
    selectedUsers:PropTypes.any

}
