import React from 'react'
import BaseComponent, {toasting} from "../pages/base/BaseComponent";
import {Button, Col, Modal, Row} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import PropTypes from 'prop-types';
import {cleanNonAlphabet, firstLetterUppercase, isEmpty, isResponseOk, isValidEmail} from "na-utilities/src/utils/Utilities";
import {faEnvelope, faPhone, faSave, faUser} from "@fortawesome/free-solid-svg-icons";
import TextInput from "./input/TextInput";
import EmailInput from "./input/EmailInput";
import PhoneNumberInput from "./input/PhoneNumberInput";
import Endpoint from "na-utilities/src/api/Endpoint";
import ItemOption from "./input/ItemOption";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isValidNumber} from "libphonenumber-js";
import {storeData} from "../utils/StorageUtil";
import Global from "../utils/Global";

export default class UserCreationModal extends BaseComponent{

    constructor(props) {
        super(props);
        let validation = this.defaultValidation()
        let validationMessage = this.defaultValidationMessage()

        this.state = {
            show:false,
            email:"",
            fullname:"",
            mobilePhone:"",
            roles:[],
            role:{},
            validation,
            validationMessage
        }
    }

    defaultValidation = () =>{
        let validation = {};
        validation.email = true ;
        validation.fullname = true ;
        validation.mobilePhone = true
        validation.role = true
        return validation ;
    }
    defaultValidationMessage = () =>{
        let validationMessage = {};
        validationMessage.email = "" ;
        validationMessage.fullname = "" ;
        validationMessage.mobilePhone = ""
        validationMessage.role = ""
        return validationMessage ;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    validateFormSubmit() {
        // await super.validateFormSubmit();
        let {roles, show, email, fullname, mobilePhone, role, validation, validationMessage} = this.state
        let valid = true;
        validation = this.defaultValidation()
        validationMessage = this.defaultValidationMessage()
        if (isEmpty(mobilePhone) || mobilePhone.length <= 2) {
            validation.mobilePhone = false
            validationMessage.mobilePhone = label.PleaseEnterCorrectPhoneNumber
            valid = false;
        }
        if (!isValidNumber(mobilePhone, 'ID')) {
            validation.mobilePhone = false
            validationMessage.mobilePhone = label.PleaseEnterCorrectPhoneNumber
            valid = false;
        }
        if (isEmpty(fullname) || fullname.length <= 2) {
            validation.fullname = false
            validationMessage.fullname = label.PleaseEnterFullName
            valid = false;
        }
        if (isEmpty(email) || email.length <= 2) {
            validation.email = false
            validationMessage.email = label.PleaseEnterEmail
            valid = false;
        }

        if (!isValidEmail(email)) {
            validation.email = false
            validationMessage.email = label.PleaseEnterCorrectEmail
            valid = false;
        }

        if (isEmpty(role) || !role.id) {
            validation.role = false
            validationMessage.role = label.PleaseSelectRoleForThisUser
            valid = false;
        }
        this.setState({validation, validationMessage}, () => {
            if(valid){
                this.save()
            }
        })
    }

    save(){
        let {roles, show, email, fullname, mobilePhone, role, validation, validationMessage} = this.state
        let {onCreateUser, onClose} = this.props
        let formData = new FormData()
        let userInfo = {}
        userInfo.email = email
        userInfo.fullname = fullname
        userInfo.mobilePhone = mobilePhone
        userInfo.roleId = role.id

        formData.append("userInfoGson", JSON.stringify(userInfo))
        this.props.showProcessing()
        this.post(Endpoint.API.USER_CREATE_NEW_USER, formData, null, (res)=>{
            if(isResponseOk(res)){
                let userCreation = res.data
                toasting.success(label.SuccessUpdatingData)
                if(onCreateUser){
                    onCreateUser(userCreation)
                }
            }else{
                toasting.error(res.message)
                if(onClose){
                    onClose()
                }
            }
            this.props.closeProcessing()
        }, false, false)
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let show = this.props.show?this.props.show:false
            this.setState({show},() => {
                if(show && !prevState.show){
                    let validation = this.defaultValidation()
                    let validationMessage = this.defaultValidationMessage()
                    this.setState({
                        show:show,
                        email:"",
                        fullname:"",
                        mobilePhone:"",
                        roles:[],
                        role:{},
                        validation,
                        validationMessage
                    },() => {
                        this.fetchRoles()
                    })
                }
            })
        }

    }

    fetchRoles = () => {
        this.get(Endpoint.API.ROLES, {
            params:{
                groupName:"USER",
            }
        }, null, response=>{
            if(response.code === 200){
                this.setState({
                    roles : response.data
                })
            }
        }, false, true);
    }


    render() {
        let {roles, show, email, fullname, mobilePhone, role, validation, validationMessage} = this.state
        let {onClose} = this.props
        return (
            <>
                <Modal as={Modal.Dialog} centered show={show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h4">{label.CreateUserAccount}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <TextInput
                                    label={label.Fullname}
                                    icon={faUser}
                                    placeholder={label.EnterFullname}
                                    value={fullname}
                                    onChange={(value) => {
                                        fullname = cleanNonAlphabet(value)
                                        fullname = firstLetterUppercase(value)
                                        validation.fullname = true
                                        validationMessage.fullname = ""
                                        this.setState({fullname, validation, validationMessage})
                                    }}
                                    isError={!validation.fullname}
                                    errorMessage={validationMessage.fullname}
                                />
                            </Col>
                        </Row>
                        <ItemOption
                            objects={roles}
                            label={label.Role}
                            value={role}
                            callback={(value) => {
                                role = value
                                validation.role = true
                                validationMessage.role = ""
                                this.setState({role, validation, validationMessage})
                            }}
                            isError={!validation.role}
                            errorMessage={validationMessage.role}
                        />
                        <Row>
                            <Col>
                                <EmailInput
                                    label={label.Email}
                                    icon={faEnvelope}
                                    placeholder={label.EnterEmail}
                                    value={email}
                                    onChange={(value) => {
                                        email = value
                                        validation.email = true
                                        validationMessage.email = ""
                                        this.setState({email, validation, validationMessage})
                                    }}
                                    isError={!validation.email}
                                    errorMessage={validationMessage.email}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PhoneNumberInput
                                    label={label.MobilePhone}
                                    icon={faPhone}
                                    placeholder={label.EnterPhoneNumber}
                                    value={mobilePhone}
                                    onChange={(value) => {
                                        mobilePhone = value
                                        validation.mobilePhone = true
                                        validationMessage.fullname = ""
                                        this.setState({mobilePhone, validation, validationMessage})
                                    }}
                                    isError={!validation.mobilePhone}
                                    errorMessage={validationMessage.mobilePhone}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {label.UserCreationPasswordNote}
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="warning"
                            onClick={e=>{
                                e.preventDefault()
                                if(onClose){
                                    onClose()
                                }
                            }}>
                            {label.Cancel}
                        </Button>
                        &nbsp;
                        <Button
                            variant="success"
                            onClick={ (e)=>{
                                e.preventDefault()
                                this.validateFormSubmit()
                            }}>
                            {label.Save}
                            &nbsp;
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </Modal.Footer>
                </Modal>
                {super.render()}
            </>
        );
    }

}
UserCreationModal.propTypes = {
    show:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onCreateUser:PropTypes.func.isRequired

}
