import React from "react";
import PropTypes from "prop-types";
import {DEFAULT_LOCATION} from "../utils/Global";
import GoogleMapReact from 'google-map-react';
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";

const AnyReactComponent = ({ text }) => <img style={{width:'36px'}} src={ApplicationIcons.Icons.markerMap}></img>;

export class MapContainer extends React.Component {

    state = {}

    constructor(props) {
        super(props);
        let state = this.state
        state.selectedPlace = {}
        state.map = {}
        state.latitude = this.props.latitude
        state.longitude = this.props.longitude
        this.setState(state)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps!=this.props){
            let state = this.state
            state.latitude = nextProps.latitude
            state.longitude = nextProps.longitude
            this.setState(state)
        }
    }


    componentDidMount() {
        let {latitude, longitude} = this.state
        if(latitude===DEFAULT_LOCATION.latitude && longitude===DEFAULT_LOCATION.longitude){
            this.initLocation()
        }
    }

    initLocation() {
        const location = window.navigator && window.navigator.geolocation
        if (location) {
            window.navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                },() => {
                    this.props.onGestureExecuting(position.coords.latitude,position.coords.longitude)
                })
            }, (error) => {
                this.setState({ latitude: DEFAULT_LOCATION.latitude, longitude: DEFAULT_LOCATION.longitude },() => {
                    this.props.onGestureExecuting(DEFAULT_LOCATION.latitude,DEFAULT_LOCATION.longitude)
                })
            })
        }
    }

    render() {
        let {latitude, longitude, map} = this.state
        if(!latitude){
            latitude = DEFAULT_LOCATION.latitude
        }
        if(!longitude){
            longitude = DEFAULT_LOCATION.longitude
        }

        let location = {
            center: {
                lat: latitude,
                lng: longitude
            },
            zoom: 11
        }
        return (
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyD716u3g7aCcZhmDh0nO_XBxFe8NYUsWvY" }}
                center={location.center}
                defaultZoom={location.zoom}
                onDragEnd={(l) => {
                    this.setState({latitude:l.center.lat(), longitude:l.center.lng()},() => {
                        // alert(l.center.lat()+","+l.center.lng())
                        if(this.props.onGestureExecuting){
                            this.props.onGestureExecuting(l.center.lat(), l.center.lng())
                        }
                    })
                }}
                onChange={(l) => {
                }}>
                <AnyReactComponent
                    lat={latitude}
                    lng={longitude}
                />
            </GoogleMapReact>

        );
    }
}

MapContainer.propTypes = {
    onGestureExecuting:PropTypes.func,
    latitude:PropTypes.number,
    longitude:PropTypes.number
}

