import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield} from "@fortawesome/free-solid-svg-icons";
import {faUserCircle} from "@fortawesome/free-regular-svg-icons";
import {
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  InputGroup,
  ListGroup,
  Nav,
  Navbar,
  Row
} from '@themesberg/react-bootstrap';

import NOTIFICATIONS_DATA from "../data/notifications";
import {getUser} from "../utils/StorageUtil";
import label from "na-utilities/src/label/Label";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import BaseComponent from "../pages/base/BaseComponent";
import {AuthConsumer} from "../context/AuthContext";
import Endpoint from "na-utilities/src/api/Endpoint";
import ProfilePage from "../pages/config/ProfilePage";
import {navigate} from "na-utilities/src/utils/Utilities";
import SettingPage from "../pages/SettingPage";

const Notification = (props) => {
  const { link, sender, image, time, message, read = false } = props;
  const readClassName = read ? "" : "text-danger";

  return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image src={image} className="user-avatar lg-avatar rounded-circle" />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
  );
};


export default class MyNavbar extends BaseComponent{
  constructor(props) {
    super(props);
    this.state = {
      user:props.user?props.user:getUser(),
      notifications:NOTIFICATIONS_DATA
    }

  }

  markNotificationsAsRead = () => {
    let {notifications} = this.state
    setTimeout(() => {
      this.setState({notifications:notifications.map(n => ({ ...n, read: true }))})
      // setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 300);
  };

  render() {
    let {notifications, user} = this.state
    const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
    return (
        <AuthConsumer>
          {({ isAuth, user, logout}) => (
              <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
                <Container fluid className="px-0">
                  <div className="d-flex justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <Form className="navbar-search">
                        <Form.Group id="topbarSearch">
                          <InputGroup className="input-group-merge search-bar">
                            <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                            <Form.Control type="text" placeholder="Search" />
                          </InputGroup>
                        </Form.Group>
                      </Form>
                    </div>
                    <Nav className="align-items-center">
                {/*      <Dropdown as={Nav.Item} onToggle={this.markNotificationsAsRead} >*/}
                {/*        <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">*/}
                {/*<span className="icon icon-sm">*/}
                {/*  <FontAwesomeIcon icon={faBell} className="bell-shake" />*/}
                {/*  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}*/}
                {/*</span>*/}
                {/*      </Dropdown.Toggle>*/}
                {/*        <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">*/}
                {/*          <ListGroup className="list-group-flush">*/}
                {/*            <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">*/}
                {/*              Notifications*/}
                {/*            </Nav.Link>*/}

                {/*            {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}*/}

                {/*            <Dropdown.Item className="text-center text-primary fw-bold py-3">*/}
                {/*              View all*/}
                {/*            </Dropdown.Item>*/}
                {/*          </ListGroup>*/}
                {/*        </Dropdown.Menu>*/}
                {/*      </Dropdown>*/}

                      <Dropdown as={Nav.Item}>
                        <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                          <div className="media d-flex align-items-center">
                            <Image src={(user&&user.photoUrl)?user.photoUrl:ApplicationIcons.Icons.userAvatar} className="user-avatar md-avatar rounded-circle border-info" />
                            <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                              <span className="mb-0 font-small fw-bold">{user?user.fullname:"Guest"}</span>
                            </div>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                          <Dropdown.Item
                              onClick={event => {
                                navigate(this.props, ProfilePage.ENDPOINT)
                              }}
                              className="fw-bold">
                            <FontAwesomeIcon icon={faUserCircle} className="me-2" />&nbsp;{label.Profile}
                          </Dropdown.Item>
                          <Dropdown.Item className="fw-bold" onClick={event => {
                            window.open(Endpoint.THIRD_PARTIES.SUPPORT_TICKET_APPLICATION, '_blank')
                          }}>
                            <FontAwesomeIcon icon={faUserShield} className="me-2" /> Support
                          </Dropdown.Item>

                          <Dropdown.Divider />

                          <Dropdown.Item
                              className="fw-bold"
                              onClick={event => {
                                this.openLogoutDialog( async ()=>{
                                  let response = await this.asyncGet(Endpoint.API.LOGOUT, null, null,  true, false)
                                  logout(this.props)
                                })
                              }}>
                            <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> {label.Logout}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav>
                  </div>
                </Container>
                {super.render()}
              </Navbar>
          )}
        </AuthConsumer>
    );
  }
}


// export default (props) => {
//   const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
//   const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
//   let user = props.user?props.user:getUser()
//
//   const markNotificationsAsRead = () => {
//     setTimeout(() => {
//       setNotifications(notifications.map(n => ({ ...n, read: true })));
//     }, 300);
//   };
//
//
//   const Notification = (props) => {
//     const { link, sender, image, time, message, read = false } = props;
//     const readClassName = read ? "" : "text-danger";
//
//     return (
//       <ListGroup.Item action href={link} className="border-bottom border-light">
//         <Row className="align-items-center">
//           <Col className="col-auto">
//             <Image src={image} className="user-avatar lg-avatar rounded-circle" />
//           </Col>
//           <Col className="ps-0 ms--2">
//             <div className="d-flex justify-content-between align-items-center">
//               <div>
//                 <h4 className="h6 mb-0 text-small">{sender}</h4>
//               </div>
//               <div className="text-end">
//                 <small className={readClassName}>{time}</small>
//               </div>
//             </div>
//             <p className="font-small mt-1 mb-0">{message}</p>
//           </Col>
//         </Row>
//       </ListGroup.Item>
//     );
//   };

  // return (
  //   <MyNavbar variant="dark" expanded className="ps-0 pe-2 pb-0">
  //     <Container fluid className="px-0">
  //       <div className="d-flex justify-content-between w-100">
  //         <div className="d-flex align-items-center">
  //           <CabinetContainerModal className="navbar-search">
  //             <CabinetContainerModal.Group id="topbarSearch">
  //               <InputGroup className="input-group-merge search-bar">
  //                 <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
  //                 <CabinetContainerModal.Control type="text" placeholder="Search" />
  //               </InputGroup>
  //             </CabinetContainerModal.Group>
  //           </CabinetContainerModal>
  //         </div>
  //         <Nav className="align-items-center">
  //           <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
  //             <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
  //               <span className="icon icon-sm">
  //                 <FontAwesomeIcon icon={faBell} className="bell-shake" />
  //                 {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
  //               </span>
  //             </Dropdown.Toggle>
  //             <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
  //               <ListGroup className="list-group-flush">
  //                 <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
  //                   Notifications
  //                 </Nav.Link>
  //
  //                 {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}
  //
  //                 <Dropdown.Item className="text-center text-primary fw-bold py-3">
  //                   View all
  //                 </Dropdown.Item>
  //               </ListGroup>
  //             </Dropdown.Menu>
  //           </Dropdown>
  //
  //           <Dropdown as={Nav.Item}>
  //             <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
  //               <div className="media d-flex align-items-center">
  //                 <Image src={user.photoUrl?user.photoUrl:ApplicationIcons.Icons.userAvatar} className="user-avatar md-avatar rounded-circle border-info" />
  //                 <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
  //                   <span className="mb-0 font-small fw-bold">{user.fullname}</span>
  //                 </div>
  //               </div>
  //             </Dropdown.Toggle>
  //             <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
  //               <Dropdown.Item className="fw-bold">
  //                 <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
  //               </Dropdown.Item>
  //               <Dropdown.Item className="fw-bold">
  //                 <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
  //               </Dropdown.Item>
  //               <Dropdown.Item className="fw-bold">
  //                 <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
  //               </Dropdown.Item>
  //               <Dropdown.Item className="fw-bold">
  //                 <FontAwesomeIcon icon={faUserShield} className="me-2" /> Support
  //               </Dropdown.Item>
  //
  //               <Dropdown.Divider />
  //
  //               <Dropdown.Item
  //                   className="fw-bold"
  //                   onClick={event => {
  //                   }}>
  //                 <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> {label.Logout}
  //               </Dropdown.Item>
  //             </Dropdown.Menu>
  //           </Dropdown>
  //         </Nav>
  //       </div>
  //     </Container>
  //   </MyNavbar>
  // );
// };
