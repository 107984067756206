import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, ButtonGroup, Card, Col, Dropdown, Row, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faEllipsisH, faEye, faPowerOff} from "@fortawesome/free-solid-svg-icons";
import label from "na-utilities/src/label/Label"
import SearchInput from "./input/SearchInput";
import TableSortOption from "./input/TableSortOption";
import SortOption from "na-utilities/src/model/SortOption";
import {Link} from "react-router-dom";
import {Routes} from "../routes";
import PaginationTable from "./PaginationTable";
import Endpoint from "na-utilities/src/api/Endpoint";
import {getUser} from "../utils/StorageUtil";
import PropTypes from 'prop-types';
import SmartDeviceDetailPage from "../pages/subscribe/SmartDeviceDetailPage";
import SockJsClient from "react-stomp";
import Global from "../utils/Global";
import {APK_TOOLBOX_LATEST_DOWNLOAD, HEART_BEAT_INTERVAL} from "../Variable";


class SmartDeviceTable extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            show:false,
            inputSearch: "",
            user : getUser(),
            smartDeviceType:props.smartDeviceType?props.smartDeviceType:{},
            page: 1,
            smartDevices:[],
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
            sortOption:{}
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillReceiveProps(props, nextContext) {
        let show = props.show?props.show:false
        let smartDeviceType = props.smartDeviceType?props.smartDeviceType:{}
        this.setState({show,smartDeviceType},() => {
            if(show){
                this.fetchSmartDevices()
            }
        })
    }

    fetchSmartDevices = () => {
        let {user, smartDevices, smartDeviceType, search, sortOption} = this.state
        this.get(Endpoint.API.SMART_DEVICES, {
            params :{
                smartDeviceTypeId:smartDeviceType.id,
                userId : user.id,
                ascending: sortOption.ascending,
                sortir: sortOption.sortir,
                search: search,
                page: this.state.page - 1,
            }
        }, null, response=>{
            if(response.code===200){
                let smartDevices = response.data
                let totalPage = response.totalPage
                let totalElement = response.totalElement
                let pageElement = response.pageElement
                this.setState({smartDevices:smartDevices,totalPage, totalElement, pageElement})
            }else{
                this.props.history.goBack();
            }
        }, false, true)
    }


    render() {
        let {user, smartDevices, page, pageElement, totalPage, totalElement, sortOption} = this.state
        return (
            <React.Fragment>
                <div className="table-settings mb-4">
                    <Row className="align-items-center">
                        <Col xs={8} md={6} lg={3} xl={4}>
                            <SearchInput onEnter={(search) => {
                                this.setState({search}, () => {
                                    this.fetchSmartDevices()
                                })
                            }} onEmpty={() => {
                                this.setState({search:""},() => {
                                    this.fetchSmartDevices()
                                })
                            }}/>
                        </Col>
                        <Col xs={4} md={4} xl={2} className="ps-md-0">
                            <TableSortOption
                                sortOptions={
                                    [
                                        SortOption.init(label.Longest,"ja.created",true),
                                        SortOption.init(label.Latest,"ja.created",false),
                                        SortOption.init(label.TheMost,"ja.itemCount",false),
                                        SortOption.init(label.Smallest,"ja.itemCount",true),
                                    ]
                                }
                                sortOption={sortOption}
                                placeholder={label.SortBy}
                                onSelect={(sortOption) => {
                                    this.setState({sortOption},() => {
                                        this.fetchSmartDevices()
                                    })
                                }}
                                onReset={() => {
                                    this.setState({sortOption:{}},() => {
                                        this.fetchSmartDevices()
                                    })
                                }}/>
                        </Col>
                    </Row>
                </div>
                <Card border="light" className="table-wrapper table-responsive shadow-sm">
                    <Card.Body className="p-0">
                        <Table hover className="table-sm align-items-center">
                            <thead>
                            <tr>
                                <th className="border-bottom">#</th>
                                <th className="border-bottom">{label.DeviceId}</th>
                                <th className="border-bottom">{label.Item}</th>
                                <th className="border-bottom">{label.Location}</th>
                                <th className="border-bottom">{label.Status}</th>
                                <th className="border-bottom">{label.State}</th>
                                <th className="border-bottom">{label.Detail}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {smartDevices.map((smartDevice, index) => (
                                <tr key={index}>
                                    <td>
                                        <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
                                            {((page - 1) * pageElement) + (index+1)}
                                        </Card.Link>
                                    </td>
                                    <td>
                                        <span className="fw-normal"><Link style={{textDecoration:'underline'}} to={"#"}>{smartDevice.deviceId}</Link></span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">0</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">{smartDevice.spotLocation.name}</span>
                                    </td>
                                    <td>
                                        <Button size="sm" className="fw-normal" style={{backgroundColor:(smartDevice.smartDeviceStatus?smartDevice.smartDeviceStatus.color:undefined)}}>
                                            {smartDevice.smartDeviceStatus?smartDevice.smartDeviceStatus.label:""}
                                        </Button>
                                    </td>
                                    <td>
                                        <Button size="sm" className="fw-normal btn-outline-black bg-white">
                                            {smartDevice.smartDeviceState?smartDevice.smartDeviceState.label:""}
                                            &nbsp;
                                            <Button
                                                className="rounded-circle"
                                                size="sm"
                                                style={{width:'16px', height:'16px', backgroundColor:(smartDevice.smartDeviceState?smartDevice.smartDeviceState.color:"red")}}/>
                                        </Button>
                                    </td>
                                    <td>
                                        <div>
                                            <Dropdown as={ButtonGroup} drop={"right"}>
                                                <Dropdown.Toggle size="sm" as={Button} split variant="link" className="text-dark m-0 p-0">
                                                <span className="icon icon-sm">
                                                    <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                                                </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu size="sm" className="p-0" style={{marginRight:'180px'}}>
                                                    <Dropdown.Item as={Link} to={SmartDeviceDetailPage.ENDPOINT+"/"+smartDevice.deviceId}>
                                                        <FontAwesomeIcon size="sm" icon={faEye} className="me-2" />&nbsp;{label.Detail}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={event => {
                                                            window.open(APK_TOOLBOX_LATEST_DOWNLOAD, '_blank')
                                                        }}
                                                        size="sm">
                                                        <FontAwesomeIcon icon={faDownload} className="me-2" />&nbsp;{label.DownloadSoftware}
                                                    </Dropdown.Item>
                                                    {/*<Dropdown.Item size="sm" className="text-danger">*/}
                                                    {/*    <FontAwesomeIcon icon={faPowerOff} className="me-2" />&nbsp;{label.Restart}*/}
                                                    {/*</Dropdown.Item>*/}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <>
                                        <SockJsClient
                                            url={Global.BASE_URL}
                                            topics={["/listen/"+smartDevice.deviceId+"/refresh"]}
                                            heartbeat={HEART_BEAT_INTERVAL}
                                            debug={Global.DEBUG}
                                            autoReconnect={true}
                                            onMessage={(message) => {
                                                smartDevices[index].smartDeviceState = message
                                                this.setState({smartDevices})
                                            }}/>
                                    </>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                    <PaginationTable
                        onSelect={() => {

                        }}
                        showingRow={smartDevices.length}
                        currentPage={page}
                        pageCount={totalPage}
                        totalElement={totalElement}/>
                </Card>
            </React.Fragment>
        );
    }

}
SmartDeviceTable.propTypes = {
    show:PropTypes.bool.isRequired,
    smartDeviceType:PropTypes.any.isRequired
}


export default SmartDeviceTable