import React from 'react'
import BaseComponent, {toasting} from "../pages/base/BaseComponent";
import {Button, ButtonGroup, Card, Col, Form, Modal, Row} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {
    cloneObject,
    firstLetterUppercase,
    imageSelector,
    isEmpty,
    isResponseOk
} from "na-utilities/src/utils/Utilities";
import PropTypes from 'prop-types';
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTrash, faUpload} from "@fortawesome/free-solid-svg-icons";
import TextInput from "./input/TextInput";
import {SpotLocationDto} from "../model/model";
import TextAreaInput from "./input/TextAreaInput";
import { withRouter } from 'react-router-dom';
import GoodsItemImageModal from "./GoodsItemImageModal";
import GoodsCategorySelectorModal from "./GoodsCategorySelectorModal";
import GoodsItemEditModal from "./GoodsItemEditModal";
import NumberInput from "./input/NumberInput";
import ItemOption from "./input/ItemOption";


const maxBorrowingDurationOptions = [{id:1, label:label.NotLimited}, {id:2, label:label.Limited}];
class GoodsInfoEditModal extends BaseComponent{

    constructor(props) {
        super(props);
        let {createGoodsInfoModal} = this.props
        let goodsInfo = createGoodsInfoModal.goodsInfo
        if(goodsInfo.borrowingDurationType===undefined){
            goodsInfo.borrowingDurationType = true
        }
        let validation = this.defaultValidation()
        let validationMessage = this.defaultValidationMessage()
        this.state = {
            goodsInfo:goodsInfo,
            validation:validation,
            validationMessage:validationMessage,
            createGoodsInfoModal:createGoodsInfoModal,
            goodsCategories:[],
            categorySelectorModal:false,
        }
    }

    defaultValidation = () =>{
        let validation = new SpotLocationDto();
        validation.name = true ;
        validation.description = true ;
        validation.goodsCategory = true ;
        validation.maximumBorrowingDurationInHour = true ;
        validation.borrowingDurationType = true
        return validation ;
    }
    defaultValidationMessage = () =>{
        let validationMessage = new SpotLocationDto();
        validationMessage.name = "" ;
        validationMessage.description = ""
        validationMessage.goodsCategory = "" ;
        validationMessage.maximumBorrowingDurationInHour = "";
        validationMessage.borrowingDurationType = "";
        return validationMessage ;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let {createGoodsInfoModal} = this.props
            let goodsInfo = createGoodsInfoModal.goodsInfo
            if(goodsInfo.borrowingDurationType===undefined){
                goodsInfo.borrowingDurationType = true
            }
            let show = createGoodsInfoModal.show
            this.setState({goodsInfo, createGoodsInfoModal}, () => {
                if(show){
                    // this.fetchGoodsCategories((goodsCategories)=>{
                    //     this.setState({goodsCategories})
                    // })
                }
            })
        }
    }

    async submit(){
        let {goodsInfos, goodsInfo, validation, validationMessage, createGoodsInfoModal} = this.state
        let valid = true ;
        if(isEmpty(goodsInfo.name)){
            validation.name = false
            validationMessage.name = label.EnterGoodsName
            valid = false
        }
        if(isEmpty(goodsInfo.description)){
            valid = false
            validation.description = false
            validationMessage.description = label.EnterGoodsDescription
        }
        if(isEmpty(goodsInfo.goodsCategory)){
            valid = false
            validation.goodsCategory = false
            validationMessage.goodsCategory = label.SelectGoodsCategory
        }
        if(!valid){
            this.setState({validation, validationMessage})
        }else{
            let form = new FormData()
            let goodsInfoModel = cloneObject(goodsInfo)
            if(goodsInfo.blob1){
                let multipart1 = await this.multipartBlobFile(goodsInfo.image1)
                form.append("multipartFile1", multipart1)
                goodsInfoModel.image1 = undefined
                goodsInfoModel.imageLink1 = undefined
            }
            if(goodsInfo.blob2){
                let multipart2 = await this.multipartBlobFile(goodsInfo.image2)
                form.append("multipartFile2", multipart2)
                goodsInfoModel.image2 = undefined
                goodsInfoModel.imageLink2 = undefined
            }
            if(goodsInfo.blob3){
                let multipart3 = await this.multipartBlobFile(goodsInfo.image3)
                form.append("multipartFile3", multipart3)
                goodsInfoModel.image3 = undefined
                goodsInfoModel.imageLink3 = undefined
            }
            if(goodsInfo.blob4){
                let multipart4 = await this.multipartBlobFile(goodsInfo.image4)
                form.append("multipartFile4", multipart4)
                goodsInfoModel.image4 = undefined
                goodsInfoModel.imageLink4 = undefined
            }

            form.append("goodsInfoDtoGson", JSON.stringify(goodsInfoModel))
            this.post(Endpoint.API.GOODS_INFO_SAVE_UPLOAD, form,
                null, (res)=>{
                    this.props.closeProcessing()
                    if(isResponseOk(res)){
                        goodsInfo = res.data
                        createGoodsInfoModal = {
                            ...createGoodsInfoModal, goodsInfo, show:false
                        }
                        this.props.onResult(createGoodsInfoModal)
                    }else{
                        toasting.error(res.message)
                    }
                }, true, true)
        }
    }

    render() {
        let {goodsInfo, createGoodsInfoModal, validation, validationMessage, categorySelectorModal} = this.state
        let goodsCategory = goodsInfo.goodsCategory?goodsInfo.goodsCategory:{}
        return (
            <>
                {super.render()}
                <GoodsCategorySelectorModal
                    show={categorySelectorModal}
                    goodsCategory={goodsCategory}
                    onClose={() => {
                        this.setState({categorySelectorModal:false})
                    }}
                    onSelectGoodsCategory={(goodsCategory) => {
                        goodsInfo.goodsCategory = goodsCategory
                        this.setState({goodsInfo, categorySelectorModal:false})
                    }}
                    showProcessing={this.props.showProcessing}
                    closeProcessing={this.props.closeProcessing}
                />
                <Modal as={Modal.Dialog} size="lg" centered show={createGoodsInfoModal.show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.GoodsInfoForm}</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={()=>{
                            this.props.onClose()
                        }} />
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-4">
                            <Col>
                                <Card border="light">
                                    <Row>
                                        <Col className="p-3">
                                            <Card.Img src={goodsInfo.image1?goodsInfo.imageLink1:ApplicationIcons.Icons.imagePlaceholder}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right px-4 pb-2">
                                            <ButtonGroup>
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={event => {
                                                        goodsInfo.imageLink1 = undefined
                                                        goodsInfo.image1 = undefined
                                                        this.setState({goodsInfo})
                                                    }}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>
                                                <Button
                                                    variant="outline-info"
                                                    size="sm"
                                                    onClick={event => {
                                                        imageSelector((file)=>{
                                                            this.openCropper(file, (blob)=>{
                                                                goodsInfo.blob1 = blob
                                                                goodsInfo.imageLink1 = blob
                                                                goodsInfo.image1 = blob
                                                                this.setState({
                                                                    goodsInfo:goodsInfo
                                                                }, () => {
                                                                    this.closeCropper()
                                                                })
                                                            },  () =>{
                                                                goodsInfo.blob1 = undefined
                                                                this.closeCropper()
                                                            })
                                                        }).click()
                                                    }}>
                                                    <FontAwesomeIcon icon={faUpload}/>
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col>
                                <Card border="light">
                                    <Row>
                                        <Col className="p-3">
                                            <Card.Img src={goodsInfo.image2?goodsInfo.imageLink2:ApplicationIcons.Icons.imagePlaceholder}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right px-4 pb-2">
                                            <ButtonGroup>
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={event => {
                                                        goodsInfo.imageLink2 = undefined
                                                        goodsInfo.image2 = undefined
                                                        this.setState({goodsInfo})
                                                    }}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>
                                                <Button
                                                    variant="outline-info"
                                                    size="sm"
                                                    onClick={event => {
                                                        imageSelector((file)=>{
                                                            this.openCropper(file, (blob)=>{
                                                                goodsInfo.blob2 = blob
                                                                goodsInfo.imageLink2 = blob
                                                                goodsInfo.image2 = blob
                                                                this.setState({
                                                                    goodsInfo:goodsInfo
                                                                }, () => {
                                                                    this.closeCropper()
                                                                })
                                                            },  () =>{
                                                                goodsInfo.blob2 = undefined
                                                                this.closeCropper()
                                                            })
                                                        }).click()
                                                    }}>
                                                    <FontAwesomeIcon icon={faUpload}/>
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col>
                                <Card border="light">
                                    <Row>
                                        <Col className="p-3">
                                            <Card.Img src={goodsInfo.image3?goodsInfo.imageLink3:ApplicationIcons.Icons.imagePlaceholder}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right px-4 pb-2">
                                            <ButtonGroup>
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={event => {
                                                        goodsInfo.imageLink3 = undefined
                                                        goodsInfo.image3 = undefined
                                                        this.setState({goodsInfo})
                                                    }}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>
                                                <Button
                                                    variant="outline-info"
                                                    size="sm"
                                                    onClick={event => {
                                                        imageSelector((file)=>{
                                                            this.openCropper(file, (blob)=>{
                                                                goodsInfo.blob3 = blob
                                                                goodsInfo.imageLink3 = blob
                                                                goodsInfo.image3 = blob
                                                                this.setState({
                                                                    goodsInfo:goodsInfo
                                                                }, () => {
                                                                    this.closeCropper()
                                                                })
                                                            },  () =>{
                                                                goodsInfo.blob3 = undefined
                                                                this.closeCropper()
                                                            })
                                                        }).click()
                                                    }}>
                                                    <FontAwesomeIcon icon={faUpload}/>
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col>
                                <Card border="light">
                                    <Row>
                                        <Col className="p-3">
                                            <Card.Img src={goodsInfo.image3?goodsInfo.imageLink3:ApplicationIcons.Icons.imagePlaceholder}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right px-4 pb-2">
                                            <ButtonGroup>
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={event => {
                                                        goodsInfo.imageLink3 = undefined
                                                        goodsInfo.image3 = undefined
                                                        this.setState({goodsInfo})
                                                    }}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>
                                                <Button
                                                    variant="outline-info"
                                                    size="sm"
                                                    onClick={event => {
                                                        imageSelector((file)=>{
                                                            this.openCropper(file, (blob)=>{
                                                                goodsInfo.blob4 = blob
                                                                goodsInfo.imageLink4 = blob
                                                                goodsInfo.image4 = blob
                                                                this.setState({
                                                                    goodsInfo:goodsInfo
                                                                }, () => {
                                                                    this.closeCropper()
                                                                })
                                                            },  () =>{
                                                                goodsInfo.blob4 = undefined
                                                                this.closeCropper()
                                                            })
                                                        }).click()
                                                    }}>
                                                    <FontAwesomeIcon icon={faUpload}/>
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form>
                                    <TextInput
                                        label={label.GoodsCategory}
                                        required={true}
                                        value={goodsCategory.name}
                                        placeholder={" "}
                                        disabled={true}
                                        readOnly={true}
                                        clickable={true}
                                        onClick={()=>{
                                            this.setState({categorySelectorModal:true})
                                        }}
                                        isError={!validation.goodsCategory}
                                        errorMessage={validationMessage.goodsCategory}
                                    />
                                </Form>
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <Col>
                                        <Form.Label>
                                            {label.MaxBorrowingDuration}
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <ItemOption
                                            objects={maxBorrowingDurationOptions}
                                            fieldForLabel={"label"}
                                            hideOptionLabel={true}
                                            hideLabel={true}
                                            value={{id:(goodsInfo.borrowingDurationType===true?1:2)}}
                                            placeholder={" "}
                                            callback={(value) => {
                                                if(value.id===1){
                                                    goodsInfo.borrowingDurationType = true
                                                    goodsInfo.maximumBorrowingDurationInHour = 0
                                                }
                                                if(value.id===2){
                                                    goodsInfo.borrowingDurationType = false
                                                    goodsInfo.maximumBorrowingDurationInHour = 24
                                                }
                                                this.setState({goodsInfo, validation, validationMessage})
                                            }}
                                            isError={!validation.borrowingDurationType}
                                            errorMessage={validationMessage.borrowingDurationType}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <NumberInput
                                            required={true}
                                            value={goodsInfo.maximumBorrowingDurationInHour?goodsInfo.maximumBorrowingDurationInHour:0}
                                            placeholder={" "}
                                            clickable={true}
                                            disabled={goodsInfo.borrowingDurationType}
                                            readOnly={goodsInfo.borrowingDurationType}
                                            isError={!validation.maximumBorrowingDurationInHour}
                                            onChange={(value) => {
                                                if(!value){
                                                    value = 1
                                                }
                                                goodsInfo.maximumBorrowingDurationInHour = value
                                                validation.maximumBorrowingDurationInHour = true
                                                validationMessage.maximumBorrowingDurationInHour = ""
                                                this.setState({goodsInfo, validation, validationMessage})
                                            }}
                                            errorMessage={validationMessage.maximumBorrowingDurationInHour}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form>
                                    <TextInput
                                        label={label.GoodsName}
                                        required={true}
                                        placeholder={label.EnterGoodsName}
                                        value={goodsInfo.name}
                                        onChange={(value) => {
                                            value = firstLetterUppercase(value)
                                            goodsInfo.name = value
                                            validation.name = true
                                            validationMessage.name = ""
                                            this.setState({goodsInfo, validation, validationMessage})
                                        }}
                                        isError={!validation.name}
                                        errorMessage={validationMessage.name}
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form>
                                    <TextAreaInput
                                        label={label.GoodsDescription}
                                        required={true}
                                        placeholder={label.EnterGoodsDescription}
                                        rows={4}
                                        value={goodsInfo.description}
                                        onChange={(value) => {
                                            value = firstLetterUppercase(value)
                                            goodsInfo.description = value
                                            validation.description = true
                                            validationMessage.description = ""
                                            this.setState({goodsInfo, validation, validationMessage})
                                        }}
                                        isError={!validation.description}
                                        errorMessage={validationMessage.description}
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right px-4 pb-2">
                                <ButtonGroup>
                                    <Button
                                        size="sm"
                                        onClick={async (event) => {
                                            await this.submit()
                                        }}>
                                        {label.Save}&nbsp;&nbsp;<FontAwesomeIcon icon={faSave}/>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}
GoodsInfoEditModal.propTypes = {
    position:PropTypes.number,
    createGoodsInfoModal:PropTypes.object.isRequired,
    onClose:PropTypes.func.isRequired,
    onResult:PropTypes.func.isRequired

}

export default withRouter(GoodsInfoEditModal)
