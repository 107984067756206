import React from 'react'
import BaseComponent, {toasting} from "../pages/base/BaseComponent";
import {Button, ButtonGroup, Card, Col, Form, Modal, Row, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {
    cloneObject,
    firstLetterUppercase,
    isEmpty,
    isImageExists,
    isResponseOk
} from "na-utilities/src/utils/Utilities";
import PropTypes from 'prop-types';
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBorderNone, faCamera, faCheck, faPlay, faSave, faStop, faTrash} from "@fortawesome/free-solid-svg-icons";
import TextInput from "./input/TextInput";
import {SpotLocationDto} from "../model/model";
import TextAreaInput from "./input/TextAreaInput";
import {Link} from 'react-router-dom';
import MyImage from "./MyImage";
import {Routes} from "../routes";
import NumberInput from "./input/NumberInput";
import Global from "na-utilities/src/utils/Global";
import GoodsItemImageModal from "./GoodsItemImageModal";

class GoodsItemEditModal extends BaseComponent{

    constructor(props) {
        super(props);
        let {createGoodsItemModal} = this.props
        let goodsInfo = createGoodsItemModal.goodsInfo
        let goodsItems = createGoodsItemModal.goodsItems
        let validation = this.defaultValidation()
        let validationMessage = this.defaultValidationMessage()
        let rfidTag = ""
        this.state = {
            goodsInfo:goodsInfo,
            goodsItems:goodsItems,
            validation:validation,
            validationMessage:validationMessage,
            createGoodsItemModal:createGoodsItemModal,
            scanningMode:false,
            rfidTag:rfidTag,
            goodsItemImageModal:{
                show:false,
                position:-1,
                goodsItem:{},
            },
        }
    }

    defaultValidation = () =>{
        let validation = new SpotLocationDto();
        validation.name = true ;
        validation.description = true ;
        validation.goodsCategory = true
        validation.rfidTag = true ;

        return validation ;
    }
    defaultValidationMessage = () =>{
        let validationMessage = new SpotLocationDto();
        validationMessage.name = "" ;
        validationMessage.description = ""
        validationMessage.goodsCategory = ""
        validationMessage.rfidTag = "" ;
        return validationMessage ;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let {createGoodsItemModal} = this.props
            let goodsInfo = createGoodsItemModal.goodsInfo
            let goodsItems = []
            this.setState({goodsItems, goodsInfo, createGoodsItemModal})
        }

    }

    async submit(){
        let {goodsItems, goodsInfo,  createGoodsItemModal} = this.state
        if(goodsItems.length<=0){
            createGoodsItemModal = {
                ...createGoodsItemModal, show:false
            }
            this.props.onResult(createGoodsItemModal)
            return
        }
        let form = new FormData()
        form.append("goodsInfoId", goodsInfo.id)
        form.append("goodsItemsJson", JSON.stringify(goodsItems))
        this.post(Endpoint.API.GOODS_ITEM_REGISTER_COLLECTION, form,
            null, (res)=>{
                this.props.closeProcessing()
                if(isResponseOk(res)){
                    goodsInfo = res.data
                    createGoodsItemModal = {
                        ...createGoodsItemModal, goodsInfo, show:false
                    }
                    this.props.onResult(createGoodsItemModal)
                }else{
                    toasting.error(res.message)
                }
            }, true, true)
    }

    startScanning(){
        let {goodsItems, scanningMode} = this.state
        this.setState({scanningMode:!scanningMode})

    }

    stopScanning(){
        let {goodsItems, scanningMode} = this.state
        this.setState({scanningMode:!scanningMode})
    }

    render() {
        let {goodsItems, goodsInfo, rfidTag, createGoodsItemModal, validation, validationMessage, scanningMode, goodsItemImageModal} = this.state
        let goodsCategory = goodsInfo.goodsCategory?goodsInfo.goodsCategory:{}
        return (
            <>
                {super.render()}
                <GoodsItemImageModal
                    goodsItemImageModal={goodsItemImageModal}
                    onResult={(goodsItemImageModal) => {
                        if(goodsItemImageModal.position==-1){
                            goodsItems.splice(0,0, cloneObject(goodsItemImageModal.goodsItem))
                        }else{
                            goodsItems.splice(goodsItemImageModal.position, 1)
                            goodsItems.splice(goodsItemImageModal.position, 0, cloneObject(goodsItemImageModal.goodsItem))
                        }
                        this.setState({goodsItems, goodsItemImageModal:{show:false, position:-1, goodsItem:{}}})
                    }}
                    onClose={() => {
                        this.setState({goodsItemImageModal:{show:false, position:-1, goodsItem:{}}})
                    }}/>
                <Modal as={Modal.Dialog} size="xl" centered show={createGoodsItemModal.show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.RegisterTagForGoodsItem}</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={()=>{
                            this.props.onClose()
                        }} />
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} xl={4}>
                                <Card className="p-0">
                                    <Card.Header className="p-3">
                                        {label.Information}
                                    </Card.Header>
                                    <Card.Body className="p-3">
                                        <Form>
                                            <TextInput
                                                label={label.GoodsName}
                                                required={true}
                                                placeholder={label.EnterGoodsName}
                                                value={goodsInfo.name}
                                                disabled={true}
                                                readOnly={true}
                                                isError={!validation.name}
                                                errorMessage={validationMessage.name}
                                            />
                                        </Form>
                                        <Form>
                                            <TextInput
                                                label={label.GoodsCategory}
                                                required={true}
                                                value={goodsCategory.name}
                                                placeholder={" "}
                                                disabled={true}
                                                readOnly={true}
                                                isError={!validation.goodsCategory}
                                                errorMessage={validationMessage.goodsCategory}
                                            />
                                        </Form>
                                        <Form>
                                            <TextAreaInput
                                                label={label.GoodsDescription}
                                                required={true}
                                                placeholder={label.EnterGoodsDescription}
                                                rows={4}
                                                value={goodsInfo.description}
                                                disabled={true}
                                                readOnly={true}
                                                isError={!validation.description}
                                                errorMessage={validationMessage.description}
                                            />
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} xl={8}>
                                <Row>
                                    <Col>
                                        <Card>
                                            <Card.Body className="p-2">
                                                <Row>
                                                    <Col xs={12} xl={2}>
                                                        <MyImage
                                                            className="gif-paused"
                                                            style={{width:'100%', padding:(scanningMode?'0px':'14px')}}
                                                            src={scanningMode?ApplicationIcons.Gifs.radar:ApplicationIcons.Icons.radar}/>

                                                    </Col>
                                                    <Col xs={12} xl={7} className="d-flex justify-content-start align-items-center">
                                                        <h6 className={scanningMode?"text-danger":"text-info"}>
                                                            {scanningMode?label.ScanningRFIDOnAReader:label.NotOnScanningMode}
                                                        </h6>
                                                    </Col>
                                                    <Col xs={12} xl={3} className="d-flex justify-content-center align-items-center text-right">
                                                        <Button
                                                            variant={scanningMode?'danger':'info'}
                                                            size="sm align-content-center"
                                                            onClick={event => {
                                                                if(scanningMode){
                                                                    this.stopScanning()
                                                                }else{
                                                                    this.startScanning()
                                                                }
                                                            }}>
                                                            {scanningMode?label.StopScanning:label.StartScanning}
                                                            &nbsp;&nbsp;
                                                            <FontAwesomeIcon color='white' icon={scanningMode?faStop:faPlay}/>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <NumberInput
                                                            required={true}
                                                            value={rfidTag}
                                                            placeholder={scanningMode?label.RFIDTag_OnTheGo:label.RFIDTag_EnterManually_PressScanButtonToRetrieveItFromReaderMachine}
                                                            disabled={scanningMode}
                                                            onChange={(value) => {
                                                                rfidTag = value
                                                                this.setState({rfidTag})
                                                            }}
                                                            onEnter={(value) => {
                                                                rfidTag = value
                                                                if(rfidTag.length<5){
                                                                    validation.rfidTag = false
                                                                    validationMessage.rfidTag = label.LengthDoesntFollowTheRuleOfTag
                                                                    this.setState({rfidTag, validationMessage, validation})
                                                                }else{
                                                                    let goodsItem = {}
                                                                    let exists = goodsItems.some((gi)=>gi.rfidTag === rfidTag)
                                                                    if(!exists){
                                                                        goodsItem.rfidTag = rfidTag
                                                                        goodsItem.goodsItemStatus = Global.GoodsItemStatuses.TAGGED
                                                                        goodsItem.isNew = true
                                                                        goodsItems.push(goodsItem)
                                                                        this.setState({rfidTag:"", goodsItems},() => {
                                                                            setTimeout( ()=>{
                                                                                goodsItem.isNew = false
                                                                                this.setState({goodsItems})
                                                                            }, 2000)
                                                                        })
                                                                    }else{
                                                                        goodsItem = goodsItems.find(gi => gi.rfidTag === rfidTag);
                                                                        goodsItem.isDuplicate = true
                                                                        this.setState({rfidTag:"", goodsItems}, () => {
                                                                            setTimeout( ()=>{
                                                                                goodsItem.isDuplicate = false
                                                                                this.setState({goodsItems})
                                                                            }, 2000)
                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                            isError={!validation.rfidTag}
                                                            errorMessage={validationMessage.rfidTag}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col>
                                        <Card>
                                            <Card.Header className="p-3">{label.NewItem}</Card.Header>
                                            <Card.Body className="p-0">
                                                <Table hover className="table-sm align-items-center">
                                                    <thead>
                                                    <tr>
                                                        <th className="border-bottom">#</th>
                                                        <th className="border-bottom">{label.RFIDTag}</th>
                                                        <th className="border-bottom text-center">{label.Image}</th>
                                                        <th className="border-bottom">{label.Status}</th>
                                                        <th className="border-bottom text-center">{label.Cancel}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {goodsItems.map((goodsItem, index) => (
                                                        <tr className={(goodsItem.isNew?"yellow-background-blinking":"")+" "+(goodsItem.isDuplicate?"red-background-blinking":"")}>
                                                            <td>
                                                                <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
                                                                    {(index+1)}
                                                                </Card.Link>
                                                            </td>
                                                            <td>
                                                                <span className="fw-normal">{goodsItem.rfidTag}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <Button
                                                                    size="sm"
                                                                    variant={isImageExists(goodsItem)?"outline-info":"outline-warning"}
                                                                    onClick={event => {
                                                                        goodsItemImageModal = {
                                                                            ...goodsItemImageModal, position:index, goodsItem:cloneObject(goodsItem), show:true
                                                                        }
                                                                        this.setState({goodsItemImageModal})
                                                                    }}>
                                                                    <FontAwesomeIcon icon={faCamera}/>
                                                                    &nbsp;
                                                                    &nbsp;
                                                                    <FontAwesomeIcon icon={isImageExists(goodsItem)?faCheck:faBorderNone}/>
                                                                </Button>
                                                            </td>
                                                            <td>
                                                                <span className="fw-normal">{goodsItem.goodsItemStatus.label}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <Button
                                                                    size="sm"
                                                                    variant="danger"
                                                                    onClick={event => {
                                                                        goodsItems.splice(index, 1)
                                                                        this.setState({goodsItems})
                                                                    }}>
                                                                    <FontAwesomeIcon icon={faTrash}/>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col className="text-right px-4 pb-2">
                                <ButtonGroup>
                                    <Button
                                        size="sm"
                                        onClick={async (event) => {
                                            await this.submit()
                                        }}>
                                        {label.Save}&nbsp;&nbsp;<FontAwesomeIcon icon={faSave}/>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}
GoodsItemEditModal.propTypes = {
    position:PropTypes.number,
    createGoodsItemModal:PropTypes.object.isRequired,
    onClose:PropTypes.func.isRequired,
    onResult:PropTypes.func.isRequired

}

export default GoodsItemEditModal
