
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container } from '@themesberg/react-bootstrap';

import {BrowserRouter, Link, Route} from 'react-router-dom';

import { Routes } from "../../routes";
import NotFoundImage from "../../assets/img/illustrations/404.svg";
import InvalidLink from "../../assets/img/illustrations/invalid-link.png";
import DashboardOverview from "../dashboard/DashboardOverview";
import BasePage from "../base/BasePage";
import PropTypes from 'prop-types';
import label from 'na-utilities/src/label/Label'
import {PROFILE} from "../../Variable";
import {navigate} from "na-utilities/src/utils/Utilities";
import {AuthConsumer, AuthProvider} from "../../context/AuthContext";
import Preloader from "../../components/Preloader";

class ExceptionPage extends BasePage {

  componentDidMount() {
    super.componentDidMount();
    this.props.closePreLoader()
  }

  render() {
    let {prefix} = this.props.match.params
    let image = NotFoundImage
    let title = "Page not found"
    let description = ""
    if(!prefix || prefix==='' || prefix==='/'){
      image = NotFoundImage
      title = label.PageNotFound
      description = label.PageNotFoundDescription
    }
    if(prefix==='404'){
      image = NotFoundImage
      title = label.PageNotFound
      description = label.PageNotFoundDescription

    }
    if(prefix==='invalid-link'){
      image = InvalidLink
      title = label.InvalidLink
      description = label.InvalidLinkDescription
    }
    return (
        <AuthConsumer>
          {({ isAuth }) => {
            return (
                <main>
                  <section className="vh-100 d-flex align-items-center justify-content-center">
                    <Container>
                      <Row>
                        <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
                          <div>
                            <Card.Link>
                              <Image src={image} style={{width:'320px'}}/>
                            </Card.Link>
                            <h1 className="text-primary mt-3">
                              {title}
                            </h1>
                            <p className="lead my-3">
                              {description}
                            </p>
                            <Button
                                variant="primary"
                                className="animate-hover"
                                onClick={()=>{
                                  navigate(this.props, PROFILE)
                                }}
                            >
                              {
                                isAuth?label.Dashboard:label.HomePage
                              }
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </section>
                </main>
            )
          }}
        </AuthConsumer>
    );
  }
}
export default Object.assign(ExceptionPage, {ENDPOINT : "/error", ENDPOINT_404:"/error/404", ENDPOINT_INVALID_LINK:"/error/invalid-link"})

