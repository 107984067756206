import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome} from '@fortawesome/free-solid-svg-icons';
import {Breadcrumb, Col, Row} from '@themesberg/react-bootstrap';
import BasePage from "../base/BasePage";
import Endpoint from "na-utilities/src/api/Endpoint";
import {isResponseOk} from "na-utilities/src/utils/Utilities";
import {GROUP_NAME} from "../../utils/Global";
import label from "na-utilities/src/label/Label";
import UserCard from "../../components/UserCard";
import DashboardPage from "../dashboard/DashboardPage";
import UserRoleTable from "../../components/UserRoleTable";


class UserRolePage extends BasePage{

  constructor(props) {
    super(props);
    let {userCode} = props.match.params
    this.state = {
      user : {},
      userCode:userCode,
      modules:[],
      roles:[],
      userRoles:[],
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.closePreLoader()
    this.setState({user:{}},() => {
      this.getUserDetail()
    })
  }

  getUserDetail(){
    let {user,userCode,userRoles} = this.state
    this.get(Endpoint.API.USER_BY_USER_CODE, {params:{"userCode":userCode}}, null, (res)=>{
      if(isResponseOk(res)){
        let user = res.data
        let userRoles = user.userRoles
        this.setState({user,userRoles}, () => {
          this.getRoles()
        })
      }
    }, false, false)
  }

  getRoles(){
    let {user,roles,modules} = this.state
    this.get(Endpoint.API.ROLES, {params:{"groupName":GROUP_NAME}}, null, (res)=>{
      if(isResponseOk(res)){
        roles = res.data
        let moduleKeyValue = {}
        roles.forEach((role)=>{
          let module = role.module
          moduleKeyValue[module.id] = module
        })
        const moduleValue = Object.values(moduleKeyValue);
        moduleValue.forEach((value, index) => {
          modules[index] = value
        })
        this.setState({roles,modules})
      }
    }, false, false)
  }

  render(){
    let {user, modules, roles,userRoles} = this.state
    return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                <Breadcrumb.Item href={DashboardPage.ENDPOINT}>{label.Dashboard}</Breadcrumb.Item>
                <Breadcrumb.Item>{label.UserList}</Breadcrumb.Item>
                <Breadcrumb.Item>{label.UserDetail}</Breadcrumb.Item>
                <Breadcrumb.Item active>{label.UserRole}</Breadcrumb.Item>
              </Breadcrumb>
              <h4>{label.Role}&nbsp;{user.fullname}</h4>
            </div>
          </div>

          <Row>
            <Col xs={12} xl={3}>
              <Row>
                <Col xs={12}>
                  <UserCard
                      hideControl={true}
                      user={user}
                      onRefresh={(user)=>{
                        this.setState({user})
                    }}/>
                </Col>
              </Row>
            </Col>
            <Col xs={12} xl={9}>
              <UserRoleTable
                  user={user}
                  modules={modules}
                  roles={roles}
                  userRoles={userRoles}
              />
            </Col>
          </Row>
          {super.render()}
        </>
    );
  };
}
export default Object.assign(UserRolePage, {ENDPOINT : "/userRole"})
