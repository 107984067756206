import React from "react";
import {faCashRegister, faChartLine, faServer} from '@fortawesome/free-solid-svg-icons';
import {Col, Row} from '@themesberg/react-bootstrap';
import BasePage from "../base/BasePage";
import DashboardSmartDeviceTable from "../../components/DashboardSmartDeviceTable";
import label from "na-utilities/src/label/Label";
import OrderCreationDropDown from "../../components/OrderCreationDropDown";
import DashboardProductOrderTable from "../../components/DashboardProductOrderTable";
import {getUser} from "../../utils/StorageUtil";
import DashboardSummaryCard from "../../components/DashboardSummaryCard";
import Endpoint from "na-utilities/src/api/Endpoint";
import DashboardSmartDeviceActivityTable from "../../components/DashboardSmartDeviceActivityTable";
import SockJsClient from 'react-stomp';
import Global from "../../utils/Global";
import DashboardSubscribeAlert from "../../components/DashboardSubscribeAlert";

let dashboardSmartDeviceTableRef = React.createRef()
let dashboardSmartDeviceActivityTable = React.createRef()


class DashboardOverview extends BasePage{

  constructor(props) {
    super(props);
    let userAdminDashboardSummary = {}
    this.state = {
      totalSmartDevice:0,
      smartDeviceProgressPercent:0.0,
      totalUser:0,
      userProgressPercent:0.0,
      totalInventory:0,
      inventoryProgressPercent:0.0,
      remainQuantity:0,
      todayActivityCount:0,
      userAdminDashboardSummary:userAdminDashboardSummary,
    }
  }

  componentDidMount() {
      super.componentDidMount();
      this.props.closePreLoader()
      this.fetchUserAdminDashboardSummary()
  }

    fetchUserAdminDashboardSummary = () => {
        let user = getUser()
        let {userAdminDashboardSummary} = this.state
        this.get(Endpoint.API.USER_ADMIN_DASHBOARD_SUMMARY, {
            params :{
                userId : user.id,
            }
        }, null, response=>{
            if(response.code===200){
                let userAdminDashboardSummary = response.data
                this.setState({userAdminDashboardSummary:userAdminDashboardSummary})
            }else{
                this.props.history.goBack();
            }
        }, false, true)
    }

    refreshDashboard=()=>{
        if(dashboardSmartDeviceTableRef){
            dashboardSmartDeviceTableRef.current.fetchSmartDevices()
        }
        if(dashboardSmartDeviceActivityTable){
            dashboardSmartDeviceActivityTable.current.fetchSmartDeviceActivities()
        }
    }
  render () {
      let user = getUser()
      let company = user.company?user.company:{}
    let {userActivities, userAdminDashboardSummary} = this.state
    return (
        <>
          {super.render()}
          <OrderCreationDropDown
              showProcessing={this.props.showProcessing}
              closeProcessing={this.props.closeProcessing}/>
          <DashboardProductOrderTable {...this.props}/>
          <DashboardSubscribeAlert {...this.props}/>
          <Row className="justify-content-md-center">
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <DashboardSummaryCard
                  category={label.SmartDevice}
                  amount={userAdminDashboardSummary.totalSmartDevice}
                  label1={label.ToolBox}
                  label2={label.VendingMachine}
                  amount1={userAdminDashboardSummary.toolboxCount}
                  amount2={userAdminDashboardSummary.vendingMachineCount}
                  icon={faCashRegister}
                  iconColor="shape-tertiary"
              />
            </Col>
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <DashboardSummaryCard
                  category={label.GoodsInfo}
                  amount={undefined}
                  label1={label.GoodsInfo}
                  label2={label.Item}
                  amount1={userAdminDashboardSummary.goodsInfoCount}
                  amount2={userAdminDashboardSummary.goodsItemCount}
                  icon={faServer}
                  iconColor="shape-tertiary"
              />
            </Col>
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <DashboardSummaryCard
                  category={label.Borrowing}
                  amount={userAdminDashboardSummary.totalBorrowingCount}
                  label1={label.Borrowing}
                  label2={label.Missing}
                  amount1={userAdminDashboardSummary.onBorrowCount}
                  amount2={userAdminDashboardSummary.missingCount}
                  icon={faChartLine}
                  iconColor="shape-secondary"
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} xl={12} className="mb-4">
              <Row>
                <Col xs={12} xl={12} className="mb-4">
                    <Row>
                        <Col xs={12} className="mb-4">
                            <DashboardSmartDeviceTable ref={dashboardSmartDeviceTableRef} />
                        </Col>
                    </Row>
                  <Row>
                    <Col xs={12} className="mb-4">
                      <DashboardSmartDeviceActivityTable ref={dashboardSmartDeviceActivityTable}  />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
              <SockJsClient
                  url={Global.BASE_URL}
                  topics={[Endpoint.API.LISTEN_COMPANY_DEVICE_REFRESH(company.code)]}
                  autoReconnect={true}
                  debug={Global.DEBUG}
                  heartbeat={5000}
                  onMessage={(message) => {
                      // this.successToast(message)
                      this.refreshDashboard()

                  }}
                  onConnectFailure={(object)=>{
                      console.error(object)
                  }}
                  ref={ (client) => { this.clientRef = client }} />

          </Row>
        </>
    );
  };
}

export default Object.assign(DashboardOverview, {MAIN_ENDPOINT : "/", ENDPOINT : "/dashboard"})


