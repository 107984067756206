import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, ButtonGroup, Card, Col, Modal, Row} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import {imageSelector} from "na-utilities/src/utils/Utilities";
import PropTypes from 'prop-types';
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faSave, faTrash, faUpload} from "@fortawesome/free-solid-svg-icons";
import {withRouter} from 'react-router-dom';
import ItemOption from "./input/ItemOption";
import Webcam from "react-webcam";
import MyImage from "./MyImage";


class GoodsItemImageModal extends BaseComponent{

    videoConstraints = {
        width: 1920,
        height: 1080,
        facingMode: "environment" // || user
    }


    constructor(props) {
        super(props);
        let {goodsItemImageModal} = this.props
        let {goodsItem, show, position} = goodsItemImageModal

        this.state = {
            goodsItemImageModal:goodsItemImageModal,
            goodsItem:goodsItem,
            show:show,
            position:position,
            availableMedias:[],
            selectedMedia1Position : 0,
            selectedMedia2Position : 0,
            selectedMedia3Position : 0,
            selectedMedia4Position : 0,
            selectedMedia1:{},
            selectedMedia2:{},
            selectedMedia3:{},
            selectedMedia4:{},
        }
    }

    availableMedia = () =>{
        let media = {
            id:undefined,
            label:"",
            kind:'',
            deviceId:"",
            mediaStreamTrack:{},
            mediaDeviceInfo:{},
        }
        return media;
    }

    mediaStorage = () =>{
        let availableMedia = this.availableMedia()
        availableMedia.id = 1
        availableMedia.label = label.MediaStorage
        availableMedia.kind = 'storage'
        availableMedia.deviceId = ''
        return availableMedia
    }

    componentDidMount() {
        super.componentDidMount();
        let availableMedias = []
        availableMedias.push(this.mediaStorage())
        if(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices){
            navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
                mediaDevices.forEach((mediaDevice, index)=>{
                    if(mediaDevice.kind==="videoinput"){
                        let availableMedia = this.availableMedia()
                        availableMedia.label = mediaDevice.label
                        availableMedia.kind = mediaDevice.kind
                        availableMedia.id = index+1
                        availableMedia.deviceId = mediaDevice.deviceId
                        availableMedia.mediaDeviceInfo = mediaDevice
                        availableMedias.push(availableMedia)
                        console.log("MEDIA DEVICE ID "+mediaDevice.deviceId)
                    }
                })
            })
        }else if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia){
            let props = this
            navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment"} }).then(function (stream) {
                let videoTracks = stream.getVideoTracks();
                videoTracks.forEach((videoTrack, index) => {
                    let availableMedia = props.availableMedia()
                    availableMedia.label = videoTrack.label
                    availableMedia.kind = videoTrack.kind
                    availableMedia.id = index+1
                    availableMedia.deviceId = videoTrack.getSettings().deviceId
                    availableMedia.mediaStreamTrack = videoTrack
                    availableMedias.push(availableMedia)
                    console.log("DEVICE ID "+JSON.stringify(videoTrack.label))
                })
            });
        }
        this.setState({availableMedias:availableMedias})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let {goodsItemImageModal} = this.props
            let {goodsItem, show, position} = goodsItemImageModal
            this.setState({
                goodsItemImageModal:goodsItemImageModal,
                goodsItem:goodsItem,
                show:show,
                position:position,
                selectedMedia1Position : 0,
                selectedMedia2Position : 0,
                selectedMedia3Position : 0,
                selectedMedia4Position : 0,
                selectedMedia1:{},
                selectedMedia2:{},
                selectedMedia3:{},
                selectedMedia4:{},
            })
        }

    }

    save(){
        let {goodsItem, goodsItemImageModal} = this.state
        goodsItem.isNew = false
        goodsItem.isDuplicate = false
        goodsItemImageModal.goodsItem = goodsItem
        if(this.props.onResult){
            this.props.onResult(goodsItemImageModal)
        }
    }


    render() {
        let {
            goodsItem, show, position, availableMedias,
            selectedMedia1Position, selectedMedia1,
            selectedMedia2Position, selectedMedia2,
            selectedMedia3Position, selectedMedia3,
            selectedMedia4Position, selectedMedia4
        } = this.state
        return (
            <>
                {super.render()}
                <Modal as={Modal.Dialog} size="xl" centered show={show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.RFIDTag+" : "+goodsItem.rfidTag+" - "+label.RegisterImage}</Modal.Title>
                        <Button
                            variant="close"
                            aria-label="Close" onClick={()=>{
                            this.props.onClose()
                        }} />
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-4">
                            <Col>
                                <Card border="light" className="p-2">
                                    <Row>
                                        <Col className="p-3 d-flex justify-content-start align-items-center" style={{width:265,height:265}}>
                                            {
                                                (selectedMedia1&&selectedMedia1.kind === 'videoinput' && !goodsItem.imageLink1)?(
                                                    <Webcam
                                                        audio={false}
                                                        width={"100%"}
                                                        height={"100%"}
                                                        ref={(cam)=> { this.cam1 = cam }}
                                                        screenshotFormat="image/jpeg"
                                                        screenshotQuality={1}
                                                        minScreenshotWidth={1920}
                                                        minScreenshotHeight={1080}
                                                        imageSmoothing={true}
                                                        style={{
                                                            backgroundImage:'url('+ApplicationIcons.Gifs.flash+')',
                                                            backgroundSize:'72px 72px',
                                                            backgroundRepeat:'no-repeat',
                                                            backgroundPosition:'center'
                                                        }}
                                                        videoConstraints={{deviceId:selectedMedia1.deviceId? selectedMedia1.deviceId:undefined, ...this.videoConstraints}}
                                                    />
                                                ):
                                                (
                                                    <MyImage
                                                        src={goodsItem.imageLink1?goodsItem.imageLink1:ApplicationIcons.Icons.imagePlaceholder}
                                                        onClick={(e)=>{
                                                            this.openLightBox(goodsItem.imageLink1)
                                                        }}
                                                    />
                                                )
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ItemOption
                                                objects={availableMedias}
                                                label={label.TakingPictureMedia}
                                                fieldForLabel={"label"}
                                                hideOptionLabel={true}
                                                value={selectedMedia1}
                                                callback={(value) => {
                                                    if(value!=selectedMedia1){
                                                        goodsItem.imageLink1 = undefined
                                                    }
                                                    this.setState({
                                                        selectedMedia1:value, goodsItem
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right">
                                            <ButtonGroup>
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={event => {
                                                        goodsItem.imageLink1 = undefined
                                                        this.setState({goodsItem})
                                                    }}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>
                                                <Button
                                                    variant="outline-info"
                                                    size="sm"
                                                    onClick={event => {
                                                        if(selectedMedia1.kind==='videoinput'){
                                                            if(this.cam1) {
                                                                let resultBase64Image = this.cam1.getScreenshot();
                                                                if(resultBase64Image){
                                                                    this.openCropper(resultBase64Image, (blob) => {
                                                                        goodsItem.imageLink1 = blob
                                                                        this.setState({
                                                                            goodsItem: goodsItem
                                                                        }, () => {
                                                                            this.closeCropper()
                                                                        })
                                                                    }, () => {
                                                                        goodsItem.imageLink1 = undefined
                                                                        this.setState({goodsItem}, () => {
                                                                            this.closeCropper()
                                                                        })
                                                                    })
                                                                }
                                                            }
                                                        }else {
                                                            imageSelector((file) => {
                                                                this.openCropper(file, (blob) => {
                                                                    goodsItem.imageLink1 = blob
                                                                    this.setState({
                                                                        goodsItem: goodsItem
                                                                    }, () => {
                                                                        this.closeCropper()
                                                                    })
                                                                }, () => {
                                                                    goodsItem.imageLink1 = undefined
                                                                    this.setState({goodsItem}, () => {
                                                                        this.closeCropper()
                                                                    })
                                                                })
                                                            }).click()
                                                        }
                                                    }}>
                                                    <FontAwesomeIcon icon={selectedMedia1&&selectedMedia1.kind === 'videoinput'?faCamera:faUpload}/>
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col>
                                <Card border="light" className="p-2">
                                    <Row>
                                        <Col className="p-3 d-flex justify-content-start align-items-center" style={{width:265,height:265}}>
                                            {
                                                (selectedMedia2&&selectedMedia2.kind === 'videoinput' && !goodsItem.imageLink2)?(
                                                        <Webcam
                                                            audio={false}
                                                            width={"100%"}
                                                            height={"100%"}
                                                            ref={(cam)=> { this.cam2 = cam }}
                                                            screenshotFormat="image/jpeg"
                                                            screenshotQuality={1}
                                                            minScreenshotWidth={1920}
                                                            minScreenshotHeight={1080}
                                                            imageSmoothing={true}
                                                            style={{
                                                                backgroundImage:'url('+ApplicationIcons.Gifs.flash+')',
                                                                backgroundSize:'72px 72px',
                                                                backgroundRepeat:'no-repeat',
                                                                backgroundPosition:'center'
                                                            }}
                                                            videoConstraints={{deviceId:selectedMedia2.deviceId? selectedMedia2.deviceId:undefined, ...this.videoConstraints}}
                                                        />
                                                    ):
                                                    (
                                                        <MyImage
                                                            src={goodsItem.imageLink2?goodsItem.imageLink2:ApplicationIcons.Icons.imagePlaceholder}
                                                            onClick={(e)=>{
                                                                this.openLightBox(goodsItem.imageLink2)
                                                            }}
                                                        />
                                                    )
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ItemOption
                                                objects={availableMedias}
                                                label={label.TakingPictureMedia}
                                                fieldForLabel={"label"}
                                                hideOptionLabel={true}
                                                value={selectedMedia2}
                                                callback={(value) => {
                                                    if(value!=selectedMedia2){
                                                        goodsItem.imageLink2 = undefined
                                                    }
                                                    this.setState({
                                                        selectedMedia2:value, goodsItem
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right">
                                            <ButtonGroup>
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={event => {
                                                        goodsItem.imageLink2 = undefined
                                                        this.setState({goodsItem})
                                                    }}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>
                                                <Button
                                                    variant="outline-info"
                                                    size="sm"
                                                    onClick={event => {
                                                        if(selectedMedia2.kind==='videoinput'){
                                                            if(this.cam2) {
                                                                let resultBase64Image = this.cam2.getScreenshot();
                                                                if(resultBase64Image){
                                                                    this.openCropper(resultBase64Image, (blob) => {
                                                                        goodsItem.imageLink2 = blob
                                                                        this.setState({
                                                                            goodsItem: goodsItem
                                                                        }, () => {
                                                                            this.closeCropper()
                                                                        })
                                                                    }, () => {
                                                                        goodsItem.imageLink2 = undefined
                                                                        this.setState({goodsItem}, () => {
                                                                            this.closeCropper()
                                                                        })
                                                                    })
                                                                }
                                                            }
                                                        }else {
                                                            imageSelector((file) => {
                                                                this.openCropper(file, (blob) => {
                                                                    goodsItem.imageLink2 = blob
                                                                    this.setState({
                                                                        goodsItem: goodsItem
                                                                    }, () => {
                                                                        this.closeCropper()
                                                                    })
                                                                }, () => {
                                                                    goodsItem.imageLink2 = undefined
                                                                    this.setState({goodsItem}, () => {
                                                                        this.closeCropper()
                                                                    })
                                                                })
                                                            }).click()
                                                        }
                                                    }}>
                                                    <FontAwesomeIcon icon={selectedMedia2&&selectedMedia2.kind === 'videoinput'?faCamera:faUpload}/>
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col>
                                <Card border="light" className="p-2">
                                    <Row>
                                        <Col className="p-3 d-flex justify-content-start align-items-center" style={{width:265,height:265}}>
                                            {
                                                (selectedMedia3&&selectedMedia3.kind === 'videoinput' && !goodsItem.imageLink3)?(
                                                        <Webcam
                                                            audio={false}
                                                            width={"100%"}
                                                            height={"100%"}
                                                            ref={(cam)=> { this.cam3 = cam }}
                                                            screenshotFormat="image/jpeg"
                                                            screenshotQuality={1}
                                                            minScreenshotWidth={1920}
                                                            minScreenshotHeight={1080}
                                                            imageSmoothing={true}
                                                            style={{
                                                                backgroundImage:'url('+ApplicationIcons.Gifs.flash+')',
                                                                backgroundSize:'72px 72px',
                                                                backgroundRepeat:'no-repeat',
                                                                backgroundPosition:'center'
                                                            }}
                                                            videoConstraints={{deviceId:selectedMedia3.deviceId? selectedMedia3.deviceId:undefined, ...this.videoConstraints}}
                                                        />
                                                    ):
                                                    (
                                                        <MyImage
                                                            src={goodsItem.imageLink3?goodsItem.imageLink3:ApplicationIcons.Icons.imagePlaceholder}
                                                            onClick={(e)=>{
                                                                this.openLightBox(goodsItem.imageLink3)
                                                            }}
                                                        />
                                                    )
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ItemOption
                                                objects={availableMedias}
                                                label={label.TakingPictureMedia}
                                                fieldForLabel={"label"}
                                                hideOptionLabel={true}
                                                value={selectedMedia3}
                                                callback={(value) => {
                                                    if(value!=selectedMedia3){
                                                        goodsItem.imageLink3 = undefined
                                                    }
                                                    this.setState({
                                                        selectedMedia3:value, goodsItem
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right px-4 pb-2">
                                            <ButtonGroup>
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={event => {
                                                        goodsItem.imageLink3 = undefined
                                                        this.setState({goodsItem})
                                                    }}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>
                                                <Button
                                                    variant="outline-info"
                                                    size="sm"
                                                    onClick={event => {
                                                        if(selectedMedia3.kind==='videoinput'){
                                                            if(this.cam3) {
                                                                let resultBase64Image = this.cam3.getScreenshot();
                                                                if(resultBase64Image){
                                                                    this.openCropper(resultBase64Image, (blob) => {
                                                                        goodsItem.imageLink3 = blob
                                                                        this.setState({
                                                                            goodsItem: goodsItem
                                                                        }, () => {
                                                                            this.closeCropper()
                                                                        })
                                                                    }, () => {
                                                                        goodsItem.imageLink3 = undefined
                                                                        this.setState({goodsItem}, () => {
                                                                            this.closeCropper()
                                                                        })
                                                                    })
                                                                }
                                                            }
                                                        }else {
                                                            imageSelector((file) => {
                                                                this.openCropper(file, (blob) => {
                                                                    goodsItem.imageLink3 = blob
                                                                    this.setState({
                                                                        goodsItem: goodsItem
                                                                    }, () => {
                                                                        this.closeCropper()
                                                                    })
                                                                }, () => {
                                                                    goodsItem.imageLink3 = undefined
                                                                    this.setState({goodsItem}, () => {
                                                                        this.closeCropper()
                                                                    })
                                                                })
                                                            }).click()
                                                        }
                                                    }}>
                                                    <FontAwesomeIcon icon={selectedMedia3&&selectedMedia3.kind === 'videoinput'?faCamera:faUpload}/>
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col>
                                <Card border="light" className="p-2">
                                    <Row>
                                        <Col className="p-3 d-flex justify-content-start align-items-center" style={{width:265,height:265}}>
                                            {
                                                (selectedMedia4&&selectedMedia4.kind === 'videoinput' && !goodsItem.imageLink4)?(
                                                        <Webcam
                                                            audio={false}
                                                            width={"100%"}
                                                            height={"100%"}
                                                            ref={(cam)=> { this.cam4 = cam }}
                                                            screenshotFormat="image/jpeg"
                                                            screenshotQuality={1}
                                                            minScreenshotWidth={1920}
                                                            minScreenshotHeight={1080}
                                                            imageSmoothing={true}
                                                            style={{
                                                                backgroundImage:'url('+ApplicationIcons.Gifs.flash+')',
                                                                backgroundSize:'72px 72px',
                                                                backgroundRepeat:'no-repeat',
                                                                backgroundPosition:'center'
                                                            }}
                                                            videoConstraints={{deviceId:selectedMedia4.deviceId? selectedMedia4.deviceId:undefined, ...this.videoConstraints}}
                                                        />
                                                    ):
                                                    (
                                                        <MyImage
                                                            src={goodsItem.imageLink4?goodsItem.imageLink4:ApplicationIcons.Icons.imagePlaceholder}
                                                            onClick={(e)=>{
                                                                this.openLightBox(goodsItem.imageLink4)
                                                            }}
                                                        />
                                                    )
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ItemOption
                                                objects={availableMedias}
                                                label={label.TakingPictureMedia}
                                                fieldForLabel={"label"}
                                                hideOptionLabel={true}
                                                value={selectedMedia4}
                                                callback={(value) => {
                                                    if(value!=selectedMedia4){
                                                        goodsItem.imageLink4 = undefined
                                                    }
                                                    this.setState({
                                                        selectedMedia4:value, goodsItem
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right px-4 pb-2">
                                            <ButtonGroup>
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={event => {
                                                        goodsItem.imageLink3 = undefined
                                                        this.setState({goodsItem})
                                                    }}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>
                                                <Button
                                                    variant="outline-info"
                                                    size="sm"
                                                    onClick={event => {
                                                        if(selectedMedia4.kind==='videoinput'){
                                                            if(this.cam4) {
                                                                let resultBase64Image = this.cam4.getScreenshot();
                                                                if(resultBase64Image){
                                                                    this.openCropper(resultBase64Image, (blob) => {
                                                                        goodsItem.imageLink4 = blob
                                                                        this.setState({
                                                                            goodsItem: goodsItem
                                                                        }, () => {
                                                                            this.closeCropper()
                                                                        })
                                                                    }, () => {
                                                                        goodsItem.imageLink4 = undefined
                                                                        this.setState({goodsItem}, () => {
                                                                            this.closeCropper()
                                                                        })
                                                                    })
                                                                }
                                                            }
                                                        }else {
                                                            imageSelector((file) => {
                                                                this.openCropper(file, (blob) => {
                                                                    goodsItem.imageLink4 = blob
                                                                    this.setState({
                                                                        goodsItem: goodsItem
                                                                    }, () => {
                                                                        this.closeCropper()
                                                                    })
                                                                }, () => {
                                                                    goodsItem.imageLink4 = undefined
                                                                    this.setState({goodsItem}, () => {
                                                                        this.closeCropper()
                                                                    })
                                                                })
                                                            }).click()
                                                        }
                                                    }}>
                                                    <FontAwesomeIcon icon={selectedMedia4&&selectedMedia4.kind === 'videoinput'?faCamera:faUpload}/>
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right px-4 pb-2">
                                <ButtonGroup>
                                    <Button
                                        size="sm"
                                        onClick={ (event) => {
                                            this.save()
                                        }}>
                                        {label.Save}&nbsp;&nbsp;<FontAwesomeIcon icon={faSave}/>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}
GoodsItemImageModal.propTypes = {
    goodsItemImageModal:PropTypes.object.isRequired,
    onClose:PropTypes.func.isRequired,
    onResult:PropTypes.func.isRequired
}

export default withRouter(GoodsItemImageModal)
