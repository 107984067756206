import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Card, FormCheck, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import PropTypes from "prop-types";
import {cloneObject, isResponseOk} from "na-utilities/src/utils/Utilities";
import Endpoint from "na-utilities/src/api/Endpoint";


class UserRoleTable extends BaseComponent{

    constructor(props) {
        super(props);
        let {userRoles} = this.props
        let checkedRole = {}
        this.state = {
            checkedRole:checkedRole
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let {checkedRole} = this.state
        let previousUserRoles = this.props.userRoles
        let {userRoles} = nextProps
        if(previousUserRoles!==userRoles) {
            userRoles.forEach((userRole) => {
                checkedRole[userRole.role.id] = userRole.checked
            })
        }
        this.setState({checkedRole})
    }

    updateSelection(newCheckedRole){
        let valid = false ;
        let {roles} = this.props
        let {checkedRole} = this.state
        roles.forEach((role)=>{
            console.log(role.name+":"+newCheckedRole[role.id])
            if(newCheckedRole[role.id]===true){
                valid = true
            }
        })
        if(valid===true){
            this.updateUserRole(newCheckedRole)
        }else{
            this.setState({checkedRole:checkedRole},() => {
                alert(label.SelectAtLeastOneRole)
            })
        }
    }

    updateUserRole(newCheckedRole){
        let {user} = this.props
        let previousCheckedRole = cloneObject(this.state.checkedRole)
        this.setState({checkedRole:newCheckedRole},() => {
            let form = new FormData()
            form.append("userId", user.id)
            form.append("mapGson", JSON.stringify(newCheckedRole))
            this.post(Endpoint.API.USER_ROLE_UPDATE, form, null, (res)=>{
                if(isResponseOk(res)){

                }else{
                    this.setState({checkedRole:previousCheckedRole})
                }
            }, true, true)
        })
    }

    renderRoles(module){
        let {roles} = this.props
        let {checkedRole} = this.state
        checkedRole = cloneObject(checkedRole)
        return (
            <>
                {
                    roles.map((role, index)=>{
                        return(
                            role.module.id===module.id?(
                                <tr key={index}>
                                    <th scope="row">{role.name}</th>
                                    <td className="text-right">
                                        <FormCheck.Input
                                            checked={checkedRole[role.id]}
                                            style={{position:'unset', marginTop:0, marginLeft:0}}
                                            onClick={()=>{
                                                roles.forEach((item)=>{
                                                    if(item.module.id===module.id && role.id != item.id){
                                                        checkedRole[item.id] = false
                                                    }
                                                })
                                                checkedRole[role.id] = checkedRole[role.id]===true?false:true
                                                this.updateSelection(checkedRole)
                                            }}
                                        />
                                    </td>
                                </tr>
                            ):undefined
                        )
                    })
                }
            </>
        )
    }

    render() {
        let {user, modules,roles} = this.props
        return (
            <div>
                <Card border="light" className="shadow-sm">
                    <Card.Header>
                        <h5>{label.Role}</h5>
                    </Card.Header>
                    {
                        modules.map(module=>{
                            return(
                                <Table key={module.id} responsive className="align-items-center table-flush">
                                    <thead className="thead-light">
                                    <tr>
                                        <th colSpan={"4"}>{label.Module}&nbsp;{module.name}</th>
                                    </tr>
                                    </thead>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">{label.Name}</th>
                                        <th scope="col" className="text-right">{label.Select}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.renderRoles(module)
                                    }
                                    </tbody>
                                </Table>
                            )
                        })
                    }
                </Card>
            </div>
        );
    }

}

UserRoleTable.propTypes = {
    user : PropTypes.any.isRequired,
    modules : PropTypes.array.isRequired,
    roles : PropTypes.array.isRequired,
    userRoles:PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
}

export default UserRoleTable