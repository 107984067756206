import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressBook,
    faBuilding,
    faEnvelope,
    faGlobe,
    faMobile,
    faPhone,
    faRandom,
    faUnlockAlt,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import {Button, Card, Col, Container, Form, FormCheck, InputGroup, Modal, Row} from '@themesberg/react-bootstrap';
import label from 'na-utilities/src/label/Label'
import {
    cleanNonAlphabet,
    cleanWebsiteDomain,
    firstLetterUppercase,
    isEmpty,
    isResponseOk,
    isValidUrl,
    navigate
} from "na-utilities/src/utils/Utilities";
import EmailInput from "../../components/input/EmailInput";
import TextInput from "../../components/input/TextInput";
import PasswordInput from "../../components/input/PasswordInput";
import PhoneNumberInput from "../../components/input/PhoneNumberInput";
import ItemOption from "../../components/input/ItemOption";
import {RegistrationVerification} from "../../model/model.ts";
import OtpInput from "react-otp-input";
import "./RegistrationVerification.css"
import otpVerification from "../../assets/img/otp-verification.png"
import registrationConfirmation from "../../assets/img/registration-confirmation.png"
import arrowRight from "../../assets/img/arrow-right.png"

import {initializeApp} from 'firebase/app';
import {getAuth, RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import BaseFormPage from "../base/BaseFormPage";
import {toasting} from "../base/BaseComponent";
import NotFoundPage from "./ExceptionPage";
import {isValidNumber, parsePhoneNumber} from "libphonenumber-js";
import RegistrationSuccessPage from "./RegistrationSuccessPage";
import Endpoint from "na-utilities/src/api/Endpoint";
// import { useHistory, useNavigate } from "react-router-dom";

var firebaseConfig = {
    apiKey: "AIzaSyCkzYFbFWyMRUB7Hs-4aYiZ33a3gNC16PA",
    authDomain: "smart-supplies-7c1e2.firebaseapp.com",
    projectId: "smart-supplies-7c1e2",
    storageBucket: "smart-supplies-7c1e2.appspot.com",
    messagingSenderId: "436766394023",
    appId: "1:436766394023:web:772b14a0d4c45b13ffbd82",
    firebaseAuthEmulatorHost:"http://127.0.0.1:8014/"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.languageCode = 'it';


// let {registrationCode} = useParams()

class RegistrationVerificationPage extends BaseFormPage{
  constructor(props) {
    super(props);
    let validation = this.defaultValidation()
    let validationMessage = this.defaultValidationMessage()
    this.state = {
        registration: {},
        registrationVerification: new RegistrationVerification(),
        validation,
        validationMessage,
        otpVerificationValue:"",
        otpModal:false,
        registrationConfirmationModal:false,
        agreeWithTermAndCondition:false,
        disableSubmitButton:true,
        phoneNumberForOtp:""
    }
    // alert(JSON.stringify(this.props))
  }

  defaultValidation = () =>{
      let validation = new RegistrationVerification();
      validation.username = true ;
      validation.password = true ;
      validation.confirmPassword = true
      validation.firstName = true
      validation.lastName = true
      validation.homePhoneNumber = true
      validation.mobilePhone = true
      validation.companyName  = true ;
      validation.website = true
      validation.companyPhoneNumber = true
      validation.industry = true ;
      validation.companyType = true
      validation.address = true ;
      validation.province = true ;
      validation.city = true
      validation.district = true ;
      validation.village = true
      return validation ;
  }
    defaultValidationMessage = () =>{
        let validationMessage = new RegistrationVerification();
        validationMessage.username = "" ;
        validationMessage.password = "" ;
        validationMessage.confirmPassword = ""
        validationMessage.firstName = ""
        validationMessage.lastName = ""
        validationMessage.homePhoneNumber = ""
        validationMessage.mobilePhone = ""
        validationMessage.companyName  = "" ;
        validationMessage.website = ""
        validationMessage.companyPhoneNumber = ""
        validationMessage.industry = "" ;
        validationMessage.companyType = ""
        validationMessage.address = "" ;
        validationMessage.province = "" ;
        validationMessage.city = ""
        validationMessage.district = "" ;
        validationMessage.village = ""
        return validationMessage ;
    }

  componentDidMount() {
    super.componentDidMount();
    this.fetchProvinces()
    this.fetchIndustries()
    this.fetchCompanyTypes()
    this.fetchRegistrationData()
  }

  async validateFormSubmit() {
      await super.validateFormSubmit();
      let {registrationVerification, validation, validationMessage} = this.state
      let valid = true ;
      validation = this.defaultValidation()
      validationMessage = this.defaultValidationMessage()
      if(isEmpty(registrationVerification.username)){
          validation.username = false
          validationMessage.username = label.EnterUsername
          valid = false ;
      }
      valid = await this.validateUsername(registrationVerification.email, registrationVerification.username, false)
      if(!valid){
          validation.username = false
          validationMessage.username = label.UsernameHasAlreadyBeenUsed
          valid = false ;
      }
      if(isEmpty(registrationVerification.password)){
          validation.password = false
          validationMessage.password = label.EnterPassword
          valid = false ;
      }
      if(isEmpty(registrationVerification.confirmPassword)){
          validation.confirmPassword = false
          validationMessage.confirmPassword = label.EnterConfirmPassword
          valid = false ;
      }

      if(registrationVerification.password && registrationVerification.confirmPassword && registrationVerification.password !== registrationVerification.confirmPassword){
          validation.confirmPassword = false
          validationMessage.confirmPassword = label.ConfirmPasswordAndNewPasswordIsNotSame
          valid = false ;
      }
      if(isEmpty(registrationVerification.firstName) || registrationVerification.firstName.length <=2){
          validation.firstName = false
          validationMessage.firstName = label.PleaseEnterFirstName
          valid = false ;
      }
      if(!isEmpty(registrationVerification.homePhoneNumber) && registrationVerification.homePhoneNumber.length <=2){
          validation.homePhoneNumber = false
          validationMessage.homePhoneNumber = label.PleaseEnterPhoneNumberCorrectly
          valid = false ;
      }
      if(!isEmpty(registrationVerification.homePhoneNumber) && !isValidNumber(registrationVerification.homePhoneNumber)){
          validation.homePhoneNumber = false
          validationMessage.homePhoneNumber = label.PleaseEnterPhoneNumberCorrectly
          valid = false ;
      }
      if(isEmpty(registrationVerification.mobilePhone) || registrationVerification.mobilePhone.length <=2){
          validation.mobilePhone = false
          validationMessage.mobilePhone = label.PleaseEnterARightMobilePhone
          valid = false ;
      }
      if(!isValidNumber(registrationVerification.mobilePhone, 'ID')){
          validation.mobilePhone = false
          validationMessage.mobilePhone = label.PleaseEnterARightMobilePhone
          valid = false ;
      }
      if(isEmpty(registrationVerification.companyName) || registrationVerification.companyName.length <=2){
          validation.companyName = false
          validationMessage.companyName = label.PleaseEnterCompanyNameCorrectly
          valid = false ;
      }
      if(!isEmpty(registrationVerification.website) && registrationVerification.website.length <=2){
          validation.website = false
          validationMessage.website = label.PleaseEnterWebsiteCorrectly
          valid = false ;
      }
      if(isEmpty(registrationVerification.companyPhoneNumber) || registrationVerification.companyPhoneNumber.length <=2){
          validation.companyPhoneNumber = false
          validationMessage.companyPhoneNumber = label.PleaseEnterPhoneNumberCorrectly
          valid = false ;
      }
      if(!isValidNumber(registrationVerification.companyPhoneNumber, 'ID')){
          validation.companyPhoneNumber = false
          validationMessage.companyPhoneNumber = label.PleaseEnterPhoneNumberCorrectly
          valid = false ;
      }
      if(isEmpty(registrationVerification.industry)){
          validation.industry = false
          validationMessage.industry = label.PleaseSelectIndustry
          valid = false ;
      }
      if(isEmpty(registrationVerification.companyType)){
          validation.companyType = false
          validationMessage.companyType = label.PleaseSelectCompanyType
          valid = false ;
      }
      if(isEmpty(registrationVerification.address) || registrationVerification.address.length <=2){
          validation.address = false
          validationMessage.address = label.PleaseEnterAddressCorrectly
          valid = false ;
      }
      if(isEmpty(registrationVerification.province)){
          validation.province = false
          validationMessage.province = label.PleaseSelectProvince
          valid = false ;
      }
      if(isEmpty(registrationVerification.city)){
          validation.city = false
          validationMessage.city = label.PleaseSelectCity
          valid = false ;
      }
      if(isEmpty(registrationVerification.district)){
          validation.district = false
          validationMessage.district = label.PleaseSelectDistrict
          valid = false ;
      }
      if(isEmpty(registrationVerification.village)){
          validation.village = false
          validationMessage.village = label.PleaseSelectVillage
          valid = false ;
      }
      this.setState({registrationConfirmationModal:valid, validation, validationMessage}, () => {
          if(valid){

          }
      })
  }

    componentDidUpdate(prevProps, prevState, snapshot) {
      let previousRegistrationConfirmationModal = prevState.registrationConfirmationModal
      let {registrationConfirmationModal} = this.state
      if(!previousRegistrationConfirmationModal && registrationConfirmationModal){
          // alert("Test")
          window.recaptchaVerifier = new RecaptchaVerifier('register-button', {
              'size': 'invisible',
              'callback': (response) => {
                  // reCAPTCHA solved, allow signInWithPhoneNumber.
                  // alert("reCAPTCHA solved")
                  // this.onStartingRegistration();
              }
          }, auth);
          // this.onStartingRegistration();
      }
  }


  onStartingRegistration(){
      let {registrationVerification} = this.state
      let correctMobilePhone = registrationVerification.mobilePhone
      // this.props.openPreLoader()
      let phoneNumber = parsePhoneNumber(correctMobilePhone, 'ID')
      let phoneNumberForOtp = phoneNumber.number
      this.setState({
          registrationConfirmationModal:false,
          phoneNumberForOtp,
          otpVerificationValue:"",
          otpModal:true
      }, () => {
          signInWithPhoneNumber(auth, phoneNumberForOtp, window.recaptchaVerifier)
              .then((confirmationResult) => {
                  // this.props.closePreLoader()
                  // alert("Success")
                  window.confirmationResult = confirmationResult;
                  // this.setState({otpModal:false, phoneNumberForOtp})
              }).catch((error) => {
                  // alert(error)
                toasting.error(error)
              // this.props.closePreLoader()
              // Error; SMS not sent
              // ...
          });
      })
  }


  fetchRegistrationData(){
    let {registrationCode} = this.props.match.params
    let {registration, registrationVerification } = this.state
    this.props.openPreLoader()
    this.get(Endpoint.API.REGISTRATION_CHECK, {params:{registrationCode:registrationCode}}, null, (response)=>{
        if(isResponseOk(response)){
            registration = response.data
            registrationVerification.email = registration.email
            registrationVerification.firstName = registration.firstname
            registrationVerification.lastName = registration.lastname
            registrationVerification.companyName = registration.companyName

            /*
            * Dummy Temporary
            * */
            // registrationVerification.password = "1q2w3e"
            // registrationVerification.confirmPassword = "1q2w3e"
            // registrationVerification.mobilePhone = "081219564551"
            // registrationVerification.companyPhoneNumber = "0183919070"

            registrationVerification.password = ""
            registrationVerification.confirmPassword = ""
            registrationVerification.mobilePhone = ""
            registrationVerification.companyPhoneNumber = ""

            this.setState({registration, registrationVerification}, () => {
                this.generateUsername(registrationVerification.email, false, (username)=>{
                    registrationVerification.username = username
                    this.setState({
                        registrationVerification:registrationVerification
                    })
                })
            })
            this.props.closePreLoader()
        }else{
            setTimeout(()=>{
                navigate(this.props, NotFoundPage.ENDPOINT_INVALID_LINK)
                this.props.closePreLoader()
            }, 2000)
        }
    }, false, true)
  }

    async submit(){
        let {registrationCode} = this.props.match.params
        let {registration, registrationVerification } = this.state
        // let form = new FormData()
        // form.append("registrationCode",registrationCode)
        // form.append("registrationVerificationGson", JSON.stringify(registrationVerification))
        let response = await this.asyncPost(Endpoint.API.REGISTRATION_CONFIRMATION_SUBMIT+"/"+registrationCode, registrationVerification, null, false, true)
        this.props.closePreLoader()
        if(isResponseOk(response)){
            registration = response.data
            navigate(this.props, RegistrationSuccessPage.ENDPOINT)
        }else{
            toasting.error(response.message)
        }
    }


    render(){
    let {validation, validationMessage, registrationVerification, provinces, cities,
        districts, villages, industries, companyTypes, registrationConfirmationModal, otpModal, otpVerificationValue,
        agreeWithTermAndCondition, disableSubmitButton, phoneNumberForOtp } = this.state


    return (
        <main>
            {super.render()}
            <React.Fragment>
                <Modal as={Modal.Dialog} centered show={registrationConfirmationModal} onHide={()=>{}}>
                    <Modal.Header style={{display:"block",textAlign:'center'}}>
                        <h4>{label.RegistrationConfirmation}</h4>
                    </Modal.Header>
                    <Modal.Body style={{display:"block",textAlign:'center'}} className="m-4">
                        <Row>
                            <Col xs={3}>
                                <img src={registrationConfirmation}></img>
                            </Col>
                            <Col xs={6}>
                                <img width="40%" src={arrowRight}></img>
                            </Col>
                            <Col xs={3}>
                                <img src={otpVerification}></img>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Body style={{display:"block",textAlign:'center'}}>
                        <p>{label.PleaseMakeSureYouHaveFulfillTheFormWithCorrectValueWeWillSendToYouOTPCodeToVerifyYourPhoneNumber_PleaseHoldYourPhoneCloseToYouAndVerifyYourPhoneNumberAtTheNextStep}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="cancel" onClick={event => {
                            this.setState({registrationConfirmationModal:false})
                        }}>
                            {label.Cancel}
                        </Button>
                        <Button variant="success" className="ms-auto"
                                onClick={()=>{
                                    this.onStartingRegistration();
                                }}>
                            {label.Continue}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>

            <React.Fragment>
                <Modal as={Modal.Dialog} centered show={otpModal} onHide={()=>{}}>
                    <Modal.Header style={{display:"block",textAlign:'center'}}>
                        <h4>{label.VerifyYourMobilePhone}</h4>
                    </Modal.Header>
                    <Modal.Body style={{display:"block",textAlign:'center'}}>
                        <img width="30%" src={otpVerification}></img>
                        <p>{label.OTPCodeSentToYourMobilePhone(phoneNumberForOtp)}</p>
                        <OtpInput
                            value={otpVerificationValue}
                            onChange={(otpVerificationValue)=>{
                                disableSubmitButton = otpVerificationValue.length<6
                                this.setState({otpVerificationValue, disableSubmitButton})
                            }}
                            numInputs={6}
                            containerStyle={"row otp-input"}
                            inputStyle={"form-control"}
                            separator={<span> </span>}
                        />
                    </Modal.Body>
                    <Modal.Body style={{display:"block",textAlign:'center'}}>
                        <p>{label.WeAreDoingVerifyYourMobilePhone_PleaseFillThisFormWithTheOTPCodeThatWeSendToYourMobilePhone}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="cancel" onClick={()=>{
                            this.setState({otpModal:false})
                        }}>
                            {label.Cancel}
                        </Button>
                        <Button disabled={disableSubmitButton} variant="success" className="ms-auto" onClick={()=>{
                            this.setState({otpModal:false}, () => {
                                this.props.openPreLoader()
                                window.confirmationResult.confirm(otpVerificationValue).then(async (result) => {
                                    const user = result.user;
                                    await this.submit()

                                }).catch((error) => {
                                    this.props.closePreLoader()
                                    toasting.error(error)
                                });
                            })
                        }}>
                            {label.Submit}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
          <section className="d-flex my-5 mb-lg-5">
            <Container className="bg-white shadow-soft border rounded border-light p-4">
              <Row className="justify-content-center form-bg-image ">
                <Col xs={6} className="d-flex justify-content-center">
                  <div className="w-100 fmxw-500">
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <h3 className="mb-0">{label.CompleteYourRegistrationInformation}</h3>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center form-bg-image ">
                <Col xs={6} className="d-flex justify-content-center">
                  <div className="w-100 fmxw-500">
                      <EmailInput
                          label={label.Email}
                          value={registrationVerification.email}
                          icon={faEnvelope}
                          placeholder={"jhon@example.com"}
                          readOnly={true}
                          required={true}
                          onChange={(value) => {
                              registrationVerification.email = value
                              validation.email = true
                              validationMessage.email = ""
                              this.setState({registrationVerification, validation, validationMessage})
                          }}
                          isError={false}
                          errorMessage={validationMessage.email}
                      />
                      <PasswordInput
                          label={label.Password}
                          icon={faUnlockAlt}
                          placeholder={label.EnterPassword}
                          value={registrationVerification.password}
                          onChange={(value) => {
                              validation.password = !isEmpty(value)
                              registrationVerification.password = value
                              validation.password = true
                              validationMessage.password = ""
                              this.setState({registrationVerification, validation, validationMessage})
                          }}
                          masking={true}
                          isError={!validation.password}
                          errorMessage={validationMessage.password}
                      />
                      <TextInput
                          label={label.Firstname}
                          icon={faUser}
                          placeholder={label.EnterFirstname}
                          value={registrationVerification.firstName}
                          onChange={(value) => {
                              value = cleanNonAlphabet(value)
                              value = firstLetterUppercase(value)
                              registrationVerification.firstName = value
                              validation.firstName = true
                              validationMessage.firstName = ""
                              this.setState({registrationVerification, validation, validationMessage})
                          }}
                          isError={!validation.firstName}
                          errorMessage={validationMessage.firstName}
                      />
                      <PhoneNumberInput
                          label={label.ContactPersonPhoneNumber}
                          icon={faPhone}
                          placeholder={label.EnterPhoneNumber}
                          value={registrationVerification.phoneNumber}
                          onChange={(value) => {
                              let valid = !isEmpty(value)
                              if(valid){
                                  registrationVerification.homePhoneNumber = value
                                  this.setState({registrationVerification},  async() => {
                                      valid = await this.validatePhoneNumber(registrationVerification.email, value, false)
                                      if(valid){
                                          validationMessage.homePhoneNumber = ""
                                      }else{
                                          validationMessage.homePhoneNumber = label.PhoneNumberHasAlreadyBeenUsed
                                      }
                                      validation.homePhoneNumber = valid
                                      this.setState({validation, validationMessage})
                                  })
                              }
                              // registrationVerification.homePhoneNumber = value
                              // this.setState({registrationVerification, validation, validationMessage})
                          }}
                          isError={!validation.homePhoneNumber}
                          errorMessage={validationMessage.homePhoneNumber}
                      />
                  </div>
                </Col>
                <Col xs={6} className="d-flex justify-content-center">
                  <div className="w-100 fmxw-500">
                      <Row>
                          <Col xs={10}>
                              <TextInput
                                  label={label.Username}
                                  icon={faUser}
                                  value={registrationVerification.username}
                                  placeholder={label.EnterUsername}
                                  onChange={async (value)=>{
                                      value = cleanNonAlphabet(value)
                                      let valid = !isEmpty(value)
                                      if(valid){
                                          registrationVerification.username = value
                                          this.setState({registrationVerification}, async () => {
                                              let valid = await this.validateUsername(registrationVerification.email, value, false)
                                              if(valid){
                                                  validationMessage.username = ""
                                              }else{
                                                  validationMessage.username = label.UsernameHasAlreadyBeenUsed
                                              }
                                              validation.username = valid
                                              this.setState({validation, validationMessage})
                                          })
                                      }
                                  }}
                                  isError={!validation.username}
                                  errorMessage={validationMessage.username}/>
                          </Col>
                          <Col xs={2} className="justify-content-end">
                              <Form.Group className="mb-4">
                                  <Form.Label>&nbsp;</Form.Label>
                                  <InputGroup>
                                      <Button onClick={()=>{
                                          this.generateUsername(registrationVerification.email, true, (username)=>{
                                              registrationVerification.username = username
                                              validation.username = true
                                              validationMessage.username = ""
                                              this.setState({registrationVerification, validation, validationMessage})
                                          })
                                      }}><FontAwesomeIcon icon={faRandom}/></Button>
                                  </InputGroup>
                              </Form.Group>
                          </Col>
                      </Row>
                      <PasswordInput
                          label={label.ConfirmPassword}
                          icon={faUnlockAlt}
                          placeholder={label.EnterConfirmPassword}
                          value={registrationVerification.confirmPassword}
                          masking={true}
                          onChange={(value) => {
                              registrationVerification.confirmPassword = value
                              validation.confirmPassword = true
                              validationMessage.confirmPassword = ""
                              this.setState({registrationVerification, validation, validationMessage})
                          }}
                          isError={!validation.confirmPassword}
                          errorMessage={validationMessage.confirmPassword}
                      />
                      <TextInput
                          label={label.Lastname}
                          icon={faUser}
                          placeholder={label.EnterLastname}
                          value={registrationVerification.lastName}
                          onChange={(value) => {
                              value = cleanNonAlphabet(value)
                              value = firstLetterUppercase(value)
                              registrationVerification.lastName = value
                              this.setState({registrationVerification})
                          }}
                          isError={!validation.lastName}
                          errorMessage={validationMessage.lastName}
                      />
                      <PhoneNumberInput
                          label={label.MobilePhone}
                          icon={faMobile}
                          placeholder={label.EnterMobilePhone}
                          value={registrationVerification.mobilePhone}
                          onChange={async (value) => {
                              let valid = !isEmpty(value)
                              if(valid){
                                  registrationVerification.mobilePhone = value
                                  this.setState({registrationVerification},async () => {
                                      valid = await this.validateMobilePhone(registrationVerification.email, value, false)
                                      if(valid){
                                          validationMessage.mobilePhone = ""
                                      }else{
                                          validationMessage.mobilePhone = label.PhoneNumberHasAlreadyBeenUsed
                                      }
                                      validation.mobilePhone = valid
                                      this.setState({validation,validationMessage})
                                  })
                              }
                              // registrationVerification.mobilePhone = value
                              // this.setState({registrationVerification, validation,validationMessage})
                          }}
                          isError={!validation.mobilePhone}
                          errorMessage={validationMessage.mobilePhone}
                      />
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center form-bg-image ">
                <Col xs={6} className="d-flex justify-content-center">
                  <div className="w-100 fmxw-500">
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <h3 className="mb-0">{label.CompanyInformation}</h3>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center form-bg-image ">
                <Col xs={4} className="d-flex justify-content-center">
                  <div className="w-100 fmxw-300">
                      <TextInput
                          label={label.CompanyName}
                          icon={faBuilding}
                          placeholder={label.EnterCompanyName}
                          value={registrationVerification.companyName}
                          onChange={ async (value) => {
                              value = cleanNonAlphabet(value)
                              value = firstLetterUppercase(value)
                              let valid = !isEmpty(value)
                              if(valid){
                                  registrationVerification.companyName = value
                                  this.setState({registrationVerification}, async () => {
                                      valid = await this.validateCompanyName(registrationVerification.email, value, false)
                                      if(valid){
                                          validationMessage.companyName = ""
                                      }else{
                                          validationMessage.companyName = label.CompanyNameHasAlreadyBeenUsed
                                      }
                                      validation.companyName = valid
                                      this.setState({validation,validationMessage})
                                  })
                              }
                              // registrationVerification.companyName = value
                              // this.setState({registrationVerification, validation,validationMessage})
                          }}
                          isError={!validation.companyName}
                          errorMessage={validationMessage.companyName}
                      />
                  </div>
                </Col>
                <Col xs={4} className="d-flex justify-content-center">
                  <div className="w-100 fmxw-300">
                      <TextInput
                          label={label.Website}
                          className={"mb-3"}
                          icon={faGlobe}
                          placeholder={label.EnterWebsite}
                          value={registrationVerification.website}
                          onChange={async (value) => {
                              value = cleanWebsiteDomain(value)
                              let valid = isValidUrl(value)
                              if(valid){
                                  registrationVerification.website = value
                                  this.setState({registrationVerification}, async () => {
                                      valid = await this.validateWebsite(registrationVerification.email, value, false)
                                      if(valid){
                                          validationMessage.website = ""
                                      }else{
                                          validationMessage.website = label.WebsiteHasAlreadyBeenUsed
                                      }
                                      validation.website = valid
                                      this.setState({validation, validationMessage})
                                  })
                              }
                              // registrationVerification.website = value
                              // this.setState({registrationVerification, validation, validationMessage})
                          }}
                          isError={!validation.website}
                          errorMessage={validationMessage.website}
                      />
                  </div>
                </Col>
                <Col xs={4} className="d-flex justify-content-center">
                  <div className="w-100 fmxw-300">
                      <PhoneNumberInput
                          label={label.CompanyPhoneNumber}
                          icon={faPhone}
                          placeholder={label.EnterCompanyPhoneNumber}
                          value={registrationVerification.companyPhoneNumber}
                          onChange={async (value) => {
                              let valid = !isEmpty(value)
                              if(valid){
                                  registrationVerification.companyPhoneNumber = value
                                  this.setState({registrationVerification}, async () => {
                                      valid = await this.validateCompanyPhoneNumber(registrationVerification.email, value, false);
                                      if(valid){
                                          validationMessage.companyPhoneNumber = ""
                                      }else{
                                          validationMessage.companyPhoneNumber = label.PhoneNumberHasAlreadyBeenUsed
                                      }
                                      validation.companyPhoneNumber = valid
                                      this.setState({validation,validationMessage})
                                  })
                              }
                              // registrationVerification.companyPhoneNumber = value
                              // this.setState({registrationVerification, validation,validationMessage})
                          }}
                          isError={!validation.companyPhoneNumber}
                          errorMessage={validationMessage.companyPhoneNumber}
                      />
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center form-bg-image">
                <Col xs={6} className="d-flex justify-content-center">
                  <div className="w-100 fmxw-500">
                      <ItemOption
                          objects={industries}
                          label={label.Industry}
                          value={registrationVerification.industry}
                          callback={(value) => {
                              registrationVerification.industry = value
                              validation.industry = true
                              validationMessage.industry = ""
                              this.setState({registrationVerification})
                          }}
                          isError={!validation.industry}
                          errorMessage={validationMessage.industry}
                      />
                  </div>
                </Col>
                <Col xs={6} className="d-flex justify-content-center">
                  <div className="w-100 fmxw-500">
                      <ItemOption
                          objects={companyTypes}
                          label={label.CompanyType}
                          value={registrationVerification.companyType}
                          callback={(value) => {
                              registrationVerification.companyType = value
                              validation.companyType = true
                              validationMessage.companyType = ""
                              this.setState({registrationVerification})
                          }}
                          isError={!validation.companyType}
                          errorMessage={validationMessage.companyType}
                      />
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center form-bg-image">
                <Col xs={12} className="d-flex justify-content-center" style={{width:'95%'}}>
                  <div className="w-100">
                    <TextInput
                        label={label.Address}
                        icon={faAddressBook}
                        placeholder={label.EnterAddress}
                        value={registrationVerification.address}
                        onChange={(value) => {
                            value = firstLetterUppercase(value)
                            registrationVerification.address = value
                            validation.address = true
                            validationMessage.address = ""
                          this.setState({registrationVerification})
                        }}
                        isError={!validation.address}
                        errorMessage={validationMessage.address}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center form-bg-image">
                <Col xs={6} className="d-flex justify-content-center">
                  <div className="w-100 fmxw-500">
                    <ItemOption
                        objects={provinces}
                        label={label.Province}
                        value={registrationVerification.province}
                        callback={(value) => {
                          registrationVerification.province = value
                          registrationVerification.village = undefined
                          registrationVerification.city = undefined
                          registrationVerification.district = undefined
                            validation.province = true
                            validationMessage.province = ""
                          if(value && value.id){
                            this.setState({registrationVerification, validation, validationMessage},() => {
                              this.fetchCities(value.id, cities=>{
                              })
                            })
                          }else{
                            this.setState({
                                registrationVerification, validation, validationMessage,
                              cities:[],
                              districts:[],
                              villages:[],
                            })
                          }
                        }}
                        isError={!validation.province}
                        errorMessage={validationMessage.province}
                    />
                    <ItemOption
                        objects={districts}
                        label={label.District}
                        value={registrationVerification.district}
                        callback={(value) => {
                          registrationVerification.district = value
                          registrationVerification.village = undefined
                            validation.district = true
                            validationMessage.district = ""
                          if(value && value.id){
                            this.setState({registrationVerification, validation, validationMessage},() => {
                              this.fetchVillages(value.id, villages=>{
                              })
                            })
                          }else{
                            this.setState({
                                registrationVerification, validation, validationMessage,
                              villages:[],
                            })
                          }
                        }}
                        isError={!validation.district}
                        errorMessage={validationMessage.district}
                    />
                  </div>
                </Col>
                <Col xs={6} className="d-flex justify-content-center">
                  <div className="w-100 fmxw-500">
                    <ItemOption
                        objects={cities}
                        label={label.City}
                        value={registrationVerification.city}
                        callback={(value) => {
                          registrationVerification.city = value
                          registrationVerification.village = undefined
                          registrationVerification.district = undefined
                            validation.city = true
                            validationMessage.city = ""
                          if(value && value.id){
                            this.setState({registrationVerification, validation, validationMessage},() => {
                              this.fetchDistricts(value.id, districts=>{
                              })
                            })
                          }else{
                            this.setState({
                                registrationVerification, validation, validationMessage,
                              districts:[],
                              villages:[],
                            })
                          }
                        }}
                        isError={!validation.city}
                        errorMessage={validationMessage.city}
                    />
                    <ItemOption
                        objects={villages}
                        label={label.Village}
                        value={registrationVerification.village}
                        callback={(value) => {
                          registrationVerification.village = value
                            validation.village = true
                            validationMessage.village = ""
                          this.setState({registrationVerification, validation, validationMessage})
                        }}
                        isError={!validation.village}
                        errorMessage={validationMessage.village}
                    />

                  </div>
                </Col>

              </Row>

              <Row className="justify-content-center form-bg-image p-4">
                <Col xs={6} className="d-flex justify-content-center">
                </Col>
                <Col xs={6} className="d-flex align-items-end justify-content-end">
                  <div className="w-100 fmxw-400">
                      <FormCheck type="checkbox" className="d-flex mb-4">
                          <FormCheck.Input
                              required id="terms"
                              className="me-2"
                              checked={agreeWithTermAndCondition}
                              onClick={()=>{
                                  this.setState({agreeWithTermAndCondition:!agreeWithTermAndCondition})
                              }}
                          />
                          <FormCheck.Label htmlFor="terms">
                              {label.AgreeWith} <Card.Link>{label.TermAndCondition}</Card.Link>
                          </FormCheck.Label>
                      </FormCheck>
                      <Button id="register-button" disabled={!agreeWithTermAndCondition} variant="success" className="w-100" onClick={async event => {
                          // this.setState({registrationConfirmationModal:true})
                          await this.validateFormSubmit()
                      }}>
                          {label.Submit}
                      </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
    );
  }
}
export default Object.assign(RegistrationVerificationPage, {ENDPOINT : "/registration/verification"})
