import React from "react";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {Button, Col, Container, Form, Row} from '@themesberg/react-bootstrap';
import SigninPage from "./SigninPage";
import BasePage from "../base/BasePage";
import PasswordInput from "../../components/input/PasswordInput";
import label from "na-utilities/src/label/Label"
import {RegistrationVerification} from "../../model/model";
import {isEmpty, isResponseOk, navigate} from "na-utilities/src/utils/Utilities";
import Endpoint from "na-utilities/src/api/Endpoint";
import {toasting} from "../base/BaseComponent";
import ResetPasswordSuccessPage from "./ResetPasswordSuccessPage";


class ResetPasswordPage extends BasePage {
  constructor(props) {
    super(props);
    let validation = this.defaultValidation()
    let validationMessage = this.defaultValidationMessage()
    this.state = {
      password:"",
      confirmPassword:"",
      validation, validationMessage, tokenValid:false
    }
  }
  componentDidMount() {
    super.componentDidMount();
    this.closePreLoader()
    this.validateToken()
  }

  defaultValidation = () =>{
    let validation = {};
    validation.password = true ;
    validation.confirmPassword = true
    return validation ;
  }
  defaultValidationMessage = () =>{
    let validationMessage = {};
    validationMessage.password = "" ;
    validationMessage.confirmPassword = ""
    return validationMessage ;
  }

  validateToken(){
    let {token} = this.props.match.params
    if(!token){
      window.history.pushState(null, null, '/');
      window.location.href = SigninPage.ENDPOINT
      return
    }
    this.props.openPreLoader()
    this.get(Endpoint.API.USER_RESET_PASSWORD_VALIDATE, {params:{token:token}}, null, (response)=>{
      this.props.closePreLoader()
      if(isResponseOk(response)){
        this.setState({tokenValid:true})
      }else{
        toasting.error(response.message)
        setTimeout(()=>{
          window.history.pushState(null, null, '/');
          window.location.href = SigninPage.ENDPOINT
        }, 3000)
      }
    }, false, false)
  }


  validateFormSubmit() {
    let {validation, validationMessage, password, confirmPassword,tokenValid} = this.state
    let valid = true ;
    validation = this.defaultValidation()
    validationMessage = this.defaultValidationMessage()

    if(isEmpty(password)){
      validation.password = false
      validationMessage.password = label.EnterPassword
      valid = false ;
    }
    if(isEmpty(confirmPassword)){
      validation.confirmPassword = false
      validationMessage.confirmPassword = label.EnterConfirmPassword
      valid = false ;
    }
    if(password && confirmPassword && password !== confirmPassword){
      validation.confirmPassword = false
      validationMessage.confirmPassword = label.ConfirmPasswordAndNewPasswordIsNotSame
      valid = false ;
    }
    this.setState({password, confirmPassword, validation, validationMessage}, () => {
      if(valid){
        this.submitResetPassword()
      }
    })
  }

  submitResetPassword(){
    let {token} = this.props.match.params
    let {validation, validationMessage, password, confirmPassword } = this.state
    let form = new FormData()
    form.append("token", token);
    form.append("password", password);
    this.post(Endpoint.API.USER_RESET_PASSWORD, form, null, (res)=>{
      if(isResponseOk(res)){
        window.history.pushState(null, null, '/');
        navigate(this.props, ResetPasswordSuccessPage.ENDPOINT+"/"+token)
      }
    }, true, true);

  }

  render() {
    let {validation, validationMessage, password, confirmPassword,tokenValid} = this.state

    return (
        <main>
          <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
            <Container>
              <Row className="justify-content-center">
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                    <h3 className="mb-4">{label.ChangePassword}</h3>
                    <p className="font-small font-weight-bold">{label.RequestToChangePasswordRequiredIsExistsToEnterApplication}</p>
                    <Form>
                      <PasswordInput
                          label={label.NewPassword}
                          icon={faLock}
                          placeholder={label.EnterNewPassword}
                          onChange={(value) => {
                            validation.password = !isEmpty(value)
                            password = value
                            validationMessage.password = ""
                            this.setState({password, validation, validationMessage})
                          }}
                          isError={!validation.password}
                          errorMessage={validationMessage.password}
                      />
                      <PasswordInput
                          label={label.ConfirmPassword}
                          icon={faLock}
                          placeholder={label.EnterConfirmPassword}
                          value={confirmPassword}
                          masking={true}
                          onChange={(value) => {
                            confirmPassword = value
                            validation.confirmPassword = !isEmpty(value)
                            validationMessage.confirmPassword = ""
                            this.setState({confirmPassword, validation, validationMessage})
                          }}
                          isError={!validation.confirmPassword}
                          errorMessage={validationMessage.confirmPassword}
                      />
                      <br/>
                      <p className="text-center text-danger font-small font-weight-bold">{label.PleaseRememberYourPasswordAsItIsImportantToYourAccessToApplicationAndDoNotShareWithAnyOtherPerson}</p>
                      <Button
                          variant="primary"
                          className="w-100"
                          disabled={!tokenValid}
                          onClick={event => {
                            this.validateFormSubmit()
                          }}>
                        {label.Submit}
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {super.render()}
        </main>
    );
  }
}
export default Object.assign(ResetPasswordPage, {ENDPOINT : "/auth/resetPassword"})

