import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome} from '@fortawesome/free-solid-svg-icons';
import {Breadcrumb, Nav, Tab} from '@themesberg/react-bootstrap';
import BasePage from "../base/BasePage";
import label from "na-utilities/src/label/Label"
import DashboardPage from "../dashboard/DashboardPage";
import SmartDeviceTable from "../../components/SmartDeviceTable";
import Endpoint from "na-utilities/src/api/Endpoint";
import {isEmpty} from "na-utilities/src/utils/Utilities";

class SmartDevicePage extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      smartDeviceTypes:[],
      defaultActiveTab:"Toolbox",
      activeTab:"Toolbox",
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.closePreLoader()
    this.fetchSmartDeviceTypes()
  }

  fetchSmartDeviceTypes = () => {
    let {smartDeviceTypes,activeTab,defaultActiveTab} = this.state
    this.get(Endpoint.API.SMART_DEVICE_TYPES, {
      params :{
        sortir: "type",
        ascending:true,
      }
    }, null, response=>{
      if(response.code===200){
        smartDeviceTypes = response.data
        this.setState({smartDeviceTypes:smartDeviceTypes},() => this.forceUpdate())
      }else{
        this.props.history.goBack();
      }
    }, false, true)
  }



  render() {
    let {smartDeviceTypes, defaultActiveTab, activeTab, pageElement, totalPage, totalElement, sortOption} = this.state

    return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                <Breadcrumb.Item href={DashboardPage.ENDPOINT}>{label.Dashboard}</Breadcrumb.Item>
                <Breadcrumb.Item active>{label.SmartDevice}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          {
            !isEmpty(defaultActiveTab)?
                (
                    <Tab.Container defaultActiveKey={defaultActiveTab}>
                      <Nav fill variant="tabs" className="flex-column flex-sm-row">
                        {
                          smartDeviceTypes.map((item, index)=>(
                              <Nav.Link
                                  eventKey={item.type}
                                  className="mb-sm-3 mb-md-0"
                                  onSelect={eventKey => {
                                    this.setState({activeTab:item.type})
                                  }}
                                  disabled={!item.active}>
                                {item.type}{item.active==true?"":" ("+label.Inactive+")"}
                              </Nav.Link>

                          ))
                        }
                      </Nav>
                      <Tab.Content>
                        {
                          smartDeviceTypes.map((item, index)=>(
                              <Tab.Pane eventKey={item.type} className="py-4">
                                <SmartDeviceTable smartDeviceType={item} show={item.type === activeTab} {...this.props}/>
                              </Tab.Pane>
                          ))
                        }
                      </Tab.Content>

                    </Tab.Container>
                )
                :
                (null)
          }
          {/*<TransactionsTable/>*/}
        </>
    );
  }
}
export default Object.assign(SmartDevicePage, {ENDPOINT : "/smartDevices"})

