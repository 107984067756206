import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Col, Form, InputGroup, Modal, Row, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faCheck, faEdit, faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import PaginationTop from "./PaginationTop";
import GoodsCategoryEditModal from "./GoodsCategoryEditModal";
import {cloneObject} from "na-utilities/src/utils/Utilities";

export default class GoodsCategorySelectorModal extends BaseComponent{

    constructor(props) {
        super(props);
        let {goodsCategory} = this.props
        this.state = {
            goodsCategories:[],
            goodsCategory:goodsCategory?goodsCategory:{},
            sortOption:{},
            page:1,
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
            show:false,
            goodsCategoryModal:{
                show:false,
                position:-1,
                goodsCategory:{},
            },
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let show = this.props.show?this.props.show:false
            let {goodsCategory} = this.props
            this.setState({show, goodsCategory},() => {
                if(show && !prevState.show){
                    this.fetchGoodsCategoryList()
                }
            })
        }

    }


    fetchGoodsCategoryList(){
        let {user,sortOption} = this.state
        this.get(Endpoint.API.GOODS_CATEGORIES, {
            params :{
                ascending: sortOption.ascending,
                sortir: sortOption.sortir,
                page: this.state.page - 1,
            }
        }, null, response=>{
            if(response.code===200){
                let goodsCategories = response.data
                let totalPage = response.totalPage
                let totalElement = response.totalElement
                let pageElement = response.pageElement
                this.setState({goodsCategories:goodsCategories,totalPage, totalElement, pageElement})
            }else{
                this.props.history.goBack();
            }
        }, false, true)
    }

    onSelectGoodsCategory(goodsCategory){
        if(this.props.onSelectGoodsCategory){
            this.props.onSelectGoodsCategory(goodsCategory);
        }
    }

    onEditGoodsCategory(goodsCategory, index){
        let {goodsCategoryModal} = this.state
        goodsCategoryModal = {
            ...goodsCategoryModal, position:index, goodsCategory:cloneObject(goodsCategory), show:true
        }
        this.setState({goodsCategoryModal})
    }


    render() {
        let {goodsCategory, goodsCategories, show, goodsCategoryModal, page, totalPage, totalElement, pageElement} = this.state
        return (
            <>
                {super.render()}
                <GoodsCategoryEditModal
                    goodsCategoryModal={goodsCategoryModal}
                    onClose={() => {
                        this.setState({goodsCategoryModal:{show:false, position:-1, goodsCategory:{}}})
                    }}
                    onResult={(goodsCategoryModal) => {
                        if(goodsCategoryModal.position==-1){
                            this.setState({page:1}, () => {
                                this.fetchGoodsCategoryList()
                            })
                        }else{
                            goodsCategories.splice(goodsCategoryModal.position, 1)
                            goodsCategories.splice(goodsCategoryModal.position, 0, cloneObject(goodsCategoryModal.goodsCategory))
                        }
                        this.setState({ goodsCategoryModal:{show:false, position:-1, goodsCategory:goodsCategoryModal.goodsCategory}})
                    }}
                    position={-1}
                    showProcessing={this.props.showProcessing}
                    closeProcessing={this.props.closeProcessing}
                />
                <Modal as={Modal.Dialog} size="xl" centered show={show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.SelectGoodsCategory}</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={()=>{
                            this.props.onClose()
                        }} />
                    </Modal.Header>
                    <Modal.Header>
                        <Row className="w-100 d-flex justify-content-center align-items-center">
                            <Col md={4}>
                                <Form className="navbar-search">
                                    <Form.Group id="topbarSearch">
                                        <InputGroup className="input-group-merge search-bar">
                                            <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                                            <Form.Control type="text" placeholder="Search" />
                                        </InputGroup>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col>
                                <Button
                                    size="sm"
                                    onClick={event => {
                                        goodsCategoryModal = {
                                            ...goodsCategoryModal, goodsCategory:{}, position:-1, show:true
                                        }
                                        this.setState({goodsCategoryModal})
                                    }}>
                                    {label.CreateCategory}
                                    &nbsp;
                                    &nbsp;
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        className="text-success"
                                        size="sm"/>
                                </Button>
                            </Col>
                            <Col md={6} className="text-right">
                                <PaginationTop
                                    onSelect={(page) => {
                                        this.setState({page}, () => {
                                            this.fetchGoodsCategoryList()
                                        })
                                    }}
                                    currentPage={page}
                                    pageCount={totalPage}
                                    totalElement={totalElement}
                                    showingRow={goodsCategories.length}/>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive className="table-sm table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{label.Name}</th>
                                <th scope="col">{label.Description}</th>
                                <th scope="col">{label.Exclusive}</th>
                                <th scope="col">{label.Edit}</th>
                                <th scope="col">{label.Select}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {goodsCategories.map((item, index) => {
                                return (
                                    <tr scope="row" style={{minHeight:82}} className={(goodsCategory.id===item.id?"bg-light":"")}>
                                        <td>{((page-1)*pageElement)+(index+1)}</td>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td className="align-items-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word'}}>
                                            {item.description}
                                        </td>
                                        <td className="text-center">
                                            {
                                                item.owner?
                                                    (
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="text-success"
                                                        size="lg"
                                                    />
                                                    ):
                                                    ("-")
                                            }
                                        </td>
                                        <td className="text-center">
                                            {
                                                item.owner?(
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                        className="text-success"
                                                        size="lg"
                                                        onClick={event => {
                                                            this.onEditGoodsCategory(item, index)
                                                        }}
                                                    />
                                                ):("-")
                                            }
                                        </td>
                                        <td scope="col" className="text-center align-items-center">
                                            <Button
                                                variant="info"
                                                size="sm"
                                                onClick={event => {
                                                    this.onSelectGoodsCategory(item)
                                                }}>
                                                {label.Select}
                                                &nbsp;
                                                &nbsp;
                                                <FontAwesomeIcon
                                                    icon={faArrowRight}
                                                    className="text-success"
                                                    size="sm"
                                                />
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}
GoodsCategorySelectorModal.propTypes = {
    show:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onSelectGoodsCategory:PropTypes.func.isRequired,
    goodsCategory:PropTypes.object

}
