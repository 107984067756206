import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Card, Col, Row, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {isResponseOk} from "na-utilities/src/utils/Utilities";
import {currencyFormat} from "na-utilities/src/utils/Utilities";
import PropTypes from 'prop-types';

export default class OrderItemTable extends BaseComponent{

    constructor(props) {
        super(props);
        let {productOrder} = this.props
        this.state = {
            orderItems:[],
            productOrder:productOrder?productOrder:{}
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let {productOrder} = this.props
            if(productOrder && productOrder.id){
                this.setState({productOrder},() => {
                    this.fetchOrderItems()
                })
            }
        }
    }

    fetchOrderItems(){
        let {orderItems, productOrder} = this.state
        this.get(Endpoint.API.ORDER_ITEMS,
            {params:{"productOrderId":productOrder.id}},
            null, (res)=>{
            if(isResponseOk(res)){
                orderItems = res.data
                this.setState({orderItems})
            }
        }, false, false)
    }


    render() {
        let {orderItems,productOrder} = this.state
        return (
            <div className="mb-4">
                <Card border="light" className="shadow-sm">
                    <Card.Header>
                        {label.ProductOrderItem}
                    </Card.Header>
                    <Table responsive className="align-items-center table-flush">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{label.ProductPackage}</th>
                            <th scope="col">{label.Product}</th>
                            <th scope="col">{label.Type}</th>
                            <th scope="col">{label.AllocatedDeviceId}</th>
                            <th scope="col">{label.TotalAmount}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orderItems.map((item, index) => {
                            return (
                                <tr>
                                    <th scope="row">{index+1}</th>
                                    <th scope="row">{item.productPackage.name}</th>
                                    <td>{item.productPackage.product.name}</td>
                                    <td>{item.type}</td>
                                    <td>{item.allocatedDeviceId}</td>
                                    <td>
                                        {currencyFormat(item.productPackage.price, 'Rp')}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    <Card.Footer>
                        <Row>
                            <Col className="text-right">{label.TotalAmount} <span className="font-medium">{currencyFormat(productOrder.amountPrice, 'Rp')}</span></Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </div>
        );
    }

}
OrderItemTable.propTypes = {
    productOrder:PropTypes.object.isRequired,

}
