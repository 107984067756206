import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Card, Form, FormCheck, InputGroup, Modal, Row, Table, Col} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {isResponseOk} from "na-utilities/src/utils/Utilities";
import PropTypes from 'prop-types';
import {navigate, parseDate} from "na-utilities/src/utils/Utilities";
import {DD_MMMM_YYYY_HH_MM_SS} from "../utils/Global";
import SpotLocationDetailPage from "../pages/management/SpotLocationDetailPage";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faCheck, faEye, faSearch} from "@fortawesome/free-solid-svg-icons";
import PaginationTable from "./PaginationTable";
import PaginationTop from "./PaginationTop";

export default class LocationSelectorModal extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            spotLocations:[],
            spotLocation:{},
            sortOption:{},
            page:1,
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
            show:false
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let show = this.props.show?this.props.show:false
            this.setState({show},() => {
                if(show && !prevState.show){
                    this.fetchLocations()
                }
            })
        }

    }

    fetchLocations(){
        let {user,sortOption} = this.state
        let company = user.company?user.company:{}
        this.get(Endpoint.API.SPOT_LOCATIONS, {
            params :{
                companyId : company.id,
                ascending: sortOption.ascending,
                sortir: sortOption.sortir,
                page: this.state.page - 1,
            }
        }, null, response=>{
            if(response.code===200){
                let spotLocations = response.data
                let totalPage = response.totalPage
                let totalElement = response.totalElement
                let pageElement = response.pageElement
                this.setState({spotLocations:spotLocations,totalPage, totalElement, pageElement})
            }else{
                this.props.history.goBack();
            }
        }, false, true)
    }

    onSelectLocation(spotLocation){
        if(this.props.onSelectLocation){
            this.props.onSelectLocation(spotLocation);
        }
    }




    render() {
        let {spotLocations,show, spotLocation,page,totalPage, totalElement, pageElement} = this.state
        return (
            <>
                {super.render()}
                <Modal as={Modal.Dialog} size="xl" centered show={show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.SelectLocation}</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={()=>{
                            this.props.onClose()
                        }} />
                    </Modal.Header>
                    <Modal.Header>
                        <Row className="w-100">
                            <Col md={4}>
                                <Form className="navbar-search">
                                    <Form.Group id="topbarSearch">
                                        <InputGroup className="input-group-merge search-bar">
                                            <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                                            <Form.Control type="text" placeholder="Search" />
                                        </InputGroup>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md={8} className="text-right">
                                <PaginationTop
                                    onSelect={(page) => {

                                    }}
                                    currentPage={page}
                                    pageCount={totalPage}
                                    totalElement={totalElement}
                                    showingRow={page}/>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Table responsive className="table-sm align-items-center table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{label.Name}</th>
                                <th scope="col">{label.Address}</th>
                                <th scope="col">{label.Detail}</th>
                                <th scope="col">{label.Select}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {spotLocations.map((item, index) => {
                                return (
                                    <tr>
                                        <th scope="row">{index+1}</th>
                                        <th scope="row" >
                                            {item.name}
                                        </th>
                                        <td>{item.fullAddress}</td>
                                        <td>
                                            <Button
                                                size="sm"
                                                className="rounded-circle text-center"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    navigate(this.props, SpotLocationDetailPage.ENDPOINT+"?id="+item.id)
                                                }}
                                                type={Link}>
                                                <FontAwesomeIcon size="sm" icon={faEye}/>
                                            </Button>
                                        </td>
                                        <td scope="col" className="text-center">
                                            <FontAwesomeIcon
                                                icon={faArrowRight}
                                                className="text-success"
                                                size="2x"
                                                onClick={event => {
                                                    this.onSelectLocation(item)
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}
LocationSelectorModal.propTypes = {
    show:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onSelectLocation:PropTypes.func.isRequired

}
