import React from 'react'
import BaseComponent, {toasting} from "../pages/base/BaseComponent";
import {Button, ButtonGroup, Col, Form, Modal, Row} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {firstLetterUppercase, isEmpty, isResponseOk} from "na-utilities/src/utils/Utilities";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import TextAreaInput from "./input/TextAreaInput";
import {withRouter} from 'react-router-dom';
import ItemOption from "./input/ItemOption";
import Global from "na-utilities/src/utils/Global";

class BorrowingOfItemChangeStatusModal extends BaseComponent{

    constructor(props) {
        super(props);
        let {borrowingOfItem, show} = this.props
        if(!borrowingOfItem){
            borrowingOfItem = {}
        }
        let borrowingOfItemStatus = {}
        let validation = this.defaultValidation()
        let validationMessage = this.defaultValidationMessage()
        this.state = {
            borrowingOfItem:borrowingOfItem,
            borrowingOfItemStatus,
            show:show,
            validation:validation,
            validationMessage:validationMessage,
            goodsCategories:[],
            categorySelectorModal:false,
        }
    }

    defaultValidation = () =>{
        let validation = {};
        validation.information = true ;
        validation.borrowingOfItemStatus = true ;
        return validation ;
    }
    defaultValidationMessage = () =>{
        let validationMessage = {};
        validationMessage.information = ""
        validationMessage.borrowingOfItemStatus = "" ;
        return validationMessage ;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let {borrowingOfItem, show} = this.props
            if(!borrowingOfItem){
                borrowingOfItem = {}
            }
            this.setState({borrowingOfItem, show}, () => {
                if(show){
                    // this.fetchGoodsCategories((goodsCategories)=>{
                    //     this.setState({goodsCategories})
                    // })
                }
            })
        }
    }

    async submit(){
        let {borrowingOfItem, borrowingOfItemStatus, validation, validationMessage} = this.state
        let valid = true ;
        if(isEmpty(borrowingOfItem.information)){
            validation.information = false
            validationMessage.information = label.EnterInformation
            valid = false
        }
        if(isEmpty(borrowingOfItemStatus)){
            valid = false
            validation.borrowingOfItemStatus = false
            validationMessage.borrowingOfItemStatus = label.SelectStatus
        }
        if(!valid){
            this.setState({validation, validationMessage})
        }else{
            let form = new FormData()
            borrowingOfItem.borrowingOfItemStatus = borrowingOfItemStatus
            form.append("borrowingOfItemId", borrowingOfItem.id)
            form.append("borrowingOfItemStatusId", borrowingOfItemStatus.id)
            form.append("information", borrowingOfItem.information)
            this.props.showProcessing()
            this.post(Endpoint.API.BORROWING_ITEM_UPDATE_STATUS, form,
                null, (res)=>{
                    this.props.closeProcessing()
                    if(isResponseOk(res)){
                        borrowingOfItem = res.data
                        this.props.onResult(borrowingOfItem)
                    }else{
                        toasting.error(res.message)
                    }
                }, true, true)
        }
    }

    render() {
        let {borrowingOfItem, borrowingOfItemStatus, show, validation, validationMessage} = this.state
        let borrowingOfItemStatusId = borrowingOfItem.borrowingOfItemStatus?borrowingOfItem.borrowingOfItemStatus.id:undefined
        let updateBorrowingOfItemStatusOptions = []
        if(borrowingOfItemStatusId===Global.BorrowingOfItemStatuses.ON_BORROW.id){
            updateBorrowingOfItemStatusOptions = [Global.BorrowingOfItemStatuses.MISSING, Global.BorrowingOfItemStatuses.REMOVED_FROM_MACHINE]
        }
        if(borrowingOfItemStatusId===Global.BorrowingOfItemStatuses.MISSING.id){
            updateBorrowingOfItemStatusOptions = [Global.BorrowingOfItemStatuses.REMOVED_FROM_MACHINE]
        }
        return (
            <>
                {super.render()}
                <Modal as={Modal.Dialog} size="lg" centered show={show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.UpdateStatus}</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={()=>{
                            this.props.onClose()
                        }} />
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <ItemOption
                                    objects={updateBorrowingOfItemStatusOptions}
                                    fieldForLabel={"label"}
                                    hideOptionLabel={false}
                                    value={borrowingOfItemStatus}
                                    placeholder={label.Status}
                                    label={label.Status}
                                    callback={(value) => {
                                        borrowingOfItemStatus = value
                                        if(value){
                                            validation.borrowingOfItemStatus = true
                                            validationMessage.borrowingOfItemStatus = ""
                                        }
                                        this.setState({borrowingOfItemStatus, validation, validationMessage})
                                    }}
                                    isError={!validation.borrowingOfItemStatus}
                                    errorMessage={validationMessage.borrowingOfItemStatus}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form>
                                    <TextAreaInput
                                        label={label.Information}
                                        required={true}
                                        placeholder={label.EnterInformation}
                                        rows={4}
                                        value={borrowingOfItem.information}
                                        onChange={(value) => {
                                            value = firstLetterUppercase(value)
                                            borrowingOfItem.information = value
                                            validation.information = true
                                            validationMessage.information = ""
                                            this.setState({borrowingOfItem, validation, validationMessage})
                                        }}
                                        isError={!validation.information}
                                        errorMessage={validationMessage.information}
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right px-4 pb-2">
                                <ButtonGroup>
                                    <Button
                                        size="sm"
                                        variant="info"
                                        onClick={async (event) => {
                                            this.props.onClose()
                                        }}>
                                        {label.Cancel}&nbsp;&nbsp;<FontAwesomeIcon icon={faWindowClose}/>
                                    </Button>
                                    &nbsp;
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={async (event) => {
                                            await this.submit()
                                        }}>
                                        {label.Save}&nbsp;&nbsp;<FontAwesomeIcon icon={faSave}/>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}
BorrowingOfItemChangeStatusModal.propTypes = {
    position:PropTypes.number,
    show:PropTypes.bool.isRequired,
    borrowingOfItem:PropTypes.object.isRequired,
    onClose:PropTypes.func.isRequired,
    onResult:PropTypes.func.isRequired

}

export default withRouter(BorrowingOfItemChangeStatusModal)
