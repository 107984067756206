import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Card, Col, Row} from "@themesberg/react-bootstrap";
import PropTypes from 'prop-types';
import AccordionComponent from "./AccordionComponent";
import label from "na-utilities/src/label/Label"
import ContainerRowCard from "./ContainerRowCard";

export default class ContainerCard extends BaseComponent{

    constructor(props) {
        super(props);
        let {smartDevice} = this.props
        this.state = {
            smartDevice,
            rackPosition:0,
            expandedPhysicalContainer:{}
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let {smartDevice} = this.props
            if(smartDevice && smartDevice.id){
                this.setState({smartDevice})
            }
        }
    }

    containerTitle(physicalContainer){
        if(!physicalContainer){
            physicalContainer = {}
        }
        return (
            <>
                <Button
                    variant="secondary"
                    size="sm">{label.Locker}&nbsp;{physicalContainer.lockerPosition}</Button> <Button variant="outline-primary" size="sm">{physicalContainer.tag}</Button>
            </>
        )
    }

    accordionData(smartDevice, physicalRack, physicalContainer){
        let {expandedPhysicalContainer} = this.state
        return (
            {
                id: physicalContainer.id,
                eventKey: physicalContainer.id,
                title: this.containerTitle(physicalContainer),
                description:
                    <ContainerRowCard
                        smartDevice={smartDevice}
                        physicalContainer={physicalContainer}
                        physicalRack={physicalRack}
                        expanded={physicalContainer.id === expandedPhysicalContainer.id}/>
            }
        )
    }


    render() {
        let {smartDevice, rackPosition, expandedPhysicalContainer} = this.state
        let physicalCabinet = smartDevice.physicalCabinet?smartDevice.physicalCabinet:{}
        let physicalRacks = physicalCabinet.physicalRacks?physicalCabinet.physicalRacks:[]
        let physicalRack = physicalRacks[rackPosition]?physicalRacks[rackPosition]:{}
        let physicalContainers = physicalRack.physicalContainers?physicalRack.physicalContainers:[]
        let data = new Array()
        physicalContainers.forEach((value, index) => {
            data.push({
                id: value.id,
                eventKey: value.id,
                title: this.containerTitle(value),
                description:
                    <ContainerRowCard
                        smartDevice={smartDevice}
                        physicalContainer={value}
                        physicalRack={physicalRack}
                        expanded={expandedPhysicalContainer.id === value.id}/>
            })
        })
        return (
            <div className="mb-4">
                <Card border="light" className="shadow-sm">
                    <Card.Header>
                        <Row>
                            <Col>
                                {physicalRacks.map((value, index) => {
                                    return(
                                        <>
                                            <Button
                                                variant="outline-primary"
                                                size="lg"
                                                active={rackPosition==index}
                                                onClick={event => {
                                                    this.setState({rackPosition:index})
                                                }}
                                                style={{minWidth:150}}>
                                                {value.title}
                                            </Button>
                                            &nbsp;
                                        </>
                                    )
                                })}
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {physicalContainers.map((value, index) => {
                            return(
                                <span>
                                    <AccordionComponent
                                        onClick={(eventKey)=>{
                                            if(expandedPhysicalContainer.id===eventKey){
                                                expandedPhysicalContainer = {}
                                            }else{
                                                expandedPhysicalContainer = physicalContainers.find((value1, index1) => {
                                                    return value1.id = eventKey;
                                                })
                                            }
                                            this.setState({expandedPhysicalContainer})
                                        }}
                                        defaultKey={expandedPhysicalContainer.id}
                                        data={[this.accordionData(smartDevice, physicalRack, value)]}>
                                    </AccordionComponent>
                                    <br/>
                                </span>
                            )
                        })}
                    </Card.Body>
                </Card>
            </div>
        );
    }

}
ContainerCard.propTypes = {
    smartDevice:PropTypes.object.isRequired,

}
