import React from 'react'
import BaseComponent, {toasting} from "../pages/base/BaseComponent";
import {Button, Card, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import PropTypes from "prop-types";
import {isResponseOk} from "na-utilities/src/utils/Utilities";
import Endpoint from "na-utilities/src/api/Endpoint";
import {handleTimeAgo} from "na-utilities/src/utils/Utilities";
import PaginationTable from "./PaginationTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus} from "@fortawesome/free-solid-svg-icons";


class UserDeviceTable extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            user:this.props.user?this.props.user:{},
            userDevices:[],
            page:1,
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
            sortOption:{}
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let {user} = this.state
        let nextUser = nextProps.user?nextProps.user:{}
        if(user.id!==nextUser.id){
            this.setState({user:nextUser},() => {
                this.getUserDevices()
            })
        }
    }

    delete(userDeviceId){
        this.props.showProcessing()
        this.get(Endpoint.API.USER_DEVICE_DELETE+"/"+userDeviceId, null, null, res => {
            if(res.code===200){
                toasting.success(label.SuccessDeletingData)
                this.getUserDevices()
            }
            this.props.closeProcessing()
        }, true, true);

    }

    getUserDevices(){
        let {user, userDevices,page} = this.state
        this.get(Endpoint.API.USER_DEVICES, {params:{"userNames":user.username, page:page-1}}, null, (res)=>{
            if(isResponseOk(res)){
                userDevices = res.data
                let totalPage = res.totalPage
                let totalElement = res.totalElement
                let pageElement = res.pageElement
                this.setState({userDevices,totalPage, totalElement, pageElement})
            }
        }, false, false)
    }


    render() {
        let {user, userDevices, page, pageElement, totalPage, totalElement, sortOption} = this.state
        return (
            <div>
                <Card border="light" className="table-wrapper shadow-sm">
                    <Card.Header>
                        <h5>{label.UserDevices}</h5>
                    </Card.Header>
                    <Table responsive className="table-sm align-items-center table-flush p-0">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{label.Email}</th>
                            <th scope="col">{label.User}</th>
                            <th scope="col">{label.Platform}</th>
                            <th scope="col">{label.IPAddress}</th>
                            <th scope="col">{label.DeviceId}</th>
                            <th scope="col">{label.Remove}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            userDevices.map((userDevice, index)=>{
                                return(
                                    <tr key={index}>
                                        <td>
                                            {((page - 1) * pageElement) + (++index)}
                                        </td>
                                        <th scope="row">{userDevice.user?userDevice.user.email:"-"}</th>
                                        <th scope="row">{userDevice.user?userDevice.user.fullname:"-"}</th>
                                        <th scope="row">{userDevice.platform}</th>
                                        <th scope="row">{userDevice.ipAddress}</th>
                                        <th scope="row">{userDevice.deviceId}</th>
                                        <th scope="row">
                                            <Button
                                                size="sm"
                                                variant="danger"
                                                onClick={event => {
                                                    this.delete(userDevice.id)
                                                }}>
                                                <FontAwesomeIcon icon={faMinus}/>
                                            </Button>
                                        </th>
                                    </tr>
                                )
                            })                                    }
                        </tbody>
                    </Table>
                    <PaginationTable
                        onSelect={(page) => {
                            this.setState({page},() => {
                                this.getUserDevices()
                            })
                        }}
                        showingRow={userDevices.length}
                        currentPage={page}
                        pageCount={totalPage}
                        totalElement={totalElement}/>

                </Card>
                {super.render()}
            </div>
        );
    }

}

UserDeviceTable.propTypes = {
    user : PropTypes.any,
}

export default UserDeviceTable