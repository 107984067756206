import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Card, Col, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faGlobeEurope} from "@fortawesome/free-solid-svg-icons";
import label from "na-utilities/src/label/Label";
import PropTypes from "prop-types";
import Endpoint from "na-utilities/src/api/Endpoint";
import {getUser} from "../utils/StorageUtil";

class DashboardSummaryCard extends BaseComponent{

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        super.componentDidMount();
    }



    render() {
        let { icon, iconColor, category, amount, label1, label2, amount1, amount2 } = this.props;
        let percentageIcon = amount2 < 0 ? faAngleDown : faAngleUp;
        let color1 = amount1 <= 0 ? "text-gray-600" : "text-success";
        let color2 = amount2 <= 0 ? "text-gray-600" : "text-info";
        return (
            <Card border="light" className="shadow-sm">
                <Card.Body className="p-3">
                    <Row className="d-block d-xl-flex align-items-center">
                        <Col>
                            <div className="d-none d-sm-block">
                                <h5 className="m-0">{category}</h5>
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-block d-xl-flex align-items-center">
                        <Col xl={3} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-0 mb-xl-0">
                            <div className={`icon icon-shape icon-md icon-${iconColor} rounded me-4 me-sm-0`}>
                                <FontAwesomeIcon icon={icon} />
                            </div>
                        </Col>
                        <Col xs={12} xl={6} className="px-xl-0">
                            {
                                (amount1||amount1==0?(
                                    <div className={`${color1} small mt-2`}>
                                        <span className={`fw-bold`}>{amount1}</span> - {label1}
                                    </div>
                                ):(null))
                            }
                            {
                                (amount2||amount2==0?(
                                    <div className={`${color2} small mt-2`}>
                                        <span className={`fw-bold`}>{amount2}</span> - {label2}
                                    </div>
                                ):(null))
                            }
                        </Col>
                        <Col xs={12} xl={3} className="px-xl-0 text-center">
                            <div className="d-none d-sm-block">
                                <h2 className="m-0">{amount}</h2>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }
}
DashboardSummaryCard.propTypes = {
    icon : PropTypes.any,
    iconColor : PropTypes.any,
    category : PropTypes.any,
    amount : PropTypes.any,
    label1 : PropTypes.any,
    label2 : PropTypes.any,
    amount1 : PropTypes.any,
    amount2 : PropTypes.any,
}
export default DashboardSummaryCard