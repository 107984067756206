import React from "react";
import {Button, Card, Col, Image, Modal, Row} from '@themesberg/react-bootstrap';
import BasePage from "./../base/BasePage";
import Endpoint from "na-utilities/src/api/Endpoint";
import {handleTimeAgo, isResponseOk} from "na-utilities/src/utils/Utilities";
import label from "na-utilities/src/label/Label";
import {currencyFormat, isEmpty, navigate, parseDate} from "na-utilities/src/utils/Utilities";
import Global, {DD_MM_YYYY, DD_MMMM_YYYY} from "../../utils/Global";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faInfo, faPlus} from "@fortawesome/free-solid-svg-icons";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import {toasting} from "../base/BaseComponent";
import TextInput from "../../components/input/TextInput";
import ContainerCard from "../../components/ContainerCard";
import LocationSelectorModal from "../../components/LocationSelectorModal";
import SmartDeviceDetailPage from "./SmartDeviceDetailPage";

// let paymentProofTable = React.createRef()

class ProductSubscribeDetailPage extends BasePage{
  paymentProofTable = React.createRef()

  constructor(props) {
    super(props);
    let {id} = props.match.params
    this.state = {
      id:id,
      productSubscribe:{},
      smartDevice:{},
      spotLocation:{},
      smartDeviceState:{},
      smartDeviceStatus:{},
      paymentAttribute:{},
      requestProcessConfirmationModal:false,
      smartDeviceStatusHistoryModal:false,
      locationSelectorModal:false
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.props.closePreLoader()
    this.fetchProductSubscribe()
    this.fetchPaymentMediaAttribute()
  }

  fetchProductSubscribe(){
    let {id,productSubscribe, smartDevice,smartDeviceState,smartDeviceStatus, spotLocation} = this.state
    this.get(Endpoint.API.PRODUCT_SUBSCRIBE, {params:{
        "id":id
      }}, null, (res)=>{
      if(isResponseOk(res)){
        productSubscribe = res.data
        smartDevice = productSubscribe.smartDevice
        spotLocation = smartDevice.spotLocation
        smartDeviceState = smartDevice.smartDeviceState
        smartDeviceStatus = smartDevice.smartDeviceStatus
        this.setState({productSubscribe,smartDevice,smartDeviceState,smartDeviceStatus, spotLocation})
      }else{
        // navigate(this.props, DashboardPage.ENDPOINT)
      }
    }, true, true)
  }

  fetchPaymentMediaAttribute(){
    let {paymentAttribute} = this.state
    this.get(Endpoint.API.PAYMENT_MEDIA_ATTRIBUTE, {}, null, (res)=>{
      if(isResponseOk(res)){
        paymentAttribute = res.data
        this.setState({paymentAttribute})
      }
    }, true, true)
  }

  requestToProcess(){
    let {id, smartDevice,smartDeviceState,smartDeviceStatus, spotLocation} = this.state
    this.get(Endpoint.API.PRODUCT_ORDER_REQUEST_TO_PROCESS, {params:{
        "id":id
      }}, null, (res)=>{
      if(isResponseOk(res)){
        toasting.success(res.message)
        smartDevice = res.data
        spotLocation = smartDevice.spotLocation
        smartDeviceState = smartDevice.smartDeviceState
        smartDeviceStatus = smartDevice.smartDeviceStatus
        this.setState({smartDevice, spotLocation, smartDeviceState, smartDeviceStatus, requestProcessConfirmationModal:false},() => {
          if(this.paymentProofTable){
            this.paymentProofTable.current.fetchPaymentProofs()
          }
        })
      }
    }, true, true)
  }

  confirmDialogRender(){
    let {requestProcessConfirmationModal} = this.state
    return(
        <Modal as={Modal.Dialog} centered show={requestProcessConfirmationModal} onHide={()=>{}}>
          <Modal.Header>
            <Modal.Title className="h6">{label.Confirmation}</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={()=>{}} />
          </Modal.Header>
          <Modal.Body className="text-center">
            <Row>
              <Col className="text-center">
                <img src={ApplicationIcons.Icons.warningAttention}/>
              </Col>
            </Row>
            <br/>
            <h4>{label.RequestToProcessTitle}</h4>
            <p>{label.RequestToProcessDescription}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
                variant="outline-warning"
                className="text-gray ms-auto"
                onClick={()=>{
                  this.setState({requestProcessConfirmationModal:false})
                }}>
              {label.Close}
            </Button>
            <Button
                variant="success"
                onClick={()=>{
                  this.requestToProcess()
                }}>
              {label.Continue}
            </Button>
          </Modal.Footer>
        </Modal>
    )
  }

  updateSpotLocation(spotLocation){
    let {smartDevice, smartDeviceState, smartDeviceStatus, productSubscribe} = this.state
    let form = new FormData()
    form.append("smartDeviceId",smartDevice.id)
    form.append("spotLocationId",spotLocation.id)
    this.post(Endpoint.API.SMART_DEVICE_UPDATE_SPOT_LOCATION, form, null, (res)=>{
      if(isResponseOk(res)){
        toasting.success(res.message)
        smartDevice = res.data
        spotLocation = smartDevice.spotLocation
        smartDeviceState = smartDevice.smartDeviceState
        smartDeviceStatus = smartDevice.smartDeviceStatus
        this.setState({smartDevice,smartDeviceState,smartDeviceStatus, spotLocation})
      }
    }, true, true)
  }

  locationSelectionModal(){
    let {locationSelectorModal} = this.state
    return(
        <LocationSelectorModal 
            show={locationSelectorModal}
            onClose={() =>{
              this.setState({locationSelectorModal:false})
            }}
            onSelectLocation={(spotLocation) => {
              this.setState({locationSelectorModal:false},() => {
                this.updateSpotLocation(spotLocation)
              })
            }}
        />
    )
  }



  render(){
    let {productSubscribe, smartDevice, smartDeviceStatus, smartDeviceState, spotLocation} = this.state
    let company = productSubscribe.company?productSubscribe.company:{}
    let productPackage = productSubscribe.productPackage?productSubscribe.productPackage:{}
    let product = productPackage.product?productPackage.product:{}
    let creator = productSubscribe.user?productSubscribe.user:{}
    return (
        <>
          {this.locationSelectionModal()}
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
          </div>
          <Row>
            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12}>
                  <Card border="light" className="text-center p-0 mb-4">
                    <Card.Header className="pb-1">
                      <Card.Title>{label.DeviceInformation}</Card.Title>
                    </Card.Header>
                    <Card.Body className="pb-2">
                      <Row className="pt-0 pl-3 pr-3">
                        <Col className="text-left">
                          <TextInput
                              label={label.Label}
                              readOnly={true}
                              value={smartDevice.label}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-0 pl-3 pr-3">
                        <Col className="text-left">
                          <TextInput
                              label={label.IPAddress}
                              readOnly={true}
                              value={smartDevice.ipAddress}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-0 pl-3 pr-3">
                        <Col className="text-left">
                          <TextInput
                              label={label.DeviceId}
                              readOnly={true}
                              value={smartDevice.deviceId}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-0 pl-3 pr-3">
                        <Col className="text-left">
                          <TextInput
                              label={label.UUID}
                              readOnly={true}
                              value={smartDevice.uuidCode}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-0 pl-3 pr-3">
                        <Col className="text-left">
                          <TextInput
                              label={label.ShortCode}
                              readOnly={true}
                              value={smartDevice.shortCode}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-0 pl-3 pr-3">
                        <Col>
                          <Button
                              className="btn-block"
                              size="sm"
                              onClick={event => {
                                  navigate(this.props, SmartDeviceDetailPage.ENDPOINT+"/"+smartDevice.deviceId)
                              }}>
                            {label.Detail}
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={12} xl={8}>
              <Row>
                <Col>
                  <Card border="light" className="bg-white shadow-sm mb-4 p-0">
                    <Card.Header>
                      <Row>
                        <Col>
                          {label.ValidityInformation}
                        </Col>
                        <Col className="text-right p-0">
                          <Button
                              variant="outline-success"
                              size="sm"
                              onClick={(event) => {
                                event.preventDefault()
                                this.setState({smartDeviceStatusHistoryModal:true})
                              }}>
                            {label.ExtendPeriod}&nbsp;<FontAwesomeIcon style={{width:'12px',height:'12px'}} icon={faCalendar} />
                          </Button>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col>
                          <TextInput
                              label={label.Creator}
                              readOnly={true}
                              value={creator.fullname}
                          />
                        </Col>
                        <Col>
                          <TextInput
                              label={label.Product}
                              readOnly={true}
                              value={product.name}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextInput
                              label={label.Company}
                              readOnly={true}
                              value={company.fullName}
                          />
                        </Col>
                        <Col>
                          <TextInput
                              label={label.Created}
                              readOnly={true}
                              value={parseDate(productSubscribe.created)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextInput
                              label={label.ValidFrom}
                              readOnly={true}
                              value={parseDate(productSubscribe.startDate)}
                          />
                        </Col>
                        <Col>
                          <TextInput
                              label={label.ValidUntil}
                              readOnly={true}
                              value={parseDate(productSubscribe.endDate)}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card border="light" className="bg-white shadow-sm mb-4 p-0">
                    <Card.Header>
                      {label.ProductInformation}
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col>
                          <TextInput
                              label={label.ProductPackage}
                              readOnly={true}
                              value={productPackage.name}
                          />
                        </Col>
                        <Col>
                          <TextInput
                              label={label.Description}
                              readOnly={true}
                              value={productPackage.description}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextInput
                              label={label.MonthPeriod}
                              readOnly={true}
                              value={productPackage.monthPeriod+" "+label.Month}
                          />
                        </Col>
                        <Col>
                          <TextInput
                              label={label.Price}
                              readOnly={true}
                              value={currencyFormat(productPackage.price)}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {this.confirmDialogRender()}
        </>
    );
  };
}
export default Object.assign(ProductSubscribeDetailPage, {ENDPOINT : "/productSubscribeDetail"})
