import React from 'react'
import BaseComponent, {toasting} from "../pages/base/BaseComponent";
import {Button, ButtonGroup, Card, Col, Form, Modal, Row} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {
    cloneObject,
    firstLetterUppercase,
    imageSelector,
    isEmpty,
    isResponseOk
} from "na-utilities/src/utils/Utilities";
import PropTypes from 'prop-types';
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTrash, faUpload} from "@fortawesome/free-solid-svg-icons";
import TextInput from "./input/TextInput";
import {SpotLocationDto} from "../model/model";
import TextAreaInput from "./input/TextAreaInput";
import { withRouter } from 'react-router-dom';
import GoodsItemImageModal from "./GoodsItemImageModal";
import GoodsCategorySelectorModal from "./GoodsCategorySelectorModal";

class GoodsCategoryEditModal extends BaseComponent{

    constructor(props) {
        super(props);
        let {goodsCategoryModal} = this.props
        let goodsCategory = goodsCategoryModal.goodsCategory
        let validation = this.defaultValidation()
        let validationMessage = this.defaultValidationMessage()

        this.state = {
            goodsCategoryModal:goodsCategoryModal,
            goodsCategory:goodsCategory,
            validation:validation,
            validationMessage:validationMessage,
            categorySelectorModal:false,
        }
    }

    defaultValidation = () =>{
        let validation = new SpotLocationDto();
        validation.name = true ;
        validation.description = true ;
        return validation ;
    }
    defaultValidationMessage = () =>{
        let validationMessage = new SpotLocationDto();
        validationMessage.name = "" ;
        validationMessage.description = ""
        return validationMessage ;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let {goodsCategoryModal} = this.props
            let goodsCategory = goodsCategoryModal.goodsCategory
            let show = goodsCategoryModal.show
            this.setState({goodsCategory, goodsCategoryModal}, () => {
                if(show){
                    // this.fetchGoodsCategories((goodsCategories)=>{
                    //     this.setState({goodsCategories})
                    // })
                }
            })
        }
    }

    async submit(){
        let {goodsCategory, validation, validationMessage, goodsCategoryModal} = this.state
        let valid = true ;
        if(isEmpty(goodsCategory.name)){
            validation.name = false
            validationMessage.name = label.EnterGoodsName
            valid = false
        }
        if(isEmpty(goodsCategory.description)){
            valid = false
            validation.description = false
            validationMessage.description = label.EnterGoodsDescription
        }
        if(!valid){
            this.setState({validation, validationMessage})
        }else{
            let form = new FormData()
            let goodsCategoryModel = cloneObject(goodsCategory)
            form.append("goodsCategoryJson", JSON.stringify(goodsCategoryModel))
            this.props.showProcessing()
            this.post(Endpoint.API.GOODS_CATEGORY_SAVE, form,
                null, (res)=>{
                    this.props.closeProcessing()
                    if(isResponseOk(res)){
                        goodsCategory = res.data
                        goodsCategoryModal = {
                            ...goodsCategoryModal, goodsCategory, show:false
                        }
                        this.props.onResult(goodsCategoryModal)
                    }else{
                        toasting.error(res.message)
                    }
                }, false, true)
        }
    }

    render() {
        let {goodsCategory, goodsCategoryModal, validation, validationMessage} = this.state
        return (
            <>
                {super.render()}
                <Modal as={Modal.Dialog} size="lg" centered show={goodsCategoryModal.show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.GoodsCategoryCreation}</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={()=>{
                            this.props.onClose()
                        }} />
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form>
                                    <TextInput
                                        label={label.CategoryName}
                                        required={true}
                                        placeholder={label.EnterCategoryName}
                                        value={goodsCategory.name}
                                        onChange={(value) => {
                                            value = firstLetterUppercase(value)
                                            goodsCategory.name = value
                                            validation.name = true
                                            validationMessage.name = ""
                                            this.setState({goodsCategory, validation, validationMessage})
                                        }}
                                        isError={!validation.name}
                                        errorMessage={validationMessage.name}
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form>
                                    <TextAreaInput
                                        label={label.CategoryDescription}
                                        required={true}
                                        placeholder={label.EnterCategoryDescription}
                                        rows={4}
                                        value={goodsCategory.description}
                                        onChange={(value) => {
                                            value = firstLetterUppercase(value)
                                            goodsCategory.description = value
                                            validation.description = true
                                            validationMessage.description = ""
                                            this.setState({goodsCategory, validation, validationMessage})
                                        }}
                                        isError={!validation.description}
                                        errorMessage={validationMessage.description}
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right px-4 pb-2">
                                <ButtonGroup>
                                    <Button
                                        size="sm"
                                        onClick={async (event) => {
                                            await this.submit()
                                        }}>
                                        {label.Save}&nbsp;&nbsp;<FontAwesomeIcon icon={faSave}/>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}
GoodsCategoryEditModal.propTypes = {
    position:PropTypes.number,
    goodsCategoryModal:PropTypes.object.isRequired,
    onClose:PropTypes.func.isRequired,
    onResult:PropTypes.func.isRequired

}

export default withRouter(GoodsCategoryEditModal)
