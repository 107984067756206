/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.19.577 on 2023-04-22 17:30:44.

export class EBaseDto implements Serializable, Comparable<EBaseDto> {
    id: number;
    created: Date;
    createdBy: number;
    updated: Date;
    updatedBy: number;
    active: boolean;
}

export class WSResponse<D, E> {
    code: number;
    message: string;
    messageError: E;
    data: D;
    totalElement: number;
    totalPage: number;
    pageElement: number;
    success: boolean;
}

export class AmountHistory {
    quantity: number;
    quantityInKw: number;
    updated: Date;
}

export class ChargingAmountDto extends EBaseDto {
    label: string;
    amount: number;
    amountInKw: number;
    code: string;
    suggestion: number;
    product: ProductDto;
    descriptor: string;
    priceAmount: number;
}

export class ChargingLogDto extends EBaseDto {
    type: string;
    message: string;
    consumptionStateAmount: number;
    consumptionStatePercent: number;
    carPowerStateStateAmount: number;
    productOrder: ProductOrderDto;
}

export class CompanyDto extends EBaseDto {
    fullName: string;
    code: string;
    website: string;
    email: string;
    buildDate: Date;
    companyPhoneNumber: string;
    companyDescription: string;
    latitude: number;
    longitude: number;
    address: string;
    village: VillageDto;
    companyType: CompanyTypeDto;
    industry: IndustryDto;
    creator: UserDto;
    image: string;
    imageUrl: string;
    imageUri: string;
    distance: number;
}

export class ContainerDto extends EBaseDto {
    smartDevice: SmartDeviceDto;
    containerType: ContainerTypeDto;
    code: string;
    label: string;
    enabled: boolean;
    position: number;
    productOrderId: number;
    available: boolean;
    message: string;
}

export class ContainerTypeDto extends EBaseDto {
    type: string;
    description: string;
}

export class FormTransitDto extends EBaseDto {
    cookieValue: string;
    product: ProductDto;
    user: UserDto;
    submitted: boolean;
}

export class MainProductDto extends EBaseDto {
    label: string;
    sortir: number;
    model: ProductDto;
}

export class MessageFromUserDto extends EBaseDto {
    name: string;
    email: string;
    message: string;
}

export class OCPPLogDto extends EBaseDto {
    command: string;
    smartDeviceCode: string;
    containerCode: string;
    position: number;
    productOrderId: number;
    requestAt: Date;
    requestObject: { [index: string]: any };
    responseAt: Date;
    responseObject: { [index: string]: any };
}

export class OrderItemDto extends EBaseDto {
    productOrder: ProductOrderDto;
    productPackage: ProductPackageDto;
    allocatedDeviceId: string;
    itemType: string;
}

export class PVInspectionStatusDto extends EBaseDto {
    inspection: ProductOrderDto;
    inspectionStatus: ProductOrderStatusDto;
    note: string;
}

export class PaymentAttribute {
    bankName: string;
    imageLink: string;
    accountNumber: string;
    adminFee: number;
    taxPercent: number;
}

export class PaymentInfoDto extends EBaseDto {
    creditCardCharge: CreditCardCharge;
    ewalletCharge: EWalletCharge;
    gopayCharge: { [index: string]: any };
    paymentMedia: string;
    subPaymentMedia: string;
}

export class PaymentResponseFlowDto extends EBaseDto {
    className: string;
    response: { [index: string]: any };
    paymentMedia: string;
    subPaymentMediaCode: string;
    type: string;
}

export class ProductDto extends EBaseDto {
    name: string;
    code: string;
    description: string;
    slug: string;
    autoSlug: boolean;
    published: boolean;
    iconPhoto: string;
    iconPhotoUrl: string;
    suggestion: number;
    transactionSummary: { [index: string]: any };
    statuses: string[];
    applyable: boolean;
    message: string;
    completeUrl: string;
    shareableAddress: string;
    situationInfo: string;
}

export class ProductOrderDto extends EBaseDto {
    user: UserDto;
    company: CompanyDto;
    orderItems: OrderItemDto[];
    paymentProofs: PaymentProofDto[];
    productOrderStatus: ProductOrderStatusDto;
    currentNote: string;
    reference: string;
    paymentReference: string;
    paymentToken: string;
    paymentAuthId: string;
    visaCard: VisaCardDto;
    viewReference: string;
    currency: string;
    amountPrice: number;
    adminFeePrice: number;
    adminFeePercent: number;
    totalAdminFee: number;
    taxPercent: number;
    taxAmount: number;
    totalProductOrderAmount: number;
    subPaymentMedia: SubPaymentMediaDto;
    paymentMedia: PaymentMediaDto;
    paymentResponseFlow: PaymentResponseFlowDto;
    transactionRequest: { [index: string]: any };
    paymentInfo: PaymentInfoDto;
    linkingData: LinkingData;
    shopeeCustomerId: string;
    ovoCustomerId: string;
    performerImageUrl: string;
    emailSent: number;
    itemCount: number;
}

export class ProductOrderStatusDto extends EBaseDto {
    name: string;
    label: string;
    description: string;
    sortir: number;
    color: string;
    icon: string;
}

export class ProductOrderStatusHistoryDto extends EBaseDto {
    productOrderStatus: ProductOrderStatusDto;
    transaction: ProductOrderDto;
    description: string;
}

export class ProductPackageDto extends EBaseDto {
    code: string;
    name: string;
    description: string;
    monthPeriod: number;
    suggestion: number;
    price: number;
    published: boolean;
    product: ProductDto;
    priceView: string;
}

export class ProductSubscribeDto extends EBaseDto {
    code: string;
    productPackage: ProductPackageDto;
    company: CompanyDto;
    user: UserDto;
    startDate: Date;
    endDate: Date;
    smartDevice: SmartDeviceDto;
}

export class RegisterForm {
    companyName: string;
    companyNameError: string;
    firstName: string;
    firstNameError: string;
    lastName: string;
    lastNameError: string;
    email: string;
    emailError: string;
}

export class RegistrationVerification {
    username: string;
    userCode: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    email: string;
    homePhoneNumber: string;
    mobilePhone: string;
    companyName: string;
    companyDescription: string;
    website: string;
    companyPhoneNumber: string;
    industry: IndustryDto;
    companyType: CompanyTypeDto;
    latitude: number;
    longitude: number;
    address: string;
    village: VillageDto;
}

export class SerialNumberDto extends EBaseDto {
    code: string;
    serialNumberPrints: SerialNumberPrintDto[];
    printedCount: number;
}

export class SerialNumberPrintDto extends EBaseDto {
    serialNumber: SerialNumberDto;
    user: UserDto;
    token: string;
}

export class SmartDeviceConfigurationDto extends EBaseDto {
    key: string;
    value: string;
    original: string;
    readonly: boolean;
    smartDevice: SmartDeviceDto;
}

export class SmartDeviceDto extends EBaseDto {
    name: string;
    label: string;
    deviceId: string;
    shortCode: string;
    description: string;
    slug: string;
    uuidCode: string;
    smartDeviceType: SmartDeviceTypeDto;
    spotLocation: SpotLocationDto;
    smartDeviceStatus: SmartDeviceStatusDto;
    smartDeviceStatusHistories: SmartDeviceStatusHistoryDto[];
    smartDeviceState: SmartDeviceStateDto;
    containers: ContainerDto[];
    productSubscribe: ProductSubscribeDto;
    lastActivityDate: Date;
    lastActivityDescription: string;
    image1: string;
    imageLink1: string;
    image2: string;
    imageLink2: string;
    image3: string;
    imageLink3: string;
    image4: string;
    imageLink4: string;
}

export class SmartDeviceStateDto extends EBaseDto {
    name: string;
    label: string;
    color: string;
    description: string;
    sortir: number;
}

export class SmartDeviceStatusDto extends EBaseDto {
    name: string;
    label: string;
    description: string;
    sortir: number;
    color: string;
    icon: string;
}

export class SmartDeviceStatusHistoryDto extends EBaseDto {
    smartDeviceStatus: SmartDeviceStatusDto;
    smartDevice: SmartDeviceDto;
    description: string;
}

export class SmartDeviceTypeDto extends EBaseDto {
    type: string;
    description: string;
}

export class SpotLocationDto extends EBaseDto {
    name: string;
    code: string;
    description: string;
    buildDate: Date;
    phoneNumber: string;
    creator: UserDto;
    company: CompanyDto;
    address: string;
    village: VillageDto;
    latitude: number;
    longitude: number;
    distance: number;
    image1: string;
    imageLink1: string;
    image2: string;
    imageLink2: string;
    image3: string;
    imageLink3: string;
    image4: string;
    imageLink4: string;
    fullAddress: string;
    regional: string;
    availableImageLink: string;
    available: boolean;
}

export class WeeklyOpenDto extends EBaseDto {
    dayCode: string;
    openTime: string;
    closeTime: string;
    _24Hours: boolean;
    dayName: string;
    spotLocation: SpotLocationDto;
    open: boolean;
}

export class AdminDashboard extends EBaseDto {
    totalProductOrder: number;
    thisMonthProductOrder: number;
    previousMonthProductOrder: number;
    thisMonthProductOrderPercent: number;
}

export class BarInfo {
    id: number;
    label: string;
    startDate: Date;
    endDate: Date;
}

export class DashboardCategoryAnalytic {
    barInfos: BarInfo[];
    datasets: DataSet<number>[];
}

export class DataSet<OBJECT> {
    label: string;
    backgroundColor: string;
    borderColor: string;
    stack: string;
    data: OBJECT[];
}

export class UserDashboard extends EBaseDto {
    totalInspection: number;
    thisMonthInspection: number;
    previousMonthInspection: number;
    thisMonthInspectionPercent: number;
}

export class AttachmentDto extends EBaseDto {
    filename: string;
    fileLink: string;
}

export class SendEmailDto extends EBaseDto {
    subject: string;
    content: string;
    allCustomer: boolean;
    allSubscriber: boolean;
    sendStatus: string;
    sendDirectly: boolean;
    sendAt: Date;
    regularSender: boolean;
    sender: string;
    customerEmails: SendEmailPreference[];
    subscriberEmails: SendEmailPreference[];
    attachments: AttachmentDto[];
    contentType: boolean;
}

export class SendEmailPreference implements Serializable {
    email: string;
    responseStatus: string;
}

export class QueryParam implements Serializable {
    key: string;
    reference: string;
}

export class PushNotificationResponse {
    status: number;
    message: string;
}

export class ApplicationVersionDto extends EBaseDto {
    versionCode: number;
    versionName: string;
    forceToUpdate: boolean;
    title: string;
    description: string;
    slug: string;
    completeUrl: string;
}

export class BannerDto extends EBaseDto {
    image: string;
    startDate: Date;
    endDate: Date;
    sortir: number;
    pagelink: string;
    title: string;
    description: string;
    previewType: string;
    imageLink: string;
}

export class CompanyTypeDto extends EBaseDto {
    name: string;
}

export class ConfigurationAdminDto extends EBaseDto {
    pricePerKW: number;
    parkingFeePerHour: number;
    hourExpiredAfterCreated: number;
    hourExpiredOnWaitingForPayment: number;
    hourExpiredAfterPaid: number;
    suspendedEVToFinishInSecond: number;
    unchangingMeterToFinishInSecond: number;
    unchangingMeterToFinishInFrequent: number;
    host: string;
    port: number;
    username: string;
    password: string;
    mailFrom: string;
    replyTo: string;
    smtpAuth: boolean;
    smtpStarttlsEnable: boolean;
    smtpStarttlsRequired: boolean;
    mailDebug: boolean;
}

export class ConfigurationDto extends EBaseDto {
    workingAgreementFileUrl: string;
    name: string;
    companyName: string;
    companyDescription: string;
    companyAddress: string;
    companyVillage: VillageDto;
    companyPhoneNumber: string;
    companyFaximile: string;
    contactPersonPhoneNumber: string;
    contactWhatsapp: string;
    pageRowSize: number;
    hashtag: string;
    instagramLink: string;
    facebookLink: string;
    twitterLink: string;
    minWithdrawal: number;
    facebookAppId: string;
    googleClientId: string;
    baseApi: string;
    bannerWidth: number;
    bannerHeight: number;
    mobileBannerWidth: number;
    mobileBannerHeight: number;
    regularEmailSender: string;
    mailDomain: string;
    modelBannerWidth: number;
    productBannerHeight: number;
}

export class DataPage<O> {
    datas: O[];
    pageElement: number;
    totalPage: number;
    totalElement: number;
}

export class IndustryDto extends EBaseDto {
    name: string;
}

export class NotificationData implements Serializable {
    notificationId: string;
    referenceId: string;
    referenceClass: string;
    addressLink: string;
}

export class NotificationDto extends EBaseDto implements Serializable {
    title: string;
    message: string;
    topic: string;
    type: string;
    data: NotificationData;
    user: UserDto;
    image: string;
    imageLink: string;
}

export class NotificationUserDto extends EBaseDto implements Serializable {
    user: UserDto;
    notification: NotificationDto;
    userDevice: UserDeviceDto;
    read: boolean;
}

export class PaymentConfigurationDto extends EBaseDto {
    name: string;
    code: string;
    mode: string;
    paymentExpired: number;
    chargingExpired: number;
    xenditSecretKey: string;
    xenditPublicKey: string;
}

export class ResultSendMailDto {
    result: boolean;
    message: string;
}

export class BankAccountDto extends EBaseDto {
    bank: BankDto;
    customerName: string;
    accountCode: string;
}

export class Option extends EBaseDto {
    name: string;
}

export class BankDto extends Option {
    label: string;
    code: string;
    image: string;
    imageLink: string;
}

export class MidtransTransactionStatus {
}

export class PaymentMediaDto extends EBaseDto {
    label: string;
    name: string;
    code: string;
    suggestion: number;
    image: string;
    imageLink: string;
}

export class PaymentProofDto extends EBaseDto {
    name: string;
    description: string;
    image: string;
    imageLink: string;
    removable: boolean;
}

export class SubPaymentMediaDto extends EBaseDto {
    label: string;
    name: string;
    code: string;
    thirdPartyCode: string;
    paymentFeePrice: number;
    paymentFeePercent: number;
    image: string;
    suggestion: number;
    paymentMedia: PaymentMediaDto;
    successCallbackUrl: string;
    failedCallbackUrl: string;
    imageLink: string;
}

export class VisaCardDto extends EBaseDto {
    cardNumber: string;
    expiredMonth: string;
    expiredYear: string;
    cvv: string;
    user: UserDto;
}

export class XenditPaymentStatus {
}

export class Account {
    name: string;
    accountDetails: any;
    balance: number;
    pointBalance: number;
}

export class Action {
    auth: string;
    urlType: string;
    url: string;
    method: string;
}

export class ChannelProperties {
    successReturnUrl: string;
    failureReturnUrl: string;
}

export class EWallet {
    channelCode: string;
    channelProperties: ChannelProperties;
    account: Account;
}

export class EWalletCustomer {
    id: string;
    referenceId: string;
    individualDetail: IndividualDetail;
    email: string;
    mobileNumber: string;
    description: string;
    phoneNumber: string;
    metadata: any;
    addresses: { [index: string]: any }[];
}

export class IndividualDetail {
    givenNames: string;
    surname: string;
    nationality: string;
    placeOfBirth: string;
    dateOfBirth: Date;
    gender: string;
}

export class LinkingData {
    id: string;
    type: string;
    country: string;
    customerId: string;
    referenceId: string;
    customerCode: string;
    businessId: string;
    reusability: string;
    status: string;
    actions: any;
    created: Date;
    updated: Date;
    metadata: any;
    ewallet: EWallet;
    directDebit: any;
    card: any;
    overTheCounter: any;
    virtualAccount: any;
    qrCode: any;
}

export class PaymentLinking {
    id: string;
    event: string;
    created: Date;
    business_id: string;
    data: LinkingData;
}

export class PaymentMethodList {
    data: LinkingData[];
}

export class PaymentValidation {
    status: string;
    customerId: string;
    id: string;
}

export class CityDto extends Option {
    platNumber: string;
    province: ProvinceDto;
    courierId: string;
}

export class DistrictDto extends Option {
    city: CityDto;
    courierId: string;
}

export class ProvinceDto extends Option {
    countryCode: string;
    courierId: string;
}

export class VillageDto extends Option {
    postalCode: string;
    district: DistrictDto;
}

export class MenuDto extends EBaseDto {
    name: string;
    clazz: string;
    groupMenu: string;
    link: string;
    sortir: number;
    module: ModuleDto;
    checked: boolean;
    childMenus: MenuDto[];
}

export class ModuleDto extends EBaseDto {
    name: string;
    moduleKey: string;
    identifier: string;
    description: string;
}

export class RoleDto extends EBaseDto {
    name: string;
    groupName: string;
    deletable: boolean;
    module: ModuleDto;
}

export class RoleMenuDto extends EBaseDto {
    role: RoleDto;
    menu: MenuDto;
}

export class UserRoleDto extends EBaseDto {
    role: RoleDto;
    user: UserDto;
    checked: boolean;
}

export class OAuthPartyDto extends EBaseDto {
    party: string;
    partyId: string;
    email: string;
    name: string;
    token: string;
    profileLink: string;
}

export class PersonDto extends EBaseDto {
    email: string;
    homePhoneNumber: string;
    mobilePhone: string;
    firstname: string;
    lastname: string;
    photo: string;
    birthdate: Date;
    gender: string;
    maritalStatus: string;
    latitude: number;
    longitude: number;
    address: string;
    village: VillageDto;
    fullname: string;
    photoUrl: string;
    fullAddress: string;
}

export class RegistrationDto extends EBaseDto {
    companyName: string;
    firstname: string;
    lastname: string;
    mobilePhone: string;
    email: string;
    userCode: string;
    fullname: string;
    userDto: UserDto;
    registrationCode: string;
    verifiedAt: Date;
    followUp: boolean;
    note: string;
}

export class UserDeviceDto extends EBaseDto {
    fcmToken: string;
    platform: string;
    userAgent: string;
    ipAddress: string;
    deviceId: string;
    latitude: number;
    longitude: number;
    lastOpenDate: Date;
}

export class UserDto extends PersonDto {
    userCode: string;
    username: string;
    password: string;
    agreeTermAndCondition: boolean;
    firstLogin: boolean;
    onGoingModel: number;
    userStatus: string;
    verificationStatus: string;
    registration: RegistrationDto;
    userDevices: UserDeviceDto[];
    installed: boolean;
    deviceCount: number;
    authorization: string;
    registrationToken: string;
    lastOpenDate: Date;
    company: CompanyDto;
    passwordSet: boolean;
    shouldChangePassword: boolean;
    userRoles: UserRoleDto[];
    shopeePaymentLinking: PaymentLinking;
    ovoPaymentLinking: PaymentLinking;
    roleInformation: string;
    creator: UserDto;
    verified: boolean;
}

export class UserInspectionSummary {
    onGoingModel: number;
    totalModel: number;
}

export class UserStatusDto extends EBaseDto {
    performer: UserDto;
    name: string;
    reason: string;
}

export class UserSummary {
    totalChargingAmountInKw: number;
    totalChargingPrice: number;
    waitingProductOrders: ProductOrderDto[];
}

export class CreditCardCharge {
    id: string;
    external_id: string;
    created: string;
    status: ChargeStatus;
    business_id: string;
    authorized_amount: number;
    merchant_id: string;
    merchant_reference_code: string;
    card_type: CardType;
    masked_card_number: string;
    charge_type: ChargeType;
    card_brand: string;
    bank_reconciliation_id: string;
    eci: EciCode;
    capture_amount: number;
    descriptor: string;
    failure_reason: FailureReason;
}

export class CreditCardChargeBuilder {
}

export class CreditCardEnum {
}

export class EWalletBasketItem {
    reference_id: string;
    name: string;
    category: string;
    currency: string;
    price: number;
    quantity: number;
    type: string;
    url: string;
    description: string;
    sub_category: string;
    metadata: { [index: string]: any };
}

export class EWalletBasketItemBuilder {
    referenceId: string;
    name: string;
    category: string;
    currency: string;
    price: number;
    quantity: number;
    type: string;
    url: string;
    description: string;
    subCategory: string;
    metadata: { [index: string]: any };
}

export class EWalletCharge {
    id: string;
    business_id: string;
    reference_id: string;
    status: string;
    currency: string;
    charge_amount: string;
    capture_amount: string;
    checkout_method: string;
    channel_code: string;
    channel_properties: { [index: string]: string };
    actions: { [index: string]: string };
    is_redirect_required: boolean;
    callback_url: string;
    created: string;
    updated: string;
    capture_now: boolean;
    customer_id: string;
    payment_method_id: string;
    failure_code: string;
    basket: EWalletBasketItem[];
    metadata: { [index: string]: any };
}

export class EWalletChargeBuilder {
    id: string;
    businessId: string;
    referenceId: string;
    status: string;
    currency: string;
    chargeAmount: string;
    captureAmount: string;
    checkoutMethod: string;
    channelCode: string;
    channelProperties: { [index: string]: string };
    actions: { [index: string]: string };
    callbackUrl: string;
    created: string;
    updated: string;
    captureNow: boolean;
    customerId: string;
    paymentMethodId: string;
    failureCode: string;
    basket: EWalletBasketItem[];
    metadata: { [index: string]: any };
    redirectRequired: boolean;
}

export class EWalletLinkajaItem {
    id: string;
    name: string;
    price: number;
    quantity: number;
}

export class EWalletLinkajaItemBuilder {
    id: string;
    name: string;
    price: number;
    quantity: number;
}

export interface Serializable {
}

export interface Comparable<T> {
}

export type CardType = "DEBIT" | "CREDIT";

export type ChargeStatus = "CAPTURED" | "AUTHORIZED" | "REVERSED" | "FAILED";

export type ChargeType = "SINGLE_USE_TOKEN" | "MULTIPLE_USE_TOKEN" | "RECURRING";

export type EciCode = "INCOMPLETE_AUTH" | "SUCCESSFUL_AUTH_MASTECARD" | "SUCCESSFUL_AUTH_VISA" | "AUTH_ATTEMPTED" | "UNABLE_AUTH";

export type FailureReason = "EXPIRED_CARD" | "CARD_DECLINED" | "INSUFFICIENT_BALANCE" | "STOLEN_CARD" | "INACTIVE_CARD" | "INVALID_CVN" | "PROCESSOR_ERROR" | "BIN_BLOCK";

export type RefundFailureReason = "INSUFFICIENT_BALANCE" | "REFUND_FAILED";

export type RefundStatus = "SUCCEEDED" | "FAILED";
