import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Col, Form, Modal, Row, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import PaginationTop from "./PaginationTop";
import Global from "na-utilities/src/utils/Global";
import SearchInput from "./input/SearchInput";

export default class SmartDeviceFilterModal extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            selectedSmartDevices:new Set([]),
            smartDevices:[],
            smartDevice:{},
            sortOption:{},
            page:1,
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
            show:false
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    addSmartDeviceSelectedId(object){
        let {selectedSmartDevices} = this.state
        selectedSmartDevices.add(object)
        this.setState({selectedSmartDevices})
    }

    removeSmartDeviceSelectedId(object){
        let {selectedSmartDevices} = this.state
        let objectToDelete = undefined ;
        selectedSmartDevices.forEach((item)=>{
            if(item.id===object.id){
                objectToDelete = item
            }
        })
        if(objectToDelete){
            selectedSmartDevices.delete(objectToDelete)
        }
        this.setState({selectedSmartDevices})
    }

    exists(object){
        let {selectedSmartDevices} = this.state
        let has = false ;
        selectedSmartDevices.forEach((item)=>{
            if(item.id===object.id){
                has = true
            }
        })
        return has
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let show = this.props.show?this.props.show:false
            let selectedSmartDevices = this.props.selectedSmartDevices
            this.setState({show, selectedSmartDevices},() => {
                if(show && !prevState.show){
                    this.fetchSmartDevices(null)
                }
            })
        }

    }

    fetchSmartDevices(search){
        let {user,sortOption} = this.state
        let company = user.company?user.company:{}
        this.get(Endpoint.API.SMART_DEVICES, {
            params :{
                smartDeviceTypeId:Global.SmartDeviceType.TOOLBOX.id,
                ascending: sortOption.ascending,
                sortir: sortOption.sortir,
                page: this.state.page - 1,
                search:search
            }
        }, null, response=>{
            if(response.code===200){
                let smartDevices = response.data
                let totalPage = response.totalPage
                let totalElement = response.totalElement
                let pageElement = response.pageElement
                this.setState({smartDevices:smartDevices,totalPage, totalElement, pageElement})
            }else{
                this.props.history.goBack();
            }
        }, false, true)
    }

    onSelect(smartDevice){
        if(this.props.onSelect){
            this.props.onSelect(smartDevice);
        }
    }




    render() {
        let {smartDevices,show, selectedSmartDevices, page,totalPage, totalElement, pageElement} = this.state
        return (
            <>
                {super.render()}
                <Modal as={Modal.Dialog} size="xl" centered show={show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.SelectSmartDevice}</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={()=>{
                            this.props.onClose()
                        }} />
                    </Modal.Header>
                    <Modal.Header>
                        <Row className="w-100">
                            <Col md={4}>
                                <SearchInput
                                    onEnter={(search) => {
                                        this.fetchSmartDevices(search)
                                    }} onEmpty={() => {
                                        this.fetchSmartDevices(undefined)
                                    }}/>
                            </Col>
                            <Col md={8} className="text-right">
                                <PaginationTop
                                    onSelect={(page) => {

                                    }}
                                    currentPage={page}
                                    pageCount={totalPage}
                                    totalElement={totalElement}
                                    showingRow={page}/>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Table responsive className="table-sm align-items-center table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{label.Name}</th>
                                <th scope="col">{label.DeviceId}</th>
                                <th scope="col">{label.UUID}</th>
                                <th scope="col">{label.Location}</th>
                                <th scope="col">{label.Select}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {smartDevices.map((item, index) => {
                                return (
                                    <tr>
                                        <th scope="row">
                                            {((page - 1) * pageElement) + (index+1)}
                                        </th>
                                        <th scope="row" >
                                            {item.name}
                                        </th>
                                        <td>{item.deviceId}</td>
                                        <td>{item.uuidCode}</td>
                                        <td>{item.spotLocation.name+" - "+item.spotLocation.village.name+", "+item.spotLocation.address}</td>
                                        <td scope="col" className="text-center">
                                            <Form.Check
                                                size="lg"
                                                id="checkbox1"
                                                htmlFor="checkbox1"
                                                checked={this.exists(item)}
                                                onChange={event => {
                                                    if(this.exists(item)){
                                                        this.removeSmartDeviceSelectedId(item)
                                                    }else{
                                                        this.addSmartDeviceSelectedId(item)
                                                    }
                                                }}
                                                className="pl-3"
                                                label="" />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="success"
                            onClick={event => {
                                this.setState({
                                    selectedSmartDevices:new Set([])
                                }, () => {
                                    this.props.onSelect(selectedSmartDevices)
                                })
                            }}>
                            {label.ResetFilter}
                        </Button>
                        <Button
                            variant="info"
                            onClick={event => {
                                this.props.onSelect(selectedSmartDevices)
                            }}>
                            {label.ApplyFilter}
                            &nbsp;
                            {label.Selected_Count(selectedSmartDevices.size?selectedSmartDevices.size:0)}
                            &nbsp;
                            <FontAwesomeIcon icon={faFilter} />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

}
SmartDeviceFilterModal.propTypes = {
    show:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onSelect:PropTypes.func.isRequired,
    selectedSmartDevices:PropTypes.any

}
