import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faHome} from '@fortawesome/free-solid-svg-icons';
import {Breadcrumb, Button, Card, Col, Form, Image, NavLink, Row, Table} from '@themesberg/react-bootstrap';
import BasePage from "./../base/BasePage";
import label from "na-utilities/src/label/Label"
import DashboardPage from "../dashboard/DashboardPage";
import PaginationTable from "../../components/PaginationTable";
import {getUser} from "../../utils/StorageUtil";
import {Link} from "react-router-dom";
import {Routes} from "../../routes";
import Endpoint from "na-utilities/src/api/Endpoint";
import queryString from 'query-string';
import {cloneObject, handleTimeAgo, isResponseOk, navigateBack} from "na-utilities/src/utils/Utilities";
import SearchInput from "../../components/input/SearchInput";
import TableSortOption from "../../components/input/TableSortOption";
import SortOption from "na-utilities/src/model/SortOption";
import GoodsItemImageModal from "../../components/GoodsItemImageModal";
import TextInput from "../../components/input/TextInput";
import TextAreaInput from "../../components/input/TextAreaInput";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import MyImage from "../../components/MyImage";
import UserDetailPage from "../management/UserDetailPage";

class GoodsItemDetailPage extends BasePage {
  constructor(props) {
    super(props);
    let {id} = props.match.params
    this.state = {
      goodsItemId:id,
      goodsItem:{},
      inputSearch: queryString.parse(this.props.query).search ? queryString.parse(this.props.query).search : "",
      user : getUser(),
      goodsItemStatusHistoryPage: queryString.parse(props.query).goodsItemStatusHistoryPage ? queryString.parse(props.query).goodsItemStatusHistoryPage : 1,
      goodsItemStatusHistories:[],
      goodsItemStatusHistoryTotalPage: 0,
      goodsItemStatusHistoryTotalElement: 0,
      goodsItemStatusHistoryPageElement: 0,
      goodsItemStatusHistorySortOption:{},
      createGoodsInfoModal:{
        show:false,
        position:-1,
        goodsInfo:{},
      },
      goodsItemImageModal:{
        show:false,
        position:-1,
        goodsItem:{},
      },
      createGoodsItemModal:{
        show:false,
        position:-1,
        goodsInfo:{},
        goodsItemStatusHistories:[],
      },
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.closePreLoader()
    this.fetchGoodsItem()
    this.fetchGoodsItemStatusHistories()
  }

  componentWillReceiveProps(props, nextContext) {
    if (props != this.props) {
      let goodsItemStatusHistoryPage = queryString.parse(props.query).goodsItemStatusHistoryPage ? queryString.parse(props.query).goodsItemStatusHistoryPage : 1
      let key = queryString.parse(props.query).tab ? queryString.parse(props.query).tab : label.All
      let inputSearch = queryString.parse(props.query).search ? queryString.parse(props.query).search : ""
      this.setState({
        goodsItemStatusHistoryPage: goodsItemStatusHistoryPage,
        key: key,
        inputSearch: inputSearch
      }, () => {
        this.fetchGoodsItemStatusHistories()
      })
    }
  }

  fetchGoodsItem(){
    let {goodsItemId, goodsItem} = this.state
    this.get(Endpoint.API.GOODS_ITEM, {params:{
        "id":goodsItemId
      }}, null, (res)=>{
      if(isResponseOk(res)){
        goodsItem = res.data
        this.setState({goodsItem})
      }else{
        navigateBack(this.props)
      }
    }, false, true)
  }


  fetchGoodsItemStatusHistories = () => {
    let {user,goodsItemStatusHistories, goodsItemStatusHistoryPage, search, goodsItemStatusHistorySortOption, goodsItemId} = this.state
    this.get(Endpoint.API.GOODS_ITEM_STATUS_HISTORIES, {
      params :{
        "goodsItemId":goodsItemId,
        ascending: goodsItemStatusHistorySortOption.ascending,
        sortir: goodsItemStatusHistorySortOption.sortir,
        search: search,
        page: goodsItemStatusHistoryPage - 1,
      }
    }, null, response=>{
      if(response.code===200){
        let goodsItemStatusHistories = response.data
        let goodsItemStatusHistoryTotalPage = response.totalPage
        let goodsItemStatusHistoryTotalElement = response.totalElement
        let goodsItemStatusHistoryPageElement = response.pageElement
        this.setState({goodsItemStatusHistories:goodsItemStatusHistories, goodsItemStatusHistoryTotalPage, goodsItemStatusHistoryTotalElement, goodsItemStatusHistoryPageElement})
      }else{
        this.props.history.goBack();
      }
    }, false, true)
  }

  render() {
    let {user, goodsItemStatusHistories, goodsItem, goodsItemStatusHistoryPage, goodsItemStatusHistoryPageElement,
      goodsItemStatusHistoryTotalPage, goodsItemStatusHistoryTotalElement, goodsItemStatusHistorySortOption,
      goodsItemImageModal, createGoodsItemModal, createGoodsInfoModal} = this.state
    let goodsInfo = goodsItem.goodsInfo?goodsItem.goodsInfo:{}
    let goodsCategory = goodsInfo.goodsCategory?goodsInfo.goodsCategory:{}
    let goodsItemStatusHistory = goodsItem.goodsItemStatusHistory?goodsItem.goodsItemStatusHistory:{}
    let goodsItemStatus = goodsItemStatusHistory.goodsItemStatus?goodsItemStatusHistory.goodsItemStatus:{}
    let lastPhysicalContainerLocation = goodsItem.lastPhysicalContainerLocation?goodsItem.lastPhysicalContainerLocation:{}
    let lastSmartDeviceLocation = goodsItem.lastSmartDeviceLocation?goodsItem.lastSmartDeviceLocation:{}
    let spotLocation = lastSmartDeviceLocation.spotLocation?lastSmartDeviceLocation.spotLocation:{}
    return (
        <>
          {super.render()}
          <GoodsItemImageModal
              goodsItemImageModal={goodsItemImageModal}
              onResult={(goodsItemImageModal) => {
                if(goodsItemImageModal.position==-1){
                  goodsItemStatusHistories.splice(0,0, cloneObject(goodsItemImageModal.goodsItem))
                }else{
                  goodsItemStatusHistories.splice(goodsItemImageModal.position, 1)
                  goodsItemStatusHistories.splice(goodsItemImageModal.position, 0, cloneObject(goodsItemImageModal.goodsItem))
                }
                this.setState({goodsItemStatusHistories, goodsItemImageModal:{show:false, position:-1, goodsItem:{}}})
              }}
              onClose={() => {
                this.setState({goodsItemImageModal:{show:false, position:-1, goodsItem:{}}})
              }}/>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2">
            <div className="d-block mb-0 mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                <Breadcrumb.Item href={DashboardPage.ENDPOINT}>{label.Dashboard}</Breadcrumb.Item>
                <Breadcrumb.Item active>{label.GoodsItemList}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div>
            <Row>
              <Col xl={3}>
                <Row className="mb-4">
                  <Col>
                    <Card className="p-0">
                      <Card.Header className="p-3">
                        {label.GoodsInformation}
                      </Card.Header>
                      <Card.Body className="p-3">
                        <Form>
                          <TextInput
                              label={label.GoodsName}
                              required={true}
                              placeholder={label.EnterGoodsName}
                              value={goodsInfo.name}
                              disabled={true}
                              readOnly={true}
                          />
                        </Form>
                        <Form>
                          <TextAreaInput
                              label={label.GoodsDescription}
                              required={true}
                              placeholder={label.EnterGoodsDescription}
                              rows={4}
                              value={goodsInfo.description}
                              disabled={true}
                              readOnly={true}
                          />
                        </Form>
                        <Form>
                          <TextInput
                              label={label.GoodsCategory}
                              required={true}
                              value={goodsCategory.name}
                              placeholder={" "}
                              disabled={true}
                              readOnly={true}
                          />
                        </Form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <Card border="light" className="bg-white shadow-sm">
                      <Card.Header className="p-3">
                        {label.Status}
                      </Card.Header>
                      <Card.Body>
                        <div className="d-xl-flex align-items-center">
                          <div className="user-avatar xl-avatar">
                            <Image fluid rounded src={goodsItemStatus.iconLink} />
                          </div>
                          <div className="file-field">
                            <div className="d-flex justify-content-xl-center ms-xl-3">
                              <div className="d-flex">
                                <div className="d-md-block text-start">
                                  <div className="fw-normal font-weight-bold mb-1 rounded px-2 text-white" style={{backgroundColor:goodsItemStatus.color}}>{goodsItemStatus.label}</div>
                                  <div className="text-gray small">{handleTimeAgo(goodsItem.updated)}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Footer className="border-warning p-2 text-center">
                        <span className="text-info" style={{fontSize:'12px', fontStyle:'italic', fontWeight:'bold'}}>{goodsItemStatusHistory.information}</span>
                      </Card.Footer>
                      {/*<Card.Footer className="border-warning p-2">*/}
                      {/*  <Button*/}
                      {/*      variant="outline-danger"*/}
                      {/*      size="lg"*/}
                      {/*      className="btn-block"*/}
                      {/*      onClick={event => {*/}
                      {/*        event.preventDefault()*/}
                      {/*      }}>*/}
                      {/*    {label.UpdateStatus}*/}
                      {/*  </Button>*/}
                      {/*</Card.Footer>*/}
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <Card border="light" className="bg-white shadow-sm">
                      <Card.Header className="p-3">
                        {label.RFIDTag}
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col className="text-center">
                            <span className="text-success" style={{fontSize:'26px', fontWeight:'bold'}}>
                              {goodsItem.rfidTag}
                            </span>
                          </Col>
                        </Row>
                      </Card.Body>
                      {/*<Card.Footer className="border-warning p-2">*/}
                      {/*  <Button*/}
                      {/*      variant="outline-warning"*/}
                      {/*      size="lg"*/}
                      {/*      className="btn-block"*/}
                      {/*      onClick={event => {*/}
                      {/*        event.preventDefault()*/}
                      {/*      }}>*/}
                      {/*    {label.UpdateRfidTag}*/}
                      {/*  </Button>*/}
                      {/*</Card.Footer>*/}
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xl={9}>
                <Row className="d-flex flex-wrap mb-4">
                  <Col md={7} className="d-flex">
                    <Card border="light" className="bg-white shadow-sm">
                      <Card.Header className="p-3">
                        <Row className="mb-0">
                          <Col>
                            {label.Image}
                          </Col>
                          <Col className="text-right">
                            <Button
                                variant="outline-warning"
                                size="sm"
                                className="rounded-circle"
                                onClick={(event) => {
                                  event.preventDefault()
                                  this.setState({productOrderStatusHistoryModal:true})
                                }}>
                              <FontAwesomeIcon icon={faEdit} size="sm" />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col>
                            <Card border="light" className="p-2">
                              <Row>
                                <Col className="p-3 d-flex justify-content-start align-items-center">
                                  <MyImage
                                      src={goodsItem.imageLink1?goodsItem.imageLink1:ApplicationIcons.Icons.imagePlaceholder}
                                      onClick={(e)=>{
                                        this.openLightBox(goodsItem.imageLink1)
                                      }}
                                  />
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          <Col>
                            <Card border="light" className="p-2">
                              <Row>
                                <Col className="p-3 d-flex justify-content-start align-items-center">
                                  <MyImage
                                      src={goodsItem.imageLink2?goodsItem.imageLink2:ApplicationIcons.Icons.imagePlaceholder}
                                      onClick={(e)=>{
                                        this.openLightBox(goodsItem.imageLink2)
                                      }}
                                  />
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          <Col>
                            <Card border="light" className="p-2">
                              <Row>
                                <Col className="p-3 d-flex justify-content-start align-items-center">
                                  <MyImage
                                      src={goodsItem.imageLink3?goodsItem.imageLink3:ApplicationIcons.Icons.imagePlaceholder}
                                      onClick={(e)=>{
                                        this.openLightBox(goodsItem.imageLink3)
                                      }}
                                  />
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          <Col>
                            <Card border="light" className="p-2">
                              <Row>
                                <Col className="p-3 d-flex justify-content-start align-items-center"  >
                                  <MyImage
                                      src={goodsItem.imageLink4?goodsItem.imageLink4:ApplicationIcons.Icons.imagePlaceholder}
                                      onClick={(e)=>{
                                        this.openLightBox(goodsItem.imageLink4)
                                      }}
                                  />
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={5} className="d-flex d-block">
                    <Card border="light" className="bg-white shadow-sm">
                      <Card.Header className="p-3">
                        {label.LastLocation}
                      </Card.Header>
                      <Card.Body>
                        <div className="d-xl-flex align-items-center">
                          <div className="user-avatar xl-avatar">
                            <Image fluid rounded src={ApplicationIcons.Icons.cabinet} />
                          </div>
                          <div className="file-field">
                            <div className="d-flex justify-content-xl-center ms-xl-3">
                              <div className="d-flex">
                                <div className="d-md-block text-start">
                                  <div
                                      className="fw-normal
                                      font-weight-bold mb-1
                                      rounded px-2 btn-info">
                                    {lastSmartDeviceLocation.deviceId}
                                  </div>
                                  <div className="text-gray small">{lastPhysicalContainerLocation.title}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Footer className="border-warning p-2 text-center">
                        <span className="text-info" style={{fontSize:'12px', fontStyle:'italic', fontWeight:'bold'}}>{spotLocation.fullAddress}</span>
                      </Card.Footer>
                      {/*<Card.Footer className="border-warning p-2">*/}
                      {/*  <Button*/}
                      {/*      variant="outline-danger"*/}
                      {/*      size="lg"*/}
                      {/*      className="btn-block"*/}
                      {/*      onClick={event => {*/}
                      {/*        event.preventDefault()*/}
                      {/*      }}>*/}
                      {/*    {label.UpdateStatus}*/}
                      {/*  </Button>*/}
                      {/*</Card.Footer>*/}
                    </Card>
                  </Col>
                </Row>
                <Row className="py-4">
                  <Col xs={4} md={4} xl={3} className="d-flex justify-content-start align-items-center">
                    <strong>{label.StatusHistory}</strong>
                  </Col>
                  <Col xs={4} md={4} xl={3} className="text-right d-flex justify-content-end align-items-center">
                    <TableSortOption
                        sortOptions={
                          [
                            SortOption.init(label.Longest,"created",true),
                            SortOption.init(label.Latest,"created",false),
                          ]
                        }
                        sortOption={goodsItemStatusHistorySortOption}
                        placeholder={label.SortBy}
                        onSelect={(goodsItemStatusHistorySortOption) => {
                          this.setState({goodsItemStatusHistorySortOption},() => {
                            this.fetchGoodsItemStatusHistories()
                          })
                        }}
                        onReset={() => {
                          this.setState({goodsItemStatusHistorySortOption:{}},() => {
                            this.fetchGoodsItemStatusHistories()
                          })
                        }}/>
                  </Col>
                  <Col xs={8} md={6} lg={3} xl={6}>
                    <SearchInput onEnter={(search) => {
                      this.setState({search}, () => {
                        this.fetchGoodsItemStatusHistories()
                      })
                    }} onEmpty={() => {
                      this.setState({search:""},() => {
                        this.fetchGoodsItemStatusHistories()
                      })
                    }}/>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <Card border="light" className="table-wrapper table-responsive shadow-sm">
                      <Card.Body className="p-0">
                        <Table hover className="table-sm align-items-center">
                          <thead>
                          <tr>
                            <th className="border-bottom">#</th>
                            <th className="border-bottom">{label.Status}</th>
                            <th className="border-bottom text-center">{label.Executor}</th>
                            <th className="border-bottom">{label.DateTime}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {goodsItemStatusHistories.map((goodsItemStatusHistory, index) => (
                              <tr className={(goodsItemStatusHistory.isNew?"yellow-background-blinking":"")+" "+(goodsItemStatusHistory.isDuplicate?"red-background-blinking":"")}>
                                <td>
                                  <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
                                    {((goodsItemStatusHistoryPage-1)* goodsItemStatusHistoryPageElement)+(index+1)}
                                  </Card.Link>
                                </td>
                                <td>
                                  <span className="fw-normal" style={{color:goodsItemStatusHistory.goodsItemStatus.color}}>
                                    {goodsItemStatusHistory.goodsItemStatus.label}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <NavLink
                                      style={{textDecoration:'underline'}}
                                      href={UserDetailPage.ENDPOINT+"/"+goodsItemStatusHistory.executor.userCode}>
                                    {goodsItemStatusHistory.executor.fullname}
                                  </NavLink>
                                </td>
                                <td>
                                  <span className="fw-normal">{handleTimeAgo(goodsItem.created)}</span>
                                </td>
                              </tr>
                          ))}
                          </tbody>
                        </Table>
                      </Card.Body>
                      <PaginationTable
                          onSelect={(page) => {
                            this.setState({goodsItemStatusHistoryPage:page},() => {
                              this.fetchGoodsItemStatusHistories()
                            })
                          }}
                          showingRow={goodsItemStatusHistories.length}
                          currentPage={goodsItemStatusHistoryPage}
                          pageCount={goodsItemStatusHistoryTotalPage}
                          totalElement={goodsItemStatusHistoryTotalElement}/>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </>
    );
  }
}
export default Object.assign(GoodsItemDetailPage, {ENDPOINT : "/goodsItemDetail"})

