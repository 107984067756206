import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Card, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import PropTypes from "prop-types";
import {isResponseOk} from "na-utilities/src/utils/Utilities";
import Endpoint from "na-utilities/src/api/Endpoint";
import {handleTimeAgo} from "na-utilities/src/utils/Utilities";
import PaginationTable from "./PaginationTable";


class UserActivityTable extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            user:this.props.user?this.props.user:{},
            userActivities:[],
            page:1,
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
            sortOption:{}
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let {user} = this.state
        let nextUser = nextProps.user?nextProps.user:{}
        if(user.id!==nextUser.id){
            this.setState({user:nextUser},() => {
                this.getUserActivities()
            })
        }
    }
    getUserActivities(){
        let {user, userActivities, page} = this.state
        this.get(Endpoint.API.USER_ACTIVITIES, {params:{"userNames":user.username, page:page-1}}, null, (res)=>{
            if(isResponseOk(res)){
                userActivities = res.data
                let totalPage = res.totalPage
                let totalElement = res.totalElement
                let pageElement = res.pageElement
                this.setState({userActivities,totalPage, totalElement, pageElement})
            }
        }, false, false)
    }


    render() {
        let {user, userActivities, page, pageElement, totalPage, totalElement, sortOption} = this.state
        return (
            <div>
                <Card border="light" className="shadow-sm">
                    <Card.Header>
                        <h5>{label.UserActivity}</h5>
                    </Card.Header>
                    <Table key={module.id} responsive className="align-items-center table-flush">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{label.User}</th>
                            <th scope="col">{label.Type}</th>
                            <th scope="col">{label.TimeActivity}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            userActivities.map((userActivity, index)=>{
                                return(
                                    <tr key={index}>
                                        <td>
                                            {((page - 1) * pageElement) + (++index)}
                                        </td>
                                        <td scope="row">{userActivity.user.fullname}</td>
                                        <td scope="row">{userActivity.message}</td>
                                        <td scope="row">{handleTimeAgo(userActivity.created)}</td>
                                    </tr>
                                )
                            })                                    }
                        </tbody>
                    </Table>
                    <PaginationTable
                        onSelect={(page) => {
                            this.setState({page},() => {
                                this.getUserActivities()
                            })
                        }}
                        showingRow={userActivities.length}
                        currentPage={page}
                        pageCount={totalPage}
                        totalElement={totalElement}/>

                </Card>
            </div>
        );
    }

}

UserActivityTable.propTypes = {
    user : PropTypes.any,
}

export default UserActivityTable