import React from "react";
import {Card, Nav, Pagination, Row, Col} from "@themesberg/react-bootstrap";
import PropTypes from 'prop-types';
import Global from '../utils/Global'
import label from 'na-utilities/src/label/Label'

export default class PaginationTop extends React.Component{

    state = {}
    constructor(props) {
        super(props);
        let currentPage = this.props.currentPage ? this.props.currentPage : 1
        this.state = {
            totalElement:this.props.totalElement?this.props.totalElement:0,
            pageCount : this.props.pageCount?this.props.pageCount:0,
            onSelect : this.props.onSelect,
            currentPage : currentPage,
            currentPaging : currentPage > Global.LIMIT_PAGING_BUTTON ? (parseInt(parseInt(currentPage) / Global.LIMIT_PAGING_BUTTON) + 1) : 1
        }
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            let currentPage = props.currentPage?props.currentPage:1
            this.setState({
                totalElement : props.totalElement?props.totalElement:0,
                pageCount : props.pageCount?props.pageCount:0,
                onSelect : props.onSelect,
                currentPage : currentPage,
                currentPaging : currentPage>Global.LIMIT_PAGING_BUTTON?(parseInt(parseInt(currentPage)/Global.LIMIT_PAGING_BUTTON)+1):1
            }, () => {
                // alert(this.state.currentPage)
            })
        }
    }
    render() {
        let pageCount = this.state.pageCount
        let totalElement = this.state.totalElement
        let currentPage = this.state.currentPage
        let onSelect = this.state.onSelect
        let pages = new Array(pageCount)
        let currentPaging = this.state.currentPaging
        for (var i = 0; i < pageCount; i++) {
            pages[i] = i + 1
        }
        let showingRow = this.props.showingRow?this.props.showingRow:0

        return (
            <>
                <span className="mb-2 mb-lg-0" >
                    <b>{((currentPage-1)*10)+showingRow}</b> {label.OutOf} <b>{totalElement}</b> {totalElement>1?label.Rows:label.Row}&nbsp;({pageCount} {(pageCount>1?label.Pages:label.Page)})
                </span>
                &nbsp;&nbsp;
                <Pagination className="mb-2 mb-lg-0 text-right d-inline-flex" size="sm">
                    {
                        currentPage>1&&(
                            <Pagination.Prev
                                style={{display:'inline-block'}}
                                onClick={event => {
                                    event.preventDefault()
                                    let currentPaging = this.state.currentPaging
                                    if((currentPaging*Global.LIMIT_PAGING_BUTTON)>parseInt(this.state.currentPage)-1){
                                        currentPaging = currentPaging-1
                                    }
                                    this.setState({
                                        currentPage : parseInt(currentPage)-1,
                                        currentPaging :currentPaging
                                    }, () => {
                                        if(onSelect){
                                            onSelect(this.state.currentPage)
                                        }
                                    })
                                }}>
                                {'<'}
                            </Pagination.Prev>
                        )
                    }
                    {
                        (currentPaging>1)&&(
                            <Pagination.Prev
                                style={{display:'inline-block'}}
                                onClick={event => {
                                    event.preventDefault()
                                    this.setState({
                                        currentPaging:this.state.currentPaging-1
                                    })
                                }}>
                                {'<<'}
                            </Pagination.Prev>
                        )
                    }
                    {
                        pages.map((item, index)=>(
                            ((Global.LIMIT_PAGING_BUTTON*(currentPaging))>index+1 && (Global.LIMIT_PAGING_BUTTON*(currentPaging-1))<=index+1)&&(
                                <>
                                    <Pagination.Item
                                        key={index}
                                        style={{display:'inline-blocks'}}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            if(item!=currentPage) {
                                                this.setState({
                                                    currentPage: item
                                                }, () => {
                                                    if(onSelect){
                                                        onSelect(this.state.currentPage)
                                                    }
                                                })
                                            }
                                        }}
                                        active={currentPage==index+1}>
                                        {item}
                                    </Pagination.Item>
                                    &nbsp;
                                </>
                            )
                        ))
                    }

                    {
                        (pages.length>(Global.LIMIT_PAGING_BUTTON*currentPaging))&&(
                            <Pagination.Next
                                style={{display:'inline-block'}}
                                onClick={event => {
                                    event.preventDefault()
                                    this.setState({
                                        currentPaging:this.state.currentPaging+1
                                    })
                                }}>
                                {'>>'}
                            </Pagination.Next>
                        )
                    }
                    {
                        (currentPage<pageCount)&&(
                            <Pagination.Next
                                style={{display:'inline-block'}}
                                onClick={event => {
                                    event.preventDefault()
                                    let currentPaging = this.state.currentPaging
                                    if((currentPaging*Global.LIMIT_PAGING_BUTTON)<parseInt(this.state.currentPage)+1){
                                        currentPaging = currentPaging+1
                                    }
                                    this.setState({
                                        currentPage : parseInt(this.state.currentPage)+1,
                                        currentPaging :currentPaging
                                    }, () => {
                                        if(onSelect){
                                            onSelect(this.state.currentPage)
                                        }
                                    })
                                }}>
                                {'>'}
                            </Pagination.Next>
                        )
                    }
                </Pagination>
            </>
        );
    }
}

PaginationTop.propTypes = {
    onSelect : PropTypes.func.isRequired,
    pageCount : PropTypes.number.isRequired,
    showingRow: PropTypes.number,
    currentPage : PropTypes.number.isRequired,
    totalElement:PropTypes.number.isRequired
}