import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Card, Col, NavLink, Row, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faEye, faUndo} from "@fortawesome/free-solid-svg-icons";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {handleTimeAgo} from "na-utilities/src/utils/Utilities";
import SmartDevicePage from "../pages/subscribe/SmartDevicePage";
import SmartDeviceDetailPage from "../pages/subscribe/SmartDeviceDetailPage";

const pageVisits = [
    { id: 1, views: 4.525, returnValue: 255, bounceRate: 42.55, pageName: "/demo/admin/index.html" },
    { id: 2, views: 2.987, returnValue: 139, bounceRate: -43.52, pageName: "/demo/admin/forms.html" },
    { id: 3, views: 2.844, returnValue: 124, bounceRate: -32.35, pageName: "/demo/admin/util.html" },
    { id: 4, views: 1.220, returnValue: 55, bounceRate: 15.78, pageName: "/demo/admin/validation.html" },
    { id: 5, views: 505, returnValue: 3, bounceRate: -75.12, pageName: "/demo/admin/modals.html" }
];

class DashboardSmartDeviceTable extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            smartDevices:[]
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchSmartDevices()
    }

    fetchSmartDevices = () => {
        let {user, smartDevices, search, sortOption} = this.state
        this.get(Endpoint.API.SMART_DEVICES_LATEST_5_HAVING_ACTIVITY, {
            params :{}
        }, null, response=>{
            if(response.code===200){
                let smartDevices = response.data
                this.setState({smartDevices:smartDevices})
            }
        }, false, true)
    }

    render() {
        let {smartDevices} = this.state
        return (
            <div>
                <Card border="light" className="shadow-sm">
                    <Card.Header className="p-3">
                        <Row className="align-items-center">
                            <Col>
                                <h5>{label.SmartDevice}</h5>
                            </Col>
                            <Col className="text-end">
                                <Button
                                    variant="info"
                                    size="sm"
                                    onClick={event => {
                                        this.fetchSmartDevices()
                                    }}>
                                    <FontAwesomeIcon size="sm" icon={faUndo}/>
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Table responsive className="table-flush">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{label.Code}</th>
                            <th scope="col">{label.Type}</th>
                            <th scope="col">{label.Location}</th>
                            <th scope="col">{label.TimeActivity}</th>
                            <th scope="col">{label.Description}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {smartDevices.map((smartDevice, index) => {
                            return (
                                <tr>
                                    <td>{index+1}</td>
                                    <td style={{padding:4}}>
                                        <NavLink
                                            style={{textDecoration:'underline'}}
                                            href={SmartDeviceDetailPage.ENDPOINT+"/"+smartDevice.deviceId}>
                                            {smartDevice.shortCode}
                                        </NavLink>
                                    </td>
                                    <td>{smartDevice.smartDeviceType.type}</td>
                                    <td>{smartDevice.spotLocation.regional}</td>
                                    <td>{handleTimeAgo(smartDevice.lastActivityDate)}</td>
                                    <td>
                                        {smartDevice.lastActivityDescription}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </Card>
            </div>
        );
    }

}

export default DashboardSmartDeviceTable