import React from 'react'
import {Form, InputGroup} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import label from "na-utilities/src/label/Label";

export default class SearchInput extends React.Component{

    state = {}
    constructor(props) {
        super(props);
        this.state = {
            value:props.value?props.value:""
        }

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            this.setState({value:this.props.value})
        }
    }



    render() {
        let {value} = this.state
        let {placeholder, onEmpty, onEnter} = this.props

        return (
            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch}/>
                </InputGroup.Text>
                <Form.Control
                    value={value}
                    type="text"
                    placeholder={placeholder?placeholder:label.Search}
                    onChange={e=>{
                        let value = e.target.value
                        if(value===''){
                            if(onEmpty){
                                onEmpty()
                            }
                        }
                        this.setState({value})
                    }}
                    onKeyDown={(event)=>{
                        if (event.key === 'Enter') {
                            let value = event.target.value
                            if(value!=''){
                                if(onEnter){
                                    onEnter(value)
                                }
                            }
                        }
                    }}
                />
            </InputGroup>
        );
    }
}
SearchInput.propTypes = {
    placeholder:PropTypes.string,
    onEnter:PropTypes.func.isRequired,
    onEmpty:PropTypes.func.isRequired,
    value:PropTypes.string
}