import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Col, Form, Modal, Row, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEdit, faFilter} from "@fortawesome/free-solid-svg-icons";
import PaginationTop from "./PaginationTop";
import SearchInput from "./input/SearchInput";

export default class GoodsCategoryFilterModal extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            selectedGoodsCategories:new Set([]),
            search:"",
            goodsCategories:[],
            goodsCategory:{},
            sortOption:{},
            page:1,
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
            show:false
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    addGoodsCategorySelectedId(object){
        let {selectedGoodsCategories} = this.state
        selectedGoodsCategories.add(object)
        this.setState({selectedGoodsCategories})
    }

    removeGoodsCategorySelectedId(object){
        let {selectedGoodsCategories} = this.state
        let objectToDelete = undefined ;
        selectedGoodsCategories.forEach((item)=>{
            if(item.id===object.id){
                objectToDelete = item
            }
        })
        if(objectToDelete){
            selectedGoodsCategories.delete(objectToDelete)
        }
        this.setState({selectedGoodsCategories})
    }

    exists(object){
        let {selectedGoodsCategories} = this.state
        let has = false ;
        selectedGoodsCategories.forEach((item)=>{
            if(item.id===object.id){
                has = true
            }
        })
        return has
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let show = this.props.show?this.props.show:false
            let selectedGoodsCategories = this.props.selectedGoodsCategories
            this.setState({show, selectedGoodsCategories},() => {
                if(show && !prevState.show){
                    this.fetchGoodsCategoryList(null)
                }
            })
        }

    }

    fetchGoodsCategoryList(){
        let {goodsCategory, sortOption, search} = this.state
        this.get(Endpoint.API.GOODS_CATEGORIES, {
            params :{
                ascending: sortOption.ascending,
                sortir: sortOption.sortir,
                page: this.state.page - 1,
                search:search
            }
        }, null, response=>{
            if(response.code===200){
                let goodsCategories = response.data
                let totalPage = response.totalPage
                let totalElement = response.totalElement
                let pageElement = response.pageElement
                this.setState({goodsCategories:goodsCategories,totalPage, totalElement, pageElement})
            }else{
                this.props.history.goBack();
            }
        }, false, true)
    }

    onSelect(goodsCategory){
        if(this.props.onSelect){
            this.props.onSelect(goodsCategory);
        }
    }




    render() {
        let {goodsCategories,show, selectedGoodsCategories, page,totalPage, totalElement, pageElement} = this.state
        return (
            <>
                {super.render()}
                <Modal as={Modal.Dialog} size="xl" centered show={show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.SelectGoodsCategory}</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={()=>{
                            this.props.onClose()
                        }} />
                    </Modal.Header>
                    <Modal.Header>
                        <Row className="w-100">
                            <Col md={4}>
                                <SearchInput
                                    onEnter={(search) => {
                                        this.setState({search}, () => {
                                            this.fetchGoodsCategoryList()
                                        })
                                    }} onEmpty={() => {
                                        this.setState({search:""}, () => {
                                            this.fetchGoodsCategoryList()
                                        })
                                     }}/>
                            </Col>
                            <Col md={8} className="text-right">
                                <PaginationTop
                                    onSelect={(page) => {
                                        this.setState({page}, () => {
                                            this.fetchGoodsCategoryList()
                                        })
                                    }}
                                    currentPage={page}
                                    pageCount={totalPage}
                                    totalElement={totalElement}
                                    showingRow={page}/>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive className="table-sm align-items-center table-flush p-0">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{label.Name}</th>
                                <th scope="col">{label.Description}</th>
                                <th scope="col">{label.Exclusive}</th>
                                <th scope="col">{label.Select}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {goodsCategories.map((item, index) => {
                                return (
                                    <tr>
                                        <th scope="row">
                                            {((page - 1) * pageElement) + (index+1)}
                                        </th>
                                        <th scope="row" >
                                            {item.name}
                                        </th>
                                        <td style={{ whiteSpace: 'normal', wordWrap: 'break-word'}}>{item.description}</td>
                                        <td className="text-center">
                                            {
                                                item.owner?
                                                    (
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className="text-success"
                                                            size="lg"
                                                        />
                                                    ):
                                                    ("-")
                                            }
                                        </td>
                                        <td scope="col" className="text-center">
                                            <Form.Check
                                                size="lg"
                                                id="checkbox1"
                                                htmlFor="checkbox1"
                                                checked={this.exists(item)}
                                                onChange={event => {
                                                    if(this.exists(item)){
                                                        this.removeGoodsCategorySelectedId(item)
                                                    }else{
                                                        this.addGoodsCategorySelectedId(item)
                                                    }
                                                }}
                                                className="pl-3"
                                                label="" />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="success"
                            onClick={event => {
                                this.setState({
                                    selectedGoodsCategories:new Set([])
                                }, () => {
                                    this.props.onSelect(selectedGoodsCategories)
                                })
                            }}>
                            {label.ResetFilter}
                        </Button>
                        <Button
                            variant="info"
                            onClick={event => {
                                this.props.onSelect(selectedGoodsCategories)
                            }}>
                            {label.ApplyFilter}
                            &nbsp;
                            {label.Selected_Count(selectedGoodsCategories.size?selectedGoodsCategories.size:0)}
                            &nbsp;
                            <FontAwesomeIcon icon={faFilter} />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

}
GoodsCategoryFilterModal.propTypes = {
    show:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onSelect:PropTypes.func.isRequired,
    selectedGoodsCategories:PropTypes.any

}
