import React from 'react'
import BaseComponent, {toasting} from "../pages/base/BaseComponent";
import {Button, Card, Col, Row, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {isResponseOk} from "na-utilities/src/utils/Utilities";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import PaymentProofUploader from "./PaymentProofUploader";
import axios from "axios";

export default class PaymentProofTable extends BaseComponent{

    constructor(props) {
        super(props);
        let {productOrder} = this.props
        this.state = {
            paymentProofs:[],
            paymentProof:{},
            productOrder:productOrder?productOrder:{},
            imageToCrop:undefined,
            imageBlob:undefined,
            imageCropperModal:false
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let {productOrder} = this.props
            if(productOrder && productOrder.id){
                this.setState({productOrder},() => {
                    this.fetchPaymentProofs()
                })
            }
        }
    }

    getPaymentProofs(){
        let {paymentProofs} = this.state
        return paymentProofs;
    }

    fetchPaymentProofs(){
        let {paymentProofs, productOrder} = this.state
        this.get(Endpoint.API.PAYMENT_PROOFS,
            {params:{"productOrderId":productOrder.id}},
            null, (res)=>{
            if(isResponseOk(res)){
                paymentProofs = res.data
                this.setState({paymentProofs})
            }
        }, false, false)
    }

    deletePaymentProofs(index){
        let {paymentProofs, productOrder} = this.state
        this.get(Endpoint.API.PAYMENT_PROOF_DELETE+"/"+paymentProofs[index].id,
            {}, null, (res)=>{
                if(isResponseOk(res)){
                    paymentProofs.splice(index,1)
                    this.setState({paymentProofs})
                }else{
                    toasting.error(label.CannotDeletePaymentProof)
                }
            }, true, false)
    }

    async savePaymentProof(){
        let {paymentProof,productOrder} = this.state
        let multipartFile = await new Promise(resolve => {
            axios({
                url: paymentProof.imageLink,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                this.closeProgress()
                var file = response.data ;
                resolve(file)
            })
        })

        paymentProof.imageLink = undefined
        let form = new FormData()
        form.append("productOrderId", productOrder.id)
        form.append("paymentProofJson", JSON.stringify(paymentProof))
        form.append("multipartFile", multipartFile)
        // let res = await this.asyncPost(Endpoint.API.AUTH, form, null, true, true);
        this.post(Endpoint.API.PAYMENT_PROOF_SAVE_UPLOAD, form, null, async (response)=>{
            if(response && isResponseOk(response)){
                this.fetchPaymentProofs()
            }else {
                if(response){
                    toasting.error(response.message)
                }else{
                    toasting.error(label.SomethingUnexpected)
                }
            }
        }, true,false)

    }


    render() {
        let {paymentProofs, paymentProof, productOrder,imageToCrop,imageCropperModal,imageBlob} = this.state
        return (
            <>
                {super.render()}
                <div className="mb-4">
                    <Card border="light" className="shadow-sm">
                        <Card.Header>
                            <Row>
                                <Col>
                                    {label.PaymentProof}
                                </Col>
                                <Col className="text-right">
                                    <Button
                                        size="sm"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.setState({imageCropperModal:true})
                                        }}>
                                        {label.Upload}&nbsp;<FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Table responsive className="align-items-center table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{label.Name}</th>
                                <th scope="col">{label.Note}</th>
                                <th scope="col">{label.Image}</th>
                                <th scope="col">{label.Remove}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {paymentProofs.map((item, index) => {
                                return (
                                    <tr>
                                        <th scope="row">{index+1}</th>
                                        <th scope="row">{item.name}</th>
                                        <td>{item.description}</td>
                                        <td>
                                            <Card.Img
                                                style={{width:'50px'}}
                                                src={item.imageLink}
                                                onClick={()=>{
                                                    this.openLightBox(item.imageLink)
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Button
                                                variant="warning"
                                                disabled={!item.removable}
                                                size="sm"
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    if(item.removable){
                                                        this.deletePaymentProofs(index)
                                                    }
                                                }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Card>
                </div>
                <PaymentProofUploader
                    show={imageCropperModal}
                    callback={paymentProof=>{
                        this.setState({
                            paymentProof:paymentProof,
                            imageCropperModal:false,
                        },async () => {
                            await this.savePaymentProof()
                        })
                    }}
                    cancelCallback={()=>{
                        this.setState({
                            paymentProof:undefined,
                            imageCropperModal:false,
                        })
                }}/>
            </>
        );
    }

    openCropper(imageToCrop, cropperCallback, cancelCallback){
        this.setState({
            imageToCrop:imageToCrop,
            imageCropperModal:true,
            cropperCallback:cropperCallback,
            cancelCallback:cancelCallback
        })
    }

}
PaymentProofTable.propTypes = {
    productOrder:PropTypes.object.isRequired,

}
