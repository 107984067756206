import React from 'react';
import {Form, InputGroup} from "@themesberg/react-bootstrap";
import PropTypes from 'prop-types';

export default class ItemOption extends React.Component{

    hmap = new Object()

    constructor(props) {
        super(props);
        this.state = {
            callback : props.callback,
            objects:props.objects?props.objects:[],
            value:props.value?props.value:{},
            disable:props.disable,
            label:props.label
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props!=nextProps){
            this.setState({
                callback : nextProps.callback,
                objects:nextProps.objects?nextProps.objects:[],
                value:nextProps.value?nextProps.value:{},
                disable:nextProps.disable,
                label:nextProps.label
            }, () => {
                this.state.objects.map((item, index)=>{
                    this.hmap[item.id] = item;
                })
            })
        }
    }
    componentDidMount() {
        this.state.objects.map((item, index)=>{
            this.hmap[item.id] = item;
        })
    }

    render() {
        let {objects, value, label, disable} = this.state
        let {isError,errorMessage} = this.props

        const {id} = value
        return (
            <Form.Group className="mb-4">
                {
                    !this.props.hideLabel&&(
                        <Form.Label for="select">{label}</Form.Label>
                    )
                }
                <Form.Select
                    size={this.props.size}
                    value={id?id:""}
                    onChange={
                        e=>{
                            e.preventDefault()
                            if(this.props.callback && this.props.objects.length>0 && e.target.value){
                               this.props.callback(this.hmap[e.target.value]!=undefined?this.hmap[e.target.value]:{})
                            }
                            this.setState({
                                value:e.target.value
                            })
                        }
                    }
                    disabled={disable}
                    isInvalid={isError}>
                    {
                        !this.props.hideOptionLabel&&(
                            <option value={null}>-- {this.state.label} --</option>
                        )
                    }
                    {
                        objects.map((item, index) =>(
                            <option key={item.id} value={item.id}>
                                {item[this.props.fieldForLabel?this.props.fieldForLabel:'name'].toString()}
                            </option>
                        ))
                    }
                </Form.Select>
                {isError&&(
                    <Form.Control.Feedback type="invalid" style={{display:'unset', position:'absolute'}}>{errorMessage?errorMessage:label}</Form.Control.Feedback>
                )}
            </Form.Group>
        );
    }
}

ItemOption.propTypes ={
    callback : PropTypes.func.isRequired,
    objects:PropTypes.any,
    value:PropTypes.any,
    disable:PropTypes.bool,
    label:PropTypes.string,
    hideLabel:PropTypes.bool,
    hideOptionLabel:PropTypes.bool,
    fieldForLabel:PropTypes.string,
    isError:PropTypes.bool,
    errorMessage:PropTypes.string,
    size:PropTypes.string
}