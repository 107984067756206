import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Card} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faDesktop, faHourglass, faUserTimes} from "@fortawesome/free-solid-svg-icons";
import label from "na-utilities/src/label/Label";
import PropTypes from "prop-types";
import {handleTimeAgo} from "na-utilities/src/utils/Utilities";
import Endpoint from "na-utilities/src/api/Endpoint";
import {isResponseOk} from "na-utilities/src/utils/Utilities";
import UserActivityDetailPage from "../pages/session/UserActivityDetailPage";

export default class User5LastActivityCard extends BaseComponent{
    constructor(props) {
        super(props);
        this.state = {
            user:this.props.user?this.props.user:{},
            userActivities:[]
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props!=prevProps){
            let previousUser = prevState.user?prevState.user:{}
            let user = this.props.user?this.props.user:{}
            if(user != previousUser){
                this.setState({user},() => {
                    this.fetchLast5UserActivities()
                })
            }
        }
    }
    componentDidMount() {
        super.componentDidMount();
    }

    fetchLast5UserActivities(){
        let {user} = this.state
        this.get(Endpoint.API.USER_LAST_5_ACTIVITIES, {params:{userId:user.id}}, null, (res)=>{
            if(isResponseOk(res)){
                let userActivities = res.data
                this.setState({userActivities})
            }
        }, false, false)
    }


    render() {
        let {user, userActivities} = this.state
        let userStatus = user.userStatus
        return (
            <Card border="light" className="shadow-sm p-0 mb-4">
                <Card.Body>
                    <div className="d-flex align-items-center justify-content-between border-bottom border-light pb-3">
                        <div>
                            <h6>{label.Last5UserActivity}</h6>
                        </div>
                        <div>
                            <Card.Link href={UserActivityDetailPage.ENDPOINT+"/"+user.userCode} className="text-primary fw-bold">
                                <FontAwesomeIcon icon={faUserTimes} className="ms-2" />
                            </Card.Link>
                        </div>
                    </div>
                    {
                        userActivities.length==0?(
                            <div className="align-items-center text-center justify-content-between border-bottom border-light py-2 px-2 font-small">
                                {label.NoActivitiesFound}
                            </div>
                        ):null
                    }
                    {
                        userActivities.map((item,index)=>(

                            <div className="d-flex align-items-center justify-content-between border-bottom border-light py-2 px-2 font-small">
                                <div>
                                    <h6 className="mb-0">#{index+1}&nbsp;{item.message}</h6>
                                </div>
                                <div>
                                    <Card.Link href="#top" className="text-primary fw-bold">
                                        {handleTimeAgo(item.created)}
                                    </Card.Link>
                                </div>

                            </div>
                        ))
                    }
                </Card.Body>
            </Card>
        );
    }
}
User5LastActivityCard.propTypes = {
    user : PropTypes.any.isRequired,
    onRefresh: PropTypes.func.isRequired
}