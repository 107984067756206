import React from "react";
import BasePage from "./BasePage";
import {Button, Col, Modal, Row} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";

export default class BaseFormPage extends BasePage{

    constructor(props) {
        super(props);
        this.state = {
            requestProcessConfirmationModal:false,
            confirmOkCallback:undefined,
            confirmCloseCallback:undefined
        }
    }


    componentDidMount() {
        // toasting.success("Test")
    }

    async validateFormSubmit(){

    }

    openConfirmDialog(confirmOkCallback, confirmCloseCallback){
        this.setState({confirmOkCallback, confirmCloseCallback, requestProcessConfirmationModal:true})
    }

    closeConfirmDialog(){
        this.setState({confirmOkCallback:undefined, confirmCloseCallback:undefined,requestProcessConfirmationModal:false})
    }

    render() {
        let {confirmOkCallback, confirmCloseCallback, requestProcessConfirmationModal} = this.state
        return (
            <>
                <Modal as={Modal.Dialog} centered show={requestProcessConfirmationModal} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.Confirmation}</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={()=>{}} />
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Row>
                            <Col className="text-center">
                                <img src={ApplicationIcons.Icons.warningAttention}/>
                            </Col>
                        </Row>
                        <br/>
                        <h4>{label.UpdateConfirmationMessage}</h4>
                        <p>{label.UpdateConfirmationDetailMessage}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="outline-warning"
                            className="text-gray ms-auto"
                            onClick={()=>{
                                if(confirmCloseCallback){
                                    confirmCloseCallback()
                                }
                                this.closeConfirmDialog()
                            }}>
                            {label.Close}
                        </Button>
                        <Button
                            variant="success"
                            onClick={()=>{
                                if(confirmOkCallback){
                                    confirmOkCallback()
                                }
                            }}>
                            {label.Submit}
                        </Button>
                    </Modal.Footer>
                </Modal>
                {super.render()}
            </>
        );
    }
    
}