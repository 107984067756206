import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faHome} from '@fortawesome/free-solid-svg-icons';
import {Breadcrumb, Button, Card, Col, Row, Table} from '@themesberg/react-bootstrap';
import BasePage from "./../base/BasePage";
import label from "na-utilities/src/label/Label"
import DashboardPage from "../dashboard/DashboardPage";
import PaginationTable from "../../components/PaginationTable";
import {getUser} from "../../utils/StorageUtil";
import {Link} from "react-router-dom";
import {Routes} from "../../routes";
import Endpoint from "na-utilities/src/api/Endpoint";
import queryString from 'query-string';
import {currencyFormat, navigate, parseDate} from "na-utilities/src/utils/Utilities";
import {DD_MMMM_YYYY_HH_MM_SS} from "../../utils/Global";
import SearchInput from "../../components/input/SearchInput";
import TableSortOption from "../../components/input/TableSortOption";
import SortOption from "na-utilities/src/model/SortOption";
import UserActivityTable from "../../components/UserActivityTable";

class UserActivityPage extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      inputSearch: queryString.parse(this.props.query).search ? queryString.parse(this.props.query).search : "",
      user : getUser(),
      page: queryString.parse(props.query).page ? queryString.parse(props.query).page : 1,
      totalPage: 0,
      totalElement: 0,
      pageElement: 0,
      sortOption:{}
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.closePreLoader()
  }

  componentWillReceiveProps(props, nextContext) {
    if (props != this.props) {
      let page = queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
      let key = queryString.parse(props.query).tab ? queryString.parse(props.query).tab : label.All
      let inputSearch = queryString.parse(props.query).search ? queryString.parse(props.query).search : ""
      this.setState({
        page: page,
        key: key,
        inputSearch: inputSearch
      })
    }
  }


  render() {
    let {sortOption} = this.state
    return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                <Breadcrumb.Item href={DashboardPage.ENDPOINT}>{label.Dashboard}</Breadcrumb.Item>
                <Breadcrumb.Item active>{label.UserActivity}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>

          <div className="table-settings mb-4">
            <Row className="align-items-center">
              <Col xs={8} md={6} lg={3} xl={4}>
                <SearchInput onEnter={(search) => {

                }} onEmpty={() => {

                }}/>
              </Col>
              <Col xs={4} md={4} xl={2} className="ps-md-0">
                <TableSortOption
                    sortOptions={
                      [
                          SortOption.init(label.Longest,"ca.created",true),
                          SortOption.init(label.Latest,"ca.created",false),
                      ]
                    }
                    sortOption={sortOption}
                    placeholder={label.SortBy}
                    onSelect={(sortOption) => {

                    }}
                    onReset={() => {

                    }}/>
              </Col>
            </Row>
          </div>
          <UserActivityTable/>
        </>
    );
  }
}
export default Object.assign(UserActivityPage, {ENDPOINT : "/userActivities"})

