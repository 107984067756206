import React from 'react'
import {Button, Col} from "@themesberg/react-bootstrap";
import PropTypes from "prop-types";
import DotLayout from "./DotLayout";
import label from "na-utilities/src/label/Label";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faTrash} from "@fortawesome/free-solid-svg-icons";
import MyImage from "./MyImage";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";

export default class FilterButton extends React.Component{

    render() {
        let {active} = this.props
        if(!active){
            active = false ;
        }
        return (
            <Button
                size="sm"
                variant="outline-primary align-item-center"
                onClick={event => {
                    this.props.onClick(event)
                }}>
                <DotLayout
                    dimension={16}
                    backgroundColor={active?"green":"red"}
                />
                &nbsp;&nbsp;
                {this.props.label}
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faFilter} />
                {(active===true)?(
                    <>
                        &nbsp;
                        <MyImage
                            src={ApplicationIcons.Icons.logout}
                            style={{width:'20px', height:'20px'}}
                            onClick={(event)=>{
                                event.stopPropagation()
                                if(this.props.onReset) {
                                    this.props.onReset()
                                }
                            }}
                        />
                    </>
                ):(undefined)}
            </Button>
        );
    }
}
FilterButton.propTypes = {
    label:PropTypes.string.isRequired,
    onClick:PropTypes.func.isRequired,
    active:PropTypes.bool,
}