import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faEllipsisH, faEye, faHome, faList, faPlus} from '@fortawesome/free-solid-svg-icons';
import {Breadcrumb, Button, ButtonGroup, Card, Col, Dropdown, Row, Table} from '@themesberg/react-bootstrap';
import BasePage from "./../base/BasePage";
import label from "na-utilities/src/label/Label"
import DashboardPage from "../dashboard/DashboardPage";
import PaginationTable from "../../components/PaginationTable";
import {getUser} from "../../utils/StorageUtil";
import {Link} from "react-router-dom";
import {Routes} from "../../routes";
import Endpoint from "na-utilities/src/api/Endpoint";
import queryString from 'query-string';
import {cloneObject, navigate, parseDate} from "na-utilities/src/utils/Utilities";
import {DD_MMMM_YYYY_HH_MM_SS} from "../../utils/Global";
import SearchInput from "../../components/input/SearchInput";
import TableSortOption from "../../components/input/TableSortOption";
import SortOption from "na-utilities/src/model/SortOption";
import GoodsInfoEditModal from "../../components/GoodsInfoEditModal";
import MyImage from "../../components/MyImage";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import GoodsItemEditModal from "../../components/GoodsItemEditModal";
import GoodsInfoDetailPage from "./GoodsInfoDetailPage";
import GoodsCategorySelectorModal from "../../components/GoodsCategorySelectorModal";

class GoodsInfoPage extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      inputSearch: queryString.parse(this.props.query).search ? queryString.parse(this.props.query).search : "",
      user : getUser(),
      createGoodsInfoModal:{
        show:false,
        position:-1,
        goodsInfo:{
            goodsCategory:{}
        },
      },
      createGoodsItemModal:{
        show:false,
        position:-1,
        goodsInfo:{},
        goodsItems:[],
      },
        categorySelectorModal:false,
      page: queryString.parse(props.query).page ? queryString.parse(props.query).page : 1,
      goodsInfos:[],
      goodsInfo:{},
      totalPage: 0,
      totalElement: 0,
      pageElement: 0,
      sortOption:SortOption.init(label.Longest,"gi.created",true)
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.closePreLoader()
    this.fetchGoodsInfos()
  }

  componentWillReceiveProps(props, nextContext) {
    if (props != this.props) {
      let page = queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
      let key = queryString.parse(props.query).tab ? queryString.parse(props.query).tab : label.All
      let inputSearch = queryString.parse(props.query).search ? queryString.parse(props.query).search : ""
      this.setState({
        page: page,
        key: key,
        inputSearch: inputSearch
      }, () => {
        this.fetchGoodsInfos()
      })
    }
  }


  fetchGoodsInfos = () => {
    let {user, goodsInfos, search, sortOption} = this.state
    this.get(Endpoint.API.GOODS_INFOS, {
      params :{
        userId : user.id,
        ascending: sortOption.ascending,
        sortir: sortOption.sortir,
        search: search,
        page: this.state.page - 1,
      }
    }, null, response=>{
      if(response.code===200){
        let goodsInfos = response.data
        let totalPage = response.totalPage
        let totalElement = response.totalElement
        let pageElement = response.pageElement
        this.setState({goodsInfos:goodsInfos,totalPage, totalElement, pageElement})
      }else{
        this.props.history.goBack();
      }
    }, false, true)
  }



  render() {
    let {user, goodsInfos, page, pageElement, totalPage, totalElement, sortOption,
        createGoodsInfoModal, createGoodsItemModal, categorySelectorModal} = this.state
        let goodsInfo = createGoodsInfoModal.goodsInfo?createGoodsInfoModal.goodsInfo:{}
        let goodsCategory = goodsInfo.goodsCategory?goodsInfo.goodsCategory:{}
      return (
        <>
          {super.render()}
            <GoodsCategorySelectorModal
                show={categorySelectorModal}
                goodsCategory={goodsCategory}
                onClose={() => {
                    this.setState({categorySelectorModal:false})
                }}
                onSelectGoodsCategory={(goodsCategory) => {
                    goodsInfo.goodsCategory = goodsCategory
                    createGoodsInfoModal.goodsInfo = goodsInfo
                    createGoodsInfoModal.show = true
                    this.setState({createGoodsInfoModal, categorySelectorModal:false})
                }}
                showProcessing={this.props.showProcessing}
                closeProcessing={this.props.closeProcessing}
            />
            <GoodsInfoEditModal
              createGoodsInfoModal={createGoodsInfoModal}
              onClose={() => {
                this.setState({createGoodsInfoModal:{show:false, position:-1, goodsInfo:{}}})
              }}
              onResult={(createGoodsInfoModal) => {
                if(createGoodsInfoModal.position==-1){
                  goodsInfos.splice(0,0, cloneObject(createGoodsInfoModal.goodsInfo))
                }else{
                  goodsInfos.splice(createGoodsInfoModal.position, 1)
                  goodsInfos.splice(createGoodsInfoModal.position, 0, cloneObject(createGoodsInfoModal.goodsInfo))
                }
                this.setState({goodsInfos, createGoodsInfoModal:{show:false, position:-1, goodsInfo:{}}})
              }}
              showProcessing={this.props.showProcessing}
              closeProcessing={this.props.closeProcessing}
          />
          <GoodsItemEditModal
              createGoodsItemModal={createGoodsItemModal}
              onClose={() => {
                this.setState({createGoodsItemModal:{show:false, position:-1, goodsInfo:{}, goodsItems:[]}})
              }}
              onResult={(createGoodsItemModal) => {
                  if(createGoodsItemModal.position==-1){
                      goodsInfos.splice(0,0, cloneObject(createGoodsItemModal.goodsInfo))
                  }else{
                      goodsInfos.splice(createGoodsItemModal.position, 1)
                      goodsInfos.splice(createGoodsItemModal.position, 0, cloneObject(createGoodsItemModal.goodsInfo))
                  }
                  this.setState({goodsInfos, createGoodsItemModal:{show:false, position:-1, goodsInfo:{}}})
              }}
              showProcessing={this.props.showProcessing}
              closeProcessing={this.props.closeProcessing}
          />
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2">
            <div className="d-block mb-0 mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                <Breadcrumb.Item href={DashboardPage.ENDPOINT}>{label.Dashboard}</Breadcrumb.Item>
                <Breadcrumb.Item active>{label.GoodsList}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="table-settings mb-4">
            <Row className="align-items-center">
              <Col xs={8} md={6} lg={3} xl={7}>
                <Button
                    variant="success"
                    size="sm"
                    onClick={(event) => {
                      event.preventDefault()
                        this.setState({categorySelectorModal:true})
                    }}>
                  {label.CreateGoods}&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
              <Col xs={4} md={4} xl={1} className="ps-md-0 text-right">
                <TableSortOption
                    sortOptions={
                      [
                        SortOption.init(label.Longest,"gi.created",true),
                        SortOption.init(label.Latest,"gi.created",false),
                        SortOption.init(label.TheMost,"gi.itemCount",false),
                        SortOption.init(label.Smallest,"gi.itemCount",true),
                      ]
                    }
                    sortOption={sortOption}
                    placeholder={label.SortBy}
                    onSelect={(sortOption) => {
                      this.setState({sortOption},() => {
                        this.fetchGoodsInfos()
                      })
                    }}
                    onReset={() => {
                      this.setState({sortOption:{}},() => {
                        this.fetchGoodsInfos()
                      })
                    }}/>
              </Col>
              <Col xs={8} md={6} lg={3} xl={4}>
                <SearchInput onEnter={(search) => {
                  this.setState({search}, () => {
                    this.fetchGoodsInfos()
                  })
                }} onEmpty={() => {
                  this.setState({search:""},() => {
                    this.fetchGoodsInfos()
                  })
                }}/>
              </Col>
            </Row>
          </div>
          <Card border="light" className="table-wrapper table-responsive shadow-sm">
            <Card.Body className="p-0">
              <Table hover className="table-sm align-items-center">
                <thead>
                <tr>
                  <th className="border-bottom">#</th>
                  <th className="border-bottom">{label.Name}</th>
                  <th className="border-bottom">{label.Category}</th>
                  <th className="border-bottom">{label.Image}</th>
                  <th className="border-bottom text-center">{label.RegisteredItem}</th>
                  <th className="border-bottom">{label.Created}</th>
                  <th className="border-bottom">{label.RegisterItem}</th>
                  <th className="border-bottom text-center">{label.Action}</th>
                </tr>
                </thead>
                <tbody>
                {goodsInfos.map((goodsInfo, index) => (
                    <tr>
                      <td>
                        <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
                          {((page - 1) * pageElement) + (index+1)}
                        </Card.Link>
                      </td>
                      <td>
                          <span className="fw-normal">{goodsInfo.name}</span>
                      </td>
                        <td>
                            <span className="fw-normal">{goodsInfo.goodsCategory?goodsInfo.goodsCategory.name:""}</span>
                        </td>
                      <td>
                        <MyImage
                            style={{width:'40px'}}
                            src={goodsInfo.placeholder}
                            defaultImage={ApplicationIcons.Icons.noImage}
                            onClick={()=>{
                              this.openLightBox(...goodsInfo.imageLinks)
                            }}
                        />
                      </td>
                      <td className="text-center"><span className="fw-normal">{goodsInfo.itemCount}</span>
                      </td>
                      <td>
                        <span className="fw-normal">{parseDate(goodsInfo.created, DD_MMMM_YYYY_HH_MM_SS)}</span>
                      </td>
                      <td>
                        <Button
                            size="sm"
                            onClick={event => {
                                createGoodsItemModal = {
                                    ...createGoodsItemModal, position:index, goodsInfo:cloneObject(goodsInfo), show:true
                                }
                                this.setState({createGoodsItemModal})
                            }}>
                            {label.NewItem}&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} className="icon-dark" />
                        </Button>
                      </td>
                      <td className="text-center">
                        <div>
                          <Dropdown as={ButtonGroup} drop={"right"}>
                            <Dropdown.Toggle
                                size="sm"
                                as={Button}
                                split
                                variant="link"
                                className="text-dark m-0 p-0">
                                  <span className="icon icon-sm">
                                      <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                                  </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu size="sm" className="p-0" style={{marginRight:'180px'}}>
                              <Dropdown.Item onClick={event => {
                                createGoodsInfoModal = {
                                  ...createGoodsInfoModal, position:index, goodsInfo:cloneObject(goodsInfo), show:true
                                }
                                this.setState({createGoodsInfoModal})
                              }}>
                                <FontAwesomeIcon size="sm" icon={faEye} className="me-2" />&nbsp;{label.Detail}
                              </Dropdown.Item>
                              <Dropdown.Item
                                  onClick={event => {
                                      navigate(this.props, GoodsInfoDetailPage.ENDPOINT+"/"+goodsInfo.id)
                                  }}
                                  size="sm">
                                <FontAwesomeIcon icon={faList} className="me-2" />&nbsp;{label.SeeAllItem}
                              </Dropdown.Item>
                             </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                ))}
                </tbody>
              </Table>
            </Card.Body>
            <PaginationTable
                onSelect={(page) => {
                    this.setState({page}, () => {
                        this.fetchGoodsInfos()
                    })
                }}
                showingRow={goodsInfos.length}
                currentPage={page}
                pageCount={totalPage}
                totalElement={totalElement}/>
          </Card>
          {/*<TransactionsTable/>*/}
        </>
    );
  }
}
export default Object.assign(GoodsInfoPage, {ENDPOINT : "/goodsInfo"})

