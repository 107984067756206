import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Card} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faDesktop, faHourglass} from "@fortawesome/free-solid-svg-icons";
import label from "na-utilities/src/label/Label";
import PropTypes from "prop-types";
import {handleTimeAgo} from "na-utilities/src/utils/Utilities";

export default class UserSessionCard extends BaseComponent{
    constructor(props) {
        super(props);
        this.state = {
            user:this.props.user?this.props.user:{}
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props!=prevProps){
            let user = this.props.user?this.props.user:{}
            this.setState({user})
        }
    }

    render() {
        let {user} = this.state
        let userStatus = user.userStatus
        return (
            <Card border="light" className="shadow-sm p-0 mb-4">
                <Card.Body>
                    <div className="d-flex align-items-center justify-content-between border-bottom border-light pb-3">
                        <div>
                            <h6>{label.UserSession}</h6>
                        </div>
                        <div>
                            <Card.Link href="#" className="text-primary fw-bold" style={{textDecoration:'underline'}}>
                                {label.SeeAll}
                            </Card.Link>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between border-bottom border-light py-3">
                        <div>
                            <h6 className="mb-0"><FontAwesomeIcon icon={faClock} className="icon icon-xs me-3" />{label.LastAccess}</h6>
                        </div>
                        <div>
                            <Card.Link href="#top" className="text-primary fw-bold">
                                {user.lastOpenDate?handleTimeAgo(user.lastOpenDate):label.NeverAccessing}
                            </Card.Link>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between pt-3">
                        <div>
                            <h6 className="mb-0"><FontAwesomeIcon icon={faDesktop} className="icon icon-xs me-3" />{label.TotalAuthenticatedDevice}</h6>
                        </div>
                        <div>
                            <Card.Link href="#top" className="text-primary fw-bold text-underline">
                                <Button variant="warning" size="sm" className="rounded">#{user.deviceCount}</Button>
                            </Card.Link>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        );
    }
}
UserSessionCard.propTypes = {
    user : PropTypes.any.isRequired,
    onRefresh: PropTypes.func.isRequired
}