
import OverviewImg from "../assets/img/pages/overview.jpg";
import TransactionsImg from "../assets/img/pages/transactions.jpg";
import SettingsImg from "../assets/img/pages/settings.jpg";
import SignInImg from "../assets/img/pages/sign-in.jpg";
import SignUpImg from "../assets/img/pages/sign-up.jpg";
import LockImg from "../assets/img/pages/lock.jpg";
import ForgotPasswordImg from "../assets/img/pages/forgot-password.jpg";
import ResetPasswordImg from "../assets/img/pages/reset-password.jpg";
import NotFoundImg from "../assets/img/pages/404.jpg";
import ServerErrorImg from "../assets/img/pages/500.jpg";

import { Routes } from "../routes";
import DashboardOverview from "../pages/dashboard/DashboardOverview";
import TransactionPage from "../pages/TransactionPage";
import SettingPage from "../pages/SettingPage";
import SigninPage from "../pages/examples/SigninPage";
import SignupPage from "../pages/examples/SignupPage";
import LockPage from "../pages/examples/LockPage";
import ForgotPasswordPage from "../pages/examples/ForgotPasswordPage";
import ResetPasswordPage from "../pages/examples/ResetPasswordPage";
import NotFoundPage from "../pages/examples/ExceptionPage";
import ServerErrorPage from "../pages/examples/ServerErrorPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import label from "na-utilities/src/label/Label"
import ProductOrderPage from "../pages/order/ProductOrderPage";

export default [
    {
        "id": 1,
        "name": label.Dashboard,
        "image": OverviewImg,
        "link": DashboardPage.ENDPOINT
    },
    {
        "id": 2,
        "name": "Transactions",
        "image": TransactionsImg,
        "link": ProductOrderPage.ENDPOINT
    },
    {
        "id": 3,
        "name": "Settings",
        "image": SettingsImg,
        "link": SettingPage.ENDPOINT
    },
    {
        "id": 4,
        "name": "Sign In",
        "image": SignInImg,
        "link": SigninPage.ENDPOINT
    },
    {
        "id": 5,
        "name": "Sign Up",
        "image": SignUpImg,
        "link": SignupPage.ENDPOINT
    },
    {
        "id": 6,
        "name": "Lock",
        "image": LockImg,
        "link": LockPage.ENDPOINT
    },
    {
        "id": 7,
        "name": "Forgot password",
        "image": ForgotPasswordImg,
        "link": ForgotPasswordPage.ENDPOINT
    },
    {
        "id": 8,
        "name": "Reset password",
        "image": ResetPasswordImg,
        "link": ResetPasswordPage.ENDPOINT
    },
    {
        "id": 9,
        "name": "404",
        "image": NotFoundImg,
        "link": NotFoundPage.ENDPOINT
    },
    {
        "id": 10,
        "name": "500",
        "image": ServerErrorImg,
        "link": ServerErrorPage.ENDPOINT
    }
];