import React from 'react'

import BaseComponent from "../pages/base/BaseComponent";
import {Button, Dropdown} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faToolbox} from "@fortawesome/free-solid-svg-icons";
import label from "na-utilities/src/label/Label";
import NewProductOrderInquiryModal from "./NewProductOrderInquiryModal";
import {navigate} from "na-utilities/src/utils/Utilities";
import OrderDetailPage from "../pages/order/OrderDetailPage";
import {getUser} from "../utils/StorageUtil";

class OrderCreationDropDown extends BaseComponent{
    constructor(props) {
        super(props);
        this.state = {
            products:[],
            newProductOrderInquiryModal:false,
            defaultProduct:{}
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchProducts((products)=>this.setState({products}))
    }

    render() {
        let {products, newProductOrderInquiryModal, defaultProduct} = this.state
        let user = getUser()
        let company = user.company?user.company:{}
        return (
            <>
                <NewProductOrderInquiryModal
                    show={newProductOrderInquiryModal}
                    showProcessing={this.props.showProcessing}
                    closeProcessing={this.props.closeProcessing}
                    company={company}
                    onClose={()=>{
                        this.setState({newProductOrderInquiryModal:false})
                    }}
                    onCreateOrder={(productOrder) => {
                        this.setState({newProductOrderInquiryModal:false}, () => {
                            navigate(this.props, OrderDetailPage.ENDPOINT+"/"+productOrder.reference)
                        })
                    }}
                    defaultProduct={defaultProduct}
                />
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Dropdown className="btn-toolbar">
                    <Dropdown.Toggle as={Button} variant="primary" size="sm" className="me-2">
                        <FontAwesomeIcon icon={faPlus} className="me-2" />{label.NewOrder}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
                        {
                            products.map((item,index)=>{
                                return(
                                    <>
                                        <Dropdown.Item
                                            className="fw-bold"
                                            disabled={!item.published}
                                            onClick={event => {
                                                if(item.published){
                                                    this.setState({newProductOrderInquiryModal:true, defaultProduct:item})
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faToolbox} className={((index+1)%2!=0?"text-primary":"")+" me-2"} />{item.name}
                                        </Dropdown.Item>
                                        {index+1<products.length&&(
                                            <Dropdown.Divider />
                                        )}
                                    </>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            </>
        );
    }
}
export default OrderCreationDropDown