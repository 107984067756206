import React from 'react';
import {BrowserRouter, Redirect, Switch} from "react-router-dom";
// pages
import PresentationPage from "./PresentationPage";
import UpgradePage from "./UpgradePage";
import DashboardOverview from "./dashboard/DashboardOverview";
import TransactionPage from "./TransactionPage";
import SettingPage from "./SettingPage";
import BootstrapTablePage from "./tables/BootstrapTablePage";
import SigninPage from "./examples/SigninPage";
import SignupPage from "./examples/SignupPage";
import ForgotPasswordPage from "./examples/ForgotPasswordPage";
import ResetPasswordPage from "./examples/ResetPasswordPage";
import LockPage from "./examples/LockPage";
import ExceptionPage from "./examples/ExceptionPage";
import ServerErrorPage from "./examples/ServerErrorPage";
// documentation pages
import DocsOverviewPage from "./documentation/DocsOverviewPage";
import DocsDownloadPage from "./documentation/DocsDownloadPage";
import DocsQuickStartPage from "./documentation/DocsQuickStartPage";
import DocsLicense from "./documentation/DocsLicensePage";
import DocsLicensePage from "./documentation/DocsLicensePage";
import DocsFolderStructure from "./documentation/DocsFolderStructurePage";
import DocsBuildPage from "./documentation/DocsBuildPage";
import DocsChangelog from "./documentation/DocsChangelogPage";
// components

import AccordionPage from "./components/AccordionPage";
import Alerts from "./components/AlertPage";
import BadgePage from "./components/BadgePage";
import BreadcrumbPage from "./components/BreadcrumbPage";
import ButtonPage from "./components/ButtonPage";
import FormPage from "./components/FormPage";
import ModalPage from "./components/ModalPage";
import NavPage from "./components/NavPage";
import NavbarPage from "./components/NavbarPage";
import PaginationPage from "./components/PaginationPage";
import PopoverPage from "./components/PopoverPage";
import ProgressPage from "./components/ProgressPage";
import TablePage from "./components/TablePage";
import TabPage from "./components/TabPage";
import TooltipPage from "./components/TooltipPage";
import ToastPage from "./components/ToastPage";
import AuthorizedRouteWithSidebar from "../routes/AuthorizedRouteWithSidebar";
import PublicRoute from "../routes/PublicRoute";
import UnauthorizedRoute from "../routes/UnauthorizedRoute";
import {AuthProvider} from "../context/AuthContext";
import RegistrationVerificationPage from "./examples/RegistrationVerificationPage";
import NotFoundImage from "../assets/img/illustrations/404.svg";
import RegistrationSuccessPage from "./examples/RegistrationSuccessPage";
import DashboardPage from "./dashboard/DashboardPage";
import OrderDetailPage from "./order/OrderDetailPage";
import ProductOrderPage from "./order/ProductOrderPage";
import SmartDevicePage from "./subscribe/SmartDevicePage";
import SmartDeviceDetailPage from "./subscribe/SmartDeviceDetailPage";
import ProductSubscribePage from "./subscribe/ProductSubscribePage";
import SpotLocationPage from "./management/SpotLocationPage";
import SpotLocationDetailPage from "./management/SpotLocationDetailPage";
import UserPage from "./management/UserPage";
import ProfilePage from "./config/ProfilePage";
import UserDetailPage from "./management/UserDetailPage";
import UserRolePage from "./management/UserRolePage";
import UserActivityDetailPage from "./session/UserActivityDetailPage";
import UserActivityPage from "./session/UserActivityPage";
import UserDevicePage from "./session/UserDevicePage";
import ResetPasswordSuccessPage from "./examples/ResetPasswordSuccessPage";
import ProductSubscribeDetailPage from "./subscribe/ProductSubscribeDetailPage";
import GoodsInfoPage from "./goods/GoodsInfoPage";
import GoodsInfoDetailPage from "./goods/GoodsInfoDetailPage";
import GoodsItemDetailPage from "./goods/GoodsItemDetailPage";
import GoodsItemPage from "./goods/GoodsItemPage";
import BorrowingOfItemPage from "./goods/BorrowingOfItemPage";
import Endpoint from "na-utilities/src/api/Endpoint";
import Global from "../utils/Global";
import {storeData} from "../utils/StorageUtil";
import kitchen from "../utils/AxiosInstance";

const getBasename = () => {
    return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};


export default class HomePage extends React.Component{

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        fetchBusinessContact()
    }


    render() {
        return (
            <BrowserRouter basename={getBasename()}>
            <AuthProvider>
                <Switch>
                    {/*<PublicRoute exact path={PresentationPage.ENDPOINT} component={PresentationPage} />*/}
                    <AuthorizedRouteWithSidebar exact path={DashboardPage.MAIN_ENDPOINT} component={DashboardPage} />
                    <UnauthorizedRoute exact path={SigninPage.ENDPOINT} component={SigninPage} />
                    <UnauthorizedRoute exact path={SignupPage.ENDPOINT} component={SignupPage} />
                    <UnauthorizedRoute exact path={RegistrationVerificationPage.ENDPOINT+"/:registrationCode"} component={RegistrationVerificationPage} />
                    <UnauthorizedRoute exact path={RegistrationSuccessPage.ENDPOINT}  component={RegistrationSuccessPage} />
                    <UnauthorizedRoute exact path={ForgotPasswordPage.ENDPOINT} component={ForgotPasswordPage} />
                    <UnauthorizedRoute exact path={ResetPasswordPage.ENDPOINT+"/:token?"} component={ResetPasswordPage} />
                    <UnauthorizedRoute exact path={ResetPasswordSuccessPage.ENDPOINT+"/:token?"} component={ResetPasswordSuccessPage} />
                    <PublicRoute exact path={LockPage.ENDPOINT} component={LockPage} />
                    <PublicRoute exact path={ExceptionPage.ENDPOINT+"/:prefix"} component={ExceptionPage}/>
                    <PublicRoute exact path={ServerErrorPage.ENDPOINT} component={ServerErrorPage} />

                    {/* pages */}
                    <AuthorizedRouteWithSidebar exact path={DashboardPage.ENDPOINT} component={DashboardPage} />
                    <AuthorizedRouteWithSidebar exact path={DashboardOverview.ENDPOINT} component={DashboardOverview} />
                    <AuthorizedRouteWithSidebar exact path={OrderDetailPage.ENDPOINT+"/:reference"} component={OrderDetailPage} />
                    <AuthorizedRouteWithSidebar exact path={UpgradePage.ENDPOINT} component={UpgradePage} />
                    <AuthorizedRouteWithSidebar exact path={ProductOrderPage.ENDPOINT} component={ProductOrderPage} />
                    <AuthorizedRouteWithSidebar exact path={SettingPage.ENDPOINT} component={SettingPage} />
                    <AuthorizedRouteWithSidebar exact path={BootstrapTablePage.ENDPOINT} component={BootstrapTablePage} />

                    <AuthorizedRouteWithSidebar exact path={ProductSubscribePage.ENDPOINT} component={ProductSubscribePage} />
                    <AuthorizedRouteWithSidebar exact path={SmartDevicePage.ENDPOINT} component={SmartDevicePage} />
                    <AuthorizedRouteWithSidebar exact path={SmartDeviceDetailPage.ENDPOINT+"/:deviceId"} component={SmartDeviceDetailPage} />
                    <AuthorizedRouteWithSidebar exact path={ProductSubscribeDetailPage.ENDPOINT+"/:id"} component={ProductSubscribeDetailPage} />

                    <AuthorizedRouteWithSidebar exact path={GoodsInfoPage.ENDPOINT} component={GoodsInfoPage}/>
                    <AuthorizedRouteWithSidebar exact path={GoodsInfoDetailPage.ENDPOINT+"/:id"} component={GoodsInfoDetailPage} />

                    <AuthorizedRouteWithSidebar exact path={GoodsItemPage.ENDPOINT} component={GoodsItemPage} />
                    <AuthorizedRouteWithSidebar exact path={GoodsItemDetailPage.ENDPOINT+"/:id"} component={GoodsItemDetailPage} />

                    <AuthorizedRouteWithSidebar exact path={BorrowingOfItemPage.ENDPOINT} component={BorrowingOfItemPage} />

                    <AuthorizedRouteWithSidebar exact path={SpotLocationPage.ENDPOINT} component={SpotLocationPage} />
                    <AuthorizedRouteWithSidebar exact path={SpotLocationDetailPage.ENDPOINT} component={SpotLocationDetailPage} />

                    <AuthorizedRouteWithSidebar exact path={UserPage.ENDPOINT} component={UserPage} />
                    <AuthorizedRouteWithSidebar exact path={UserDetailPage.ENDPOINT+"/:userCode"} component={UserDetailPage} />
                    <AuthorizedRouteWithSidebar exact path={UserRolePage.ENDPOINT+"/:userCode"} component={UserRolePage} />
                    <AuthorizedRouteWithSidebar exact path={UserActivityDetailPage.ENDPOINT+"/:userCode"} component={UserActivityDetailPage} />
                    <AuthorizedRouteWithSidebar exact path={ProfilePage.ENDPOINT} component={ProfilePage}/>


                    <AuthorizedRouteWithSidebar exact path={UserActivityPage.ENDPOINT} component={UserActivityPage}/>
                    <AuthorizedRouteWithSidebar exact path={UserDevicePage.ENDPOINT} component={UserDevicePage}/>

                    {/* components */}
                    <AuthorizedRouteWithSidebar exact path={AccordionPage.ENDPOINT} component={AccordionPage} />
                    <AuthorizedRouteWithSidebar exact path={Alerts.ENDPOINT} component={Alerts} />
                    <AuthorizedRouteWithSidebar exact path={BadgePage.ENDPOINT} component={BadgePage} />
                    <AuthorizedRouteWithSidebar exact path={BreadcrumbPage.ENDPOINT} component={BreadcrumbPage} />
                    <AuthorizedRouteWithSidebar exact path={ButtonPage.ENDPOINT} component={ButtonPage} />
                    <AuthorizedRouteWithSidebar exact path={FormPage.ENDPOINT} component={FormPage} />
                    <AuthorizedRouteWithSidebar exact path={ModalPage.ENDPOINT} component={ModalPage} />
                    <AuthorizedRouteWithSidebar exact path={NavPage.ENDPOINT} component={NavPage} />
                    <AuthorizedRouteWithSidebar exact path={NavbarPage.ENDPOINT} component={NavbarPage} />
                    <AuthorizedRouteWithSidebar exact path={PaginationPage.ENDPOINT} component={PaginationPage} />
                    <AuthorizedRouteWithSidebar exact path={PopoverPage.ENDPOINT} component={PopoverPage} />
                    <AuthorizedRouteWithSidebar exact path={ProgressPage.ENDPOINT} component={ProgressPage} />
                    <AuthorizedRouteWithSidebar exact path={TablePage.ENDPOINT} component={TablePage} />
                    <AuthorizedRouteWithSidebar exact path={TabPage.ENDPOINT} component={TabPage} />
                    <AuthorizedRouteWithSidebar exact path={TooltipPage.ENDPOINT} component={TooltipPage} />
                    <AuthorizedRouteWithSidebar exact path={ToastPage.ENDPOINT} component={ToastPage} />

                    {/* documentation */}
                    <AuthorizedRouteWithSidebar exact path={DocsOverviewPage.ENDPOINT} component={DocsOverviewPage} />
                    <AuthorizedRouteWithSidebar exact path={DocsDownloadPage.ENDPOINT} component={DocsDownloadPage} />
                    <AuthorizedRouteWithSidebar exact path={DocsQuickStartPage.ENDPOINT} component={DocsQuickStartPage} />
                    <AuthorizedRouteWithSidebar exact path={DocsLicensePage.ENDPOINT} component={DocsLicense} />
                    <AuthorizedRouteWithSidebar exact path={DocsFolderStructure.ENDPOINT} component={DocsFolderStructure} />
                    <AuthorizedRouteWithSidebar exact path={DocsBuildPage.ENDPOINT} component={DocsBuildPage} />
                    <AuthorizedRouteWithSidebar exact path={DocsChangelog.ENDPOINT} component={DocsChangelog} />

                    <Redirect to={ExceptionPage.ENDPOINT_404} />
                </Switch>
            </AuthProvider>
            </BrowserRouter>
        );
    }

}
function fetchBusinessContact() {
    get(Endpoint.API.BUSINESS_CONTACT, null, null, response => {
        if (response.data.code === 200) {
            storeData(Global.BUSINESS_CONTACT, response.data.data)
        }
    })
}

function get(url, params, config, callback) {
    kitchen.get(url, params, config).then(response => {
        callback(response)
    }).catch(e => {

    });
}

