import React from 'react';
import BaseComponent from "../../pages/base/BaseComponent";
import PropTypes from 'prop-types';
import {FormCheck} from "@themesberg/react-bootstrap";


export default class Checkbox extends BaseComponent{
    constructor(props) {
        super(props);
        this.state ={
            checked:this.props.checked,
            onChange:this.props.onChange
        }
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!=props){
            this.setState({
                checked:props.checked,
                onChange:props.onChange
            }, () => {
                // if(this.props.onChange){
                //     this.props.onChange(this.state.checked)
                // }
            })
        }
    }

    render() {
        let {checked, onChange} = this.state

        return (
            <FormCheck type="checkbox" className="text-center">
                <FormCheck.Input
                    checked={checked}
                    onClick={()=>{
                        if(onChange){
                            onChange(!checked)
                        }
                    }}
                />
            </FormCheck>
        );
    }
}

Checkbox.propTypes = {
    checked:PropTypes.bool,
    onChange:PropTypes.func
}
