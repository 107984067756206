import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {Card} from "@themesberg/react-bootstrap";

class MyImage extends React.Component{

    state = {}

    constructor(props) {
        super(props);
        this.state = {
            src : props.src
        }
        this.setState(this.state)
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let props = this.props
        if(props.src != nextProps.src){
            this.setState({src:nextProps.src})
        }
    }

    render() {
        let {style, defaultImage, onClick, className} = this.props
        let {src} = this.state

        return (
            <Card.Img
                className={className}
                style={style}
                src={src?src:defaultImage}
                onClick={onClick}
            />
        );
    }
}
MyImage.propTypes = {
    style:PropTypes.any,
    src:PropTypes.any,
    defaultImage:PropTypes.any,
    onClick:PropTypes.any,
    className:PropTypes.string,

}
export default MyImage