import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Card, Col, Row} from "@themesberg/react-bootstrap";
import PropTypes from 'prop-types';
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {isResponseOk} from "na-utilities/src/utils/Utilities";

export default class ContainerRowCard extends BaseComponent{


    constructor(props) {
        super(props);
        let {smartDevice, physicalRack, physicalContainer, expanded} = this.props
        let previousExpanded = this.state.expanded?this.state.expanded:false
        if(!physicalContainer.goodsInfoSize){
            physicalContainer.goodsInfoSize = 0
        }
        if(!physicalContainer.goodsItemSize){
            physicalContainer.goodsItemSize = 0
        }
        this.state = {
            expanded,
            physicalRack:physicalRack?physicalRack:{},
            smartDevice:smartDevice?smartDevice:{},
            physicalContainer:physicalContainer?physicalContainer:{}
        }
        if(expanded===true && previousExpanded===false){
            this.countGoodsInfo()
            this.countGoodsItem()
        }
    }

    async countGoodsInfo(){
        let {smartDevice, physicalRack, physicalContainer} = this.state
        if(!smartDevice.id || !physicalRack.id || !physicalContainer.id){
            return 0
        }
        let physicalCabinet = smartDevice.physicalCabinet?smartDevice.physicalCabinet:{}
        let params = {
            smartDeviceId:smartDevice.id,
            physicalCabinetId:physicalCabinet.id,
            physicalRackId:physicalRack.id,
            physicalContainerId:physicalContainer.id,
        }
        let response = await this.asyncGet(Endpoint.API.GOODS_ITEM_LOCATION_COUNT_GOODS_INFO, {
            params:params
        }, undefined, false, false)
        if(isResponseOk(response)){
            physicalContainer.goodsInfoSize = response.data
        }
        this.setState({physicalContainer})
    }
    async countGoodsItem(){
        let {smartDevice, physicalRack, physicalContainer} = this.state
        if(!smartDevice.id || !physicalRack.id || !physicalContainer.id){
            return 0
        }
        let physicalCabinet = smartDevice.physicalCabinet?smartDevice.physicalCabinet:{}
        let params = {
            smartDeviceId:smartDevice.id,
            physicalCabinetId:physicalCabinet.id,
            physicalRackId:physicalRack.id,
            physicalContainerId:physicalContainer.id,
        }
        let response = await this.asyncGet(Endpoint.API.GOODS_ITEM_LOCATION_COUNT_GOODS_ITEM, {
            params:params
        }, undefined, false, false)
        if(isResponseOk(response)){
            physicalContainer.goodsItemSize = response.data
        }
        this.setState({physicalContainer})
    }



    render() {
        let {smartDevice, physicalRack, physicalContainer} = this.state
        return (
            <>
                {/*<Button>Coba</Button>*/}
                <Row className="d-flex align-items-stretch">
                    <Col xl={3}>
                        <Card className="text-center d-flex flex-column h-100">
                            <Card.Header className="p-3">
                                {label.Title}
                            </Card.Header>
                            <Card.Body className="p-2 d-flex align-items-center">
                                <h5>{physicalContainer.title}</h5>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl={3}>
                        <Card className="text-center">
                            <Card.Header className="p-3">
                                {label.Status}
                            </Card.Header>
                            <Card.Body className="p-2">
                                <h3 style={{color:physicalContainer.lockerStatusColor}}>{physicalContainer.lockerStatus}</h3>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl={3}>
                        <Card className="text-center">
                            <Card.Header className="p-3">
                                {label.GoodsInfo}
                            </Card.Header>
                            <Card.Body className="p-2">
                                <h3><a>{physicalContainer.goodsInfoSize}</a></h3>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl={3}>
                        <Card className="text-center">
                            <Card.Header className="p-3">
                                {label.GoodsItem}
                            </Card.Header>
                            <Card.Body className="p-2">
                                <h3><a>{physicalContainer.goodsItemSize}</a></h3>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        {physicalContainer.placeHolder}
                    </Col>
                </Row>
            </>
        );
    }

}
ContainerRowCard.propTypes = {
    expanded:PropTypes.bool,
    smartDevice:PropTypes.object.isRequired,
    physicalRack:PropTypes.object.isRequired,
    physicalContainer:PropTypes.object.isRequired,

}
