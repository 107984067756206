import React from 'react';
import {Button, Card, Col, Form, Modal, ModalBody, ModalFooter, Row, Table,} from '@themesberg/react-bootstrap';
import {allIsEmpty, isEmpty, parseDate} from 'na-utilities/src/utils/Utilities';
import {DD_MM_YYYY_HH_MM_SS} from '../../utils/Global';
import label from 'na-utilities/src/label/Label'
import PropTypes from "prop-types";
import queryString from "query-string";
import Endpoint from "na-utilities/src/api/Endpoint";
import BaseComponent from "../../pages/base/BaseComponent";
import SearchInput from "./SearchInput";
import ItemOption from "./ItemOption";
import PaginationTable from "../PaginationTable";
import Checkbox from "./Checkbox";
import {faFilter, faTrash, faWindowRestore} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class SpotLocationSelectionModal extends BaseComponent{

    constructor(props) {
        super(props);
        let spotLocation = this.props.spotLocation?this.props.spotLocation:{}
        let spotLocations = []
        this.state ={
            company:props.company?props.company:{},
            spotLocation:spotLocation,
            spotLocations:spotLocations,
            sortir:'jie.updated',
            page : 1,
            inputSearch : "",
            totalPage:0,
            totalElement:0,
            pageElement:0,
            showing:this.props.showing,
            provinces:[],
            cities:[],
            districts:[],
            villages:[],
            province:{},
            city:{},
            district:{},
            village:{}
        }
    }

    componentWillReceiveProps(props, nextContext) {
        if(props.showing){
            let company = props.company?props.company:{}
            let spotLocation = props.spotLocation?props.spotLocation:{}
            let spotLocations = []
            let page = 1
            let inputSearch = ""
            this.setState({
                company:company,
                spotLocation:spotLocation,
                spotLocations:spotLocations,
                page:page,
                inputSearch:inputSearch,
                showing:props.showing,
                provinces:[],
                cities:[],
                districts:[],
                villages:[],
                province:{},
                city:{},
                district:{},
                village:{}
            },() => {
                if(props.showing){
                    this.fetchSpotLocations()
                    this.fetchProvinces((provinces)=>this.setState({provinces:provinces}))
                }
            })
        }
    }
    componentDidMount() {
        super.componentDidMount();
        let {showing} = this.state
        if(showing){
            this.fetchSpotLocations()
            this.fetchProvinces((provinces)=>this.setState({provinces:provinces}))

        }
    }

    fetchSpotLocations(){
        let {company, ascending, sortir, page, province, city, district, village,inputSearch} = this.state
        this.get(Endpoint.API.SPOT_LOCATIONS, {
            params:{
                companyId:company.id,
                active:true,
                page:page-1,
                ascending:ascending,
                sortir:sortir,
                provinceId:province.id,
                pcityId:city.id,
                districtId:district.id,
                villageId:village.id,
                keyword:inputSearch
            }
        }, null, response=>{
            if(response.code===200){
                this.setState({
                    spotLocations:response.data,
                    totalPage: response.totalPage,
                    totalElement: response.totalElement,
                    pageElement: response.pageElement,
                })
            }
        }, false, false)
    }

    render() {
        let {spotLocation, spotLocations} = this.state

        return (
            <>
                {super.render()}
                <Modal
                    autoFocus={false}
                    as={Modal.Dialog}
                    style={{minWidth:'90%'}}
                    size="xl"
                    centered={true}
                    show={this.props.showing}
                    backdrop={true}>
                    {super.render()}
                    <Modal.Header>
                        {label.SpotLocations}
                    </Modal.Header>
                    <ModalBody>
                        {this.renderSpotLocations()}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="warning"
                            onClick={e=>{
                                e.preventDefault()
                                this.props.onClose()
                            }}>
                            {label.Cancel}
                        </Button>
                        <Button
                            variant="success"
                            onClick={e=>{
                                e.preventDefault()
                                this.props.onSelect(spotLocation)
                            }}>
                            {label.Select}
                        </Button>
                    </ModalFooter>
                </Modal>

            </>
        )
    }

    resetFilter(){
        this.setState({
            inputSearch:"",
            page :1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            province:{},
            city:{},
            district:{},
            village:{},
        }, () => {
            this.fetchSpotLocations()
        })
    }

    renderSpotLocations = () =>{
        let {province, city, district, village, inputSearch} = this.state
        return (
            <Row key={2}>
                <Col>
                    <Row>
                        <Col md={3}>
                            <ItemOption
                                size="sm"
                                label={label.Province}
                                fieldForLabel={"name"}
                                objects={this.state.provinces}
                                value={province}
                                callback={(province) => {
                                    this.setState({
                                        province:!isEmpty(province.id)?province:{},
                                        cities:[],
                                        city:!isEmpty(province.id)?this.state.city:{},
                                        districts:[],
                                        district:!isEmpty(province.id)?this.state.district:{},
                                        villages:[],
                                        village:!isEmpty(province.id)?this.state.village:{},
                                    }, () => {
                                        if(!isEmpty(province.id)){
                                            this.fetchCities(province.id, cities=>{
                                                this.setState({
                                                    cities:cities
                                                })
                                            })
                                        }
                                    })
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <ItemOption
                                size="sm"
                                label={label.City}
                                fieldForLabel={"name"}
                                objects={this.state.cities}
                                value={city}
                                callback={(city) => {
                                    this.setState({
                                        city:!isEmpty(city.id)?city:{},
                                        districts:[],
                                        district:!isEmpty(city.id)?this.state.district:{},
                                        villages:[],
                                        village:!isEmpty(city.id)?this.state.village:{},
                                    }, () => {
                                        if(!isEmpty(city.id)){
                                            this.fetchDistricts(city.id, districts =>{
                                                this.setState({
                                                    districts:districts
                                                })
                                            })
                                        }
                                    })
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <ItemOption
                                size="sm"
                                label={label.District}
                                fieldForLabel={"name"}
                                objects={this.state.districts}
                                value={district}
                                callback={(district) => {
                                    this.setState({
                                        district:!isEmpty(district.id)?district:{},
                                        villages:[],
                                        village:!isEmpty(district.id)?this.state.village:{},
                                    }, () => {
                                        if(!isEmpty(district.id)){
                                            this.fetchVillages(district.id, villages=>{
                                                this.setState({
                                                    villages:villages
                                                })
                                            })
                                        }
                                    })
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <ItemOption
                                size="sm"
                                label={label.Village}
                                fieldForLabel={"name"}
                                objects={this.state.villages}
                                value={village}
                                callback={(village) => {
                                    this.setState({
                                        village:!isEmpty(village)?village:{},
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>{label.Searching}</Form.Label>
                                <SearchInput
                                    value={inputSearch}
                                    onEnter={(inputSearch)=>{
                                        this.setState({inputSearch}, () => {
                                            if(!isEmpty(inputSearch)){
                                                this.fetchSpotLocations()
                                            }
                                        })
                                    }}
                                    onEmpty={()=>{
                                        this.setState({inputSearch:""}, () => {
                                            this.fetchSpotLocations()
                                        })
                                    }}/>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>&nbsp;</Form.Label>
                                <br/>
                                <Button
                                    size="sm"
                                    block
                                    className={"float-left"}
                                    variant="warning"
                                    color={"primary"}
                                        onClick={event => {
                                            this.fetchSpotLocations()
                                        }}>
                                    <FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;{label.ApplyFilter}
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>&nbsp;</Form.Label>
                                <br/>
                                <Button
                                    size="sm"
                                    block
                                    variant="info"
                                    className={"float-left"}
                                    color={"yellow"}
                                        onClick={event => {
                                            this.resetFilter()
                                        }}>
                                    <FontAwesomeIcon icon={faWindowRestore} />&nbsp;&nbsp;{label.ResetFilter}
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            {this.renderSpotLocationsTable()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }


    renderSpotLocationsTable = () =>{
        let {spotLocations, spotLocation} = this.state
        return (
            <Card>
                <Card.Body>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{label.Name}</th>
                            <th>{label.Description}</th>
                            <th>{label.Address}</th>
                            <th>{label.LastUpdate}</th>
                            <th>{label.Select}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                           spotLocations.map((item, index)=>(
                                <tr key={item.id}>
                                    <th scope="row">{(++index)}</th>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>
                                    <td style={{maxWidth:"240px"}} className="text-wrap">{item.fullAddress}</td>
                                    <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                    <td>
                                        <Checkbox
                                            checked={spotLocation&&(spotLocation.id===item.id)}
                                            onChange={
                                                checked => {
                                                    if(checked && checked===true){
                                                        spotLocation = item
                                                    }else{
                                                        spotLocation = {}
                                                    }
                                                    this.setState({
                                                        spotLocation:spotLocation
                                                    })
                                                }
                                            }/>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </Card.Body>
                <PaginationTable
                    onSelect={(page) => {
                        this.setState({
                            page:page
                        }, () => {
                            this.fetchSpotLocations()
                        })

                    }}
                    currentPage={this.state.page}
                    pageCount={this.state.totalPage}
                    totalElement={this.state.totalElement}/>
            </Card>
        )
    }

}

SpotLocationSelectionModal.propTypes = {
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onSelect:PropTypes.func.isRequired,
    company:PropTypes.object,
    spotLocation:PropTypes.object
}
