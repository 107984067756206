import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Col, Modal, Row, Card, Form, InputGroup} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import PropTypes from 'prop-types';
import TextInput from "./input/TextInput";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import {parseDate} from "na-utilities/src/utils/Utilities";
import {DD_MM_YYYY_HH_MM_SS} from "../utils/Global";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BorrowingOfItemChangeStatusModal from "./BorrowingOfItemChangeStatusModal";
import Global from "na-utilities/src/utils/Global";


export default class BorrowingOfItemModal extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            borrowingOfItem:props.borrowingOfItem,
            show:false,
            borrowingOfItemChangeStatusModal:false
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let show = this.props.show?this.props.show:false
            let borrowingOfItem = this.props.borrowingOfItem
            this.setState({show, borrowingOfItem})
        }

    }





    render() {
        let {borrowingOfItem, show, borrowingOfItemChangeStatusModal} = this.state
        let {borrowingOfItemStatus} = borrowingOfItem
        if(!borrowingOfItemStatus){
            borrowingOfItemStatus = {}
        }
        let buttonStatusEnable = (borrowingOfItemStatus.id==Global.BorrowingOfItemStatuses.ON_BORROW.id||borrowingOfItemStatus.id==Global.BorrowingOfItemStatuses.MISSING.id)
        let buttonStatusColor = buttonStatusEnable?"btn-danger":"btn-gray-200"
        return (
            <>
                {super.render()}
                <BorrowingOfItemChangeStatusModal
                    borrowingOfItem={borrowingOfItem}
                    show={borrowingOfItemChangeStatusModal}
                    onClose={() => {
                        this.setState({borrowingOfItemChangeStatusModal:false})
                    }}
                    onResult={(borrowingOfItem) => {
                        this.setState({borrowingOfItemChangeStatusModal:false, borrowingOfItem})
                    }}
                    showProcessing={this.props.showProcessing}
                    closeProcessing={this.props.closeProcessing}
                />
                <Modal as={Modal.Dialog} size="xl" centered show={show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.BorrowingInformation}</Modal.Title>
                        <Button
                            variant="close"
                            aria-label="Close"
                            onClick={()=>{
                                this.props.onClose()
                            }} />
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={10}>
                                <Row>
                                    <Col>
                                        <TextInput
                                            label={label.Name}
                                            value={borrowingOfItem.goodsItem?borrowingOfItem.goodsItem.goodsInfo.name:"-"}
                                        />
                                    </Col>
                                    <Col>
                                        <TextInput
                                            label={label.Category}
                                            value={borrowingOfItem.goodsItem?borrowingOfItem.goodsItem.goodsInfo.goodsCategory.name:"-"}
                                        />
                                    </Col>
                                    <Col>
                                        <TextInput
                                            label={label.RFIDTag}
                                            value={borrowingOfItem.goodsItem?borrowingOfItem.goodsItem.rfidTag:"-"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextInput
                                            label={label.ShouldReturnedAt}
                                            value={borrowingOfItem.shouldReturnedAt?parseDate(borrowingOfItem.shouldReturnedAt, DD_MM_YYYY_HH_MM_SS):"-"}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>{label.Status}</Form.Label>
                                            <Row>
                                                <Col md={8}>
                                                    <Button
                                                        size="sm"
                                                        className="fw-normal btn-block btn-outline-light"
                                                        disabled={true}
                                                        style={{color:(borrowingOfItem.borrowingOfItemStatus?borrowingOfItem.borrowingOfItemStatus.color:undefined)}}>
                                                        {borrowingOfItem.borrowingOfItemStatus?label(borrowingOfItem.borrowingOfItemStatus.label):"-"}
                                                    </Button>
                                                </Col>
                                                <Col md={4}>
                                                    <Button
                                                        size="sm"
                                                        className={"fw-normal btn-block "+buttonStatusColor}
                                                        disabled={!buttonStatusEnable}
                                                        onClick={event => {
                                                            this.setState({borrowingOfItemChangeStatusModal:true})
                                                        }}>
                                                        {label.ChangeStatus}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2}>
                                <Card.Img src={ApplicationIcons.Icons.imagePlaceholder}/>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <Card.Title className="p-0 m-0">{label.Borrowing}</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md={8}>
                                                <Row>
                                                    <Col>
                                                        <TextInput
                                                            label={label.Borrower}
                                                            value={borrowingOfItem.borrower?borrowingOfItem.borrower.fullname:"-"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <TextInput
                                                            label={label.BorrowingTime}
                                                            value={parseDate(borrowingOfItem.borrowedAt, DD_MM_YYYY_HH_MM_SS)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={4}>
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <Card.Body>
                                                                <Card.Img src={ApplicationIcons.Icons.userAvatar}/>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TextInput
                                                    label={label.BorrowingSmartDevice}
                                                    value={borrowingOfItem.borrowedOnSmartDevice?borrowingOfItem.borrowedOnSmartDevice.deviceId:"-"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TextInput
                                                    label={label.BorrowingPhysicalContainer}
                                                    value={borrowingOfItem.borrowedOnPhysicalContainer?borrowingOfItem.borrowedOnPhysicalContainer.title:"-"}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <Card.Title className="p-0 m-0">{label.Returning}</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md={8}>
                                                <Row>
                                                    <Col>
                                                        <TextInput
                                                            label={label.Returner}
                                                            value={borrowingOfItem.returner?borrowingOfItem.returner.fullname:"-"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <TextInput
                                                            label={label.ReturningTime}
                                                            value={borrowingOfItem.returnedAt?parseDate(borrowingOfItem.borrowedAt, DD_MM_YYYY_HH_MM_SS):"-"}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={4}>
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <Card.Body>
                                                                <Card.Img src={ApplicationIcons.Icons.userAvatar}/>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TextInput
                                                    label={label.ReturningSmartDevice}
                                                    value={borrowingOfItem.returnedOnSmartDevice?borrowingOfItem.returnedOnSmartDevice.deviceId:"-"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TextInput
                                                    label={label.ReturningPhysicalContainer}
                                                    value={borrowingOfItem.returnedOnPhysicalContainer?borrowingOfItem.returnedOnPhysicalContainer.title:"-"}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}
BorrowingOfItemModal.propTypes = {
    show:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    borrowingOfItem:PropTypes.object.isRequired

}
