import ApplicationIcons from "../misc/ApplicationIcons";

export const ACTIVE = "ACTIVE"
export const INACTIVE = "INACTIVE"
export const BLOCKED = "BLOCKED"
export const DELETED = "DELETED"

export const UserStatus = {
    ACTIVE:{
        clazz:"success",
        icon:ApplicationIcons.Icons.active
    },
    INACTIVE:{
        clazz:"warning",
        icon:ApplicationIcons.Icons.inactive
    },
    BLOCKED:{
        clazz:"danger",
        icon:ApplicationIcons.Icons.rejected
    },
    DELETED:{
        clazz:"danger",
        icon:ApplicationIcons.Icons.error
    }
}

export default {UserStatus,ACTIVE,INACTIVE,BLOCKED,DELETED}