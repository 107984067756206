import {BASE_URL, COPYRIGHT, DEBUG} from '../Variable';
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";

export let SINGLE_ITEM="single_item"
export let JOIN_ITEM="join_item"
export let ANY_ITEM="any_item"

export let SPECIFIC = "specific"
export let ANY = "any"


export let PERCENTAGE_POINT = "percentage_point";
export let FIX_POINT = "fix_point";

export let FREE_SHIPPING = "free_shipping"
export let SHIPPING_DISCOUNT = "shipping_discount"
export let PURCHASE_DISCOUNT = "purchase_discount"

export let  _ORDER = 1;
export let  _PACKAGING = 3;
export let  _DELIVERY = 4;
export let  _DONE = 5;
export let  _ON_RETURN = 7;
export let  _PAID_RETURN = 8;

export let _CREATED_ID = 1
export let _WAITING_FOR_PAYMENT_ID = 2
export let _REQUEST_TO_PROCESS = 3
export let _PAID = 4
export let _ON_PREPARING_EQUIPMENT_ID = 5
export let _EXPIRED_ID = 6
export let _CANCELED_ID = 7
export let _REFUND_ID = 8
export let _DONE_ID = 9
export let _REJECTED_ID = 10
export let _ERROR_ID = 11

export let INITIATED_ID = 1;
export let ACTIVE_ID = 2;
export let INACTIVE_ID = 3;
export let ON_MAINTENANCE_ID = 4;

export let CURRENCY_SYMBOL = 'Rp'

export let DD_MM_YYYY_HH_MM_SS = 'dd-mm-yyyy HH:MM:ss'
export let DD_MM_YYYY = 'dd-mm-yyyy'
export let DD_MMMM_YYYY = 'dd-mmmm-yyyy'
export let DD_MMMM_YYYY_HH_MM_SS = 'dd-mmmm-yyyy HH:MM:ss'

export let ITEM_POINT = "item_point";
export let PURCHASE_POINT = "purchase_point";

export let MIME_PNG = "image/x-png"
export let MIME_PNG_JPEG = "image/x-png,image/jpeg"
export let MIME_JPEG = "image/jpeg"
export let MIME_ICON = "image/x-icon"
export let MIME_TEXT_HTML = "text/html"

export let CATEGORY_IMAGE_MAX_FILE_SIZE = 2000
export let MAIN_PRODUCT_ICON_MAX_FILE_SIZE = 200
export let PRODUCT_IMAGE_MAX_FILE_SIZE = 200
export let BRAND_IMAGE_MAX_FILE_SIZE = 200
export let SELEBGRAM_IMAGE_MAX_FILE_SIZE = 100
export let COMPANY_IMAGE_MAX_FILE_SIZE = 200
export let SPOT_LOCATION_IMAGE_MAX_FILE_SIZE = 2000
export let SMART_DEVICE_IMAGE_MAX_FILE_SIZE = 2000
export let BANNER_IMAGE_MAX_FILE_SIZE = 500
export let DISCUSSION_IMAGE_MAX_FILE_SIZE = 300
export let MAIN_PRODUCT_MAX_FILE_SIZE = 200
export let ONLINE_SHOP_MAX_FILE_SIZE = 100
export let PRINT_DATA = "PRINT_DATA"
export let REGULAR_ID = 1
export let PRODUCT_KEUANGAN_ID = 2

export let WEBPAGE = "Webpage"
export let APPLICATION = "Application"



export let PRODUCT_ORDER_STATUS_COLOR = {
    "ALL":"#e56ecb",
    "Taken":"#f3b519",
    "Pass":"#6983FB",
    "Not Pass":"#FF9803",
    "Postponed":"#FF4181",
    "Open":"#108989",
    "Closed":"#0d6c10",
    "Suspended":"#FF0839",
}

export let SC_UNAUTHORIZED = 401


export let PREVIEW_TYPES = [
    {
        id:1,
        name:WEBPAGE
    },
    {
        id:2,
        name:APPLICATION
    }
]
export function previewTypeMap(name) {
    let previewType = undefined
    PREVIEW_TYPES.forEach(value => {
        if(value.name===name){
            previewType = value
        }
    })
    return previewType
}

export let DEFAULT_LOCATION = {
    latitude:-6.2293867,longitude:106.6894316
}

export let FORM_TYPES = [
    {
        id:1,
        name:"CABINET"
    },
    {
        id:2,
        name:"PAGE_LINK"
    }
]
export let CABINET_ID = 1
export let PAGE_LINK_ID = 2


export let BRAND_TYPES = [
    {
        id:1,
        name:"PERSONAL"
    },
    {
        id:2,
        name:"CORPORATE"
    }
]

export let BRAND_STATUSES = [
    {
        id:1,
        name:"PUBLISHED"
    },
    // {
    //     id:2,
    //     name:"BANNED"
    // },
    // {
    //     id:3,
    //     name:"SUBJECT_OF_VIOLATION"
    // },
    {
        id:4,
        name:"UNPUBLISH"
    }
]

// export let GENDERS = [
//     {
//         id:1,
//         name:"MALE"
//     },
//     {
//         id:2,
//         name:"FEMALE"
//     }
// ]

export let USER_STATUSES = [
    {
        id:1,
        name:"ACTIVE"
    },
    {
        id:2,
        name:"INACTIVE"
    },
    {
        id:3,
        name:"BLOCKED"
    }
]

export let NEED_VERIFIED = {
    id : 3,
    name : "NEED_VERIFIED"
}

export let VERIFICATION_STATUSES = [
    {
        id:1,
        name:"VERIFIED"
    },
    {
        id:2,
        name:"NOT_VERIFIED"
    },
    NEED_VERIFIED
]

export let NOTIFICATION_TOPICS = [
    {
        id:1,
        name:"NEWS"
    },
    {
        id:2,
        name:"ARTICLE"
    },
    {
        id:3,
        name:"BROADCAST"
    },
    {
        id:4,
        name:"SPECIFIC"
    }
]

export let CHARGING_ORDER_STATUSES = [
    {
        id:1,
        name:"Waiting"
    },
    {
        id:2,
        name:"Accepted"
    },
    {
        id:3,
        name:"Issued"
    },
    {
        id:4,
        name:"Rejected"
    },
    {
        id:5,
        name:"Postponed"
    }
]

export let ADMIN_STATUSES = [
    {
        id:1,
        name:"ACTIVE"
    },
    {
        id:2,
        name:"INACTIVE"
    },
    {
        id:3,
        name:"BLOCKED"
    }
]


export let ACTION_VERIFICATION_STATUSES = [
    {
        id:1,
        name:"VERIFIED"
    },
    {
        id:2,
        name:"NOT_VERIFIED"
    },
    {
        id:3,
        name:"NEED_VERIFIED"
    },
    {
        id:4,
        name:"VERIFICATION_REJECTED"
    }
]

export let FORCE_TO_UPDATES = [
    {
        id:1,
        name:true
    },
    {
        id:2,
        name:false
    }
]


export let MODULE_ID = 1

export let PRODUCT_TYPES = ["REFERRAL_AGENT_PRODUCT","REGULAR_PRODUCT"]

export let GROUP_NAME = "USER"
export let PLATFORM = "USER"

export default {
    BUSINESS_CONTACT: 'com.numeralasia.smartsupplies.user.BUSINESS_CONTACT#'+COPYRIGHT,
    CONFIGURATION: 'com.numeralasia.smartsupplies.user.CONFIGURATION#'+COPYRIGHT,
    CONFIGURATION_ADMIN: 'com.numeralasia.smartsupplies.user.CONFIGURATION_ADMIN#'+COPYRIGHT,
    AUTHORIZATION: btoa('com.numeralasia.smartsupplies.user.AUTHORIZATION#'+COPYRIGHT),
    MENUS: 'com.numeralasia.smartsupplies.user.MENUS#'+COPYRIGHT,
    USER: 'com.numeralasia.smartsupplies.user.USER#'+COPYRIGHT,

    // DEV_LOGIN_USERNAME :"numeralasia@gmail.com",
    // DEV_LOGIN_PASSWORD :"1q2w3e",
    DEV_LOGIN_USERNAME :"",
    DEV_LOGIN_PASSWORD :"",
    COPYRIGHT:COPYRIGHT,
    BASE_URL: BASE_URL,
    DEBUG : DEBUG,
    COLOR: {
        ORANGE: '#C50',
        DARKBLUE: '#0F3274',
        LIGHTBLUE: '#6EA8DA',
        DARKGRAY: '#999',
    },

    LIMIT_PAGING_BUTTON : 10,
    LOGGED_IN: 'LOGGED_IN',
    LANGUAGE:'LANGUAGE',
    DEFAULT_LANGUAGE:'in',

    CONFIRM_DG_ACTION_YES : 1,
    CONFIRM_DG_ACTION_NO : 2,

    PRODUCT_TYPE_MULTIGUNA : 1,
    PRODUCT_TYPE_KREDIT : 2,
    PRODUCT_TYPE_ASURANSI: 3,

    MESSAGE_DG_TYPE_WARNING : 1,
    MESSAGE_DG_TYPE_SUCCESS : 2,
    MESSAGE_DG_TYPE_ERROR   : 3,
    HEADER_USERID : "customerId",
    DD_MM_YYYY:"dd-MM-yyyy",
    DD_MM_YYYY_HH_MM_SS:"dd-MM-yyyy hh:mm:ss",
    DD_MM_YYYY_HH_MM_SS_2:"dd/MM/yyyy hh:mm:ss",

    ALL_INVENTORY:"ALL_INVENTORY",
    _ALL_INVENTORY:"All Inventory",
    ACTIVE:"ACTIVE",
    EXPIRED:"EXPIRED",
    ALL:"ALL",
    INACTIVE:"INACTIVE",
    _ALL_TIME : "all_time",
    _DYNAMIC : "dynamic",
    _STATIC : "static",
    ALL_TIME : "All Time",
    DYNAMIC : "Dynamic",
    STATIC : "Static",

    FREE_SHIPPING : "Free Shipping",
    SHIPPING_DISCOUNT : "Shipping Discount",
    PURCHASE_DISCOUNT : "Purchase Discount",



    _ITEM_POINT : "item_point",
    _PURCHASE_POINT : "purchase_point",

    ITEM_POINT : "Item Point",
    PURCHASE_POINT : "Purchase Point",


    _PERCENTAGE_DISCOUNT : "percentage_discount",
    _PRICE_DISCOUNT : "price_discount",

    PERCENTAGE_DISCOUNT : "Percentage Discount",
    PRICE_DISCOUNT : "Price Discount",

    SMART_DEVICE_TURNED_ON_ID:1,
    SMART_DEVICE_TURNED_OFF_ID:2,
    SMART_DEVICE_USED_ID:3,

    VOUCHER_BENEFIT_TYPE_OPTIONS:[
        {id:FREE_SHIPPING,name:"Free Shipping"},
        {id:SHIPPING_DISCOUNT,name:"Shipping Discount"},
        {id:PURCHASE_DISCOUNT,name:"Purchase Discount"}]
    ,


    VOUCHER_AGGREGATE_OPTIONS_ANY:[
        {
            id:ANY_ITEM,
            name:"Any Item"
        }
    ],

    VOUCHER_AGGREGATE_OPTIONS:[
        {
            id:SINGLE_ITEM,
            name:"Single Item"
        },
        {
            id:JOIN_ITEM,
            name:"Join Item"
        },
        {
            id:ANY_ITEM,
            name:"Any Item"
        }
    ],
    VOUCHER_OWNER_TYPES:[
        {
            id:SPECIFIC,
            name:"Specific"
        },
        {
            id:ANY,
            name:"Any"
        }
    ],
    POINT_AGGREGATE_OPTIONS:[
        {
            id:SINGLE_ITEM,
            name:"Single Item"
        },
        {
            id:JOIN_ITEM,
            name:"Join Item"
        }
    ],
    POINT_AGGREGATE_OPTIONS_2:[
        {
            id:ANY_ITEM,
            name:"Any Item"
        }
    ],
    POINT_TYPE_OPTIONS:[
        {
            id:PERCENTAGE_POINT,
            name:"Percentage"
        },
        {
            id:FIX_POINT,
            name:"Fixed"
        }
    ],

    STATUS_ICONS:{
        "warning-info":ApplicationIcons.Icons.warningInfo,
        "warning-attention":ApplicationIcons.Icons.warningAttention,
        "work-in-progress":ApplicationIcons.Icons.workInProgress,
        "paid":ApplicationIcons.Icons.paid,
        "expired":ApplicationIcons.Icons.expired,
        "canceled":ApplicationIcons.Icons.canceled,
        "refund":ApplicationIcons.Icons.refund,
        "well-done":ApplicationIcons.Icons.wellDone,
        "rejected":ApplicationIcons.Icons.rejected,
        "error":ApplicationIcons.Icons.error
    },
    DEVICE_STATUS_ICONS:{
        "initiated":ApplicationIcons.Icons.initiated,
        "active":ApplicationIcons.Icons.active,
        "inactive":ApplicationIcons.Icons.inactive,
        "maintenance":ApplicationIcons.Icons.maintenance,
        "expired":ApplicationIcons.Icons.expired,
    }



}