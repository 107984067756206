import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Modal, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import PropTypes from 'prop-types';
import {getData} from "../utils/StorageUtil";
import Global from "../utils/Global";

class BusinessContactModal extends BaseComponent{

    constructor(props) {
        super(props);
        let {show} = this.props
        let businessContact = getData(Global.BUSINESS_CONTACT)
        if(!businessContact){
            businessContact = {}
        }
        this.state = {
            businessContact:businessContact,
            show:show
        }
    }


    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let {show} = this.props
            this.setState({show})
        }
    }

    render() {
        let {businessContact, show} = this.state
        return (
            <>
                {super.render()}
                <Modal style={{zIndex:99999}} size="lg" as={Modal.Dialog} centered show={show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.BusinessContact}</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={()=>{
                            this.props.onClose()
                        }} />
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive className="table-flush">
                            <tbody>
                            <tr style={{ borderBottom: '1px solid grey' }}>
                                <td style={{width:'200px'}}  className="bg-light">{label.Name}</td>
                                <td>{businessContact.name}</td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid grey' }}>
                                <td style={{width:'200px'}}  className="bg-light">{label.Address}</td>
                                <td>{businessContact.address}</td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid grey' }}>
                                <td style={{width:'200px'}}  className="bg-light">{label.Email}</td>
                                <td>{businessContact.email}</td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid grey' }}>
                                <td style={{width:'200px'}}  className="bg-light">{label.PhoneNumber}</td>
                                <td>{businessContact.phoneNumber}</td>
                            </tr>
                            <tr>
                                <td style={{width:'200px'}}  className="bg-light">{label.Whatsapp}</td>
                                <td><a style={{color:'blue', textDecoration:'underline'}} href={`whatsapp://send?phone=${businessContact.whatsappNumber}`} target="_blank">{businessContact.whatsappNumber}</a></td>
                            </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}
BusinessContactModal.propTypes = {
    show:PropTypes.bool,
    onClose:PropTypes.func.isRequired,
}

export default BusinessContactModal
