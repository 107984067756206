
import React from 'react';
import { Image } from '@themesberg/react-bootstrap';
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";


export default (props) => {

  const { show } = props;

  return (
    <div style={{backgroundColor:'#f5f8fb90'}} className={`preloader flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
      <Image className="loader-element animate__animated animate__jackInTheBox" src={ApplicationIcons.Icons.smartSuppliesIcon} height={80} />
    </div>
  );
};
