import React from "react";
import {Button, Card, Col, Container, Image, Row} from '@themesberg/react-bootstrap';

import {Link} from 'react-router-dom';
import BasePage from "../base/BasePage";
import SigninPage from "./SigninPage";
import label from 'na-utilities/src/label/Label'
import Countdown from "react-countdown";
import {isResponseOk} from "na-utilities/src/utils/Utilities";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import Endpoint from "na-utilities/src/api/Endpoint";

class ResetPasswordSuccessPage extends BasePage {

  componentDidMount() {
    super.componentDidMount();
    this.props.closePreLoader()
    this.validateToken()
  }

  validateToken(){
    let {token} = this.props.match.params
    if(!token){
      window.location.href = SigninPage.ENDPOINT
      return
    }
    this.props.showProcessing()
    this.get(Endpoint.API.USER_RESET_PASSWORD_BY_TOKEN, {params:{token:token}}, null, async (response)=>{
      this.props.closeProcessing()
      if(isResponseOk(response)){
        let resetPassword = response.data
        if(!resetPassword.finished){
          let form = new FormData()
          form.append("token",token)
          let res = await this.asyncPost(Endpoint.API.USER_RESET_PASSWORD_FINISHING, form, null, false,true)
          return
        }
      }
      window.location.href = SigninPage.ENDPOINT
    }, false, false)
  }

  render() {
    let {prefix} = this.props.match.params
    let title = "Page not found"
    let description = ""

    return (
        <main>
          <section className="vh-100 d-flex align-items-center justify-content-center">
            <Container>
              <Row>
                <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
                  <div>
                    <h1 className="text-success mb-4">
                      {label.ResetPasswordSucceed}
                    </h1>
                    <Card.Link>
                      <Image src={ApplicationIcons.Icons.unlock} style={{width:'320px'}}/>
                    </Card.Link>
                    <p className="lead my-3">
                      {label.ResetPasswordSucceedNote}
                    </p>
                    <p>
                      <div class="text-success">
                        {label.YouWillBeRedirectToLoginPageForMoment}
                      </div>
                      <Countdown
                          date={Date.now() + 10000}
                          precision={3}
                          onComplete={()=>{
                            window.history.pushState(null, null, '/');
                            window.location.href = SigninPage.ENDPOINT
                          }}
                      />
                    </p>
                    <Button as={Link} variant="success" className="animate-hover" to={SigninPage.ENDPOINT}>
                      {label.SignInNow}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
    );
  }
}
export default Object.assign(ResetPasswordSuccessPage, {ENDPOINT:"/auth/success/resetPassword"})

