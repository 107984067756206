import React from "react";
import {Button, Card, Col, Image, Modal, Row} from '@themesberg/react-bootstrap';
import BasePage from "./../base/BasePage";
import Endpoint from "na-utilities/src/api/Endpoint";
import {handleTimeAgo, isResponseOk} from "na-utilities/src/utils/Utilities";
import label from "na-utilities/src/label/Label";
import {isEmpty} from "na-utilities/src/utils/Utilities";
import Global from "../../utils/Global";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo, faPlus} from "@fortawesome/free-solid-svg-icons";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import {toasting} from "../base/BaseComponent";
import TextInput from "../../components/input/TextInput";
import ContainerCard from "../../components/ContainerCard";
import LocationSelectorModal from "../../components/LocationSelectorModal";
import SockJsClient from 'react-stomp';
import {HEART_BEAT_INTERVAL} from "../../Variable";

// let paymentProofTable = React.createRef()

class SmartDeviceDetailPage extends BasePage{
  paymentProofTable = React.createRef()

  constructor(props) {
    super(props);
    let {deviceId} = props.match.params
    this.state = {
      deviceId:deviceId,
      smartDevice:{},
      spotLocation:{},
      smartDeviceState:{},
      smartDeviceStatus:{},
      paymentAttribute:{},
      requestProcessConfirmationModal:false,
      smartDeviceStatusHistoryModal:false,
      locationSelectorModal:false
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.props.closePreLoader()
    this.fetchSmartDevice()
    this.fetchPaymentMediaAttribute()
  }

  fetchSmartDevice(){
    let {deviceId,smartDevice,smartDeviceState,smartDeviceStatus, spotLocation} = this.state
    this.get(Endpoint.API.SMART_DEVICE, {params:{
        "deviceId":deviceId
      }}, null, (res)=>{
      if(isResponseOk(res)){
        smartDevice = res.data
        spotLocation = smartDevice.spotLocation
        smartDeviceState = smartDevice.smartDeviceState
        smartDeviceStatus = smartDevice.smartDeviceStatus
        this.setState({smartDevice,smartDeviceState,smartDeviceStatus, spotLocation})
      }else{
        // navigate(this.props, DashboardPage.ENDPOINT)
      }
    }, true, true)
  }

  fetchPaymentMediaAttribute(){
    let {paymentAttribute} = this.state
    this.get(Endpoint.API.PAYMENT_MEDIA_ATTRIBUTE, {}, null, (res)=>{
      if(isResponseOk(res)){
        paymentAttribute = res.data
        this.setState({paymentAttribute})
      }
    }, true, true)
  }

  requestToProcess(){
    let {deviceId,smartDevice,smartDeviceState,smartDeviceStatus, spotLocation} = this.state
    this.get(Endpoint.API.PRODUCT_ORDER_REQUEST_TO_PROCESS, {params:{
        "deviceId":deviceId
      }}, null, (res)=>{
      if(isResponseOk(res)){
        toasting.success(res.message)
        smartDevice = res.data
        spotLocation = smartDevice.spotLocation
        smartDeviceState = smartDevice.smartDeviceState
        smartDeviceStatus = smartDevice.smartDeviceStatus
        this.setState({smartDevice, spotLocation, smartDeviceState, smartDeviceStatus, requestProcessConfirmationModal:false},() => {
          if(this.paymentProofTable){
            this.paymentProofTable.current.fetchPaymentProofs()
          }
        })
      }
    }, true, true)
  }

  confirmDialogRender(){
    let {requestProcessConfirmationModal} = this.state
    return(
        <Modal as={Modal.Dialog} centered show={requestProcessConfirmationModal} onHide={()=>{}}>
          <Modal.Header>
            <Modal.Title className="h6">{label.Confirmation}</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={()=>{}} />
          </Modal.Header>
          <Modal.Body className="text-center">
            <Row>
              <Col className="text-center">
                <img src={ApplicationIcons.Icons.warningAttention}/>
              </Col>
            </Row>
            <br/>
            <h4>{label.RequestToProcessTitle}</h4>
            <p>{label.RequestToProcessDescription}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
                variant="outline-warning"
                className="text-gray ms-auto"
                onClick={()=>{
                  this.setState({requestProcessConfirmationModal:false})
                }}>
              {label.Close}
            </Button>
            <Button
                variant="success"
                onClick={()=>{
                  this.requestToProcess()
                }}>
              {label.Continue}
            </Button>
          </Modal.Footer>
        </Modal>
    )
  }

  updateSpotLocation(spotLocation){
    let {smartDevice,smartDeviceState,smartDeviceStatus} = this.state
    let form = new FormData()
    form.append("smartDeviceId",smartDevice.id)
    form.append("spotLocationId",spotLocation.id)
    this.post(Endpoint.API.SMART_DEVICE_UPDATE_SPOT_LOCATION, form, null, (res)=>{
      if(isResponseOk(res)){
        toasting.success(res.message)
        smartDevice = res.data
        spotLocation = smartDevice.spotLocation
        smartDeviceState = smartDevice.smartDeviceState
        smartDeviceStatus = smartDevice.smartDeviceStatus
        this.setState({smartDevice,smartDeviceState,smartDeviceStatus, spotLocation})
      }
    }, true, true)
  }

  locationSelectionModal(){
    let {locationSelectorModal} = this.state
    return(
        <LocationSelectorModal 
            show={locationSelectorModal}
            onClose={() =>{
              this.setState({locationSelectorModal:false})
            }}
            onSelectLocation={(spotLocation) => {
              this.setState({locationSelectorModal:false},() => {
                this.updateSpotLocation(spotLocation)
              })
            }}
        />
    )
  }



  render(){
    let {deviceId, smartDevice, smartDeviceStatus, smartDeviceState, spotLocation} = this.state
    return (
        <>
          {this.locationSelectionModal()}
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
          </div>
          <Row>
            <Col xs={12} xl={3}>
              <Row>
                <Col xs={12}>
                  <Card border="light" className="text-center p-0 mb-4">
                    <Card.Header className="pb-1">
                      <Card.Title>{label.DeviceInformation}</Card.Title>
                    </Card.Header>
                    <Card.Body className="p-2">
                      <Row className="pt-0 pl-3 pr-3">
                        <Col className="text-left">
                          <TextInput
                              label={label.Label}
                              readOnly={true}
                              value={smartDevice.label}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-0 pl-3 pr-3">
                        <Col className="text-left">
                          <TextInput
                              label={label.PublicIpAddress}
                              readOnly={true}
                              value={smartDevice.publicIpAddress}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-0 pl-3 pr-3">
                        <Col className="text-left">
                          <TextInput
                              label={label.LocalIpAddress}
                              readOnly={true}
                              value={smartDevice.localIpAddress}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-0 pl-3 pr-3">
                        <Col className="text-left">
                          <TextInput
                              label={label.DeviceId}
                              readOnly={true}
                              value={smartDevice.deviceId}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-0 pl-3 pr-3">
                        <Col className="text-left">
                          <TextInput
                              label={label.UUID}
                              readOnly={true}
                              value={smartDevice.uuidCode}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-0 pl-3 pr-3">
                        <Col className="text-left">
                          <TextInput
                              label={label.ShortCode}
                              readOnly={true}
                              value={smartDevice.shortCode}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={12} xl={9}>
              <Row>
                <Col xs={6}>
                  <Card border="light" className="bg-white shadow-sm mb-4 p-0">
                    <Card.Header>
                      <Row>
                        <Col>
                          {label.Status}
                        </Col>
                        <Col className="text-right p-0">
                          <Button
                              variant="outline-warning"
                              size="sm"
                              style={{width:'24px',height:'24px'}}
                              className="rounded-circle p-0"
                              onClick={(event) => {
                                event.preventDefault()
                                this.setState({smartDeviceStatusHistoryModal:true})
                              }}>
                            <FontAwesomeIcon style={{width:'12px',height:'12px'}} icon={faInfo} />
                          </Button>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-xl-flex align-items-center">
                        <div className="user-avatar xl-avatar">
                          <Image fluid rounded src={Global.DEVICE_STATUS_ICONS[smartDeviceStatus.icon]} />
                        </div>
                        <div className="file-field">
                          <div className="d-flex justify-content-xl-center ms-xl-3">
                            <div className="d-flex">
                              <div className="d-md-block text-start">
                                <div className="fw-normal font-weight-bold mb-1 rounded px-2 text-white" style={{backgroundColor:smartDeviceStatus.color}}>{smartDeviceStatus.label}</div>
                                <div className="text-gray small">{label.UpdatedAt} {handleTimeAgo(smartDevice.updated)}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer className="border-warning p-3">
                      <span className="rounded pt-1 px-4 pb-1 text-white" style={{fontSize:'12px', fontStyle:'italic', fontWeight:'bold', backgroundColor:smartDeviceState.color}}>{smartDeviceState.label}</span>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col xs={6}>
                  <Card border="light" className="bg-white shadow-sm mb-4">
                    <Card.Header>
                      <Row>
                        <Col>
                          {label.Location}
                        </Col>
                        <Col className="text-right p-0">
                          <Button
                              size="sm"
                              variant={!smartDevice.spotLocation?"success":"warning"}
                              onClick={event => {
                                this.setState({locationSelectorModal:true})
                              }}>
                            {label.SelectLocation}&nbsp;
                            <FontAwesomeIcon style={{width:'12px',height:'12px'}} icon={faPlus} />
                          </Button>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-xl-flex align-items-center">
                        <div className="user-avatar xl-avatar">
                          <Image fluid rounded src={ApplicationIcons.Icons.markerMap} />
                        </div>
                        <div className="file-field">
                          <div className="d-flex justify-content-xl-center ms-xl-3">
                            <div className="d-flex">
                              <div className="d-md-block text-start">
                                <div className="fw-normal font-weight-bold mb-1 rounded px-2">{!isEmpty(spotLocation.name)?spotLocation.name:label.NoInformation}</div>
                                <div className="text-gray small">{!isEmpty(spotLocation.phoneNumber)?spotLocation.phoneNumber:"-"}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer className="border-warning p-3">
                      <span className={!isEmpty(spotLocation.name)?"text-info":"text-danger"} style={{fontSize:'12px', fontStyle:'italic', fontWeight:'bold'}}>
                        {
                          isEmpty(spotLocation.name)? (label.PleaseFillUpDeviceLocated):(spotLocation.address+", "+spotLocation.regional)
                        }
                      </span>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={12} xl={12}>
                  <ContainerCard smartDevice={smartDevice}/>
                </Col>
              </Row>
            </Col>
          </Row>
          {this.confirmDialogRender()}
          <SockJsClient
              url={Global.BASE_URL}
              topics={["/listen/"+smartDevice.deviceId+"/refresh"]}
              heartbeat={HEART_BEAT_INTERVAL}
              debug={Global.DEBUG}
              autoReconnect={true}
              onMessage={(message) => {
                this.setState({smartDeviceState:message})
                console.log(message)
              }}/>
        </>
    );
  };
}
export default Object.assign(SmartDeviceDetailPage, {ENDPOINT : "/smartDeviceDetail"})
