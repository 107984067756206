import React from "react";
import {Button, Card, Col, Container, Image, Row} from '@themesberg/react-bootstrap';

import {Link} from 'react-router-dom';
import NotFoundImage from "../../assets/img/illustrations/404.svg";
import RegistrationSuccess from "../../assets/img/illustrations/registration-success.png";
import DashboardOverview from "../dashboard/DashboardOverview";
import BasePage from "../base/BasePage";
import SigninPage from "./SigninPage";
import label from 'na-utilities/src/label/Label'
import Countdown from "react-countdown";
import {navigate} from "na-utilities/src/utils/Utilities";

class RegistrationSuccessPage extends BasePage {

  componentDidMount() {
    super.componentDidMount();
    this.props.closePreLoader()
  }

  render() {
    let {prefix} = this.props.match.params
    let title = "Page not found"
    let description = ""

    return (
        <main>
          <section className="vh-100 d-flex align-items-center justify-content-center">
            <Container>
              <Row>
                <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
                  <div>
                    <h1 className="text-success mb-4">
                      {label.RegistrationSucceed}
                    </h1>
                    <Card.Link>
                      <Image src={RegistrationSuccess} style={{width:'320px'}}/>
                    </Card.Link>
                    <p className="lead my-3">
                      {label.RegistrationSucceedNote}
                    </p>
                    <p>
                      <div class="text-success">
                        {label.YouWillBeRedirectToLoginPageForMoment}
                      </div>
                      <Countdown
                          date={Date.now() + 10000}
                          precision={3}
                          onComplete={()=>{
                            window.location.href = SigninPage.ENDPOINT
                          }}
                      />
                    </p>
                    <Button as={Link} variant="success" className="animate-hover" to={SigninPage.ENDPOINT}>
                      {label.SignInNow}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
    );
  }
}
export default Object.assign(RegistrationSuccessPage, {ENDPOINT:"/success/registration"})

