import React from 'react'
import BaseComponent from "../../pages/base/BaseComponent";
import {Button, ButtonGroup, Card, Dropdown, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEllipsisH, faEye, faQrcode} from "@fortawesome/free-solid-svg-icons";
import label from "na-utilities/src/label/Label"
import {Link} from "react-router-dom";
import {Routes} from "../../routes";
import Endpoint from "na-utilities/src/api/Endpoint";
import {getUser} from "../../utils/StorageUtil";
import PropTypes from 'prop-types';
import SockJsClient from "react-stomp";
import Global from "../../utils/Global";
import {HEART_BEAT_INTERVAL} from "../../Variable";
import PaginationTable from "../../components/PaginationTable";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import MyImage from "../../components/MyImage";
import GoodsItemDetailPage from "./GoodsItemDetailPage";
import GoodsInfoDetailPage from "./GoodsInfoDetailPage";
import {navigate} from "na-utilities/src/utils/Utilities";


class GoodsItemTable extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            show:false,
            inputSearch: "",
            user : getUser(),
            sortOption:props.sortOption?props.sortOption:{},
            goodsItemStatus:props.goodsItemStatus?props.goodsItemStatus:{},
            tabDataCallback:props.tabDataCallback?props.tabDataCallback:{},
            page: 1,
            goodsItems:[],
            totalPage: 0,
            totalElement: 0,
            pageElement: 0
        }
    }
    componentDidMount() {
        super.componentDidMount();
        this.fetchGoodsItems(undefined, undefined, undefined, undefined, this.state.sortOption)
    }

    componentWillReceiveProps(props, nextContext) {
        let show = props.show?props.show:false
        let goodsItemStatus = props.goodsItemStatus?props.goodsItemStatus:{}
        let tabDataCallback = props.tabDataCallback?props.tabDataCallback:{}
        this.setState({show, goodsItemStatus, tabDataCallback},() => {
            if(show && !this.props.show){
                this.fetchGoodsItems(undefined, undefined, undefined, undefined, this.state.sortOption)
            }
        })
    }

    goToPage(page){
        this.setState({page}, () => {
            this.refresh()
        })
    }

    refresh(){
        let {search, filteredSmartDevices, filteredUsers, filteredGoodsCategories, sortOption} = this.state
        this.fetchGoodsItems(search, filteredSmartDevices, filteredUsers, filteredGoodsCategories, sortOption)
    }


    fetchGoodsItems = (search, filteredSmartDevices, filteredUsers, filteredGoodsCategories, sortOption) => {
        let {user, goodsItemStatus, goodsItems, tabDataCallback} = this.state
        let filterSmartDeviceIds = undefined
        if(filteredSmartDevices){
            filterSmartDeviceIds = new Array()
            filteredSmartDevices.forEach((item)=>{
                filterSmartDeviceIds.push(item.id)
            })
        }
        let filterUserIds = undefined
        if(filteredUsers){
            filterUserIds = new Array()
            filteredUsers.forEach((item)=>{
                filterUserIds.push(item.id)
            })
        }
        let filterGoodsCategoryIds = undefined
        if(filteredGoodsCategories){
            filterGoodsCategoryIds = new Array()
            filteredGoodsCategories.forEach((item)=>{
                filterGoodsCategoryIds.push(item.id)
            })
        }
        this.setState({
            search, filteredSmartDevices, filteredUsers, filteredGoodsCategories, sortOption
        }, () => {
            this.get(Endpoint.API.GOODS_ITEMS, {
                params :{
                    goodsItemStatusId : goodsItemStatus.id==0?undefined:goodsItemStatus.id,
                    ascending: sortOption.ascending,
                    sortir: sortOption.sortir,
                    search: search,
                    filteredSmartDeviceIdStrings:JSON.stringify(filterSmartDeviceIds),
                    filteredUserIdStrings:JSON.stringify(filterUserIds),
                    filteredGoodsCategoryIdStrings:JSON.stringify(filterGoodsCategoryIds),
                    page: this.state.page - 1,
                }
            }, null, response=>{
                if(response.code===200){
                    let goodsItems = response.data
                    let totalPage = response.totalPage
                    let totalElement = response.totalElement
                    let pageElement = response.pageElement
                    this.setState({goodsItems:goodsItems,totalPage, totalElement, pageElement}, () => {
                        if(tabDataCallback){
                            tabDataCallback(goodsItemStatus.id, totalElement)
                        }
                    })
                }
            }, true, true)
        })
    }


    render() {
        let {user, goodsItems, page, pageElement, totalPage, totalElement} = this.state
        return (
            <React.Fragment>
                <Card border="light" className="table-wrapper table-responsive shadow-sm">
                    <Card.Body className="p-0">
                        <Table hover className="table-sm align-items-center p-0">
                            <thead>
                            <tr>
                                <th className="border-bottom">#</th>
                                <th className="border-bottom">{label.Goods}</th>
                                <th className="border-bottom">{label.GoodsCategory}</th>
                                <th className="border-bottom">{label.RFIDTag}</th>
                                <th className="border-bottom">{label.Allocation}</th>
                                <th className="border-bottom">{label.Status}</th>
                                <th className="border-bottom">{label.Action}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {goodsItems.map((goodsItem, index) => (
                                <tr key={index}>
                                    <td>
                                        <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
                                            {((page - 1) * pageElement) + (index+1)}
                                        </Card.Link>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            <Link style={{textDecoration:'underline'}} to={GoodsInfoDetailPage.ENDPOINT+"/"+goodsItem.goodsInfo.id}>
                                                {goodsItem.goodsInfo.name}
                                            </Link>
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {goodsItem.goodsInfo.goodsCategory.name}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">{goodsItem.rfidTag}</span>
                                    </td>
                                    <td>
                                        <Button
                                            size="sm"
                                            variant={goodsItem.lastPhysicalContainerLocation?"outline-primary":"outline-danger"}
                                            className="fw-normal"
                                            disabled={true}
                                            color={{color:(goodsItem.lastPhysicalContainerLocation?"info":"red")}}>
                                            <MyImage src={ApplicationIcons.Icons.notAllocated} style={{width:'24px'}} />&nbsp;
                                            {goodsItem.lastPhysicalContainerLocation?goodsItem.lastPhysicalContainerLocation.deviceId:label.NotYetAllocated}
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            size="sm"
                                            variant="outline-info"
                                            className="fw-normal"
                                            disabled={true}
                                            style={{color:(goodsItem.goodsItemStatus?goodsItem.goodsItemStatus.color:undefined)}}>
                                            {goodsItem.goodsItemStatus?label(goodsItem.goodsItemStatus.label):"-"}
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            size="sm"
                                            onClick={event => {
                                                window.location.href = GoodsItemDetailPage.ENDPOINT+"/"+goodsItem.id
                                            }}>
                                            <FontAwesomeIcon icon={faEye}/>
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                    <PaginationTable
                        onSelect={(page) => {
                            this.goToPage(page)
                        }}
                        showingRow={goodsItems.length}
                        currentPage={page}
                        pageCount={totalPage}
                        totalElement={totalElement}/>
                </Card>
            </React.Fragment>
        );
    }

}
GoodsItemTable.propTypes = {
    show:PropTypes.bool.isRequired,
    goodsItemStatus:PropTypes.any.isRequired,
    sortOption:PropTypes.object,
    tabDataCallback:PropTypes.func
}


export default GoodsItemTable