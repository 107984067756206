import React from 'react'
import {Button} from "@themesberg/react-bootstrap";
import PropTypes from "prop-types";

export default class DotLayout extends React.Component{

    render() {
        return (
            <Button
                className="rounded-circle"
                size="sm"
                disabled={true}
                style={{width:this.props.dimension, height:this.props.dimension,
                    backgroundColor:(this.props.backgroundColor?this.props.backgroundColor:"red")}}/>
        );
    }
}
DotLayout.propTypes = {
    dimension:PropTypes.number,
    backgroundColor:PropTypes.string
}