import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome} from '@fortawesome/free-solid-svg-icons';
import {Breadcrumb, Col, Nav, Row, Tab} from '@themesberg/react-bootstrap';
import BasePage from "../base/BasePage";
import label from "na-utilities/src/label/Label"
import DashboardPage from "../dashboard/DashboardPage";
import Endpoint from "na-utilities/src/api/Endpoint";
import {isEmpty} from "na-utilities/src/utils/Utilities";
import GoodsItemTable from "./GoodsItemTable";
import Global from "na-utilities/src/utils/Global";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import SearchInput from "../../components/input/SearchInput";
import TableSortOption from "../../components/input/TableSortOption";
import SortOption from "na-utilities/src/model/SortOption";
import FilterButton from "../../components/FilterButton";
import SmartDeviceFilterModal from "../../components/SmartDeviceFilterModal";
import UserFilterModal from "../../components/UserFilterModal";
import GoodsCategoryFilterModal from "../../components/GoodsCategoryFilterModal";

let goodsItemTableRef = React.createRef();

class GoodsItemPage extends BasePage {
    constructor(props) {
        super(props);
        this.state = {
            goodsItemStatuses:this.defaultGoodsItemStatus(),
            defaultActiveTab:"0",
            activeTab:0,
            categoryFilter:false,
            userFilter:false,
            deviceFilter:false,
            filteredSmartDevices:new Set([]),
            filteredUsers:new Set([]),
            filteredGoodsCategories:new Set([]),
            sortOption:SortOption.init(label.Longest,"gi.created",true),
            color:'blue',
            tabDataSize:{}
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.closePreLoader()
        this.fetchGoodsItemStatus()
    }
    defaultGoodsItemStatus(){
        let goodsItemStatuses = []
        let allGoodsItemStatus = {
            id:0,
            label:label.All,
            active:true,
            iconLink:ApplicationIcons.Icons.lines,
        }
        goodsItemStatuses.push(allGoodsItemStatus)
        return goodsItemStatuses
    }

    fetchGoodsItemStatus = () => {
        let {goodsItemStatuses,activeTab,defaultActiveTab} = this.state
        this.get(Endpoint.API.GOODS_ITEM_STATUSES, {
            params :{
                excludeIds:JSON.stringify([
                    Global.GoodsItemStatuses.TAGGED.id, Global.GoodsItemStatuses.RETURNED.id,
                    Global.GoodsItemStatuses.PUT_IN.id, Global.GoodsItemStatuses.TAKEN_OUT.id
                ]),
                sortir: "id",
                ascending:true,
            }
        }, null, response=>{
            if(response.code===200){
                goodsItemStatuses = this.defaultGoodsItemStatus();
                goodsItemStatuses.push(...response.data)
                let tabDataSize = {}
                for(let goodsItemStatus of goodsItemStatuses){
                    tabDataSize[goodsItemStatus.id] = 0
                }
                this.setState({goodsItemStatuses:goodsItemStatuses, tabDataSize},() => this.forceUpdate())
            }else{
                this.props.history.goBack();
            }
        }, false, true)
    }
    fetchGoodsItems(){
        let {search, filteredSmartDevices, filteredUsers, filteredGoodsCategories, sortOption} = this.state
        this.setState({activeTab:0},
            () => {
                this.goodsItemTableRef.fetchGoodsItems(search, filteredSmartDevices, filteredUsers, filteredGoodsCategories, sortOption)
            })
    }
    tabDataCallback(id, size){
        let {tabDataSize} = this.state
        tabDataSize[id] = size
        this.setState({tabDataSize})
    }

    render() {
        let {goodsItemStatuses, defaultActiveTab, activeTab,
            userFilter, deviceFilter, categoryFilter, filteredGoodsCategories, filteredSmartDevices, filteredUsers,
            tabDataSize, pageElement, totalPage, totalElement, sortOption} = this.state

        return (
            <>
                <GoodsCategoryFilterModal
                    show={categoryFilter}
                    selectedGoodsCategories={filteredGoodsCategories}
                    onClose={()=>{
                        this.setState({categoryFilter:false})
                    }}
                    onSelect={(selectedGoodsCategories)=>{
                        this.setState({categoryFilter:false, filteredGoodsCategories:selectedGoodsCategories}, () => {
                            this.fetchGoodsItems()
                        })
                    }}/>
                <SmartDeviceFilterModal
                    show={deviceFilter}
                    selectedSmartDevices={filteredSmartDevices}
                    onClose={()=>{
                        this.setState({deviceFilter:false})
                    }}
                    onSelect={(selectedSmartDevices)=>{
                        this.setState({deviceFilter:false, filteredSmartDevices:selectedSmartDevices}, () => {
                            this.fetchGoodsItems()
                        })
                    }}/>
                <UserFilterModal
                    show={userFilter}
                    selectedUsers={filteredUsers}
                    onClose={()=>{
                        this.setState({userFilter:false})
                    }}
                    onSelect={(selectedUsers)=>{
                        this.setState({userFilter:false, filteredUsers:selectedUsers}, () => {
                            this.fetchGoodsItems()
                        })
                    }}/>

                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-md-0">
                        <Breadcrumb className="d-none d-md-inline-block" listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={DashboardPage.ENDPOINT}>{label.Dashboard}</Breadcrumb.Item>
                            <Breadcrumb.Item active>{label.GoodsItemList}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <Row>
                    <Col>
                        <Row className="mb-4 align-items-center">
                            <Col xl={4}>
                                <SearchInput onEnter={(search) => {
                                    this.setState({search}, () => {
                                        this.fetchGoodsItems()
                                    })
                                }} onEmpty={() => {
                                    this.setState({search:""},() => {
                                        this.fetchGoodsItems()
                                    })
                                }}/>
                            </Col>
                            <Col xs={4} md={4} xl={2} className="ps-md-0">
                                <TableSortOption
                                    sortOptions={
                                        [
                                            SortOption.init(label.Longest,"gi.created",true),
                                            SortOption.init(label.Latest,"gi.created",false),
                                            SortOption.init(label.RFIDTag,"gi.rfidTag",false),
                                        ]
                                    }
                                    sortOption={sortOption}
                                    placeholder={label.SortBy}
                                    onSelect={(sortOption) => {
                                        this.setState({sortOption},() => {
                                            this.fetchGoodsItems()
                                        })
                                    }}
                                    onReset={() => {
                                        this.setState({sortOption:{}},() => {
                                            this.fetchGoodsItems()
                                        })
                                    }}/>
                            </Col>
                            <Col xs={4} md={4} xl={6} className="ps-md-0 text-right">
                                <FilterButton
                                    label={label.CategoryFilter}
                                    active={filteredGoodsCategories.size>0}
                                    onReset={() => {
                                        this.setState({
                                            filteredGoodsCategories:new Set([])
                                        }, () => {
                                            this.fetchGoodsItems()
                                        })
                                    }}
                                    onClick={() => {
                                        this.setState({categoryFilter:true})
                                    }}/>
                                &nbsp;
                                &nbsp;
                                <FilterButton
                                    label={label.UserFilter}
                                    active={filteredUsers.size>0}
                                    onReset={() => {
                                        this.setState({
                                            filteredUsers:new Set([])
                                        }, () => {
                                            this.fetchGoodsItems()
                                        })
                                    }}
                                    onClick={() => {
                                        this.setState({userFilter:true})
                                    }}/>
                                &nbsp;
                                &nbsp;
                                <FilterButton
                                    label={label.DeviceFilter}
                                    active={filteredSmartDevices.size>0}
                                    onReset={() => {
                                        this.setState({
                                            filteredSmartDevices:new Set([])
                                        }, () => {
                                            this.fetchGoodsItems()
                                        })
                                    }}
                                    onClick={() => {
                                        this.setState({deviceFilter:true})
                                    }}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    !isEmpty(defaultActiveTab)?
                                        (
                                            <Tab.Container defaultActiveKey={defaultActiveTab}>
                                                <Nav fill variant="tabs" className="flex-column flex-sm-row p-0">
                                                    {
                                                        goodsItemStatuses.map((item, index)=>(
                                                            <Nav.Link
                                                                eventKey={item.id}
                                                                className={"mb-sm-3 mb-md-0 mx-1 p-2 text-black "+(item.id === activeTab?'bg-soft-green':'bg-white')}
                                                                onSelect={eventKey => {
                                                                    this.setState({activeTab:item.id})
                                                                }}
                                                                disabled={!item.active}>
                                                                <img style={{width:'24px'}} src={item.iconLink}/>&nbsp;
                                                                <span style={{color:(item.id === activeTab?'white':item.color)}}>{label(item.label)}</span>
                                                                <span style={{color:(item.id === activeTab?'white':item.color), fontWeight:'bold'}}>{" ("+tabDataSize[item.id]+")"}</span>
                                                            </Nav.Link>

                                                        ))
                                                    }
                                                </Nav>
                                                <Tab.Content>
                                                    {
                                                        goodsItemStatuses.map((goodsItemStatus, index)=>(
                                                            <Tab.Pane eventKey={goodsItemStatus.id} className="py-4">
                                                                <GoodsItemTable
                                                                    showProcessing={this.props.showProcessing}
                                                                    closeProcessing={this.props.closeProcessing}
                                                                    sortOption={sortOption}
                                                                    ref={(ref)=>{
                                                                        if(goodsItemStatus.id === activeTab){
                                                                            this.goodsItemTableRef = ref
                                                                        }
                                                                    }}
                                                                    show={goodsItemStatus.id === activeTab}
                                                                    goodsItemStatus={goodsItemStatus}
                                                                    tabDataCallback={(id, size) => {
                                                                        this.tabDataCallback(id, size)
                                                                    }}/>
                                                            </Tab.Pane>
                                                        ))
                                                    }
                                                </Tab.Content>

                                            </Tab.Container>
                                        )
                                        :
                                        (null)
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}
export default Object.assign(GoodsItemPage, {ENDPOINT : "/goodsItems"})

