import React from "react";
import {Col, Row} from '@themesberg/react-bootstrap';
import BasePage from "./../base/BasePage";
import ProfileForms from "../../components/ProfileForms";
import {UserDto} from "../../model/model";
import ProfileCard from "../../components/ProfileCard";
import UserSessionCard from "../../components/UserSessionCard";
import User5LastActivityCard from "../../components/User5LastActivityCard";
import Endpoint from "na-utilities/src/api/Endpoint";
import {isEmpty, isResponseOk, isValidEmail} from "na-utilities/src/utils/Utilities";
import {storeData} from "../../utils/StorageUtil";
import Global from "../../utils/Global";
import label from "na-utilities/src/label/Label";
import {toasting} from "../base/BaseComponent";
import {isValidNumber} from "libphonenumber-js";
import {AuthConsumer} from "../../context/AuthContext";


class ProfilePage extends BasePage{

  constructor(props) {
    super(props);
    let validation = this.defaultValidation()
    let validationMessage = this.defaultValidationMessage()
    this.state = {
      user : {},
      validation : validation,
      validationMessage : validationMessage
    }
  }

  defaultValidation = () =>{
    let validation = new UserDto();
    validation.firstname = true ;
    validation.lastname = true ;
    validation.birthdate = true
    validation.mobilePhone = true
    validation.gender = true ;
    validation.email = true
    validation.address = true ;
    validation.province = true ;
    validation.city = true
    validation.district = true ;
    validation.village = true
    return validation ;
  }
  defaultValidationMessage = () =>{
    let validationMessage = new UserDto();
    validationMessage.firstname = "" ;
    validationMessage.lastname = "" ;
    validationMessage.birthdate = ""
    validationMessage.mobilePhone = ""
    validationMessage.gender = "" ;
    validationMessage.email = ""
    validationMessage.address = "" ;
    validationMessage.province = "" ;
    validationMessage.city = ""
    validationMessage.district = "" ;
    validationMessage.village = ""
    return validationMessage ;
  }
  componentDidMount() {
    super.componentDidMount();
    this.closePreLoader()
    this.refreshUser((user)=>{this.setState({user})})
  }

  onChangeGeneralForm(field, value){
    console.log("Field = "+field+" : "+JSON.stringify(value))
    let {user, validation, validationMessage} = this.state
    if(field==='firstname'){
      validation.firstname = true
      validationMessage.firstname = ""
    }
    if(field==='email'){
      validation.email = true
      validationMessage.email = ""
    }
    if(field==='mobilePhone'){
      validation.mobilePhone = true
      validationMessage.mobilePhone = ""
    }
    if(field==='province'){
      validation.province = true
      validationMessage.province = ""
    }
    if(field==='city'){
      validation.city = true
      validationMessage.city = ""
    }
    if(field==='district'){
      validation.district = true
      validationMessage.district = ""
    }
    if(field==='village'){
      validation.village = true
      validationMessage.village = ""
    }
    user[field] = value
    this.setState({user, validation, validationMessage})
  }

  async validateFormSubmit() {
    // await super.validateFormSubmit();
    let {user, validation, validationMessage} = this.state
    let valid = true ;
    validation = this.defaultValidation()
    validationMessage = this.defaultValidationMessage()
    if(isEmpty(user.mobilePhone) || user.mobilePhone.length <=2){
      validation.mobilePhone = false
      validationMessage.mobilePhone = label.PleaseEnterCorrectPhoneNumber
      valid = false ;
    }
    if(!isValidNumber(user.mobilePhone, 'ID')){
      validation.mobilePhone = false
      validationMessage.mobilePhone = label.PleaseEnterCorrectPhoneNumber
      valid = false ;
    }
    if(isEmpty(user.firstname) || user.firstname.length <=2){
      validation.firstname = false
      validationMessage.firstname = label.PleaseEnterFirstName
      valid = false ;
    }
    if(isEmpty(user.email) || user.email.length <=2){
      validation.email = false
      validationMessage.email = label.PleaseEnterEmail
      valid = false ;
    }
    if(!isValidEmail(user.email)){
      validation.email = false
      validationMessage.email = label.PleaseEnterCorrectEmail
      valid = false ;
    }

    if(!isEmpty(user.address) || !isEmpty(user.province) || !isEmpty(user.city) || !isEmpty(user.district) || !isEmpty(user.village)){
      if(isEmpty(user.address) || user.address.length <=2){
        validation.address = false
        validationMessage.address = label.PleaseEnterAddress
        valid = false ;
      }
      if(isEmpty(user.province)){
        validation.province = false
        validationMessage.province = label.PleaseSelectProvince
        valid = false ;
      }
      if(isEmpty(user.city)){
        validation.city = false
        validationMessage.city = label.PleaseSelectCity
        valid = false ;
      }
      if(isEmpty(user.district)){
        validation.district = false
        validationMessage.district = label.PleaseSelectDistrict
        valid = false ;
      }
      if(isEmpty(user.village)){
        validation.village = false
        validationMessage.village = label.PleaseSelectVillage
        valid = false ;
      }
    }


    // if(isEmpty(registrationVerification.address) || registrationVerification.address.length <=2){
    //     validation.address = false
    //     validationMessage.address = label.PleaseEnterAddressCorrectly
    //     valid = false ;
    // }
    // if(isEmpty(registrationVerification.province)){
    //     validation.province = false
    //     validationMessage.province = label.PleaseSelectProvince
    //     valid = false ;
    // }
    // if(isEmpty(registrationVerification.city)){
    //     validation.city = false
    //     validationMessage.city = label.PleaseSelectCity
    //     valid = false ;
    // }
    // if(isEmpty(registrationVerification.district)){
    //     validation.district = false
    //     validationMessage.district = label.PleaseSelectDistrict
    //     valid = false ;
    // }
    // if(isEmpty(registrationVerification.village)){
    //     validation.village = false
    //     validationMessage.village = label.PleaseSelectVillage
    //     valid = false ;
    // }
    this.setState({user, validation, validationMessage}, async () => {
      if(valid){
        await this.save()
      }
    })
  }

  async save(){
    let {user} = this.state
    let formData = new FormData()
    formData.append("userDtoGson",JSON.stringify(user))
    this.post(Endpoint.API.USER_SAVE, formData, null, (res)=>{
      if(isResponseOk(res)){
        toasting.success(label.SuccessUpdatingData)
        let user = res.data
        storeData(Global.USER, user)
        this.setState({user})
      }else{
        toasting.error(res.message)
      }
    }, true, false)
  }

  validate(){

  }


  render(){
    let {user,validation,validationMessage} = this.state
    return (
        <AuthConsumer>
          {({ isAuth, user,refresh }) => {
            return (
                <>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
                    <h4>{label.YourProfilePage}</h4>
                  </div>

                  <Row>
                    <Col xs={12} xl={8}>
                      <ProfileForms
                          user={user}
                          firstname={user.firstname}
                          lastname={user.lastname}
                          birthdate={user.birthdate}
                          gender={user.gender}
                          email={user.email}
                          mobilePhone={user.mobilePhone}
                          address={user.address}
                          province={user.province}
                          city={user.city}
                          district={user.district}
                          village={user.village}
                          validation={validation}
                          validationMessage={validationMessage}
                          onChange={(field,value) => {
                            this.onChangeGeneralForm(field,value)
                          }}
                          onSave={async () => await this.validateFormSubmit()}
                      />
                    </Col>

                    <Col xs={12} xl={4}>
                      <Row>
                        <Col xs={12}>
                          <ProfileCard
                              user={user}
                              showProcessing={this.props.showProcessing}
                              closeProcessing={this.props.closeProcessing}
                              onRefresh={(user)=>{
                                if(refresh){
                                  refresh()
                                }
                              }}/>
                        </Col>
                        <Col xs={12}>
                          <UserSessionCard user={user} onRefresh={() => {}}/>
                        </Col>
                        <Col xs={12}>
                          <User5LastActivityCard user={user} onRefresh={() => {}}/>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {super.render()}
                </>
            )
          }}
        </AuthConsumer>
    );
  };
}
export default Object.assign(ProfilePage, {ENDPOINT : "/profile"})
