import React, {useState} from "react";
import SimpleBar from 'simplebar-react';
import {Link, useLocation} from "react-router-dom";
import {CSSTransition} from 'react-transition-group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBook,
  faBoxOpen,
  faChartPie,
  faFileAlt,
  faHandHoldingUsd,
  faHistory,
  faInbox,
  faSignOutAlt,
  faTable,
  faTicketAlt,
  faToolbox,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import {Accordion, Badge, Button, Dropdown, Image, Nav, Navbar} from '@themesberg/react-bootstrap';
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import SigninPage from "../pages/examples/SigninPage";
import SignupPage from "../pages/examples/SignupPage";
import LockPage from "../pages/examples/LockPage";
import ForgotPasswordPage from "../pages/examples/ForgotPasswordPage";
import ResetPasswordPage from "../pages/examples/ResetPasswordPage";
import NotFoundPage from "../pages/examples/ExceptionPage";
import ServerErrorPage from "../pages/examples/ServerErrorPage";
import BootstrapTablePage from "../pages/tables/BootstrapTablePage";
import DocsOverviewPage from "../pages/documentation/DocsOverviewPage";
import DocsDownloadPage from "../pages/documentation/DocsDownloadPage";
import DocsQuickStartPage from "../pages/documentation/DocsQuickStartPage";
import DocsLicensePage from "../pages/documentation/DocsLicensePage";
import DocsFolderStructure from "../pages/documentation/DocsFolderStructurePage";
import DocsChangelog from "../pages/documentation/DocsChangelogPage";
import AccordionPage from "../pages/components/AccordionPage";
import AlertPage from "../pages/components/AlertPage";
import BadgePage from "../pages/components/BadgePage";
import BreadcrumbPage from "../pages/components/BreadcrumbPage";
import FormPage from "../pages/components/FormPage";
import ButtonPage from "../pages/components/ButtonPage";
import ModalPage from "../pages/components/ModalPage";
import NavPage from "../pages/components/NavPage";
import NavbarPage from "../pages/components/NavbarPage";
import PaginationPage from "../pages/components/PaginationPage";
import PopoverPage from "../pages/components/PopoverPage";
import ProgressPage from "../pages/components/ProgressPage";
import TablePage from "../pages/components/TablePage";
import TabPage from "../pages/components/TabPage";
import TooltipPage from "../pages/components/TooltipPage";
import ToastPage from "../pages/components/ToastPage";
import DocsBuildPage from "../pages/documentation/DocsBuildPage";
import ReactLogo from "../assets/img/smart-supplies-icon.png";
import label from 'na-utilities/src/label/Label'
import DashboardPage from "../pages/dashboard/DashboardPage";
import ProductOrderPage from "../pages/order/ProductOrderPage";
import SmartDevicePage from "../pages/subscribe/SmartDevicePage";
import ProductSubscribePage from "../pages/subscribe/ProductSubscribePage";
import SpotLocationPage from "../pages/management/SpotLocationPage";
import UserPage from "../pages/management/UserPage";
import ProfilePage from "../pages/config/ProfilePage";
import SmartDeviceDetailPage from "../pages/subscribe/SmartDeviceDetailPage";
import SpotLocationDetailPage from "../pages/management/SpotLocationDetailPage";
import UserDetailPage from "../pages/management/UserDetailPage";
import UserRolePage from "../pages/management/UserRolePage";
import UserActivityDetailPage from "../pages/session/UserActivityDetailPage";
import UserActivityPage from "../pages/session/UserActivityPage";
import UserDevicePage from "../pages/session/UserDevicePage";
import GoodsInfoPage from "../pages/goods/GoodsInfoPage";
import GoodsItemPage from "../pages/goods/GoodsItemPage";
import BorrowingOfItemPage from "../pages/goods/BorrowingOfItemPage";
import Endpoint from "na-utilities/src/api/Endpoint";
import GoodsItemDetailPage from "../pages/goods/GoodsItemDetailPage";
import {getData} from "../utils/StorageUtil";
import Global from "../utils/Global";
import {AuthConsumer} from "../context/AuthContext";
import {COPYRIGHT} from "../Variable";

const showExample = false;

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = (element) => {
    setShow(!show)
  };

  const user = getData(Global.USER)
  const registration = user.registration?user.registration:{}


  const CollapsableNavItem = (props) => {
    const {members, eventKey, title, icon, children = null } = props;
    let defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";
    // alert(JSON.stringify(window.location))
    if(members){
      members.forEach((member)=>{
        if(pathname.indexOf(member) !== -1){
          defaultKey = eventKey
        }
      })
    }

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary", onClick} = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => {
        if(!onClick){
          setShow(false)
          return ;
        }
        onClick()
      }}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
      <AuthConsumer>
        {({ isAuth, user, logout}) => (
            <>
              <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
                <Navbar.Brand className="me-lg-5" as={Link} to={DashboardPage.ENDPOINT}>
                  <Image src={ReactHero} className="navbar-brand-light" />
                </Navbar.Brand>
                <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
                  <span className="navbar-toggler-icon" />
                </Navbar.Toggle>
              </Navbar>
              <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
                <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
                  <div className="sidebar-inner px-4 pt-3">
                    <Nav className="flex-column pt-3 pt-md-0">
                      <NavItem className="bg-yellow" title={label.AppName} link={"/"} image={ReactLogo} />
                      <NavItem title={label.Dashboard} link={DashboardPage.ENDPOINT} icon={faChartPie} />
                      <NavItem title={label.ProductOrder} icon={faHandHoldingUsd} link={ProductOrderPage.ENDPOINT} />
                      <CollapsableNavItem
                          members={[ProductSubscribePage.ENDPOINT, SmartDevicePage.ENDPOINT, SmartDeviceDetailPage.ENDPOINT]}
                          eventKey={label.Service}
                          title={label.Service}
                          icon={faInbox}>
                        <NavItem title={label.ProductSubscribe} link={ProductSubscribePage.ENDPOINT} />
                        <NavItem title={label.SmartDevice} link={SmartDevicePage.ENDPOINT} />
                      </CollapsableNavItem>
                      <CollapsableNavItem
                          members={[GoodsInfoPage.ENDPOINT, GoodsItemPage.ENDPOINT, GoodsItemDetailPage.ENDPOINT, BorrowingOfItemPage.ENDPOINT]}
                          eventKey={label.ToolBox}
                          title={label.ToolBox}
                          icon={faToolbox}>
                        <NavItem title={label.GoodsList} link={GoodsInfoPage.ENDPOINT} />
                        <NavItem title={label.GoodsItemList} link={GoodsItemPage.ENDPOINT} />
                        <NavItem title={label.BorrowingList} link={BorrowingOfItemPage.ENDPOINT} />
                      </CollapsableNavItem>
                      <CollapsableNavItem
                          members={[DocsDownloadPage.ENDPOINT, UserPage.ENDPOINT, UserDetailPage.ENDPOINT, UserRolePage.ENDPOINT, UserActivityDetailPage.ENDPOINT,
                            SpotLocationPage.ENDPOINT, SpotLocationDetailPage.ENDPOINT]}
                          eventKey={label.Management}
                          title={label.Management}
                          icon={faTable}>
                        <NavItem title={label.User} link={UserPage.ENDPOINT} />
                        <NavItem title={label.Location} link={SpotLocationPage.ENDPOINT} />
                      </CollapsableNavItem>
                      <CollapsableNavItem
                          members={[UserDevicePage.ENDPOINT, UserActivityPage.ENDPOINT]}
                          eventKey={label.SessionAndActivity}
                          title={label.SessionAndActivity}
                          icon={faHistory}>
                        <NavItem title={label.Devices} link={UserDevicePage.ENDPOINT} />
                        <NavItem title={label.UserActivity} link={UserActivityPage.ENDPOINT} />
                      </CollapsableNavItem>
                      {
                        showExample?(
                            <>
                              <CollapsableNavItem eventKey="tables/" title="TablePage" icon={faTable}>
                                <NavItem title="Bootstrap Table" link={BootstrapTablePage.ENDPOINT} />
                              </CollapsableNavItem>

                              <CollapsableNavItem eventKey="examples/" title="Page Examples" icon={faFileAlt}>
                                <NavItem title="Sign In" link={SigninPage.ENDPOINT} />
                                <NavItem title="Sign Up" link={SignupPage.ENDPOINT} />
                                <NavItem title="Forgot password" link={ForgotPasswordPage.ENDPOINT} />
                                <NavItem title="Reset password" link={ResetPasswordPage.ENDPOINT} />
                                <NavItem title="Lock" link={LockPage.ENDPOINT} />
                                <NavItem title="404 Not Found" link={NotFoundPage.ENDPOINT} />
                                <NavItem title="500 Server Error" link={ServerErrorPage.ENDPOINT} />
                              </CollapsableNavItem>
                            </>
                        ):(null)
                      }

                      <Dropdown.Divider className="my-3 border-white" />
                      {
                        showExample?(
                            <>
                              <CollapsableNavItem eventKey="documentation/" title="Getting Started" icon={faBook}>
                                <NavItem title="Overview" link={DocsOverviewPage.ENDPOINT} />
                                <NavItem title="Download" link={DocsDownloadPage.ENDPOINT} />
                                <NavItem title="Quick Start" link={DocsQuickStartPage.ENDPOINT} />
                                <NavItem title="License" link={DocsLicensePage.ENDPOINT} />
                                <NavItem title="Folder Structure" link={DocsFolderStructure.ENDPOINT} />
                                <NavItem title="Build Tools" link={DocsBuildPage.ENDPOINT} />
                                <NavItem title="Changelog" link={DocsChangelog.path} />
                              </CollapsableNavItem>
                              <CollapsableNavItem eventKey="components/" title="Components" icon={faBoxOpen}>
                                <NavItem title="Accordion" link={AccordionPage.ENDPOINT} />
                                <NavItem title="Alert" link={AlertPage.ENDPOINT} />
                                <NavItem title="Badges" link={BadgePage.ENDPOINT} />
                                <NavItem external title="Widgets" link="https://demo.themesberg.com/volt-pro-react/#/components/widgets" target="_blank" badgeText="Pro" />
                                <NavItem title="Breadcrumbs" link={BreadcrumbPage.ENDPOINT} />
                                <NavItem title="Buttons" link={ButtonPage.ENDPOINT} />
                                <NavItem title="Forms" link={FormPage.ENDPOINT} />
                                <NavItem title="Modals" link={ModalPage.ENDPOINT} />
                                <NavItem title="Navbars" link={NavbarPage.ENDPOINT} />
                                <NavItem title="Navs" link={NavPage.ENDPOINT} />
                                <NavItem title="Pagination" link={PaginationPage.ENDPOINT} />
                                <NavItem title="Popovers" link={PopoverPage.ENDPOINT} />
                                <NavItem title="Progress" link={ProgressPage.ENDPOINT} />
                                <NavItem title="TablePage" link={TablePage.ENDPOINT} />
                                <NavItem title="Tabs" link={TabPage.ENDPOINT} />
                                <NavItem title="Toasts" link={ToastPage.ENDPOINT} />
                                <NavItem title="Tooltips" link={TooltipPage.ENDPOINT} />
                              </CollapsableNavItem>
                            </>
                        ):(null)
                      }
                      <NavItem title={label.Profile} icon={faUserEdit} link={ProfilePage.ENDPOINT} />
                      <NavItem title={label.SupportTicket} icon={faTicketAlt} link={Endpoint.THIRD_PARTIES.SUPPORT_TICKET_APPLICATION} target={"_blank"} external={true} />
                      {registration.followUp?(
                          <NavItem title={label.Logout} icon={faSignOutAlt} link={COPYRIGHT} external={true} onClick={()=>{
                            logout(props)
                          }}/>
                      ):(null)}
                      {/*<NavItem title={label.Configuration} icon={faCog} link={SettingPage.ENDPOINT} />*/}
                      {/*<NavItem external title={label.Logout} image={logoutIcon}/>*/}
                      {/*<Button as={Link} to={UpgradePage.ENDPOINT} variant="secondary" className="upgrade-to-pro"><FontAwesomeIcon icon={faRocket} className="me-1" /> Upgrade to Pro</Button>*/}
                    </Nav>
                  </div>
                </SimpleBar>
              </CSSTransition>
            </>
        )}
      </AuthConsumer>
  );
};
