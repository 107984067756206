import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Button, Card, Col, Container, Form, Row} from '@themesberg/react-bootstrap';
import {Link} from 'react-router-dom';
import SigninPage from "./SigninPage";
import BasePage from "../base/BasePage";
import label from "na-utilities/src/label/Label";
import Endpoint from "na-utilities/src/api/Endpoint";
import EmailInput from "../../components/input/EmailInput";
import {RegistrationVerification} from "../../model/model";
import {isEmpty, isResponseOk, isValidEmail} from "na-utilities/src/utils/Utilities";
import {toasting} from "../base/BaseComponent";
import {PLATFORM} from "../../utils/Global";

class ForgotPasswordPage extends BasePage {
  constructor(props) {
    super(props);
    let validation = this.defaultValidation()
    let validationMessage = this.defaultValidationMessage()
    this.state = {
      email:"",
      validation, validationMessage
    }
  }
  defaultValidation = () =>{
    let validation = new RegistrationVerification();
    validation.email = true ;
    return validation ;
  }
  defaultValidationMessage = () =>{
    let validationMessage = new RegistrationVerification();
    return validationMessage ;
  }
  componentDidMount() {
    super.componentDidMount();
    this.closePreLoader()
  }

  forgetPassword(){
    let {email,validation,validationMessage} = this.state
    let valid = true ;
    validation = this.defaultValidation()
    validationMessage = this.defaultValidationMessage()
    if (isEmpty(email) || email.length <= 2) {
      validation.email = false
      validationMessage.email = label.PleaseEnterEmail
      valid = false;
    }

    if (!isValidEmail(email)) {
      validation.email = false
      validationMessage.email = label.PleaseEnterCorrectEmail
      valid = false;
    }

    this.setState({email, validation, validationMessage}, () => {
      if(valid){
        this.props.showProcessing()
        let form = new FormData()
        form.append("email",email)
        form.append("platform",PLATFORM)
        this.post(Endpoint.API.USER_FORGET_PASSWORD, form, null, res => {
          this.props.closeProcessing()
          if(isResponseOk(res)){
            toasting.success(res.message)
          }
          window.history.pushState(null, null, '/');
          setTimeout(()=>{
            window.location.href = SigninPage.ENDPOINT
          }, 2000)
        }, false, true);
      }
    })
  }

  render() {
    let {email,validation, validationMessage} = this.state
    return (
        <main>
          <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
            <Container>
              <Row className="justify-content-center">
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div
                      className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                    <h3>{label.ForgetPassword}</h3>
                    <p className="mb-4">{label.WeWillSendYourPasswordOnForgetPasswordMessage}</p>
                    <Form>
                      <div className="mb-4">
                        <EmailInput
                            label={label.EnterEmail}
                            icon={faEnvelope}
                            onChange={(value) => {
                              email = value
                              validation.email = true
                              validationMessage.email = ""
                              this.setState({email, validation, validationMessage})
                            }}
                            value={email}
                            isError={!validation.email}
                            errorMessage={validationMessage.email}
                            placeholder={"somebody@..."}
                        />
                      </div>
                      <br/>
                      <Button
                          variant="primary"
                          onClick={event => {
                            this.forgetPassword()
                          }}
                          className="w-100">
                        {label.Submit}
                      </Button>
                      <br/>
                      <br/>
                      <div className="text-center">
                        <Card.Link as={Link} to={SigninPage.ENDPOINT} className="block text-gray-700">
                          <FontAwesomeIcon icon={faAngleLeft} className="me-2"/> {label.BackToSignIn}
                        </Card.Link>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {super.render()}
        </main>
    );
  }
}
export default Object.assign(ForgotPasswordPage, {ENDPOINT : "/auth/forgot-password"})

