import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faHome, faPlus} from '@fortawesome/free-solid-svg-icons';
import {Breadcrumb, Button, Card, Col, Row, Table} from '@themesberg/react-bootstrap';
import BasePage from "./../base/BasePage";
import label from "na-utilities/src/label/Label"
import DashboardPage from "../dashboard/DashboardPage";
import PaginationTable from "../../components/PaginationTable";
import {getUser} from "../../utils/StorageUtil";
import {Link} from "react-router-dom";
import {Routes} from "../../routes";
import Endpoint from "na-utilities/src/api/Endpoint";
import queryString from 'query-string';
import SearchInput from "../../components/input/SearchInput";
import TableSortOption from "../../components/input/TableSortOption";
import SortOption from "na-utilities/src/model/SortOption";
import {handleTimeAgo} from "na-utilities/src/utils/Utilities";
import UserDetailPage from "./UserDetailPage";
import {navigate} from "na-utilities/src/utils/Utilities";
import StatusAttribute from "na-utilities/src/model/StatusAttribute";
import UserCreationModal from "../../components/UserCreationModal";

class UserPage extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      userCreationModal:false,
      inputSearch: queryString.parse(this.props.query).search ? queryString.parse(this.props.query).search : "",
      user : getUser(),
      page: queryString.parse(props.query).page ? queryString.parse(props.query).page : 1,
      users:[],
      totalPage: 0,
      totalElement: 0,
      pageElement: 0,
      sortOption:{}
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.closePreLoader()
    this.fetchUsers()
  }

  componentWillReceiveProps(props, nextContext) {
    if (props != this.props) {
      let page = queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
      let key = queryString.parse(props.query).tab ? queryString.parse(props.query).tab : label.All
      let inputSearch = queryString.parse(props.query).search ? queryString.parse(props.query).search : ""
      this.setState({
        page: page,
        key: key,
        inputSearch: inputSearch
      }, () => {
        this.fetchUsers()
      })
    }
  }


  fetchUsers = () => {
    let {user,users, search, sortOption} = this.state
    this.get(Endpoint.API.USERS, {
      params :{
        userId : user.id,
        ascending: sortOption.ascending,
        sortir: sortOption.sortir,
        search: search,
        page: this.state.page - 1,
      }
    }, null, response=>{
      if(response.code===200){
        let users = response.data
        let totalPage = response.totalPage
        let totalElement = response.totalElement
        let pageElement = response.pageElement
        this.setState({users:users,totalPage, totalElement, pageElement})
      }else{
        this.props.history.goBack();
      }
    }, false, true)
  }



  render() {
    let {user, users, page, pageElement, totalPage, totalElement, sortOption,userCreationModal} = this.state
    return (
        <>
          <UserCreationModal
              show={userCreationModal}
              onClose={()=>{
                this.setState({userCreationModal:false})
              }}
              onCreateUser={() => {
                this.setState({userCreationModal:false},() => this.fetchUsers())
              }} showProcessing={this.props.showProcessing} closeProcessing={this.props.closeProcessing}/>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                <Breadcrumb.Item href={DashboardPage.ENDPOINT}>{label.Dashboard}</Breadcrumb.Item>
                <Breadcrumb.Item active>{label.UserList}</Breadcrumb.Item>
              </Breadcrumb>
              <h4>{label.UserList}</h4>
            </div>
          </div>

          <div className="table-settings mb-4">
            <Row className="align-items-center">
              <Col xs={8} md={6} lg={3} xl={4}>
                <SearchInput onEnter={(search) => {
                  this.setState({search}, () => {
                    this.fetchUsers()
                  })
                }} onEmpty={() => {
                  this.setState({search:""},() => {
                    this.fetchUsers()
                  })
                }}/>
              </Col>
              <Col xs={4} md={4} xl={2} className="ps-md-0">
                <TableSortOption
                    sortOptions={
                      [
                          SortOption.init(label.Longest,"ja.created",true),
                          SortOption.init(label.Latest,"ja.created",false),
                          SortOption.init(label.TheMost,"ja.itemCount",false),
                          SortOption.init(label.Smallest,"ja.itemCount",true),
                      ]
                    }
                    sortOption={sortOption}
                    placeholder={label.SortBy}
                    onSelect={(sortOption) => {
                      this.setState({sortOption},() => {
                        this.fetchUsers()
                      })
                    }}
                    onReset={() => {
                      this.setState({sortOption:{}},() => {
                        this.fetchUsers()
                      })
                    }}/>
              </Col>
              <Col xs={8} md={6} lg={3} xl={6} className="text-right">
                <Button
                    size="sm"
                    variant="warning"
                    onClick={event => {
                        this.setState({userCreationModal:true})
                    }}>
                  {label.CreateUser}&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                </Button>
              </Col>
            </Row>
          </div>
          <Card border="light" className="table-wrapper table-responsive shadow-sm">
            <Card.Body className="pt-0">
              <Table hover className="table-sm align-items-center">
                <thead>
                <tr>
                  <th className="border-bottom">#</th>
                  <th className="border-bottom">{label.Name}</th>
                  <th className="border-bottom">{label.Email}</th>
                  <th className="border-bottom">{label.Role}</th>
                  <th className="border-bottom">{label.LastAccess}</th>
                  <th className="border-bottom">{label.Status}</th>
                  <th className="border-bottom">{label.Detail}</th>
                </tr>
                </thead>
                <tbody>
                {users.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
                          {((page - 1) * pageElement) + (++index)}
                        </Card.Link>
                      </td>
                      <td>
                        <span className="fw-normal">{item.fullname}</span>
                      </td>
                      <td>
                        <span className="fw-normal">{item.email}</span>
                      </td>
                      <td>
                        <span className="fw-normal">{item.roleInformation}</span>
                      </td>
                      <td>
                        <span className="fw-normal">{item.lastOpenDate?handleTimeAgo(item.lastOpenDate):label.NeverAccessing}</span>
                      </td>
                      <td>
                        <Button variant={"outline-"+StatusAttribute.UserStatus[item.userStatus.toUpperCase()].clazz} size="sm">
                          <img width="18px" src={StatusAttribute.UserStatus[item.userStatus.toUpperCase()].icon}className="me-1" />{item.userStatus}
                        </Button>
                      </td>
                      <td>
                        {
                          user.id!==item.id?(
                              <Button
                                  size="sm"
                                  className="rounded-circle text-center"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    navigate(this.props, UserDetailPage.ENDPOINT+"/"+item.userCode)
                                  }}
                                  type={Link}>
                                <FontAwesomeIcon icon={faEye}/>
                              </Button>
                          ):null
                        }
                      </td>
                    </tr>
                ))}
                </tbody>
              </Table>
            </Card.Body>
            <PaginationTable
                onSelect={(currentPage) => {
                  this.setState({page:currentPage},() => {this.fetchUsers()})
                }}
                showingRow={users.length}
                currentPage={page}
                pageCount={totalPage}
                totalElement={totalElement}/>
          </Card>
          {/*<TransactionsTable/>*/}
        </>
    );
  }
}
export default Object.assign(UserPage, {ENDPOINT : "/users"})

