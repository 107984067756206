import React from "react";
import {faAddressBook, faCalendar, faEnvelope, faPhone, faSave, faUser} from '@fortawesome/free-solid-svg-icons';
import {Button, Card, Col, Form, Row} from '@themesberg/react-bootstrap';
import BaseComponent from "../pages/base/BaseComponent";
import label, {GENDERS} from "na-utilities/src/label/Label"
import TextInput from "./input/TextInput";
import {cleanNonAlphabet, firstLetterUppercase} from "na-utilities/src/utils/Utilities";
import DateInput from "./input/DateInput";
import ItemOption from "./input/ItemOption";
import EmailInput from "./input/EmailInput";
import PhoneNumberInput from "./input/PhoneNumberInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

export default class ProfileForms extends BaseComponent{

  constructor(props) {
    super(props);
    this.state = {
      validation:{},
      validationMessage:{},
      provinces:[],
      province:{},
      cities:[],
      city:{},
      districts:[],
      district:{},
      villages:[],
      village:{},
    }
    this.reState()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props!= prevProps){
      let prevProvince = prevProps.province?prevProps.province:{}
      let prevCity = prevProps.city?prevProps.city:{}
      let prevDistrict = prevProps.district?prevProps.district:{}

      let province = this.props.province?this.props.province:{}
      let city = this.props.city?this.props.city:{}
      let district = this.props.district?this.props.district:{}

      this.reState(()=>{
        if(prevProvince  && province.id){
          if(prevProvince.id != province.id){
            this.fetchCities(province.id, (cities)=>{
              this.setState({cities})
            })
          }
        }else if(!province.id){
          this.setState({cities:[],districts:[],villages:[],city:{},district:{},village:{}})
        }

        if(prevCity &&  city.id){
          if(prevCity.id != city.id){
            this.fetchDistricts(city.id, (districts)=>{
              this.setState({districts})
            })
          }
        }else if(!city.id){
          this.setState({districts:[],villages:[],district:{},village:{}})
        }
        if(prevDistrict && district.id){
          if(prevDistrict.id != district.id){
            this.fetchVillages(district.id, (villages)=>{
              this.setState({villages})
            })
          }
        }else if(!district.id){
          this.setState({villages:[],village:{}})
        }
      })
    }
  }

  componentDidMount() {
    super.componentDidMount();
    let {province, city, district, village} = this.state
    this.fetchProvinces((provinces)=>{
      this.setState({provinces})
    })
  }

  reState(callback){
    let firstname = this.props.firstname?this.props.firstname:""
    let lastname = this.props.lastname?this.props.lastname:""
    let birthdate = this.props.birthdate?this.props.birthdate:""
    let gender = this.props.gender?this.props.gender:""
    let email = this.props.email?this.props.email:""
    let mobilePhone = this.props.mobilePhone?this.props.mobilePhone:""
    let address = this.props.address?this.props.address:""
    let province = this.props.province?this.props.province:{}
    let city = this.props.city?this.props.city:{}
    let district = this.props.district?this.props.district:{}
    let village = this.props.village?this.props.village:{}
    let validation = this.props.validation?this.props.validation:{}
    let validationMessage = this.props.validationMessage?this.props.validationMessage:{}
    this.setState({
      firstname:firstname,
      lastname:lastname,
      birthdate:birthdate,
      gender:gender,
      email:email,
      mobilePhone:mobilePhone,
      address:address,
      province:province,
      city:city,
      district:district,
      village:village,
      validation:validation,
      validationMessage:validationMessage,
    },() => callback())
  }

  onChange(field, value){
    let {onChange} = this.props
    this.setState({field:value},() => {
      onChange(field, value)
    })
  }


  render() {
    let {
      firstname, lastname, gender, birthdate, email, mobilePhone, address,
        provinces, province, cities, city, districts, district, villages, village,
      validation, validationMessage
    } = this.state
    let genderValue = {}
    GENDERS.forEach(value => {
      if(value.key===gender){
        genderValue = value
      }
    })
    return (
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <Row>
              <Col>
                <h5 className="mb-4">{label.GeneralInformation}</h5>
              </Col>
              <Col className="text-right">
                <Button
                    size="sm"
                    variant="warning"
                    onClick={event => {
                      if(this.props.onSave) {
                        this.props.onSave()
                      }
                    }}>
                  {label.UpdateInformation}&nbsp;<FontAwesomeIcon icon={faSave}/>
                </Button>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col md={6} className="mb-3">
                  <TextInput
                      label={label.Firstname}
                      icon={faUser}
                      placeholder={label.EnterFirstname}
                      value={firstname}
                      onChange={(value) => {
                        value = cleanNonAlphabet(value)
                        value = firstLetterUppercase(value)
                        this.onChange("firstname", value)
                      }}
                      isError={!validation.firstname}
                      errorMessage={validationMessage.firstname}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <TextInput
                      label={label.Lastname}
                      icon={faUser}
                      placeholder={label.EnterLastname}
                      value={lastname}
                      onChange={(value) => {
                        value = cleanNonAlphabet(value)
                        value = firstLetterUppercase(value)
                        this.onChange("lastname", value)
                      }}
                      isError={!validation.lastname}
                      errorMessage={validationMessage.lastname}
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={6} className="mb-3">
                  <DateInput
                      label={label.BirthDate}
                      icon={faCalendar}
                      placeholder={label.EnterBirthDate}
                      value={birthdate}
                      onChange={(value) => {
                        this.onChange("birthdate", value)
                      }}
                      isError={!validation.birthdate}
                      errorMessage={validationMessage.birthdate}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <ItemOption
                      objects={GENDERS}
                      label={label.Gender}
                      value={genderValue}
                      callback={(value) => {
                        this.onChange("gender", value.key)
                      }}
                      isError={!validation.gender}
                      errorMessage={validationMessage.gender}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <EmailInput
                      label={label.Email}
                      icon={faEnvelope}
                      placeholder={label.EnterEmail}
                      value={email}
                      onChange={(value) => {
                        this.onChange("email", value)
                      }}
                      isError={!validation.email}
                      errorMessage={validationMessage.email}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <PhoneNumberInput
                      label={label.MobilePhone}
                      icon={faPhone}
                      placeholder={label.EnterPhoneNumber}
                      value={mobilePhone}
                      onChange={async (value) => {
                        this.onChange("mobilePhone", value)
                      }}
                      isError={!validation.mobilePhone}
                      errorMessage={validationMessage.mobilePhone}
                  />
                </Col>
              </Row>
              <h5 className="my-4">{label.Address}</h5>
              <Row>
                <Col sm={12} className="mb-3">
                  <TextInput
                      label={label.Address}
                      icon={faAddressBook}
                      placeholder={label.EnterAddress}
                      value={address}
                      onChange={(value) => {
                        value = firstLetterUppercase(value)
                        this.onChange("address", value)
                      }}
                      isError={!validation.address}
                      errorMessage={validationMessage.address}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="mb-3">
                  <ItemOption
                      objects={provinces}
                      label={label.Province}
                      value={province}
                      callback={(value) => {
                        this.onChange("province", value)
                        this.onChange("city", {})
                        this.onChange("district", {})
                        this.onChange("village", {})
                      }}
                      isError={!validation.province}
                      errorMessage={validationMessage.province}
                  />
                </Col>
                <Col sm={6} className="mb-3">
                  <ItemOption
                      objects={cities}
                      label={label.City}
                      value={city}
                      callback={(value) => {
                        this.onChange("city", value)
                        this.onChange("district", {})
                        this.onChange("village", {})
                      }}
                      isError={!validation.city}
                      errorMessage={validationMessage.city}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="mb-3">
                  <ItemOption
                      objects={districts}
                      label={label.District}
                      value={district}
                      callback={(value) => {
                        this.onChange("district", value)
                        this.onChange("village", {})
                      }}
                      isError={!validation.district}
                      errorMessage={validationMessage.district}
                  />
                </Col>
                <Col sm={6} className="mb-3">
                  <ItemOption
                      objects={villages}
                      label={label.Village}
                      value={village}
                      callback={(value) => {
                        this.onChange("village", value)
                      }}
                      isError={!validation.village}
                      errorMessage={validationMessage.village}
                  />
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
    );
  }
}
ProfileForms.propTypes = {
  user : PropTypes.any.isRequired,
  firstname : PropTypes.any.isRequired,
  lastname : PropTypes.any.isRequired,
  birthdate : PropTypes.any.isRequired,
  gender : PropTypes.any.isRequired,
  email : PropTypes.any.isRequired,
  mobilePhone : PropTypes.any.isRequired,
  address : PropTypes.any.isRequired,
  province : PropTypes.any.isRequired,
  city : PropTypes.any.isRequired,
  district : PropTypes.any.isRequired,
  village : PropTypes.any.isRequired,
  validation : PropTypes.any.isRequired,
  validationMessage : PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

// export const GeneralInfoForm = () => {
//   const [birthday, setBirthday] = useState("");
//
//   return (
//   );
// };
