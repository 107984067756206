import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBan,
  faBoxOpen,
  faCartArrowDown,
  faChartPie,
  faChevronDown,
  faClipboard, faEnvelopeOpen, faHistory, faList,
  faRocket,
  faStore, faTasks, faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import {Button, Col, Dropdown, Row} from '@themesberg/react-bootstrap';
import BasePage from "../base/BasePage";
import ProfileForms from "../../components/ProfileForms";
import {UserDto} from "../../model/model";
import ProfileCard from "../../components/ProfileCard";
import UserSessionCard from "../../components/UserSessionCard";
import User5LastActivityCard from "../../components/User5LastActivityCard";
import Endpoint from "na-utilities/src/api/Endpoint";
import {isEmpty, isResponseOk, isValidEmail, navigate} from "na-utilities/src/utils/Utilities";
import {storeData} from "../../utils/StorageUtil";
import Global from "../../utils/Global";
import label from "na-utilities/src/label/Label";
import {toasting} from "../base/BaseComponent";
import {isValidNumber} from "libphonenumber-js";
import UserCard from "../../components/UserCard";
import {ACTIVE, INACTIVE} from "na-utilities/src/model/StatusAttribute";
import {faOpenid} from "@fortawesome/free-brands-svg-icons";
import UserRolePage from "./UserRolePage";
import UserActivityDetailPage from "../session/UserActivityDetailPage";


class UserDetailPage extends BasePage{

  constructor(props) {
    super(props);
    let {userCode} = props.match.params
    let validation = this.defaultValidation()
    let validationMessage = this.defaultValidationMessage()
    this.state = {
      user : {},
      userCode:userCode,
      validation : validation,
      validationMessage : validationMessage
    }
  }

  defaultValidation = () =>{
    let validation = new UserDto();
    validation.firstname = true ;
    validation.lastname = true ;
    validation.birthdate = true
    validation.mobilePhone = true
    validation.gender = true ;
    validation.email = true
    validation.address = true ;
    validation.province = true ;
    validation.city = true
    validation.district = true ;
    validation.village = true
    return validation ;
  }
  defaultValidationMessage = () =>{
    let validationMessage = new UserDto();
    validationMessage.firstname = "" ;
    validationMessage.lastname = "" ;
    validationMessage.birthdate = ""
    validationMessage.mobilePhone = ""
    validationMessage.gender = "" ;
    validationMessage.email = ""
    validationMessage.address = "" ;
    validationMessage.province = "" ;
    validationMessage.city = ""
    validationMessage.district = "" ;
    validationMessage.village = ""
    return validationMessage ;
  }
  componentDidMount() {
    super.componentDidMount();
    this.closePreLoader()
    this.setState({user:{}},() => {
      this.getUserDetail()
    })
  }

  getUserDetail(){
    let {user,userCode} = this.state
    this.get(Endpoint.API.USER_BY_USER_CODE, {params:{"userCode":userCode}}, null, (res)=>{
      if(isResponseOk(res)){
        let user = res.data
        this.setState({user})
      }
    }, false, false)
  }


  onChangeGeneralForm(field, value){
    console.log("Field = "+field+" : "+JSON.stringify(value))
    let {user, validation, validationMessage} = this.state
    if(field==='firstname'){
      validation.firstname = true
      validationMessage.firstname = ""
    }
    if(field==='email'){
      validation.email = true
      validationMessage.email = ""
    }
    if(field==='mobilePhone'){
      validation.mobilePhone = true
      validationMessage.mobilePhone = ""
    }
    if(field==='province'){
      validation.province = true
      validationMessage.province = ""
    }
    if(field==='city'){
      validation.city = true
      validationMessage.city = ""
    }
    if(field==='district'){
      validation.district = true
      validationMessage.district = ""
    }
    if(field==='village'){
      validation.village = true
      validationMessage.village = ""
    }
    user[field] = value
    this.setState({user, validation, validationMessage})
  }

  async validateFormSubmit() {
    // await super.validateFormSubmit();
    let {user, validation, validationMessage} = this.state
    let valid = true ;
    validation = this.defaultValidation()
    validationMessage = this.defaultValidationMessage()
    if(isEmpty(user.mobilePhone) || user.mobilePhone.length <=2){
      validation.mobilePhone = false
      validationMessage.mobilePhone = label.PleaseEnterCorrectPhoneNumber
      valid = false ;
    }
    if(!isValidNumber(user.mobilePhone, 'ID')){
      validation.mobilePhone = false
      validationMessage.mobilePhone = label.PleaseEnterCorrectPhoneNumber
      valid = false ;
    }
    if(isEmpty(user.firstname) || user.firstname.length <=2){
      validation.firstname = false
      validationMessage.firstname = label.PleaseEnterFirstName
      valid = false ;
    }
    if(isEmpty(user.email) || user.email.length <=2){
      validation.email = false
      validationMessage.email = label.PleaseEnterEmail
      valid = false ;
    }
    if(!isValidEmail(user.email)){
      validation.email = false
      validationMessage.email = label.PleaseEnterCorrectEmail
      valid = false ;
    }

    if(!isEmpty(user.address) || !isEmpty(user.province) || !isEmpty(user.city) || !isEmpty(user.district) || !isEmpty(user.village)){
      if(isEmpty(user.address) || user.address.length <=2){
        validation.address = false
        validationMessage.address = label.PleaseEnterAddress
        valid = false ;
      }
      if(isEmpty(user.province)){
        validation.province = false
        validationMessage.province = label.PleaseSelectProvince
        valid = false ;
      }
      if(isEmpty(user.city)){
        validation.city = false
        validationMessage.city = label.PleaseSelectCity
        valid = false ;
      }
      if(isEmpty(user.district)){
        validation.district = false
        validationMessage.district = label.PleaseSelectDistrict
        valid = false ;
      }
      if(isEmpty(user.village)){
        validation.village = false
        validationMessage.village = label.PleaseSelectVillage
        valid = false ;
      }
    }


    // if(isEmpty(registrationVerification.address) || registrationVerification.address.length <=2){
    //     validation.address = false
    //     validationMessage.address = label.PleaseEnterAddressCorrectly
    //     valid = false ;
    // }
    // if(isEmpty(registrationVerification.province)){
    //     validation.province = false
    //     validationMessage.province = label.PleaseSelectProvince
    //     valid = false ;
    // }
    // if(isEmpty(registrationVerification.city)){
    //     validation.city = false
    //     validationMessage.city = label.PleaseSelectCity
    //     valid = false ;
    // }
    // if(isEmpty(registrationVerification.district)){
    //     validation.district = false
    //     validationMessage.district = label.PleaseSelectDistrict
    //     valid = false ;
    // }
    // if(isEmpty(registrationVerification.village)){
    //     validation.village = false
    //     validationMessage.village = label.PleaseSelectVillage
    //     valid = false ;
    // }
    this.setState({user, validation, validationMessage}, async () => {
      if(valid){
        await this.save()
      }
    })
  }

  async save(){
    let {user} = this.state
    let formData = new FormData()
    formData.append("userDtoGson",JSON.stringify(user))
    this.post(Endpoint.API.USER_SAVE, formData, null, (res)=>{
      if(isResponseOk(res)){
        toasting.success(label.SuccessUpdatingData)
        this.setState({user})
      }else{
        toasting.error(res.message)
      }
    }, true, false)
  }

  validate(){

  }


  render(){
    let {user,validation,validationMessage} = this.state
    return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <Dropdown>
              <Dropdown.Toggle size="sm" as={Button} variant="info">
                <FontAwesomeIcon icon={faList} className="me-2" /> {label.Action}
                <span className="icon icon-small ms-1"><FontAwesomeIcon icon={faChevronDown} /></span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-1">
                <Dropdown.Item onClick={event => {
                  navigate(this.props, UserRolePage.ENDPOINT+"/"+user.userCode)
                }}>
                  <FontAwesomeIcon icon={faTasks} className="me-2" /> {label.Role}
                </Dropdown.Item>
                <Dropdown.Item onClick={event => {
                  navigate(this.props, UserActivityDetailPage.ENDPOINT+"/"+user.userCode)
                }}>
                  <FontAwesomeIcon icon={faHistory} className="me-2" /> {label.UserActivity}
                </Dropdown.Item>
                <Dropdown.Divider />
                {
                  user.userStatus===ACTIVE?(
                      <Dropdown.Item className="text-danger">
                        <FontAwesomeIcon icon={faBan} className="me-2" /> {label.Inactivate}
                      </Dropdown.Item>
                  ):(null)
                }
                {
                  user.userStatus===INACTIVE?(
                      <Dropdown.Item className="text-warning">
                        <FontAwesomeIcon icon={faOpenid} className="me-2" /> {label.Activate}
                      </Dropdown.Item>
                  ):(null)
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <Row>
            <Col xs={12} xl={8}>
              <ProfileForms
                  user={user}
                  firstname={user.firstname}
                  lastname={user.lastname}
                  birthdate={user.birthdate}
                  gender={user.gender}
                  email={user.email}
                  mobilePhone={user.mobilePhone}
                  address={user.address}
                  province={user.province}
                  city={user.city}
                  district={user.district}
                  village={user.village}
                  validation={validation}
                  validationMessage={validationMessage}
                  onChange={(field,value) => {
                    this.onChangeGeneralForm(field,value)
                  }}
                  onSave={async () => await this.validateFormSubmit()}
            />
            </Col>

            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12}>
                  <UserCard user={user} onRefresh={(user)=>{
                    this.setState({user})
                  }}/>
                </Col>
                <Col xs={12}>
                  <UserSessionCard user={user} onRefresh={() => {}}/>
                </Col>
                <Col xs={12}>
                  <User5LastActivityCard user={user} onRefresh={() => {}}/>
                </Col>
              </Row>
            </Col>
          </Row>
          {super.render()}
        </>
    );
  };
}
export default Object.assign(UserDetailPage, {ENDPOINT : "/userDetail"})
