import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Button, Col, Form, Modal, Row} from '@themesberg/react-bootstrap';
import PropTypes from 'prop-types';
import label from 'na-utilities/src/label/Label'
import BaseComponent from "../pages/base/BaseComponent";
import {faCrop, faImage, faInfo, faSave, faStickyNote} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {imageSelector, isEmpty} from "na-utilities/src/utils/Utilities";
import TextInput from "./input/TextInput";
import {PaymentProofDto} from "../model/model";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";

export default class PaymentProofUploader extends BaseComponent {

  constructor(props) {
    super(props);
    let validation = this.defaultValidation()
    let validationMessage = this.defaultValidationMessage()
    this.state = {
      crop : {
        unit: "%",
        width: 30,
        aspect: 1 / 1
      },
      modalShow : props.show,
      paymentProof:{},
      validation,
      validationMessage,
    }
  }
  defaultValidation = () =>{
    let validation = new PaymentProofDto();
    validation.name = true ;
    validation.description = true ;
    validation.imageLink = true ;
    return validation ;
  }
  defaultValidationMessage = () =>{
    let validationMessage = new PaymentProofDto();
    validationMessage.name = "" ;
    validationMessage.description = "" ;
    validationMessage.imageLink = "" ;
    return validationMessage ;
  }

  componentWillReceiveProps(props, nextContext) {
    if(props!=this.props){
      this.setState({
        modalShow:props.show,
        crop:{
            unit: "%",
            width: 30,
            aspect: 1 / 1
        },
        paymentProof:{},
      })
    }
  }
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, "newFile.png");
      this.setState({ croppedImageUrl });
    }
  }
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // const scaleX = image.width / image.width;
    // const scaleY = image.height / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve, reject) => {
      var dataURL = canvas.toDataURL('image/png', 1.0);
      resolve(dataURL)
    });
  }

  openCropperModal=()=>{
    this.setState({
      modalShow:true
    })
  }

  closeCropperModal=()=>{
    this.setState({
      modalShow:false
    })
  }

  async validateFormSubmit(){
    let {paymentProof, validation, validationMessage} = this.state
    let valid = true ;
    validation = this.defaultValidation()
    validationMessage = this.defaultValidationMessage()
    if(isEmpty(paymentProof.name)){
      validation.name = false
      validationMessage.name = label.EnterName
      valid = false ;
    }
    if(isEmpty(paymentProof.imageLink)){
      validation.imageLink = false
      validationMessage.imageLink = label.SelectImageToUpload
      valid = false ;
    }
    this.setState({validation, validationMessage}, () => {
      if(valid){
        this.callSubmit()
      }
    })
  }

  callSubmit(){
    let {paymentProof, validation, validationMessage} = this.state
    this.props.callback(paymentProof)
  }

  render() {
    let { validation, validationMessage, crop, croppedImageUrl, modalShow, paymentProof} = this.state;
    let { callback,cancelCallback } = this.props;
    if(!cancelCallback){
      cancelCallback = (function () {

      })
    }

    return (
        <Modal
            show={modalShow}
            centered={true}
            backdrop={false}>
          {super.render()}
          <Modal.Header className="block" style={{display:'block'}}>
            <Row className="block">
              <Col>
                {label.UploadPaymentProof}
              </Col>
              <Col className="text-right">
                <Button
                    size="sm"
                    variant="info"
                    onClick={e=>{
                      e.preventDefault()
                      paymentProof.imageLink = croppedImageUrl
                      validationMessage.imageLink = ""
                      validation.imageLink = true
                      this.setState({validation, validationMessage, paymentProof})
                    }}>
                  {label.Apply}
                  &nbsp;
                  <FontAwesomeIcon icon={faCrop} />
                </Button>
                &nbsp;
                <Button
                    size="sm"
                    variant="success"
                    onClick={e=>{
                      e.preventDefault()
                      imageSelector((file)=>{
                        paymentProof.imageLink = file
                        validationMessage.imageLink = ""
                        validation.imageLink = true
                        this.setState({validation, validationMessage, paymentProof})
                      }).click()
                    }}>
                  {label.SelectImage}
                  &nbsp;
                  <FontAwesomeIcon icon={faImage} />
                </Button>
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body className="text-center align-items-center p-0" style={{height:'300px'}}>
            {paymentProof.imageLink ? (
                <ReactCrop
                    style={{height:'100%'}}
                    src={paymentProof.imageLink}
                    maxHeight={300}
                    imageStyle={{height:'300px'}}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                />
            ):(<img style={{height:'100%'}} src={ApplicationIcons.Icons.imageUpload}/>)}
          </Modal.Body>
          {
            (!validation.imageLink)?(
                <Modal.Body className="text-center align-items-center p-0 bg-danger">
                  <Form.Control.Feedback type="invalid" style={{display:'unset', color:'white'}}>{validationMessage.imageLink}</Form.Control.Feedback>
                </Modal.Body>
            ):(null)
          }
          <hr className="m-0"/>
          <Modal.Body className="p-4 m-0">
            <Row>
              <Col>
                <TextInput
                    label={label.Name}
                    icon={faInfo}
                    value={paymentProof.name}
                    placeholder={label.EnterName}
                    onChange={(name) => {
                      validation.name = true
                      validationMessage.name = ""
                      paymentProof.name = name
                      this.setState({validation, validationMessage, paymentProof})
                    }}
                    isError={!validation.name}
                    errorMessage={validationMessage.name}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                    label={label.Note}
                    icon={faStickyNote}
                    value={paymentProof.description}
                    placeholder={label.EnterNoteIfYouNeedTo}
                    onChange={(description) => {
                      paymentProof.description = description
                      validationMessage.description = ""
                      validation.description = true
                      this.setState({validation, validationMessage, paymentProof})
                    }}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="m-0 p-2" style={{display:'block'}}>
            <Row>
              <Col>
                <Button
                    size="sm"
                    variant="warning"
                    onClick={e=>{
                      e.preventDefault()
                      cancelCallback()
                    }}>
                  {label.Cancel}
                </Button>
              </Col>
              <Col className="text-right">
                <Button
                    size="sm"
                    variant="success"
                    onClick={async (e)=>{
                      e.preventDefault()
                      await this.validateFormSubmit()
                    }}>
                  {label.Save}
                  &nbsp;
                  <FontAwesomeIcon icon={faSave} />
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
    );
  }
}

PaymentProofUploader.propTypes = {
     show:PropTypes.bool,
     callback:PropTypes.func.isRequired,
     cancelCallback:PropTypes.func.isRequired,
}
