import Global from './Global';

// import { lang } from "moment";

export function getUser(){
    if(localStorage.getItem(Global.USER)!=null){
        var json = localStorage.getItem(Global.USER);
        if(json!=null){
            try{
                return JSON.parse(json);
            }catch (e) {
            }
        }
    }
    if(sessionStorage.getItem(Global.USER)!=null){
        var json = sessionStorage.getItem(Global.USER);
        if(json!=null){
            try{
                return JSON.parse(sessionStorage.getItem(Global.USER));
            }catch (e) {
            }
        }
    }
    return null ;
}

export function saveUserSession(admin, localStorage){
    if(localStorage){
        saveUserLocal(admin)
    }
    sessionStorage.setItem(Global.USER, JSON.stringify(admin))
}

export function saveUserLocal(admin){
    localStorage.setItem(Global.USER, JSON.stringify(admin))
}

export function removeStorage(){
    localStorage.clear();
    sessionStorage.clear();
}

export function setLanguage(language){
    localStorage.setItem(Global.LANGUAGE, language) //set Language Catcha
}

export function getLanguage(){
    if (localStorage.getItem(Global.LANGUAGE)) {
        return localStorage.getItem(Global.LANGUAGE)
    }
    return null
}

export function removeData(key){
    localStorage.removeItem(key)
    sessionStorage.removeItem(key)
}

export function storeData(key, value){
    if(localStorage.getItem(key)){
        localStorage.removeItem(key)
        sessionStorage.removeItem(key)
    }
    localStorage.setItem(key, JSON.stringify(value))
    sessionStorage.setItem(key, JSON.stringify(value))
}

export function getData(key){
    let value = localStorage.getItem(key)
    if(value!=null){
        return JSON.parse(value)
    }else{
        value = sessionStorage.getItem(key)
        if(value!=null){
            return JSON.parse(value)
        }
    }
    return null
}
