import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Modal, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {isResponseOk} from "na-utilities/src/utils/Utilities";
import PropTypes from 'prop-types';
import {parseDate} from "na-utilities/src/utils/Utilities";
import {DD_MMMM_YYYY_HH_MM_SS} from "../utils/Global";

export default class ProductOrderStatusHistoryModal extends BaseComponent{

    constructor(props) {
        super(props);
        let {productOrder, show} = this.props
        this.state = {
            productOrderStatusHistories:[],
            productOrderStatusHistory:{},
            productOrder:productOrder?productOrder:{},
            show:show
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let {productOrder,show} = this.props
            if(productOrder && productOrder.id){
                this.setState({productOrder,show},() => {
                    this.fetchProductOrderStatusHistories()
                })
            }
        }

    }

    fetchProductOrderStatusHistories(){
        let {productOrderStatusHistories, productOrder} = this.state
        this.get(Endpoint.API.PRODUCT_ORDER_STATUS_HISTORIES,
            {params:{"productOrderId":productOrder.id,"ascending":false,"sortir":"created"}},
            null, (res)=>{
            if(isResponseOk(res)){
                productOrderStatusHistories = res.data
                this.setState({productOrderStatusHistories})
            }
        }, false, false)
    }




    render() {
        let {productOrderStatusHistories,show} = this.state
        return (
            <>
                {super.render()}
                <Modal as={Modal.Dialog} size="xl" centered show={show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.ProductOrderStatusHistory}</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={()=>{
                            this.props.onClose()
                        }} />
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Table responsive className="align-items-center table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{label.Status}</th>
                                <th scope="col">{label.Note}</th>
                                <th scope="col">{label.Created}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {productOrderStatusHistories.map((item, index) => {
                                return (
                                    <tr>
                                        <th scope="row">{index+1}</th>
                                        <th scope="row" >
                                            <span className="rounded text-white font-small p-2" style={{backgroundColor:item.productOrderStatus.color}}>{item.productOrderStatus.label}</span>
                                        </th>
                                        <td>{item.description}</td>
                                        <td>
                                            {parseDate(item.created, DD_MMMM_YYYY_HH_MM_SS)}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

    openCropper(imageToCrop, cropperCallback, cancelCallback){
        this.setState({
            imageToCrop:imageToCrop,
            imageCropperModal:true,
            cropperCallback:cropperCallback,
            cancelCallback:cancelCallback
        })
    }

}
ProductOrderStatusHistoryModal.propTypes = {
    productOrder:PropTypes.object.isRequired,
    show:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired

}
