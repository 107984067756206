import React, {useEffect, useState} from "react";
import {Route, useHistory} from "react-router-dom";
import Preloader from "../components/Preloader";
import Sidebar from "../components/Sidebar";
import MyNavbar from "../components/MyNavbar";
// import Footer from "../components/Footer";
import {AuthConsumer} from "../context/AuthContext";
import DashboardOverview from "../pages/dashboard/DashboardOverview";
import SigninPage from "../pages/examples/SigninPage";
import PreProcess from "../components/PreProcess";
import MySidebar from "../components/MySidebar";
import FollowUpWarning from "../components/FollowUpWarning";

const AuthorizedRouteWithSidebar = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);
    const [processing, setProcessing] = useState(false);

    const openPreLoader = (function openPreLoader(){
        setLoaded(false)
    })
    const closePreLoader = (function closePreLoader(){
        setTimeout(()=>setLoaded(true), 1000)
    })

    const showProcessing = (function openProcessing(){
        setProcessing(true)
    })
    const closeProcessing = (function closeProcessing(){
        setTimeout(()=>setProcessing(false), 1000)
    })


 let history = useHistory()

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
      <AuthConsumer>
          {({ isAuth, user, logout }) => {
              if (!isAuth) {
                  window.location.href = SigninPage.ENDPOINT
                  return (
                      <Route {...rest} render={props => (
                          <>
                              <Preloader show={loaded ? false : true} />
                          </>
                          )}
                      />
                  )
              }
              let registration = user.registration?user.registration:{}
              console.log("##### REGISTRATION "+JSON.stringify(registration))
              return(
                  <Route
                      {...rest}
                      render={props => (
                      <>
                          <Preloader show={loaded ? false : true} />
                          <MySidebar {...props}/>

                          <main className="content">
                              <PreProcess show={processing}/>
                              <MyNavbar {...props} user={user} />
                              <Component
                                  {...props}
                                  openPreLoader={openPreLoader}
                                  closePreLoader={closePreLoader}
                                  showProcessing={showProcessing}
                                  closeProcessing={closeProcessing}
                              />
                              {registration.followUp?(
                                    <>
                                        <FollowUpWarning show={true} />
                                    </>):(null)}
                              {/*<Footer toggleSettings={toggleSettings} showSettings={showSettings} />*/}
                          </main>
                      </>
                  )}
                  />

              )
          }}
      </AuthConsumer>

  );
};
export default AuthorizedRouteWithSidebar