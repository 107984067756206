import {Form, InputGroup} from "@themesberg/react-bootstrap";
import label from 'na-utilities/src/label/Label'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import PropTypes from 'prop-types';
import Datetime from "react-datetime";
import moment from "moment-timezone";

export default class DateInput extends React.Component{
    state = {}
    constructor(props) {
        super(props);
        this.state = {
            open:false
        }
    }


    render() {
        let {open} = this.state
        let {id, placeholder, label, value, readOnly, icon, isError, errorMessage} = this.props
        if(!id){
            id = "text"
        }
        if(!label){
            label = ""
        }
        if(!placeholder){
            placeholder = label
        }
        return (
            <Form.Group id={id} className="mb-4">
                <Form.Label>{label}</Form.Label>
                <Datetime
                    timeFormat={false}
                    onChange={value => {
                        if(this.props.onChange){
                            this.props.onChange(value)
                        }
                        this.setState({open: false})
                    }}
                    open={open}
                    renderInput={(props, openCalendar) => (
                        <InputGroup>
                            {icon&&(
                                <InputGroup.Text style={{borderColor:(isError?'#FA5252':'')}}>
                                    <FontAwesomeIcon icon={icon} />
                                </InputGroup.Text>
                            )}
                            <Form.Control
                                required={this.props.required}
                                type="text"
                                placeholder={placeholder}
                                isInvalid={isError}
                                value={value ? moment(value).format("MM/DD/YYYY") : ""}
                                readOnly={readOnly}
                                onFocus={(e)=>{
                                    openCalendar()
                                    this.setState({open: true})
                                }}
                                onClick={this.props.onClick}
                                style={this.props.style}
                            />
                        </InputGroup>
                    )}/>
                {isError&&(
                    <Form.Control.Feedback type="invalid" style={{display:'unset', position:'absolute'}}>{errorMessage?errorMessage:placeholder}</Form.Control.Feedback>
                )}
            </Form.Group>
        );
    }
}
DateInput.propTypes = {
    label:PropTypes.string.isRequired,
    value:PropTypes.string,
    placeholder:PropTypes.string,
    icon:PropTypes.any,
    required:PropTypes.bool,
    readOnly:PropTypes.bool,
    onChange:PropTypes.func,
    isError:PropTypes.bool,
    errorMessage:PropTypes.string
}