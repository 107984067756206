import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Card, Col, Row, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {isResponseOk, navigate, parseDate} from "na-utilities/src/utils/Utilities";
import {currencyFormat} from "na-utilities/src/utils/Utilities";
import {DD_MMMM_YYYY_HH_MM_SS} from "../utils/Global";
import OrderDetailPage from "../pages/order/OrderDetailPage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const pageVisits = [
    { id: 1, views: 4.525, returnValue: 255, bounceRate: 42.55, pageName: "/demo/admin/index.html" },
    { id: 2, views: 2.987, returnValue: 139, bounceRate: -43.52, pageName: "/demo/admin/forms.html" },
    { id: 3, views: 2.844, returnValue: 124, bounceRate: -32.35, pageName: "/demo/admin/util.html" },
    { id: 4, views: 1.220, returnValue: 55, bounceRate: 15.78, pageName: "/demo/admin/validation.html" },
    { id: 5, views: 505, returnValue: 3, bounceRate: -75.12, pageName: "/demo/admin/modals.html" }
];

class DashboardProductOrderTable extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            productOrders:[],
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchProductOrders()
    }

    fetchProductOrders(){
        let {productOrders} = this.state
        this.get(Endpoint.API.USER_PRODUCT_ORDERS_ON_GOING, null, null, (res)=>{
            if(isResponseOk(res)){
                productOrders = res.data
                this.setState({productOrders})
            }
        }, false, false)
    }


    render() {
        let {productOrders} = this.state
        let render = productOrders.length>0
        if(!render){
            return (<></>)
        }
        return (
            <Row className="justify-content-md-center">
                <Col xs={12} sm={12} xl={12} className="mb-4">
                    <div>
                        <Card border="light" className="shadow-sm">
                            <Card.Header className="bg-gradient-danger">
                                <Row className="align-items-center">
                                    <Col>
                                        <h5 className="text-white">{label.CurrentProductOrderList}</h5>
                                    </Col>
                                    {
                                        productOrders.length==1?(
                                            <Col className="text-end">
                                                <Button
                                                    variant="info"
                                                    size="sm"
                                                    onClick={event => {
                                                        navigate(this.props, OrderDetailPage.ENDPOINT+"/"+productOrders[0].reference)
                                                    }}>
                                                    {label.SeeOrderDetail}
                                                </Button>
                                            </Col>
                                        ):(null)
                                    }
                                </Row>
                            </Card.Header>
                            <Table responsive className="align-items-center table-flush">
                                <thead className="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{label.Reference}</th>
                                    <th scope="col">{label.Item}</th>
                                    <th scope="col">{label.Status}</th>
                                    <th scope="col">{label.Created}</th>
                                    <th scope="col">{label.TotalAmount}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {productOrders.map((item, index) => {
                                    return (
                                        <tr>
                                            <th scope="row">{index+1}</th>
                                            <th scope="row">
                                                <Link style={{textDecoration:'underline'}} to={OrderDetailPage.ENDPOINT+"/"+productOrders[index].reference}>{item.reference}</Link>
                                            </th>
                                            <td>{item.itemCount}</td>
                                            <td><span className="p-1 rounded font-weight-bold text-white" style={{backgroundColor:item.productOrderStatus.color}}>{item.productOrderStatus.label}</span></td>
                                            <td>{parseDate(item.created, DD_MMMM_YYYY_HH_MM_SS)}</td>
                                            <td>
                                                {currencyFormat(item.totalProductOrderAmount, 'Rp')}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </Card>
                    </div>
                </Col>
            </Row>
        );
    }

}

export default DashboardProductOrderTable