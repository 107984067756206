import {Form, InputGroup} from "@themesberg/react-bootstrap";
import label from 'na-utilities/src/label/Label'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import PropTypes from 'prop-types';

export default class EmailInput extends React.Component{

    render() {
        let {isError,errorMessage,placeholder,icon} = this.props

        return (
            <Form.Group id="email" className="mb-4">
                <Form.Label>{this.props.label?this.props.label:label.Email}</Form.Label>
                <InputGroup>
                    {icon&&(
                        <InputGroup.Text style={{borderColor:(isError?'#FA5252':'')}}>
                            <FontAwesomeIcon icon={icon} />
                        </InputGroup.Text>
                    )}
                    <Form.Control
                        autoFocus
                        required={this.props.required}
                        type="email"
                        placeholder={placeholder}
                        onChange={(e)=>{
                            if(this.props.onChange){
                                this.props.onChange(e.target.value)
                            }
                        }}
                        isInvalid={isError}
                        value={this.props.value} readOnly={this.props.readOnly}/>
                </InputGroup>
                {isError&&(
                    <Form.Control.Feedback type="invalid" style={{display:'unset', position:'absolute'}}>{errorMessage?errorMessage:placeholder}</Form.Control.Feedback>
                )}
            </Form.Group>
        );
    }
}
EmailInput.propTypes = {
    label:PropTypes.string,
    value:PropTypes.string,
    placeholder:PropTypes.string,
    required:PropTypes.bool,
    readOnly:PropTypes.bool,
    onChange:PropTypes.func,
    isError:PropTypes.bool,
    errorMessage:PropTypes.string
}