import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCartPlus, faClock, faEye, faHome, faUndo} from '@fortawesome/free-solid-svg-icons';
import {Breadcrumb, Button, Card, Col, Row, Table, Form} from '@themesberg/react-bootstrap';
import BasePage from "./../base/BasePage";
import label from "na-utilities/src/label/Label"
import DashboardPage from "../dashboard/DashboardPage";
import PaginationTable from "../../components/PaginationTable";
import {getUser} from "../../utils/StorageUtil";
import {Link} from "react-router-dom";
import {Routes} from "../../routes";
import Endpoint from "na-utilities/src/api/Endpoint";
import queryString from 'query-string';
import {currencyFormat, handleTimeAgo, handleTimeFuture, navigate, parseDate} from "na-utilities/src/utils/Utilities";
import {DD_MM_YYYY, DD_MMMM_YYYY, DD_MMMM_YYYY_HH_MM_SS} from "../../utils/Global";
import SearchInput from "../../components/input/SearchInput";
import TableSortOption from "../../components/input/TableSortOption";
import SortOption from "na-utilities/src/model/SortOption";
import ProductSubscribeDetailPage from "./ProductSubscribeDetailPage";
import ExtendProductOrderInquiryModal from "../../components/ExtendProductOrderInquiryModal";
import NewProductOrderInquiryModal from "../../components/NewProductOrderInquiryModal";
import OrderDetailPage from "../order/OrderDetailPage";

class ProductSubscribePage extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      inputSearch: queryString.parse(this.props.query).search ? queryString.parse(this.props.query).search : "",
      user : getUser(),
      page: queryString.parse(props.query).page ? queryString.parse(props.query).page : 1,
      productSubscribes:[],
      totalPage: 0,
      totalElement: 0,
      pageElement: 0,
      sortOption:{},
      extendProductOrderInquiryModal:false,
      newProductOrderInquiryModal:false,
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.closePreLoader()
    this.fetchProductSubscribes()
  }

  componentWillReceiveProps(props, nextContext) {
    if (props != this.props) {
      let page = queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
      let key = queryString.parse(props.query).tab ? queryString.parse(props.query).tab : label.All
      let inputSearch = queryString.parse(props.query).search ? queryString.parse(props.query).search : ""
      this.setState({
        page: page,
        key: key,
        inputSearch: inputSearch
      }, () => {
        this.fetchProductSubscribes()
      })
    }
  }


  fetchProductSubscribes = () => {
    let {user,productSubscribes, search, sortOption} = this.state
    this.get(Endpoint.API.PRODUCT_SUBSCRIBES, {
      params :{
        userId : user.id,
        ascending: sortOption.ascending,
        sortir: sortOption.sortir,
        search: search,
        page: this.state.page - 1,
      }
    }, null, response=>{
      if(response.code===200){
        let productSubscribes = response.data
        let totalPage = response.totalPage
        let totalElement = response.totalElement
        let pageElement = response.pageElement
        this.setState({productSubscribes:productSubscribes,totalPage, totalElement, pageElement})
      }else{
        this.props.history.goBack();
      }
    }, false, true)
  }



  render() {
    let {user, extendProductOrderInquiryModal, newProductOrderInquiryModal, productSubscribes, page, pageElement, totalPage, totalElement, sortOption} = this.state
    let company = user.company?user.company:{}
    return (
        <>
          <ExtendProductOrderInquiryModal
              show={extendProductOrderInquiryModal}
              company={user.company}
              productSubscribes={productSubscribes}
              showProcessing={this.props.showProcessing}
              closeProcessing={this.props.closeProcessing}
              onClose={() => {
                this.setState({extendProductOrderInquiryModal:false})
              }}
              onCreateOrder={(productOrder) => {
                this.setState({extendProductOrderInquiryModal:false}, () => {
                  navigate(this.props, OrderDetailPage.ENDPOINT+"/"+productOrder.reference)
                })
              }}/>
          <NewProductOrderInquiryModal
              show={newProductOrderInquiryModal}
              showProcessing={this.props.showProcessing}
              closeProcessing={this.props.closeProcessing}
              company={company}
              onClose={()=>{
                this.setState({newProductOrderInquiryModal:false})
              }}
              onCreateOrder={(productOrder) => {
                this.setState({newProductOrderInquiryModal:false}, () => {
                  navigate(this.props, OrderDetailPage.ENDPOINT+"/"+productOrder.reference)
                })
              }}/>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                <Breadcrumb.Item href={DashboardPage.ENDPOINT}>{label.Dashboard}</Breadcrumb.Item>
                <Breadcrumb.Item active>{label.ProductSubscribe}</Breadcrumb.Item>
              </Breadcrumb>
              <h4>{label.ProductSubscribe}</h4>
            </div>
          </div>

          <div className="table-settings mb-4">
            <Row className="align-items-center">
              <Col md={4}>
                <SearchInput onEnter={(search) => {
                  this.setState({search}, () => {
                    this.fetchProductSubscribes()
                  })
                }} onEmpty={() => {
                  this.setState({search:""},() => {
                    this.fetchProductSubscribes()
                  })
                }}/>
              </Col>
              <Col md={4} className="ps-md-0">
                <TableSortOption
                    sortOptions={
                      [
                          SortOption.init(label.Longest,"ja.created",true),
                          SortOption.init(label.Latest,"ja.created",false),
                          SortOption.init(label.TheMost,"ja.itemCount",false),
                          SortOption.init(label.Smallest,"ja.itemCount",true),
                      ]
                    }
                    sortOption={sortOption}
                    placeholder={label.SortBy}
                    onSelect={(sortOption) => {
                      this.setState({sortOption},() => {
                        this.fetchProductSubscribes()
                      })
                    }}
                    onReset={() => {
                      this.setState({sortOption:{}},() => {
                        this.fetchProductSubscribes()
                      })
                    }}/>
              </Col>
              <Col md={4} className="text-right">
                <Form.Group>
                  <Button
                      variant="success"
                      size="sm"
                      onClick={event => {
                        this.setState({newProductOrderInquiryModal:true})
                      }}>
                    {label.CreateNewOrder}&nbsp;
                    <FontAwesomeIcon size="sm" icon={faCartPlus}/>
                  </Button>
                  &nbsp;
                  <Button
                      variant="info"
                      size="sm"
                      onClick={event => {
                        this.setState({extendProductOrderInquiryModal:true})
                      }}>
                    {label.ExtendPeriod}&nbsp;
                    <FontAwesomeIcon size="sm" icon={faClock}/>
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <Card border="light" className="table-wrapper table-responsive shadow-sm">
            <Card.Body className="p-1">
              <Table hover className="table-sm user-table align-items-center">
                <thead>
                <tr>
                  <th className="border-bottom">#</th>
                  <th className="border-bottom">{label.Reference}</th>
                  <th className="border-bottom">{label.ProductPackage}</th>
                  <th className="border-bottom">{label.Status}</th>
                  <th className="border-bottom">{label.StartDate}</th>
                  <th className="border-bottom">{label.EndDate}</th>
                  <th className="border-bottom">{label.Created}</th>
                  <th className="border-bottom">{label.ActivePeriod}</th>
                  <th className="border-bottom">{label.Action}</th>
                </tr>
                </thead>
                <tbody>
                {productSubscribes.map((productSubscribe, index) => (
                    <tr>
                      <td>
                        <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
                          {((page - 1) * pageElement) + (++index)}
                        </Card.Link>
                      </td>
                      <td>
                        <span className="fw-normal">{productSubscribe.code}</span>
                      </td>
                      <td>
                        <span className="fw-normal">{productSubscribe.productPackage.name}</span>
                      </td>
                      <td>
                        <Button
                            size="sm"
                            disabled={true}
                            style={{backgroundColor:productSubscribe.productSubscribeStatus.color}}>
                          {productSubscribe.productSubscribeStatus.label}
                        </Button>
                      </td>
                      <td>
                        <span className="fw-normal">{parseDate(productSubscribe.startDate)}</span>
                      </td>
                      <td>
                        <span className="fw-normal">{parseDate(productSubscribe.endDate)}</span>
                      </td>
                      <td>
                        <span className="fw-normal">{parseDate(productSubscribe.created, DD_MMMM_YYYY_HH_MM_SS)}</span>
                      </td>
                      <td>
                        <span className="fw-normal" style={{color:(productSubscribe.almostExpired?"red":"green")}}>{handleTimeFuture(productSubscribe.endDate)}</span>
                      </td>
                      <td>
                        <Button
                            size="sm"
                            className="rounded-circle text-center"
                            onClick={(e) => {
                              e.preventDefault()
                              navigate(this.props, ProductSubscribeDetailPage.ENDPOINT+"/"+productSubscribe.id)
                            }}
                            type={Link}>
                          <FontAwesomeIcon icon={faEye}/>
                        </Button>
                      </td>
                    </tr>
                ))}
                </tbody>
              </Table>
            </Card.Body>
            <PaginationTable
                onSelect={() => {

                }}
                currentPage={page}
                pageCount={totalPage}
                totalElement={totalElement}/>
          </Card>
          {/*<TransactionsTable/>*/}
        </>
    );
  }
}
export default Object.assign(ProductSubscribePage, {ENDPOINT : "/productSubscribe"})

