import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Card, Col, Row} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {isResponseOk, navigate} from "na-utilities/src/utils/Utilities";
import ProductSubscribePage from "../pages/subscribe/ProductSubscribePage";
import {faClock, faUndo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class DashboardSubscribeAlert extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            expired:false,
            almostExpired:false,
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.hasExpiredOrAlmostExpired()
    }

    hasExpiredOrAlmostExpired(){
        let {expired, almostExpired} = this.state
        this.get(Endpoint.API.PRODUCT_SUBSCRIBE_HAS_EXPIRED_OR_ALMOST_EXPIRED, null, null, (res)=>{
            if(isResponseOk(res)){
                let data = res.data
                expired = data.expired
                almostExpired = data.almostExpired
                this.setState({expired, almostExpired})
            }
        }, false, false)
    }


    render() {
        let {expired, almostExpired} = this.state
        if(!expired && !almostExpired){
            return (<></>)
        }
        let alertTitle = ""
        if(almostExpired && !expired){
            alertTitle = label.SomeOfSubscriptionGonnaExpiredAlert
        }
        if(expired){
            alertTitle = label.SomeOfSubscriptionIsExpiredAlert
        }
        let backgroundClassName = 'bg-gradient-warning'
        if(almostExpired && !expired){
            backgroundClassName = 'bg-gradient-warning'
        }
        if(expired){
            backgroundClassName = 'bg-gradient-danger'
        }

        return (
            <Row className="justify-content-md-center">
                <Col xs={12} sm={12} xl={12} className="mb-4">
                    <div>
                        <Card border="light" className="shadow-sm">
                            <Card.Header className={backgroundClassName}>
                                <Row className="align-items-center">
                                    <Col>
                                        <h5 className="text-white">{alertTitle}</h5>
                                    </Col>
                                    <Col className="text-end">
                                        <Button
                                            variant="success"
                                            size="sm"
                                            onClick={event => {
                                                navigate(this.props, ProductSubscribePage.ENDPOINT)
                                            }}>
                                            {label.ExtendPeriod}
                                            &nbsp;&nbsp;&nbsp;
                                            <FontAwesomeIcon size="sm" icon={faClock}/>
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Header>
                        </Card>
                    </div>
                </Col>
            </Row>
        );
    }

}

export default DashboardSubscribeAlert