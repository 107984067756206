import React from "react";
import BaseComponent from "./BaseComponent";
import Global from "../../utils/Global";
import Endpoint from "na-utilities/src/api/Endpoint";
import {isResponseOk} from "na-utilities/src/utils/Utilities";
import {storeData} from "../../utils/StorageUtil";

export default class BasePage extends BaseComponent{

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        // toasting.success("Test")
        super.componentDidMount()
        this.openPage()
    }

    closePreLoader(){
        this.props.closePreLoader()
    }

    openPage(){
        this.get(Endpoint.API.USER_OPEN_PAGE, null, null, (res)=>{}, false, false)
    }

    refreshUser(successCallback){
        this.get(Endpoint.API.USER_AUTHORIZATION, null, null, (res)=>{
            if(isResponseOk(res)){
                let user = res.data
                storeData(Global.USER, user)
                this.setState({user}, () => {
                    if(successCallback){
                        successCallback(user)
                    }
                })
            }else{
                this.logout()
            }
        }, false, false)
    }


    render() {
        return (
            <>
                {super.render()}
            </>
        );
    }
    
}