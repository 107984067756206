import React from "react";
import {Button, ButtonGroup, Card, Col, Form, Row} from '@themesberg/react-bootstrap';
import Endpoint from "na-utilities/src/api/Endpoint";
import {isResponseOk} from "na-utilities/src/utils/Utilities";
import label from "na-utilities/src/label/Label";
import {firstLetterUppercase, imageSelector, isEmpty, navigateBack} from "na-utilities/src/utils/Utilities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBook,
    faChevronCircleLeft,
    faInfo,
    faLocationArrow,
    faPhone,
    faSave,
    faTrash,
    faUpload
} from "@fortawesome/free-solid-svg-icons";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import TextInput from "../../components/input/TextInput";
import queryString from 'query-string';
import PhoneNumberInput from "../../components/input/PhoneNumberInput";
import ItemOption from "../../components/input/ItemOption";
import {MapContainer} from "../../components/MapContainer";
import {SpotLocationDto} from "../../model/model";
import {isValidNumber, parsePhoneNumber} from "libphonenumber-js";
import BaseFormPage from "../base/BaseFormPage";
import DateInputWithModal from "../../components/DateInputWithModal";
import axios from "axios";
import {toasting} from "../base/BaseComponent";

class SpotLocationDetailPage extends BaseFormPage{

  constructor(props) {
    super(props);
    let validation = this.defaultValidation()
    let validationMessage = this.defaultValidationMessage()

    let spotLocationId = queryString.parse(window.location.search).id ? queryString.parse(window.location.search).id : undefined
    this.state = {
        spotLocationId:spotLocationId,
        spotLocation:{},
        validation,
        validationMessage,
        paymentAttribute:{},
        requestProcessConfirmationModal:false,
        spotLocationStatusHistoryModal:false,
    }
  }
    defaultValidation = () =>{
        let validation = new SpotLocationDto();
        validation.name = true ;
        validation.code = true ;
        validation.description = true
        validation.buildDate = true
        validation.phoneNumber = true
        validation.address = true ;
        validation.province = true ;
        validation.city = true
        validation.district = true ;
        validation.village = true
        validation.geoLocation = true

        return validation ;
    }
    defaultValidationMessage = () =>{
        let validationMessage = new SpotLocationDto();
        validationMessage.name = "" ;
        validationMessage.code = "" ;
        validationMessage.description = ""
        validationMessage.buildDate = ""
        validationMessage.phoneNumber = ""
        validationMessage.address = "" ;
        validationMessage.province = "" ;
        validationMessage.city = ""
        validationMessage.district = "" ;
        validationMessage.village = ""
        validationMessage.geoLocation = ""
        return validationMessage ;
    }

  componentDidMount() {
        super.componentDidMount();
        this.props.closePreLoader()
        this.fetchProvinces(()=>{
            this.fetchSpotLocation()
        })
  }

    async validateFormSubmit() {
        await super.validateFormSubmit();
        let {spotLocation, validation, validationMessage} = this.state
        let valid = true ;
        validation = this.defaultValidation()
        validationMessage = this.defaultValidationMessage()
        if(isEmpty(spotLocation.latitude)){
            validation.geoLocation = false
            validationMessage.geoLocation = label.PleaseSelectYourPlaceGeoLocation
            valid = false ;
        }
        if(isEmpty(spotLocation.longitude)){
            validation.geoLocation = false
            validationMessage.geoLocation = label.PleaseSelectYourPlaceGeoLocation
            valid = false ;
        }
        if(isEmpty(spotLocation.name)){
            validation.name = false
            validationMessage.name = label.EnterName
            valid = false ;
        }
        if(isEmpty(spotLocation.description)){
            validation.description = false
            validationMessage.description = label.EnterDescription
            valid = false ;
        }
        if(isEmpty(spotLocation.buildDate)){
            validation.buildDate = false
            validationMessage.buildDate = label.EnterBuildDate
            valid = false ;
        }
        if(isEmpty(spotLocation.phoneNumber)){
            validation.phoneNumber = false
            validationMessage.phoneNumber = label.PleaseEnterPhoneNumberCorrectly
            valid = false ;
        }
        if(!isEmpty(spotLocation.phoneNumber) && spotLocation.phoneNumber.length <=2){
            validation.phoneNumber = false
            validationMessage.phoneNumber = label.PleaseEnterPhoneNumberCorrectly
            valid = false ;
        }
        if(!isEmpty(spotLocation.phoneNumber)){
            let phoneNumber = parsePhoneNumber(spotLocation.phoneNumber, 'ID')
            if(!isValidNumber(spotLocation.phoneNumber)){
                validation.phoneNumber = false
                validationMessage.phoneNumber = label.PleaseEnterPhoneNumberCorrectly
                valid = false ;
            }
        }

        if(isEmpty(spotLocation.address) || spotLocation.address.length <=2){
            validation.address = false
            validationMessage.address = label.PleaseEnterAddressCorrectly
            valid = false ;
        }
        if(isEmpty(spotLocation.province)){
            validation.province = false
            validationMessage.province = label.PleaseSelectProvince
            valid = false ;
        }
        if(isEmpty(spotLocation.city)){
            validation.city = false
            validationMessage.city = label.PleaseSelectCity
            valid = false ;
        }
        if(isEmpty(spotLocation.district)){
            validation.district = false
            validationMessage.district = label.PleaseSelectDistrict
            valid = false ;
        }
        if(isEmpty(spotLocation.village)){
            validation.village = false
            validationMessage.village = label.PleaseSelectVillage
            valid = false ;
        }
        this.setState({validation, validationMessage}, () => {
            if(valid){
                this.openConfirmDialog(()=>{
                    this.saveUpload()
                },()=>{

                })
            }
        })
    }

    saveUpload = async () => {
        let spotLocation = this.state.spotLocation;
        var formData = new FormData();

        formData.append("spotLocationDtoGson", JSON.stringify(spotLocation))
        if(spotLocation.image1){
            let multipartFile = await new Promise(resolve => {
                axios({
                    url: spotLocation.image1,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    this.closeProgress()
                    var file = response.data ;
                    resolve(file)
                })
            })
            formData.append("multipartFile1", multipartFile);
        }
        if(spotLocation.image2){
            let multipartFile = await new Promise(resolve => {
                axios({
                    url: spotLocation.image2,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    this.closeProgress()
                    var file = response.data ;
                    resolve(file)
                })
            })
            formData.append("multipartFile2", multipartFile);
        }

        if(spotLocation.image3){
            let multipartFile = await new Promise(resolve => {
                axios({
                    url: spotLocation.image3,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    this.closeProgress()
                    var file = response.data ;
                    resolve(file)
                })
            })
            formData.append("multipartFile3", multipartFile);
        }
        if(spotLocation.image4){
            let multipartFile = await new Promise(resolve => {
                axios({
                    url: spotLocation.image4,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    this.closeProgress()
                    var file = response.data ;
                    resolve(file)
                })
            })
            formData.append("multipartFile4", multipartFile);
        }
        this.setState({
            modalAddConfirm: false
        }, () => {
            this.post(Endpoint.API.SPOT_LOCATION_SAVE_UPLOAD, formData, null, res => {
                if (res.code === 200) {
                    this.setState({
                        spotLocation: res.data
                    }, () => {
                        toasting.success(label.SuccessUpdatingData);
                        navigateBack(this.props)
                    })
                }
            }, true, true)
        })
    }

  fetchSpotLocation(){
    let {spotLocationId, spotLocation} = this.state
      if(!spotLocationId    ){
          return
      }
    this.get(Endpoint.API.SPOT_LOCATION, {params:{
            "id":spotLocationId
        }}, null, (res)=>{
    if(isResponseOk(res)){
        spotLocation = res.data
        let village = spotLocation.village?spotLocation.village:{}
        let district = village.district?village.district:{}
        let city = district.city?district.city:{}
        let province = city.province?city.province:{}
        spotLocation.district = district
        spotLocation.city = city
        spotLocation.province = province
        this.setState({
            spotLocation,
        },() => {
            if(province.id){
                this.fetchCities(province.id)
            }
            if(city.id){
                this.fetchDistricts(city.id)
            }
            if(district.id){
                this.fetchVillages(district.id)
            }
        })
      }else{
        // navigate(this.props, DashboardPage.ENDPOINT)
      }
    }, true, true)
  }

  
  render(){
    let {validation, validationMessage, spotLocation, provinces, cities, districts, villages,} = this.state
    return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
          </div>
            <Row>
                <Col md={2}>
                    <Row className="mb-4">
                        <Col>
                            <Card border="light">
                                <Row>
                                    <Col className="p-3">
                                        <Card.Img src={spotLocation.image1?spotLocation.imageLink1:ApplicationIcons.Icons.imagePlaceholder}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-right px-4 pb-2">
                                        <ButtonGroup>
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={event => {
                                                    spotLocation.imageLink1 = undefined
                                                    spotLocation.image1 = undefined
                                                    this.setState({spotLocation})
                                                }}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Button>
                                            <Button
                                                variant="outline-info"
                                                size="sm"
                                                onClick={event => {
                                                    imageSelector((file)=>{
                                                        spotLocation.imageLink1 = file
                                                        spotLocation.image1 = file
                                                        this.setState({spotLocation})
                                                    }).click()
                                                }}>
                                                <FontAwesomeIcon icon={faUpload}/>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Card border="light">
                                <Row>
                                    <Col className="p-3">
                                        <Card.Img src={spotLocation.image2?spotLocation.imageLink2:ApplicationIcons.Icons.imagePlaceholder}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-right px-4 pb-2">
                                        <ButtonGroup>
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={event => {
                                                    spotLocation.imageLink2 = undefined
                                                    spotLocation.image2 = undefined
                                                    this.setState({spotLocation})
                                                }}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Button>
                                            <Button
                                                variant="outline-info"
                                                size="sm"
                                                onClick={event => {
                                                    imageSelector((file)=>{
                                                        spotLocation.imageLink2 = file
                                                        spotLocation.image2 = file
                                                        this.setState({spotLocation})
                                                    }).click()
                                                }}>
                                                <FontAwesomeIcon icon={faUpload}/>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Card border="light">
                                <Row>
                                    <Col className="p-3">
                                        <Card.Img src={spotLocation.image3?spotLocation.imageLink3:ApplicationIcons.Icons.imagePlaceholder}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-right px-4 pb-2">
                                        <ButtonGroup>
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={event => {
                                                    spotLocation.imageLink3 = undefined
                                                    spotLocation.image3 = undefined
                                                    this.setState({spotLocation})
                                                }}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Button>
                                            <Button
                                                variant="outline-info"
                                                size="sm"
                                                onClick={event => {
                                                    imageSelector((file)=>{
                                                        spotLocation.imageLink3 = file
                                                        spotLocation.image3 = file
                                                        this.setState({spotLocation})
                                                    }).click()
                                                }}>
                                                <FontAwesomeIcon icon={faUpload}/>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Card border="light">
                                <Row>
                                    <Col className="p-3">
                                        <Card.Img src={spotLocation.image4?spotLocation.imageLink4:ApplicationIcons.Icons.imagePlaceholder}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-right px-4 pb-2">
                                        <ButtonGroup>
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={event => {
                                                    spotLocation.imageLink4 = undefined
                                                    spotLocation.image4 = undefined
                                                    this.setState({spotLocation})
                                                }}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Button>
                                            <Button
                                                variant="outline-info"
                                                size="sm"
                                                onClick={event => {
                                                    imageSelector((file)=>{
                                                        spotLocation.imageLink4 = file
                                                        spotLocation.image4 = file
                                                        this.setState({spotLocation})
                                                    }).click()
                                                }}>
                                                <FontAwesomeIcon icon={faUpload}/>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col md={10}>
                    <Row className="pb-2">
                        <Col className="text-right">
                            <ButtonGroup>
                                <Button
                                    size="sm"
                                    variant="warning"
                                    onClick={event => {
                                        this.fetchSpotLocation()
                                    }}
                                >
                                    {label.Reset}&nbsp;<FontAwesomeIcon icon={faChevronCircleLeft}/>
                                </Button>
                                <Button
                                    size="sm"
                                    variant="success"
                                    onClick={async event => {
                                        await this.validateFormSubmit()
                                    }}>
                                    {label.Save}&nbsp;<FontAwesomeIcon icon={faSave}/>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card border="light" className="p-0 mb-4">
                                <Card.Header className="text-center">
                                    <Card.Title>{label.LocationInformation}</Card.Title>
                                </Card.Header>
                                <Card.Body style={{height:'350px'}}>
                                    <MapContainer
                                        latitude={spotLocation.latitude}
                                        longitude={spotLocation.longitude}
                                        onGestureExecuting={(latitude, longitude)=>{
                                            console.log(latitude+","+longitude)
                                            spotLocation.latitude = latitude
                                            spotLocation.longitude = longitude
                                            this.setState({spotLocation:spotLocation})
                                        }}/>
                                </Card.Body>
                                {
                                    (!validation.geoLocation || !validation.geoLocation)?(
                                        <Card.Footer>
                                            <Form.Control.Feedback
                                                type="invalid"
                                                style={{display:'unset'}}>
                                                {validationMessage.geoLocation}
                                            </Form.Control.Feedback>
                                        </Card.Footer>
                                    ):(
                                        null
                                    )
                                }
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card border="light" className="p-0 mb-4">
                                <Card.Header className="text-center">
                                    <Card.Title>{label.BuildingInformation}</Card.Title>
                                </Card.Header>
                                <Card.Body className="p-3">
                                    <Row>
                                        <Col md={6}>
                                            <Form className="mt-2">
                                                <TextInput
                                                    label={label.Name}
                                                    icon={faBook}
                                                    required={false}
                                                    placeholder={label.EnterName}
                                                    value={spotLocation.name}
                                                    onChange={(value) => {
                                                        value = firstLetterUppercase(value)
                                                        spotLocation.name = value
                                                        validation.name = true
                                                        validationMessage.name = ""
                                                        this.setState({spotLocation, validation, validationMessage})
                                                    }}
                                                    isError={!validation.name}
                                                    errorMessage={validationMessage.name}
                                                />
                                                <DateInputWithModal
                                                    title={label.BuildDate}
                                                    placeholder={label.EnterBuildDate}
                                                    initialValue={spotLocation.buildDate}
                                                    onSelectCallback={(value) => {
                                                        spotLocation.buildDate = value
                                                        validation.buildDate = true
                                                        validationMessage.buildDate = ""
                                                        this.setState({spotLocation, validation, validationMessage})
                                                    }}
                                                    isError={!validation.buildDate}
                                                    errorMessage={validationMessage.buildDate}
                                                />
                                            </Form>
                                        </Col>
                                        <Col md={6}>
                                            <Form className="mt-2">
                                                <TextInput
                                                    label={label.Description}
                                                    icon={faInfo}
                                                    required={false}
                                                    placeholder={label.EnterDescription}
                                                    value={spotLocation.description}
                                                    onChange={(value) => {
                                                        value = firstLetterUppercase(value)
                                                        spotLocation.description = value
                                                        validation.description = true
                                                        validationMessage.description = ""
                                                        this.setState({spotLocation:spotLocation, validation, validationMessage})
                                                    }}
                                                    isError={!validation.description}
                                                    errorMessage={validationMessage.description}
                                                />
                                                <PhoneNumberInput
                                                    label={label.PhoneNumber}
                                                    icon={faPhone}
                                                    placeholder={label.EnterPhoneNumber}
                                                    value={spotLocation.phoneNumber}
                                                    onChange={async (value) => {
                                                        spotLocation.phoneNumber = value
                                                        validation.phoneNumber = true
                                                        validationMessage.phoneNumber = ""
                                                        this.setState({spotLocation:spotLocation, validation, validationMessage})
                                                    }}
                                                    isError={!validation.phoneNumber}
                                                    errorMessage={validationMessage.phoneNumber}
                                                />

                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card border="light" className="p-0 mb-4">
                                <Card.Header className="text-center">
                                    <Card.Title>{label.Address}</Card.Title>
                                </Card.Header>
                                <Card.Body className="p-3">
                                    <Row>
                                        <Col md={12}>
                                            <Form className="mt-2">
                                                <TextInput
                                                    label={label.Address}
                                                    icon={faLocationArrow}
                                                    required={false}
                                                    placeholder={label.EnterAddress}
                                                    value={spotLocation.address}
                                                    onChange={(value) => {
                                                        value = firstLetterUppercase(value)
                                                        spotLocation.address = value
                                                        validation.address = true
                                                        validationMessage.address = ""
                                                        this.setState({spotLocation:spotLocation, validation, validationMessage})
                                                    }}
                                                    isError={!validation.address}
                                                    errorMessage={validationMessage.address}
                                                />
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <ItemOption
                                                objects={provinces}
                                                label={label.Province}
                                                value={spotLocation.province}
                                                callback={(value) => {
                                                    spotLocation.province = value
                                                    spotLocation.village = undefined
                                                    spotLocation.city = undefined
                                                    spotLocation.district = undefined
                                                    validation.province = true
                                                    validationMessage.province = ""
                                                    if(value && value.id){
                                                        this.setState({spotLocation, validationMessage, validation},() => {
                                                            this.fetchCities(value.id, cities=>{
                                                            })
                                                        })
                                                    }else{
                                                        this.setState({
                                                            validation, validationMessage, spotLocation,
                                                            cities:[],
                                                            districts:[],
                                                            villages:[],
                                                        })
                                                    }
                                                }}
                                                isError={!validation.province}
                                                errorMessage={validationMessage.province}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <ItemOption
                                                objects={districts}
                                                label={label.District}
                                                value={spotLocation.district}
                                                callback={(value) => {
                                                    spotLocation.district = value
                                                    spotLocation.village = undefined
                                                    validation.district = true
                                                    validationMessage.district = ""
                                                    if(value && value.id){
                                                        this.setState({spotLocation, validation, validationMessage},() => {
                                                            this.fetchVillages(value.id, villages=>{
                                                            })
                                                        })
                                                    }else{
                                                        this.setState({
                                                            validation, validationMessage, spotLocation,
                                                            villages:[],
                                                        })
                                                    }
                                                }}
                                                isError={!validation.district}
                                                errorMessage={validationMessage.district}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <ItemOption
                                                objects={cities}
                                                label={label.City}
                                                value={spotLocation.city}
                                                callback={(value) => {
                                                    spotLocation.city = value
                                                    spotLocation.village = undefined
                                                    spotLocation.district = undefined
                                                    validation.city = true
                                                    validationMessage.city = ""
                                                    if(value && value.id){
                                                        this.setState({spotLocation, validation, validationMessage},() => {
                                                            this.fetchDistricts(value.id, districts=>{})
                                                        })
                                                    }else{
                                                        this.setState({
                                                            validation, validationMessage, spotLocation,
                                                            districts:[],
                                                            villages:[],
                                                        })
                                                    }
                                                }}
                                                isError={!validation.city}
                                                errorMessage={validationMessage.city}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <ItemOption
                                                objects={villages}
                                                label={label.Village}
                                                value={spotLocation.village}
                                                callback={(value) => {
                                                    spotLocation.village = value
                                                    validation.village = true
                                                    validationMessage.village = ""
                                                    this.setState({spotLocation, validation, validationMessage})
                                                }}
                                                isError={!validation.village}
                                                errorMessage={validationMessage.village}
                                            />
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {super.render()}
        </>
    );
  };
}
export default Object.assign(SpotLocationDetailPage, {ENDPOINT : "/spotLocationDetail"})
