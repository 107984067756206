import React from 'react';
import {Button, Card, Col, Row} from '@themesberg/react-bootstrap';
import BaseComponent from "../pages/base/BaseComponent";
import BusinessContactModal from "./BusinessContactModal";

export default class FollowUpWarning extends BaseComponent{
    constructor(props) {
        super(props);
        let show = this.props
        this.state = {
            show:show,
            businessContactModal:false
        }
    }

    render() {
        let {show, businessContactModal} = this.state
        return (
            <>
                <div style={{backgroundColor:'#f5f8fb90'}} className={`content preloader ${show ? "" : "show"} px-0`}>
                    <Card className="mt-auto block w-100 bg-gradient-warning" fluid rounded={false} style={{borderRadius:0}}>
                        <Card.Body rounded={false} style={{borderRadius:0}} className="pr-5">
                            <Row>
                                <Col md={9}>
                                    <h3 className="text-danger border-light">Oops....</h3>
                                    <h6>Kamu belum dapat menggunakan aplikasi Smart Supplies, mohon menghubungi kontak support kami untuk membantu pendaftaran anda.</h6>
                                </Col>
                                <Col md={3}>
                                    <Button onClick={event => {
                                        this.setState({businessContactModal:true})
                                    }}>
                                        Kontak Support
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
                <BusinessContactModal show={businessContactModal} onClose={() => {
                    this.setState({businessContactModal:false})
                }}/>
            </>

        );
    }
}
