import React from 'react'
import BaseComponent, {toasting} from "../pages/base/BaseComponent";
import {Button, ButtonGroup, Col, Form, Modal, Row} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {cleanSpace, isEmpty, isResponseOk} from "na-utilities/src/utils/Utilities";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {withRouter} from 'react-router-dom';
import PasswordInput from "./input/PasswordInput";
import {saveUserSession} from "../utils/StorageUtil";

class ChangePasswordModal extends BaseComponent{

    constructor(props) {
        super(props);
        let {show} = this.props
        let validation = this.defaultValidation()
        let validationMessage = this.defaultValidationMessage()
        this.state = {
            show:show,
            password:"",
            changePassword:"",
            confirmPassword:"",
            validation:validation,
            validationMessage:validationMessage,
            goodsCategories:[],
            categorySelectorModal:false,
        }
    }

    defaultValidation = () =>{
        let validation = {};
        validation.password = true ;
        validation.changePassword = true ;
        validation.confirmPassword = true ;
        return validation ;
    }
    defaultValidationMessage = () =>{
        let validationMessage = {};
        validationMessage.password = ""
        validationMessage.changePassword = "" ;
        validationMessage.confirmPassword = "" ;
        return validationMessage ;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let {show} = this.props
            this.setState({show})
        }
    }

    async submit(){
        let {password, changePassword, confirmPassword, validation, validationMessage} = this.state
        let valid = true ;
        if(isEmpty(password)){
            validation.password = false
            validationMessage.password = label.EnterPassword
            valid = false
        }
        if(isEmpty(changePassword)){
            validation.changePassword = false
            validationMessage.changePassword = label.EnterNewPassword
            valid = false
        }
        if(isEmpty(confirmPassword)){
            validation.confirmPassword = false
            validationMessage.confirmPassword = label.EnterConfirmPassword
            valid = false
        }
        if(changePassword!=confirmPassword) {
            validation.confirmPassword = false
            validationMessage.confirmPassword = label.ConfirmPasswordAndNewPasswordIsNotSame
            valid = false
        }
        if(changePassword.length<6){
            validation.changePassword = false
            validationMessage.changePassword = label.PasswordMustAtLeastContain6Characters
            valid = false
        }
        if(confirmPassword.length<6){
            validation.confirmPassword = false
            validationMessage.confirmPassword = label.PasswordMustAtLeastContain6Characters
            valid = false
        }
        if(!valid){
            this.setState({validation, validationMessage})
        }else{
            let form = new FormData()
            form.append("oldPassword", password)
            form.append("newPassword", changePassword)
            this.props.showProcessing()
            this.post(Endpoint.API.USER_CHANGE_PASSWORD, form,
                null, (res)=>{
                    this.props.closeProcessing()
                    if(isResponseOk(res)){
                        saveUserSession(res.data, true)
                        toasting.success(res.message)
                        this.props.onResult()
                    }
                }, true, true)
        }
    }

    render() {
        let {show, password, changePassword, confirmPassword, validation, validationMessage} = this.state
        return (
            <>
                {super.render()}
                <Modal as={Modal.Dialog} size="lg" centered show={show} onHide={()=>{}}>
                    <Modal.Header>
                        <Modal.Title className="h6">{label.UpdateStatus}</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={()=>{
                            this.props.onClose()
                        }} />
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form>
                                    <PasswordInput
                                        masking={true}
                                        label={label.Password}
                                        required={true}
                                        placeholder={label.EnterPassword}
                                        rows={4}
                                        value={password}
                                        onChange={(value) => {
                                            value = cleanSpace(value)
                                            password = value
                                            validation.password = true
                                            validationMessage.password = ""
                                            this.setState({password, validation, validationMessage})
                                        }}
                                        isError={!validation.password}
                                        errorMessage={validationMessage.password}
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form>
                                    <PasswordInput
                                        masking={false}
                                        label={label.ChangePassword}
                                        required={true}
                                        placeholder={label.EnterNewPassword}
                                        rows={4}
                                        value={changePassword}
                                        onChange={(value) => {
                                            value = cleanSpace(value)
                                            changePassword = value
                                            validation.changePassword = true
                                            validationMessage.changePassword = ""
                                            this.setState({changePassword, validation, validationMessage})
                                        }}
                                        isError={!validation.changePassword}
                                        errorMessage={validationMessage.changePassword}
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form>
                                    <PasswordInput
                                        masking={true}
                                        label={label.ConfirmPassword}
                                        required={true}
                                        placeholder={label.EnterConfirmPassword}
                                        rows={4}
                                        value={confirmPassword}
                                        onChange={(value) => {
                                            value = cleanSpace(value)
                                            confirmPassword = value
                                            validation.confirmPassword = true
                                            validationMessage.confirmPassword = ""
                                            this.setState({confirmPassword, validation, validationMessage})
                                        }}
                                        isError={!validation.confirmPassword}
                                        errorMessage={validationMessage.confirmPassword}
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right px-4 pb-2">
                                <ButtonGroup>
                                    <Button
                                        size="sm"
                                        variant="info"
                                        onClick={async (event) => {
                                            this.props.onClose()
                                        }}>
                                        {label.Cancel}&nbsp;&nbsp;<FontAwesomeIcon icon={faWindowClose}/>
                                    </Button>
                                    &nbsp;
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={async (event) => {
                                            await this.submit()
                                        }}>
                                        {label.Save}&nbsp;&nbsp;<FontAwesomeIcon icon={faSave}/>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}
ChangePasswordModal.propTypes = {
    show:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onResult:PropTypes.func.isRequired
}

export default withRouter(ChangePasswordModal)
