import React from 'react';
import Global from "../utils/Global";
import {getUser, saveUserSession, removeData} from "../utils/StorageUtil";
import {useHistory} from "react-router-dom";
import {navigate} from "na-utilities/src/utils/Utilities";
import SigninPage from "../pages/examples/SigninPage";

const AuthContext = React.createContext()


//https://medium.freecodecamp.org/how-to-protect-your-routes-with-react-context-717670c4713a
class AuthProvider extends React.Component {
    state = {
        isAuth: getUser()!=null,
        user: getUser()
    }

    constructor() {
        super()
        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    login(user) {
        // alert(JSON.stringify(user))
        saveUserSession(user, true)
        this.setState({
            user : getUser(),
            isAuth:getUser()!=null
        }, () => {
            setTimeout(() => this.setState({ isAuth: true }), 1000)
        })
    }

    logout(props) {
        // removeStorage()
        // let history = useHistory()
        window.history.pushState(null, null, '/');
        window.location.href = SigninPage.ENDPOINT
        removeData(Global.USER)
        removeData(Global.AUTHORIZATION)
        this.setState({
            isAuth: getUser()!=null,
            user: null
        })
    }

    refresh(){
        this.setState({
            isAuth: getUser()!=null,
            user:getUser()
        }, () => {
            this.forceUpdate()
        })
    }

    render() {
        return (
            <AuthContext.Provider
                value={{
                    isAuth: this.state.isAuth,
                    user: this.state.user,
                    login: this.login,
                    logout: this.logout,
                    refresh:this.refresh,
                }}>
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
