import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faBorderNone,
  faCamera,
  faCheck, faDatabase, faEllipsisH, faEye,
  faHome, faList,
  faPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import {Breadcrumb, Button, ButtonGroup, Card, Col, Dropdown, Form, Row, Table} from '@themesberg/react-bootstrap';
import BasePage from "./../base/BasePage";
import label from "na-utilities/src/label/Label"
import DashboardPage from "../dashboard/DashboardPage";
import PaginationTable from "../../components/PaginationTable";
import {getUser} from "../../utils/StorageUtil";
import {Link} from "react-router-dom";
import {Routes} from "../../routes";
import Endpoint from "na-utilities/src/api/Endpoint";
import queryString from 'query-string';
import {
  cloneObject,
  isImageExists,
  isResponseOk,
  navigate,
  navigateBack,
  parseDate
} from "na-utilities/src/utils/Utilities";
import SearchInput from "../../components/input/SearchInput";
import TableSortOption from "../../components/input/TableSortOption";
import SortOption from "na-utilities/src/model/SortOption";
import GoodsItemImageModal from "../../components/GoodsItemImageModal";
import TextInput from "../../components/input/TextInput";
import TextAreaInput from "../../components/input/TextAreaInput";
import GoodsItemEditModal from "../../components/GoodsItemEditModal";
import GoodsInfoEditModal from "../../components/GoodsInfoEditModal";
import {DD_MMMM_YYYY} from "../../utils/Global";
import GoodsItemDetailPage from "./GoodsItemDetailPage";
import ItemOption from "../../components/input/ItemOption";

const maxBorrowingDurationOptions = [{id:1, label:label.NotLimited}, {id:2, label:label.Limited}];

class GoodsInfoDetailPage extends BasePage {
  constructor(props) {
    super(props);
    let {id} = props.match.params
    this.state = {
      goodsInfoId:id,
      goodsInfo:{},
      inputSearch: queryString.parse(this.props.query).search ? queryString.parse(this.props.query).search : "",
      user : getUser(),
      page: queryString.parse(props.query).page ? queryString.parse(props.query).page : 1,
      goodsItems:[],
      totalPage: 0,
      totalElement: 0,
      pageElement: 0,
      sortOption:{},
      createGoodsInfoModal:{
        show:false,
        position:-1,
        goodsInfo:{},
      },
      goodsItemImageModal:{
        show:false,
        position:-1,
        goodsItem:{},
      },
      createGoodsItemModal:{
        show:false,
        position:-1,
        goodsInfo:{},
        goodsItems:[],
      },
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.closePreLoader()
    this.fetchGoodsInfo()
  }

  componentWillReceiveProps(props, nextContext) {
    if (props != this.props) {
      let page = queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
      let key = queryString.parse(props.query).tab ? queryString.parse(props.query).tab : label.All
      let inputSearch = queryString.parse(props.query).search ? queryString.parse(props.query).search : ""
      this.setState({
        page: page,
        key: key,
        inputSearch: inputSearch
      }, () => {
        this.fetchGoodsItems()
      })
    }
  }

  fetchGoodsInfo(){
    let {goodsInfoId, goodsInfo, createGoodsInfoModal} = this.state
    this.get(Endpoint.API.GOODS_INFO, {params:{
        "id":goodsInfoId
      }}, null, (res)=>{
      if(isResponseOk(res)){
        goodsInfo = res.data
        createGoodsInfoModal.goodsInfo = goodsInfo
        this.setState({goodsInfo, createGoodsInfoModal}, () => this.fetchGoodsItems())
      }else{
        navigateBack(this.props)
      }
    }, false, true)
  }


  fetchGoodsItems = () => {
    let {user,goodsItems, search, sortOption, goodsInfoId} = this.state
    this.get(Endpoint.API.GOODS_ITEMS, {
      params :{
        "goodsInfoId":goodsInfoId,
        ascending: sortOption.ascending,
        sortir: sortOption.sortir,
        search: search,
        page: this.state.page - 1,
      }
    }, null, response=>{
      if(response.code===200){
        let goodsItems = response.data
        let totalPage = response.totalPage
        let totalElement = response.totalElement
        let pageElement = response.pageElement
        this.setState({goodsItems:goodsItems,totalPage, totalElement, pageElement})
      }else{
        this.props.history.goBack();
      }
    }, false, true)
  }

  render() {
    let {user, goodsInfo, goodsItems, page, pageElement, totalPage, totalElement, sortOption,
      goodsItemImageModal, createGoodsItemModal, createGoodsInfoModal} = this.state
    let goodsCategory = goodsInfo.goodsCategory?goodsInfo.goodsCategory:{}
    let borrowingDurationInfo = goodsInfo.borrowingDurationType===true?maxBorrowingDurationOptions[0].label:maxBorrowingDurationOptions[1].label
    if(!goodsInfo.borrowingDurationType){
      borrowingDurationInfo += " ("+goodsInfo.maximumBorrowingDurationInHour+" jam)";
    }

    return (
        <>
          {super.render()}
          <GoodsInfoEditModal
              createGoodsInfoModal={createGoodsInfoModal}
              onClose={() => {
                this.setState({createGoodsInfoModal:{show:false, position:-1, goodsInfo:{}}})
              }}
              onResult={(createGoodsInfoModal) => {
                goodsInfo = cloneObject(createGoodsInfoModal.goodsInfo)
                this.setState({goodsInfo, createGoodsInfoModal:{show:false, position:-1, goodsInfo:{}}})
              }}
              showProcessing={this.props.showProcessing}
              closeProcessing={this.props.closeProcessing}
          />
          <GoodsItemEditModal
              createGoodsItemModal={createGoodsItemModal}
              onClose={() => {
                this.setState({createGoodsItemModal:{show:false, position:-1, goodsInfo:{}, goodsItems:[]}})
              }}
              onResult={(createGoodsItemModal) => {
                goodsInfo = cloneObject(createGoodsItemModal.goodsInfo)
                this.setState({goodsInfo, createGoodsItemModal:{show:false, position:-1, goodsInfo:{}}})
              }}
              showProcessing={this.props.showProcessing}
              closeProcessing={this.props.closeProcessing}
          />
          <GoodsItemImageModal
              goodsItemImageModal={goodsItemImageModal}
              onResult={(goodsItemImageModal) => {
                if(goodsItemImageModal.position==-1){
                  goodsItems.splice(0,0, cloneObject(goodsItemImageModal.goodsItem))
                }else{
                  goodsItems.splice(goodsItemImageModal.position, 1)
                  goodsItems.splice(goodsItemImageModal.position, 0, cloneObject(goodsItemImageModal.goodsItem))
                }
                this.setState({goodsItems, goodsItemImageModal:{show:false, position:-1, goodsItem:{}}})
              }}
              onClose={() => {
                this.setState({goodsItemImageModal:{show:false, position:-1, goodsItem:{}}})
              }}/>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2">
            <div className="d-block mb-0 mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                <Breadcrumb.Item href={DashboardPage.ENDPOINT}>{label.Dashboard}</Breadcrumb.Item>
                <Breadcrumb.Item active>{label.GoodsItemList}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div>
            <Row>
              <Col xl={3}>
                <Card className="p-0">
                  <Card.Header className="p-3">
                    {label.GoodsInformation}
                  </Card.Header>
                  <Card.Body className="p-3">
                    <Form>
                      <TextInput
                          label={label.GoodsName}
                          required={true}
                          placeholder={label.EnterGoodsName}
                          value={goodsInfo.name}
                          disabled={true}
                          readOnly={true}
                      />
                    </Form>
                    <Form>
                      <TextAreaInput
                          label={label.GoodsDescription}
                          required={true}
                          placeholder={label.EnterGoodsDescription}
                          rows={4}
                          value={goodsInfo.description}
                          disabled={true}
                          readOnly={true}
                      />
                    </Form>
                    <Form>
                      <TextInput
                          label={label.GoodsCategory}
                          required={true}
                          value={goodsCategory.name}
                          placeholder={" "}
                          disabled={true}
                          readOnly={true}
                      />
                    </Form>
                    <Form>
                      <TextInput
                          label={label.MaxBorrowingDuration}
                          value={borrowingDurationInfo}
                          placeholder={" "}
                          disabled={true}
                          readOnly={true}/>
                    </Form>
                    <Form className="text-right">
                      <Button
                          size="sm"
                          variant="warning"
                          onClick={(event) => {
                            event.preventDefault()
                            createGoodsInfoModal = {
                              ...createGoodsInfoModal, position:-1, goodsInfo:cloneObject(goodsInfo), show:true
                            }
                            this.setState({createGoodsInfoModal})
                          }}>
                        {label.Update}&nbsp;&nbsp;<FontAwesomeIcon icon={faDatabase} className="icon-dark" />
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={9}>
                <Row className="py-2">
                  <Col xs={4} md={4} xl={3}>
                    <Button
                        size="sm"
                        onClick={(event) => {
                          event.preventDefault()
                          createGoodsItemModal = {
                            ...createGoodsItemModal, position:-1, goodsInfo:cloneObject(goodsInfo), show:true
                          }
                          this.setState({createGoodsItemModal})
                        }}>
                      {label.NewItem}&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} className="icon-dark" />
                    </Button>
                  </Col>
                  <Col xs={4} md={4} xl={3} className="text-right d-flex justify-content-end align-items-center">
                    <TableSortOption
                        sortOptions={
                          [
                            SortOption.init(label.Longest,"gi.created",true),
                            SortOption.init(label.Latest,"gi.created",false),
                          ]
                        }
                        sortOption={sortOption}
                        placeholder={label.SortBy}
                        onSelect={(sortOption) => {
                          this.setState({sortOption},() => {
                            this.fetchGoodsItems()
                          })
                        }}
                        onReset={() => {
                          this.setState({sortOption:{}},() => {
                            this.fetchGoodsItems()
                          })
                        }}/>
                  </Col>
                  <Col xs={8} md={6} lg={3} xl={6}>
                    <SearchInput onEnter={(search) => {
                      this.setState({search}, () => {
                        this.fetchGoodsItems()
                      })
                    }} onEmpty={() => {
                      this.setState({search:""},() => {
                        this.fetchGoodsItems()
                      })
                    }}/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card border="light" className="table-wrapper table-responsive shadow-sm">
                      <Card.Body className="p-0">
                        <Table hover className="table-sm align-items-center">
                          <thead>
                          <tr>
                            <th className="border-bottom">#</th>
                            <th className="border-bottom">{label.RFIDTag}</th>
                            <th className="border-bottom text-center">{label.Image}</th>
                            <th className="border-bottom">{label.Status}</th>
                            <th className="border-bottom">{label.Created}</th>
                            <th className="border-bottom text-center">{label.Detail}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {goodsItems.map((goodsItem, index) => (
                              <tr className={(goodsItem.isNew?"yellow-background-blinking":"")+" "+(goodsItem.isDuplicate?"red-background-blinking":"")}>
                                <td>
                                  <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
                                    {(index+1)}
                                  </Card.Link>
                                </td>
                                <td>
                                  <span className="fw-normal">{goodsItem.rfidTag}</span>
                                </td>
                                <td className="text-center">
                                  <Button
                                      size="sm"
                                      variant={isImageExists(goodsItem)?"outline-info":"outline-warning"}
                                      onClick={event => {
                                        goodsItemImageModal = {
                                          ...goodsItemImageModal, position:index, goodsItem:cloneObject(goodsItem), show:true
                                        }
                                        this.setState({goodsItemImageModal})
                                      }}>
                                    <FontAwesomeIcon icon={faCamera}/>
                                    &nbsp;
                                    &nbsp;
                                    <FontAwesomeIcon icon={isImageExists(goodsItem)?faCheck:faBorderNone}/>
                                  </Button>
                                </td>
                                <td>
                                  <span className="fw-normal" style={{color:goodsItem.goodsItemStatus.color}}>{goodsItem.goodsItemStatus.label}</span>
                                </td>
                                <td>
                                  <span className="fw-normal">{parseDate(goodsItem.created, DD_MMMM_YYYY)}</span>
                                </td>
                                <td className="text-center">
                                  <Button size="sm" onClick={event => {
                                    navigate(this.props, GoodsItemDetailPage.ENDPOINT+"/"+goodsItem.id)
                                  }}>
                                    <FontAwesomeIcon icon={faEye}/>
                                  </Button>
                                </td>
                              </tr>
                          ))}
                          </tbody>
                        </Table>
                      </Card.Body>
                      <PaginationTable
                          onSelect={() => {

                          }}
                          showingRow={goodsItems.length}
                          currentPage={page}
                          pageCount={totalPage}
                          totalElement={totalElement}/>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </>
    );
  }
}
export default Object.assign(GoodsInfoDetailPage, {ENDPOINT : "/goodsInfoDetail"})

