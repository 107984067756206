import React from 'react'
import * as DatePicker from "@kamil-perczynski/bootstrap-date-picker";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import TextInput from "./input/TextInput";
import PropTypes from "prop-types";
import {Button, Modal} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class DateInputWithModal extends React.Component{
    state = {

    }
    constructor(props) {
        super(props);
        this.state = {
            isOpen : false,
            value : undefined
        }
    }


    render() {
        let {isOpen, value} = this.state
        let {initialValue, isError,errorMessage,title,placeholder,onSelectCallback} = this.props
        if(!value && initialValue){
            value = initialValue//format(initialValue, 'yyyy-MM-dd')
        }
        return (
            <>
                <TextInput
                    style={{backgroundColor:'unset'}}
                    label={title}
                    icon={faCalendar}
                    required={false}
                    placeholder={placeholder}
                    value={value}
                    isError={isError}
                    readOnly={true}
                    onClick={()=>{this.setState({isOpen:true})}}
                    errorMessage={errorMessage}
                />
                <Modal size="lg" as={Modal.Dialog} centered show={isOpen} onHide={()=>{}}>
                    <Modal.Header className={"p-2"}>
                        <Button
                            variant="outline-warning"
                            className="text-gray ms-auto"
                            size="sm"
                            onClick={()=>{
                                this.setState({isOpen: false, value: initialValue})
                            }}>
                            {label.Cancel}
                        </Button>
                        &nbsp;
                        <Button
                            variant="success"
                            size="sm"
                            onClick={()=>{
                                if(onSelectCallback){
                                    onSelectCallback(value)
                                }
                                this.setState({isOpen: false, value: value})
                            }}>
                            {label.SelectCalendar}&nbsp;<FontAwesomeIcon icon={faCalendar}/>
                        </Button>
                    </Modal.Header>
                    <Modal.Body className="text-center" style={{border:'none'}}>
                        <DatePicker.DatePicker
                            className={"date-picker-custom"}
                            type="localdate"
                            open={true}
                            value={value}
                            onChange={(value)=>{
                                this.setState({value})
                            }}
                            locale={"en"}
                        />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
DateInputWithModal.propTypes = {
    initialValue:PropTypes.any,
    value:PropTypes.string,
    title:PropTypes.string,
    placeholder:PropTypes.string,
    required:PropTypes.bool,
    readOnly:PropTypes.bool,
    onSelectCallback:PropTypes.func,
    isError:PropTypes.bool,
    errorMessage:PropTypes.string
}