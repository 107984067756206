import React from 'react'
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Card, Col, NavLink, Row, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faEye, faUndo} from "@fortawesome/free-solid-svg-icons";
import label from "na-utilities/src/label/Label"
import Endpoint from "na-utilities/src/api/Endpoint";
import {handleTimeAgo} from "na-utilities/src/utils/Utilities";
import SmartDevicePage from "../pages/subscribe/SmartDevicePage";
import SmartDeviceDetailPage from "../pages/subscribe/SmartDeviceDetailPage";

const pageVisits = [
    { id: 1, views: 4.525, returnValue: 255, bounceRate: 42.55, pageName: "/demo/admin/index.html" },
    { id: 2, views: 2.987, returnValue: 139, bounceRate: -43.52, pageName: "/demo/admin/forms.html" },
    { id: 3, views: 2.844, returnValue: 124, bounceRate: -32.35, pageName: "/demo/admin/util.html" },
    { id: 4, views: 1.220, returnValue: 55, bounceRate: 15.78, pageName: "/demo/admin/validation.html" },
    { id: 5, views: 505, returnValue: 3, bounceRate: -75.12, pageName: "/demo/admin/modals.html" }
];

class DashboardSmartDeviceActivityTable extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            smartDeviceActivities:[],
            page: 1,
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchSmartDeviceActivities()

    }


    fetchSmartDeviceActivities = () => {
        let {smartDeviceActivities} = this.state
        this.get(Endpoint.API.SMART_DEVICE_ACTIVITIES, {
            params :{
                ascending: false,
                sortir: "ca.created",
                page: this.state.page - 1,
            }
        }, null, response=>{
            if(response.code===200){
                let smartDeviceActivities = response.data
                let totalPage = response.totalPage
                let totalElement = response.totalElement
                let pageElement = response.pageElement
                this.setState({smartDeviceActivities:smartDeviceActivities,totalPage, totalElement, pageElement})
            }else{
                this.props.history.goBack();
            }
        }, false, true)
    }


    render() {
        let {smartDeviceActivities} = this.state
        return (
            <div>
                <Card border="light" className="shadow-sm">
                    <Card.Header className="p-3">
                        <Row className="align-items-center">
                            <Col>
                                <h5>{label.Latest10UserActivity}</h5>
                            </Col>
                            <Col className="text-end">
                                <Button
                                    variant="info"
                                    size="sm"
                                    onClick={event => {
                                        this.fetchSmartDeviceActivities()
                                    }}>
                                    <FontAwesomeIcon size="sm" icon={faUndo}/>
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Table responsive className="table-flush table-striped table-sm text-wrap">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{label.DeviceId}</th>
                            <th scope="col">{label.Platform}</th>
                            <th scope="col">{label.User}</th>
                            <th scope="col">{label.Title}</th>
                            <th scope="col">{label.Description}</th>
                            <th scope="col">{label.TimeActivity}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {smartDeviceActivities.map((smartDeviceActivity, index) => {
                            return (
                                <tr>
                                    <td>{index+1}</td>
                                    <td>
                                        {
                                            smartDeviceActivity.platform==='TOOLBOX'?
                                                (
                                                    <NavLink
                                                        style={{textDecoration:'underline'}}
                                                        className="p-0"
                                                        href={SmartDeviceDetailPage.ENDPOINT+"/"+smartDeviceActivity.deviceId}>
                                                        {smartDeviceActivity.deviceId}
                                                    </NavLink>
                                                ):(
                                                    <span>{smartDeviceActivity.deviceId}</span>
                                                )
                                        }
                                    </td>
                                    <td>{smartDeviceActivity.platform?smartDeviceActivity.platform:"-"}</td>
                                    <td>{smartDeviceActivity.user.fullname}</td>
                                    <td>{smartDeviceActivity.title}</td>
                                    <td className="text-wrap">{smartDeviceActivity.description}</td>
                                    <td>{handleTimeAgo(smartDeviceActivity.created)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </Card>
            </div>
        );
    }

}

export default DashboardSmartDeviceActivityTable