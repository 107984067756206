export const PROFILE = "https://smart-supplies.numeralasia.com"
export const COPYRIGHT = "https://smart-supplies-user.numeralasia.com"
export const BASE_URL = 'https://smart-supplies-api.numeralasia.com/api'
export const UCVDWEYGZH2K4M5N7Q8R9TBUCV = 'ucvdweygzh2k4m5n7q8r9tbucv'
export const APK_TOOLBOX_LATEST_DOWNLOAD = 'https://smart-supplies-api.numeralasia.com/api/applicationVersion/download/toolbox/latest'
export const _T6jiWPmT3chqZbpSQMzyVJzPMFIP5oHO = '_T6jiWPmT3chqZbpSQMzyVJzPMFIP5oHO'
export const HEART_BEAT_INTERVAL = 1000;
export const DEBUG = true
if(!DEBUG){
    window.console.log = function() {
        return DEBUG;
    }
    window.console.debug = function() {
        return DEBUG;
    }
    window.console.info = function() {
        return DEBUG;
    }
    window.console.error = function() {
        return DEBUG;
    }
}