import {Form, InputGroup} from "@themesberg/react-bootstrap";
import label from 'na-utilities/src/label/Label'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLock} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import PropTypes from 'prop-types';
import {cleanSpace} from "na-utilities/src/utils/Utilities";

export default class PasswordInput extends React.Component{

    render() {
        let {id, placeholder, label, value, readOnly, icon, masking} = this.props
        let {isError,errorMessage} = this.props
        if(!id){
            id = "password"
        }
        if(!label){
            label = ""
        }
        if(!placeholder){
            placeholder = label
        }
        let type = "text"
        if(masking){
            type = "password"
        }
        return (
            <Form.Group id={id} className="mb-4">
                <Form.Label>{label}</Form.Label>
                <InputGroup>
                    {icon&&(
                        <InputGroup.Text style={{borderColor:(isError?'#FA5252':'')}}>
                            <FontAwesomeIcon icon={icon} />
                        </InputGroup.Text>
                    )}
                    <Form.Control
                        autoFocus
                        required={this.props.required}
                        type={type}
                        placeholder={placeholder}
                        onChange={(e)=>{
                            if(this.props.onChange){
                                let value = e.target.value
                                value = cleanSpace(value)
                                this.props.onChange(value)
                            }
                        }}
                        isInvalid={isError}
                        value={value} readOnly={readOnly}/>
                </InputGroup>
                {isError&&(
                    <Form.Control.Feedback type="invalid" style={{display:'unset', position:'absolute'}}>{errorMessage?errorMessage:placeholder}</Form.Control.Feedback>
                )}
            </Form.Group>
        );
    }
}
PasswordInput.propTypes = {
    id:PropTypes.string,
    label:PropTypes.string.isRequired,
    value:PropTypes.string,
    placeholder:PropTypes.string,
    icon:PropTypes.any.isRequired,
    required:PropTypes.bool,
    readOnly:PropTypes.bool,
    onChange:PropTypes.func,
    isError:PropTypes.bool,
    errorMessage:PropTypes.string,
    masking:PropTypes.bool

}