import React from "react";
import DashboardOverview from "./pages/dashboard/DashboardOverview";
import TransactionPage from "./pages/TransactionPage";
import SettingPage from "./pages/SettingPage";
import PresentationPage from "./pages/PresentationPage";
import SigninPage from "./pages/examples/SigninPage";
import SignupPage from "./pages/examples/SignupPage";
import LockPage from "./pages/examples/LockPage";
import NotFoundPage from "./pages/examples/ExceptionPage";
import UpgradePage from "./pages/UpgradePage";
import DocsFolderStructure from "./pages/documentation/DocsFolderStructurePage";
// import ForgotPasswordPage from "./pages/examples/ForgotPasswordPage";
// import ResetPasswordPage from "./pages/examples/ResetPasswordPage";

export const Routes = {
    // pages
    // Presentation: { path: PresentationPage.ENDPOINT },
    // DashboardOverview: { path: DashboardPage.ENDPOINT },
    // Transactions: { path: ProductOrderPage.ENDPOINT },
    // Settings: { path: SettingPage.ENDPOINT },
    // Upgrade: { path: UpgradePage.ENDPOINT },
    // BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    // Signin: { path: SigninPage.ENDPOINT },
    // Signup: { path: SignupPage.ENDPOINT},
    // ForgotPassword: { path: ForgotPasswordPage.ENDPOINT },
    // ResetPassword: { path: ResetPasswordPage.ENDPOINT },
    // Lock: { path: LockPage.ENDPOINT },
    // NotFound: { path: NotFoundPage.ENDPOINT },
    // ServerError: { path: "/examples/500" },

    // docs
    // DocsOverview: { path: "/documentation/overview" },
    // DocsDownload: { path: "/documentation/download" },
    // DocsQuickStart: { path: "/documentation/quick-start" },
    // DocsLicense: { path: "/documentation/license" },
    // DocsFolderStructure: { path: DocsFolderStructure.ENDPOINT },
    // DocsBuild: { path: "/documentation/build-com.numeralasia.smartsupplies.tools" },
    // DocsChangelog: { path: "/documentation/changelog" },

    // components
    // Accordions: { path: "/components/accordions" },
    // Alerts: { path: "/components/alerts" },
    // Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    // Breadcrumbs: { path: "/components/breadcrumbs" },
    // Buttons: { path: "/components/buttons" },
    // Forms: { path: "/components/forms" },
    // Modals: { path: "/components/modals" },
    // Navs: { path: "/components/navs" },
    // Navbars: { path: "/components/navbars" },
    // Pagination: { path: "/components/pagination" },
    // Popovers: { path: "/components/popovers" },
    // Progress: { path: "/components/progress" },
    // TablePage: { path: "/components/tables" },
    // Tabs: { path: "/components/tabs" },
    // Tooltips: { path: "/components/tooltips" },
    // Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};

