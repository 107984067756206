import React from 'react'
import BaseComponent from "../../pages/base/BaseComponent";
import {Button, Card, Table} from "@themesberg/react-bootstrap";
import label from "na-utilities/src/label/Label"
import {Link} from "react-router-dom";
import {Routes} from "../../routes";
import Endpoint from "na-utilities/src/api/Endpoint";
import {getUser} from "../../utils/StorageUtil";
import PropTypes from 'prop-types';
import {DD_MM_YYYY_HH_MM_SS} from "../../utils/Global";
import PaginationTable from "../../components/PaginationTable";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import MyImage from "../../components/MyImage";
// import BorrowingOfItemDetailPage from "./BorrowingOfItemDetailPage";
import GoodsInfoDetailPage from "./GoodsInfoDetailPage";
import {parseDate} from "na-utilities/src/utils/Utilities";
import BorrowingOfItemChangeStatusModal from "../../components/BorrowingOfItemChangeStatusModal";


class BorrowingOfItemTable extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            show:false,
            search: "",
            user : getUser(),
            borrowingOfItemStatus:props.borrowingOfItemStatus?props.borrowingOfItemStatus:{},
            tabDataCallback:props.tabDataCallback?props.tabDataCallback:{},
            page: 1,
            borrowingOfItems:[],
            totalPage: 0,
            totalElement: 0,
            pageElement: 0
        }
    }
    componentDidMount() {
        super.componentDidMount();
        this.fetchBorrowingOfItems(undefined, undefined, undefined)
    }

    componentWillReceiveProps(props, nextContext) {
        let show = props.show?props.show:false
        let borrowingOfItemStatus = props.borrowingOfItemStatus?props.borrowingOfItemStatus:{}
        let tabDataCallback = props.tabDataCallback?props.tabDataCallback:{}
        this.setState({show, borrowingOfItemStatus, tabDataCallback},() => {
            if(show && !this.props.show){
                this.fetchBorrowingOfItems(undefined, {}, undefined, undefined)
            }
        })
    }

    goToPage(page){
        this.setState({page}, () => {
            this.refresh()
        })
    }

    refresh(){
        let {search, sortOption, filteredSmartDevices, filteredUsers, filteredGoodsCategories} = this.state
        this.fetchBorrowingOfItems(search, sortOption, filteredSmartDevices, filteredUsers, filteredGoodsCategories)
    }


    fetchBorrowingOfItems = (search, sortOption, filteredSmartDevices, filteredUsers, filteredGoodsCategories) => {
        let {user, borrowingOfItemStatus, borrowingOfItems, tabDataCallback} = this.state
        let filterSmartDeviceIds = []
        if(filteredSmartDevices){
            filterSmartDeviceIds = new Array()
            filteredSmartDevices.forEach((item)=>{
                filterSmartDeviceIds.push(item.id)
            })
        }
        let filterUserIds = []
        if(filteredUsers){
            filterUserIds = new Array()
            filteredUsers.forEach((item)=>{
                filterUserIds.push(item.id)
            })
        }
        let filterGoodsCategoryIds = []
        if(filteredGoodsCategories){
            filterGoodsCategoryIds = new Array()
            filteredGoodsCategories.forEach((item)=>{
                filterGoodsCategoryIds.push(item.id)
            })
        }
        if(!sortOption){
            sortOption = {}
        }
        this.setState({
            search, sortOption, filteredSmartDevices, filteredUsers, filteredGoodsCategories
        }, () => {
            this.get(Endpoint.API.BORROWING_ITEMS, {
                params :{
                    borrowingOfItemStatusId : borrowingOfItemStatus.id==0?undefined:borrowingOfItemStatus.id,
                    ascending: sortOption.ascending,
                    sortir: sortOption.sortir,
                    search: search,
                    borrowedOnSmartDeviceIds:filterSmartDeviceIds.join(","),
                    borrowerIds:filterUserIds.join(','),
                    goodsCategoryIds:filterGoodsCategoryIds.join(","),
                    page: this.state.page - 1,
                }
            }, null, response=>{
                if(response.code===200){
                    let borrowingOfItems = response.data
                    let totalPage = response.totalPage
                    let totalElement = response.totalElement
                    let pageElement = response.pageElement
                    this.setState({borrowingOfItems:borrowingOfItems, totalPage, totalElement, pageElement}, () => {
                        if(tabDataCallback){
                            tabDataCallback(borrowingOfItemStatus.id, totalElement)
                        }
                    })
                }
            }, true, true)
        })
    }


    render() {
        let {user, borrowingOfItems, page, pageElement, totalPage, totalElement} = this.state
        return (
            <React.Fragment>
                <Card border="light" className="table-wrapper table-responsive shadow-sm">
                    <Card.Body className="p-0">
                        <Table hover className="table-sm align-items-center p-0">
                            <thead>
                            <tr>
                                <th className="border-bottom pl-3 pr-3" style={{maxWidth:'30px'}}>#</th>
                                <th className="border-bottom pl-3 pr-3" style={{maxWidth:'190px'}}>{label.Goods}</th>
                                <th className="border-bottom pl-3 pr-3" style={{maxWidth:'190px'}}>{label.TakenFrom}</th>
                                <th className="border-bottom pl-3 pr-3" style={{maxWidth:'160px'}}>{label.Borrower}</th>
                                <th className="border-bottom pl-3 pr-3" style={{maxWidth:'150px'}}>{label.Time}</th>
                                <th className="border-bottom pl-3 pr-3" style={{maxWidth:'180px'}}>{label.Status}</th>
                                <th className="border-bottom pl-3 pr-3" style={{maxWidth:'190px'}}>{label.ReturnedOn}</th>
                                <th className="border-bottom pl-3 pr-3" style={{maxWidth:'150px'}}>{label.Time}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {borrowingOfItems.map((borrowingOfItem, index) => (
                                <tr key={index}>
                                    <td style={{maxWidth:'30px'}} className='pl-3 pr-3'>
                                        <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
                                            {((page - 1) * pageElement) + (index+1)}
                                        </Card.Link>
                                    </td>
                                    <td style={{maxWidth:'190px'}} className='pl-3 pr-3'>
                                        <span className="fw-normal">
                                            <Link
                                                style={{textDecoration:'underline'}}
                                                to={"#"}
                                                onClick={() => {
                                                    this.props.onSelect(borrowingOfItem)
                                                }}>
                                                {borrowingOfItem.goodsItem.goodsInfo.name}
                                            </Link>
                                        </span>
                                    </td>
                                    <td style={{maxWidth:'190px'}} className='pl-3 pr-3'>
                                        <Button
                                            size="sm"
                                            variant={"outline-danger"}
                                            className="fw-normal"
                                            disabled={true}
                                            color={"red"}>
                                            <MyImage src={ApplicationIcons.Icons.notAllocated} style={{width:'24px'}} />&nbsp;
                                            {borrowingOfItem.borrowedOnSmartDevice.deviceId}
                                        </Button>
                                    </td>
                                    <td style={{maxWidth:'160px'}} className='pl-3 pr-3'>
                                        <Button
                                            size="sm"
                                            variant={"outline-danger"}
                                            className="fw-normal"
                                            disabled={true}
                                            color={"red"}>
                                            <MyImage src={ApplicationIcons.Icons.userAvatar} style={{width:'24px'}} />&nbsp;
                                            {borrowingOfItem.borrower.firstname}
                                        </Button>
                                    </td>
                                    <td style={{maxWidth:'150px'}} className='pl-3 pr-3'>
                                        <span className="fw-normal">
                                            {parseDate(borrowingOfItem.borrowedAt, DD_MM_YYYY_HH_MM_SS)}
                                        </span>
                                    </td>
                                    <td style={{maxWidth:'180px'}} className='pl-3 pr-3'>
                                        <Button
                                            size="sm"
                                            className="fw-normal"
                                            disabled={true}
                                            style={{backgroundColor:(borrowingOfItem.borrowingOfItemStatus?borrowingOfItem.borrowingOfItemStatus.color:undefined)}}>
                                            {borrowingOfItem.borrowingOfItemStatus?label(borrowingOfItem.borrowingOfItemStatus.label):"-"}
                                        </Button>
                                    </td>
                                    <td style={{maxWidth:'190px'}} className='pl-3 pr-3'>
                                        {borrowingOfItem.returnedOnSmartDevice?(
                                            <Button
                                                size="sm"
                                                variant={"outline-info"}
                                                className="fw-normal"
                                                disabled={true}
                                                color={"info"}>
                                                <MyImage src={ApplicationIcons.Icons.allocated} style={{width:'24px'}} />&nbsp;
                                                {borrowingOfItem.returnedOnSmartDevice.deviceId}
                                            </Button>
                                        ):null}
                                    </td>
                                    <td style={{maxWidth:'150px'}} className='pl-3 pr-3'>
                                        {borrowingOfItem.returnedOnSmartDevice?(
                                            <span className="fw-normal">
                                                {parseDate(borrowingOfItem.returnedAt, DD_MM_YYYY_HH_MM_SS)}
                                            </span>
                                        ):null}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                    <PaginationTable
                        onSelect={(page) => {
                            this.goToPage(page)
                        }}
                        showingRow={borrowingOfItems.length}
                        currentPage={page}
                        pageCount={totalPage}
                        totalElement={totalElement}/>
                </Card>
            </React.Fragment>
        );
    }

}
BorrowingOfItemTable.propTypes = {
    show:PropTypes.bool.isRequired,
    borrowingOfItemStatus:PropTypes.any.isRequired,
    onSelect:PropTypes.func.isRequired,
    tabDataCallback:PropTypes.func,
}


export default BorrowingOfItemTable