import React from "react";
import BaseComponent from "../pages/base/BaseComponent";
import {Button, Card} from "@themesberg/react-bootstrap";
import Profile1 from "../assets/img/team/profile-picture-1.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage, faKey} from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import label from "na-utilities/src/label/Label"
import {imageSelector, isResponseOk} from "na-utilities/src/utils/Utilities";
import ImageCropper from "./ImageCropper";
import Endpoint from "na-utilities/src/api/Endpoint";
import axios from "axios";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import {getUser, storeData} from "../utils/StorageUtil";
import Global from "../utils/Global";
import ChangePasswordModal from "./ChangePasswordModal";

export default class ProfileCard extends BaseComponent{
    constructor(props) {
        super(props);
        this.state = {
            user:this.props.user?this.props.user:{},
            imageCropper:false,
            imageBlob:undefined,
            imageUrl:undefined,
            changePasswordModal:false,
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props!=prevProps){
            let user = this.props.user?this.props.user:{}
            this.setState({user})
        }
    }

    async updatePhoto(){
        let {user} = this.state
        let {onRefresh} = this.props
        let multipartFile = await new Promise(resolve => {
            axios({
                url: user.photoUrl,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                this.closeProgress()
                var file = response.data ;
                resolve(file)
            })
        })

        let form = new FormData()
        form.append("multipartFile", multipartFile)
        this.post(Endpoint.API.USER_UPLOAD_IMAGE, form, null, (res)=>{
            if(isResponseOk(res)){
                let userResponse = res.data
                user.photoUrl = userResponse.photoUrl
                let localUser = getUser()
                localUser.photoUrl  =  user.photoUrl
                storeData(Global.USER, localUser)
                if(onRefresh){
                    onRefresh(user)
                }
                this.setState({user})
            }
        }, true, true)
    }

    render() {
        let {user, imageCropper, imageUrl, imageBlob, changePasswordModal} = this.state
        let userStatus = user.userStatus
        return (
            <>
                <ChangePasswordModal
                    showProcessing={this.props.showProcessing}
                    closeProcessing={this.props.closeProcessing}
                    show={changePasswordModal}
                    onResult={() => {
                        this.setState({changePasswordModal:false}, () => {
                            setTimeout(()=>{
                                window.location.reload()
                            }, 2000)
                        })
                    }}
                    onClose={() => {
                    this.setState({changePasswordModal:false})
                }}/>
                <Card border="light" className="text-center p-0 mb-4">
                    <div className="profile-cover rounded-top bg-cyan" />
                    <Card.Body className="pb-5">
                        <Card.Img src={user.photoUrl?user.photoUrl:ApplicationIcons.Icons.userAvatar} alt={user.fullname}
                                  className="bg-white border-secondary user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4" />
                        <Card.Title>{user.fullname}</Card.Title>
                        <Card.Subtitle className="fw-normal">{user.userCode}</Card.Subtitle>
                        <Card.Text className="text-gray">{user.username}</Card.Text>
                        <Card.Text className="text-gray mb-4">{user.roleInformation}</Card.Text>

                        <Button
                            variant="primary"
                            size="sm"
                            className="me-2"
                            onClick={event => {
                                imageSelector((file)=>{
                                    imageUrl = file
                                    this.setState({imageUrl:imageUrl, imageCropper:true})
                                }).click()
                            }}>
                            <FontAwesomeIcon icon={faImage} className="me-1" />{label.ChangePhoto}
                        </Button>
                        <Button variant="danger" size="sm" onClick={event => {
                            this.setState({changePasswordModal:true})
                        }}>
                            <FontAwesomeIcon icon={faKey} className="me-1" />{label.ChangePassword}
                        </Button>
                    </Card.Body>
                    <ImageCropper
                        src={imageUrl}
                        show={imageCropper}
                        callback={(imageUrl) => {
                            user.photoUrl = imageUrl
                            this.setState({imageUrl:undefined, user, imageCropper:false},async () => {
                                await this.updatePhoto()
                            })
                        }}
                        cancelCallback={() => {
                            this.setState({imageCropper:false,imageUrl:undefined})
                        }}/>
                    {super.render()}
                </Card>
            </>
        );
    }

}
ProfileCard.propTypes = {
    user : PropTypes.any.isRequired,
    onRefresh: PropTypes.func.isRequired
}