import React from "react";
import {Button, Card, Col, Image, Modal, Row} from '@themesberg/react-bootstrap';
import BasePage from "./../base/BasePage";
import Endpoint from "na-utilities/src/api/Endpoint";
import {handleTimeAgo, isResponseOk, navigate} from "na-utilities/src/utils/Utilities";
import label from "na-utilities/src/label/Label";
import {currencyFormat} from "na-utilities/src/utils/Utilities";
import Global, {_CREATED_ID, _WAITING_FOR_PAYMENT_ID} from "../../utils/Global";
import OrderItemTable from "../../components/OrderItemTable";
import PaymentProofTable from "../../components/PaymentProofTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import {BASE_URL} from "../../Variable";
import {toasting} from "../base/BaseComponent";
import DashboardPage from "../dashboard/DashboardPage";
import ProductOrderStatusHistoryModal from "../../components/ProductOrderStatusHistoryModal";

// let paymentProofTable = React.createRef()

class OrderDetailPage extends BasePage{
  paymentProofTable = React.createRef()

  constructor(props) {
    super(props);
    let {reference} = props.match.params
    this.state = {
      reference:reference,
      productOrder:{},
      paymentAttribute:{},
      requestProcessConfirmationModal:false,
      productOrderStatusHistoryModal:false
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.props.closePreLoader()
    this.fetchProductOrder()
    this.fetchPaymentMediaAttribute()
  }

  fetchProductOrder(){
    let {reference,productOrder} = this.state
    this.get(Endpoint.API.PRODUCT_ORDER, {params:{
        "reference":reference
      }}, null, (res)=>{
      if(isResponseOk(res)){
        productOrder = res.data
        this.setState({productOrder})
      }else{
        navigate(this.props, DashboardPage.ENDPOINT)
      }
    }, true, true)
  }

  fetchPaymentMediaAttribute(){
    let {paymentAttribute} = this.state
    this.get(Endpoint.API.PAYMENT_MEDIA_ATTRIBUTE, {}, null, (res)=>{
      if(isResponseOk(res)){
        paymentAttribute = res.data
        this.setState({paymentAttribute})
      }
    }, true, true)
  }

  requestToProcess(){
    let {reference,productOrder} = this.state
    this.get(Endpoint.API.PRODUCT_ORDER_REQUEST_TO_PROCESS, {params:{
        "reference":reference
      }}, null, (res)=>{
      if(isResponseOk(res)){
        toasting.success(res.message)
        productOrder = res.data
        this.setState({productOrder,requestProcessConfirmationModal:false},() => {
          if(this.paymentProofTable){
            this.paymentProofTable.current.fetchPaymentProofs()
          }
        })
      }
    }, true, true)
  }

  confirmDialogRender(){
    let {requestProcessConfirmationModal} = this.state
    return(
        <Modal as={Modal.Dialog} centered show={requestProcessConfirmationModal} onHide={()=>{}}>
          <Modal.Header>
            <Modal.Title className="h6">{label.Confirmation}</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={()=>{}} />
          </Modal.Header>
          <Modal.Body className="text-center">
            <Row>
              <Col className="text-center">
                <img src={ApplicationIcons.Icons.warningAttention}/>
              </Col>
            </Row>
            <br/>
            <h4>{label.RequestToProcessTitle}</h4>
            <p>{label.RequestToProcessDescription}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
                variant="outline-warning"
                className="text-gray ms-auto"
                onClick={()=>{
                  this.setState({requestProcessConfirmationModal:false})
                }}>
              {label.Close}
            </Button>
            <Button
                variant="success"
                onClick={()=>{
                  this.requestToProcess()
                }}>
              {label.Continue}
            </Button>
          </Modal.Footer>
        </Modal>
    )
  }



  render(){
    let {reference, productOrder,paymentAttribute,requestProcessConfirmationModal,productOrderStatusHistoryModal} = this.state
    let productOrderStatus = productOrder.productOrderStatus?productOrder.productOrderStatus:{}
    return (
        <>
          {super.render()}
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
          </div>
          <Row>
            <Col xs={12} xl={8}>
              <Row>
                <Col md={12} xs={12} xl={12}>
                  <OrderItemTable productOrder={productOrder}/>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={12} xl={12}>
                  <PaymentProofTable
                      productOrder={productOrder}
                      {...this.props}
                      ref={this.paymentProofTable}/>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={12} xl={12}>
                  <Card border="light" className="bg-white shadow-sm mb-4">
                    <Card.Header>
                      {label.PaymentGuideline}
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="text-center mb-2">
                          <h5>{label.TransferAsPaymentMethod}</h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center mb-4">
                          <h5>{paymentAttribute.bankName}</h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center mb-4">
                          <Card.Img style={{width:'20%'}} src={paymentAttribute.imageLink}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center">
                          <h5>{label.BankAccountNumber}</h5>
                          <h2><span className="text-info font-weight-bold"><b>{paymentAttribute.accountNumber}</b></span></h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {label.PaymentGuidelineDescription}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12}>
                  <Card border="light" className="bg-white shadow-sm mb-4">
                    <Card.Header>
                      <Row>
                        <Col>
                          {label.Status}
                        </Col>
                        <Col className="text-right p-0">
                          <Button
                              variant="outline-warning"
                              size="sm"
                              style={{width:'32px',height:'32px'}}
                              className="rounded-circle"
                              onClick={(event) => {
                                event.preventDefault()
                                this.setState({productOrderStatusHistoryModal:true})
                              }}>
                            <FontAwesomeIcon icon={faInfo} />
                          </Button>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-xl-flex align-items-center">
                        <div className="user-avatar xl-avatar">
                          <Image fluid rounded src={Global.STATUS_ICONS[productOrderStatus.icon]} />
                        </div>
                        <div className="file-field">
                          <div className="d-flex justify-content-xl-center ms-xl-3">
                            <div className="d-flex">
                              <div className="d-md-block text-start">
                                <div className="fw-normal font-weight-bold mb-1 rounded px-2 text-white" style={{backgroundColor:productOrderStatus.color}}>{productOrderStatus.label}</div>
                                <div className="text-gray small">{label.UpdatedAt} {handleTimeAgo(productOrder.updated)}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer className="border-warning p-2">
                      <span className="text-info" style={{fontSize:'12px', fontStyle:'italic', fontWeight:'bold'}}>{productOrder.currentNote}</span>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col xs={12}>
                  <Card border="light" className="text-center p-0 mb-4">
                    <Card.Header>
                      <Card.Title>{label.SummaryOfOrder}</Card.Title>
                    </Card.Header>
                    <Card.Body className="pb-5">
                      <Row className="pt-0 pl-3 pr-3">
                        <Col className="text-left">
                          {label.Amount}
                        </Col>
                        <Col className="text-right">
                          {currencyFormat(productOrder.amountPrice, 'Rp')}
                        </Col>
                      </Row>
                      <Row className="pt-3 pl-3 pr-3">
                        <Col className="text-left">
                          {label.AdminFee}
                        </Col>
                        <Col className="text-right">
                          {currencyFormat(productOrder.totalAdminFee, 'Rp')}
                        </Col>
                      </Row>
                      <Row className="pt-3 pl-3 pr-3 pb-0">
                        <Col className="text-left">
                          {label.Tax}&nbsp;-&nbsp;{productOrder.taxPercent}%
                        </Col>
                        <Col className="text-right">
                          {currencyFormat(productOrder.taxAmount, 'Rp')}
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer>
                      <Row className="pt-3 pl-3 pr-3">
                        <Col className="text-left">
                          {label.TotalAmount}
                        </Col>
                        <Col className="text-right">
                          <h4>{currencyFormat(productOrder.totalProductOrderAmount, 'Rp')}</h4>
                        </Col>
                      </Row>
                      <Row className="pt-3 pl-3 pr-3">
                        <Col md={12} className="text-center">
                          <Button
                              variant="outline-info"
                              size="lg"
                              className="btn-block"
                              onClick={event => {
                                event.preventDefault()
                                window.open(BASE_URL+"/invoice/generate/"+productOrder.reference,'_blank');
                              }}>
                            {label.SeeInvoice}
                          </Button>
                        </Col>
                      </Row>
                      {
                        (productOrderStatus.id===_WAITING_FOR_PAYMENT_ID || productOrderStatus.id===_CREATED_ID)?(
                            <Row className="pt-3 pl-3 pr-3">
                              <Col md={12} className="text-center">
                                <Button
                                    variant="secondary"
                                    size="lg"
                                    className="btn-block"
                                    onClick={event => {
                                      event.preventDefault()
                                      let paymentProofs = this.paymentProofTable.current.getPaymentProofs();
                                      if(!paymentProofs){
                                        paymentProofs = []
                                      }
                                      if(paymentProofs.length<=0){
                                        this.openWarningDialog(label.UploadPaymentReceipt, label.PaymentReceiptNeededToRequestProcessing,
                                            ()=>{
                                              this.setState({requestProcessConfirmationModal:true})
                                            });
                                        return ;
                                      }
                                      this.setState({requestProcessConfirmationModal:true})
                                    }}
                                >
                                  {label.RequestProcess}
                                </Button>
                              </Col>
                            </Row>
                        ):(null)
                      }
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <ProductOrderStatusHistoryModal
              productOrder={productOrder}
              onClose={() => {
                this.setState({productOrderStatusHistoryModal:false})
              }}
              show={productOrderStatusHistoryModal}/>
          {this.confirmDialogRender()}
        </>
    );
  };
}
export default Object.assign(OrderDetailPage, {ENDPOINT : "/orderDetail"})
