import React from "react";
import {faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {Button, Card, Col, Container, Form, FormCheck, Image, Row} from '@themesberg/react-bootstrap';
import SmartSuppliesImage from "../../assets/img/smart-supplies.png";
import BasePage from "../base/BasePage";
import label from 'na-utilities/src/label/Label'
import {PROFILE} from "../../Variable";
import {cleanSpace, isResponseOk, navigate} from "na-utilities/src/utils/Utilities";
import ForgotPasswordPage from "./ForgotPasswordPage";
import Global from "../../utils/Global";
import {toasting} from "../base/BaseComponent";
import TextInput from "../../components/input/TextInput";
import PasswordInput from "../../components/input/PasswordInput";
import {removeData, storeData} from "../../utils/StorageUtil";
import Endpoint from "na-utilities/src/api/Endpoint";
import ResetPasswordPage from "./ResetPasswordPage";

class SigninPage extends BasePage{

  constructor(props) {
    super(props);
    this.state = {
      username : "",
      password:"",
      rememberMe : false,
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.props.closePreLoader()
  }

  login(){
    let {username, password, rememberMe} = this.state
    let form = new FormData()
    form.append("usernameOrEmail", username)
    form.append("password", password)
    form.append("rememberMe", rememberMe)
    // let res = await this.asyncPost(Endpoint.API.AUTH, form, null, true, true);
    this.post(Endpoint.API.AUTH, form, null, async (response)=>{
      if(response && isResponseOk(response)){
        let user = response.data
        if(user.shouldChangePassword){
          let form = new FormData()
          form.append("userId", user.id)
          let res = await this.asyncPost(Endpoint.API.USER_FETCH_RESET_PASSWORD, form, null, true, true);
          if(isResponseOk(res)){
            let resetPassword = res.data
            window.location.href = ResetPasswordPage.ENDPOINT+"/"+resetPassword.token
            return
          }
        }
        storeData(Global.AUTHORIZATION, response.data.authorization)
        let res = await this.asyncGet(Endpoint.API.USER_ROLE_MENUS, {}, null, true, true);
        if(isResponseOk(res)){
          storeData(Global.MENUS, res.data)
          storeData(Global.USER, user)
          window.location.reload()
        }else{
          removeData(Global.AUTHORIZATION)
        }
      }else {
        if(response){
          toasting.error(response.message)
        }else{
          toasting.error(label.SomethingUnexpected)
        }
      }
    }, true,false)
  }

  render(){
    let {username, password, rememberMe} = this.state
    let disableSubmit = !(username.length>4 && password.length>2)
    return (
        <>
          {super.render()}
          <main>
            <section className="d-flex align-items-center my-5 mt-lg-5 mb-lg-5">
              <Container>
                <Row className="justify-content-center form-bg-image">
                  <Col xs={12} className="d-flex align-items-center justify-content-center">
                    <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-4 w-100 fmxw-500">
                      <div className="text-center text-md-center mb-4 mt-md-0">
                        <Image className="w-75" src={SmartSuppliesImage}/>
                        <h3 className="mb-0">{label.SignInToApplication}</h3>
                      </div>
                      <Form className="mt-2">
                        <TextInput
                            label={label.UsernameOrEmail}
                            required={false}
                            placeholder={label.EnterUsernameMail}
                            value={username}
                            onChange={(value) => {
                              value = cleanSpace(value)
                              this.setState({username:value})
                            }}
                        />
                        <Form.Group>
                          <PasswordInput
                              label={label.Password}
                              icon={faUnlockAlt}
                              placeholder={label.EnterConfirmPassword}
                              value={password}
                              masking={true}
                              onChange={(value) => {
                                password = cleanSpace(value)
                                this.setState({password})
                              }}/>
                        </Form.Group>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <Form.Check type="checkbox">
                            <FormCheck.Input
                                id="defaultCheck5"
                                className="me-2" style={{marginLeft:0}}
                                checked={rememberMe}
                                onClick={()=>{
                                  rememberMe = rememberMe===true?false:true
                                  this.setState({rememberMe})
                                }}/>
                            <FormCheck.Label htmlFor="defaultCheck5" className="mb-0 mx-4">&nbsp;{label.KeepLoggedIn}</FormCheck.Label>
                          </Form.Check>
                          <Card.Link onClick={()=>{
                            navigate(this.props, ForgotPasswordPage.ENDPOINT)
                          }} className="small text-end">{label.ForgetPassword}</Card.Link>
                        </div>
                        <Button
                            variant="primary"
                            className="w-100"
                            onClick={async (event) => {
                              await this.login()
                            }}
                            disabled={disableSubmit}>
                          {label.SignIn}
                        </Button>
                      </Form>
                      <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    {label.DoesntHaveAnAccount}
                    <Card.Link className="fw-bold" onClick={()=>navigate(this.props, PROFILE)}>
                      &nbsp;{label.RegisterNow}
                    </Card.Link>
                  </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </main>
        </>
    );
  }
}
export const SIGNIN_ENDPOINT = "/auth/sign-in"
export default Object.assign(SigninPage, {ENDPOINT : SIGNIN_ENDPOINT})
