import React from "react";
import {Card, Nav, Pagination} from "@themesberg/react-bootstrap";
import PropTypes from 'prop-types';
import Global from '../utils/Global'
import label from 'na-utilities/src/label/Label'

export default class PaginationTable extends React.Component{

    state = {}
    constructor(props) {
        super(props);
        let currentPage = this.props.currentPage ? this.props.currentPage : 1
        this.state = {
            totalElement:this.props.totalElement?this.props.totalElement:0,
            pageCount : this.props.pageCount?this.props.pageCount:0,
            onSelect : this.props.onSelect,
            currentPage : currentPage,
            currentPaging : currentPage > Global.LIMIT_PAGING_BUTTON ? (parseInt(parseInt(currentPage) / Global.LIMIT_PAGING_BUTTON) + 1) : 1
        }
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            let currentPage = props.currentPage?props.currentPage:1
            this.setState({
                totalElement : props.totalElement?props.totalElement:0,
                pageCount : props.pageCount?props.pageCount:0,
                onSelect : props.onSelect,
                currentPage : currentPage,
                currentPaging : currentPage>Global.LIMIT_PAGING_BUTTON?(parseInt(parseInt(currentPage)/Global.LIMIT_PAGING_BUTTON)+1):1
            }, () => {
                // alert(this.state.currentPage)
            })
        }
    }
    render() {
        let pageCount = this.state.pageCount
        let totalElement = this.state.totalElement
        let currentPage = this.state.currentPage
        let onSelect = this.state.onSelect
        let pages = new Array(pageCount)
        let currentPaging = this.state.currentPaging
        for (var i = 0; i < pageCount; i++) {
            pages[i] = i + 1
        }

        let showingRow = this.props.showingRow?this.props.showingRow:0

        return (
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                <Nav>
                    <Pagination className="mb-2 mb-lg-0" size="sm">
                        {
                            currentPage>1&&(
                                <Pagination.Prev
                                    onClick={event => {
                                        event.preventDefault()
                                        let currentPaging = this.state.currentPaging
                                        if((currentPaging*Global.LIMIT_PAGING_BUTTON)>parseInt(currentPage)-1){
                                            currentPaging = currentPaging-1
                                        }
                                        this.setState({
                                            currentPage : parseInt(currentPage)-1,
                                            currentPaging :currentPaging
                                        }, () => {
                                            if(onSelect){
                                                onSelect(this.state.currentPage)
                                            }
                                        })
                                    }}>
                                    {'<'}
                                </Pagination.Prev>
                            )
                        }
                        {
                            (currentPaging>1)&&(
                                <Pagination.Prev
                                    onClick={event => {
                                        event.preventDefault()
                                        this.setState({
                                            currentPaging:this.state.currentPaging-1
                                        })
                                    }}>
                                    {'<<'}
                                </Pagination.Prev>
                            )
                        }
                        {
                            pages.map((item, index)=>(
                                ((Global.LIMIT_PAGING_BUTTON*(currentPaging))>index+1 && (Global.LIMIT_PAGING_BUTTON*(currentPaging-1))<=index+1)&&(
                                    <>
                                    <Pagination.Item
                                        key={index}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            if(item!=currentPage) {
                                                this.setState({
                                                    currentPage: item
                                                }, () => {
                                                    if(onSelect){
                                                        onSelect(this.state.currentPage)
                                                    }
                                                })
                                            }
                                        }}
                                        active={currentPage==index+1}>
                                        {item}
                                    </Pagination.Item>
                                    &nbsp;
                                    </>
                                )
                            ))
                        }
                        {
                            (pages.length>(Global.LIMIT_PAGING_BUTTON*currentPaging))&&(
                                <Pagination.Next
                                    onClick={event => {
                                        event.preventDefault()
                                        this.setState({
                                            currentPaging:this.state.currentPaging+1
                                        })
                                    }}>
                                    {'>>'}
                                </Pagination.Next>
                            )
                        }
                        {
                            (currentPage<pageCount)&&(
                                <Pagination.Next
                                    onClick={event => {
                                        event.preventDefault()
                                        let currentPaging = this.state.currentPaging
                                        if((currentPaging*Global.LIMIT_PAGING_BUTTON)<parseInt(currentPage)+1){
                                            currentPaging = currentPaging+1
                                        }
                                        this.setState({
                                            currentPage : parseInt(currentPage)+1,
                                            currentPaging :currentPaging
                                        }, () => {
                                            if(onSelect){
                                                onSelect(this.state.currentPage)
                                            }
                                        })
                                    }}>
                                    {'>'}
                                </Pagination.Next>
                            )
                        }
                    </Pagination>
                </Nav>
                <small className="fw-bold">
                    {label.Showing} <b>{showingRow}</b> {label.OutOf} <b>{totalElement}</b> {totalElement>1?label.Entries:label.Entry}
                </small>
            </Card.Footer>
        );
    }
}
PaginationTable.propTypes = {
    onSelect : PropTypes.func.isRequired,
    pageCount : PropTypes.number.isRequired,
    currentPage : PropTypes.number.isRequired,
    showingRow: PropTypes.number,
    totalElement:PropTypes.number.isRequired
}