import React from "react"
import {Form} from "@themesberg/react-bootstrap";
import PropTypes from "prop-types";

class WarningLabel extends React.Component{

    render() {
        let {show, message, placeholder} = this.props
        return (
            <div>
                {show&&(
                    <Form.Control.Feedback type="invalid" style={{display:'unset', position:'absolute'}}>{message?message:placeholder}</Form.Control.Feedback>
                )}
            </div>
        );
    }
}
WarningLabel.propTypes = {
    placeholder:PropTypes.string,
    show:PropTypes.bool,
    message:PropTypes.string,
}
export default WarningLabel