import React, {useEffect, useState} from "react";
import {Route, useHistory} from "react-router-dom";
import Preloader from "../components/Preloader";
import {AuthConsumer} from "../context/AuthContext";
import PreProcess from "../components/PreProcess";

const PublicRoute = ({component: Component, ...rest}) => {
    const [loaded, setLoaded] = useState(false);
    const [processing, setProcessing] = useState(false);

    // useEffect(() => {
    // const timer = setTimeout(() => setLoaded(true), 1000);
    // return () => clearTimeout(timer);
    // }, []);

    const openPreLoader = (function openPreLoader(){
        setLoaded(false)
    })
    const closePreLoader = (function closePreLoader(){
        setTimeout(()=>setLoaded(true), 1000)
    })

    const showProcessing = (function openProcessing(){
        setProcessing(true)
    })
    const closeProcessing = (function closeProcessing(){
        setTimeout(()=>setProcessing(false), 1000)
    })

    let history = useHistory()

    return (
        <AuthConsumer>
            {({ isAuth, user }) => (
                <Route
                    {...rest}
                    render={
                        props => (
                            <>
                                <PreProcess show={processing}/>
                                <Preloader show={loaded ? false : true}/>
                                <Component
                                    {...props}
                                    openPreLoader={openPreLoader}
                                    closePreLoader={closePreLoader}
                                    showProcessing={showProcessing}
                                    closeProcessing={closeProcessing}
                                />
                            </>
                        )}/>
            )}
       </AuthConsumer>
    );
};
export default PublicRoute