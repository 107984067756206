import React from "react";

import kitchen from "../../utils/AxiosInstance";
import Global, {SC_UNAUTHORIZED} from "../../utils/Global";
import {getData, getUser, removeData} from "../../utils/StorageUtil";
import axios from 'axios'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Endpoint from "na-utilities/src/api/Endpoint";
import {isResponseOk} from "na-utilities/src/utils/Utilities";
import {Button, Modal} from "@themesberg/react-bootstrap";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import {Fullscreen, Zoom} from "yet-another-react-lightbox/dist/plugins";
import label from "na-utilities/src/label/Label";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import ImageCropper from "../../components/ImageCropper";

const SIGNIN_ENDPOINT = "/auth/sign-in"

export const toasting = {
    error:(function (message) {
        toast(message, {
            position: "top-right",
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }),
    info:(function (message) {
        toast(message, {
            position: "top-right",
            type: 'info',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark",
        });
    }),
    success:(function (message) {
        toast(message, {
            position: "top-right",
            type: 'success',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark",
        });
    })
}

export default class BaseComponent extends React.Component{

    state = {}

    constructor(props) {
        super(props);
        this.state = {
            user:{},
            axiosRun:false,
            failed:false,
            message:undefined,
            provinces : [],
            cities : [],
            districts : [],
            villages : [],
            progressShow : false,
            lightBoxOpen:false,
            lightBoxSlides:[],
            confirmModal:false,
            confirmTitle:false,
            confirmMessage:false,
            confirmOk:undefined,
            confirmCancel:undefined,
            warningModal:false,
            warningTitle:false,
            warningMessage:false,
            warningOk:undefined,
            warningCancel:undefined,
            logoutModal:false,
            logoutOk:undefined,
            logoutCancel:undefined,
            imageToCrop:undefined,
            imageCropperModal:false,
            cropperCallback:undefined,
            cancelCallback:undefined
        }
    }

    componentDidMount() {
        let user = getUser();
        this.setState({user})
    }

    openLightBox(...src){
        let {lightBoxOpen, lightBoxSlides} = this.state
        lightBoxSlides = []
        src.forEach(value => {
            lightBoxSlides.push({src:value})
        })
        this.setState({
            lightBoxOpen: true,
            lightBoxSlides: lightBoxSlides
        })
    }
    closeLightBox(){
        let {lightBoxOpen,lightBoxSlides} = this.state
        this.setState({
            lightBoxOpen: false,
            lightBoxSlides: []
        })
    }

    openConfirmDialog(confirmTitle,confirmMessage,confirmOk,confirmCancel){
        this.setState({confirmModal:true, confirmTitle,confirmMessage,confirmOk,confirmCancel})
    }

    closeConfirmDialog(){
        this.setState({confirmModal:false, confirmTitle:undefined,confirmMessage:undefined,confirmOk:undefined,confirmCancel:undefined})
    }

    openWarningDialog(warningTitle,warningMessage,warningOk,warningCancel){
        this.setState({warningModal:true, warningTitle,warningMessage,warningOk,warningCancel})
    }

    closeWarningDialog(){
        this.setState({warningModal:false, warningTitle:undefined,warningMessage:undefined,warningOk:undefined,warningCancel:undefined})
    }

    openLogoutDialog(logoutOk,logoutCancel){
        this.setState({logoutModal:true, logoutOk,logoutCancel})
    }

    closeLogoutDialog(){
        this.setState({logoutModal:false, logoutOk:undefined,logoutCancel:undefined})
    }

    openCropper(imageToCrop,cropperCallback,cancelCallback){
        this.setState({
            imageCropperModal:true, imageToCrop,cropperCallback,cancelCallback
        })
    }
    closeCropper(){
        this.setState({
            imageCropperModal:false, imageToCrop:undefined,cropperCallback:undefined,cancelCallback:undefined
        })
    }


    render() {
        let {lightBoxOpen,lightBoxSlides,
            confirmModal,confirmTitle,confirmMessage,confirmOk,confirmCancel,
            warningModal,warningTitle,warningMessage,warningOk,warningCancel,
            logoutModal, logoutOk, logoutCancel,
            imageCropperModal, imageToCrop, cropperCallback, cancelCallback
        } = this.state
        if(!lightBoxSlides){
            lightBoxSlides = []
        }

        return (
            <>
                <ToastContainer />
                <ImageCropper src={imageToCrop} show={imageCropperModal} callback={cropperCallback} cancelCallback={cancelCallback}/>
                <React.Fragment>
                    <Modal as={Modal.Dialog} centered show={false} onHide={()=>{}}>
                        <Modal.Header>
                            <Modal.Title className="h6">Terms of Service</Modal.Title>
                            <Button variant="close" aria-label="Close" onClick={()=>{}} />
                        </Modal.Header>
                        <Modal.Body>
                            <p>With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.</p>
                            <p>The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>{}}>
                                I Got It
                            </Button>
                            <Button variant="link" className="text-gray ms-auto" onClick={()=>{}}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Lightbox
                        open={lightBoxOpen}
                        close={() => this.setState({lightBoxOpen:false})}
                        plugins={[Zoom,Fullscreen]}
                        slides={lightBoxSlides}
                    />
                </React.Fragment>
                <React.Fragment>
                    <Modal as={Modal.Dialog} centered show={confirmModal} onHide={()=>{}}>
                        <Modal.Header style={{display:"block",textAlign:'center'}}>
                            <h4>{confirmTitle}</h4>
                        </Modal.Header>
                        <Modal.Body style={{display:"block",textAlign:'center'}} className="m-4">
                            <img style={{width:'50%'}} src={ApplicationIcons.Icons.warningInfo}></img>
                        </Modal.Body>
                        <Modal.Body style={{display:"block",textAlign:'center'}}>
                            <p>{confirmMessage}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button size="sm" variant="cancel" onClick={event => {
                                if(confirmCancel){
                                    confirmCancel()
                                }
                                this.closeConfirmDialog()
                            }}>
                                {label.Cancel}
                            </Button>
                            <Button size="sm" variant="success" className="ms-auto"
                                    onClick={()=>{
                                        if(confirmOk){
                                            confirmOk()
                                        }
                                        this.closeConfirmDialog()
                                    }}>
                                {label.Continue}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </React.Fragment>
                <React.Fragment>
                    <Modal as={Modal.Dialog} centered show={warningModal} onHide={()=>{}}>
                        <Modal.Header style={{display:"block",textAlign:'center'}}>
                            <h4>{warningTitle}</h4>
                        </Modal.Header>
                        <Modal.Body style={{display:"block",textAlign:'center'}}>
                            <img style={{width:'50%'}} src={ApplicationIcons.Icons.warningAttention}></img>
                        </Modal.Body>
                        <Modal.Body style={{display:"block",textAlign:'center'}}>
                            <p className="text-danger bold">{warningMessage}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button size="sm" variant="success" onClick={event => {
                                if(warningCancel){
                                    warningCancel()
                                }
                                this.closeWarningDialog()
                            }}>
                                <FontAwesomeIcon icon={faCheck} className="me-2" />  {label.Cancel}
                            </Button>
                            <Button size="sm" variant="danger" className="ms-auto"
                                    onClick={()=>{
                                        if(warningOk){
                                            warningOk()
                                        }
                                        this.closeWarningDialog()
                                    }}>
                                {label.Continue}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </React.Fragment>
                <React.Fragment>
                    <Modal as={Modal.Dialog} centered show={logoutModal} onHide={()=>{}}>
                        <Modal.Header style={{display:"block",textAlign:'center'}}>
                            <h4>{label.LogoutConfirmation}</h4>
                        </Modal.Header>
                        <Modal.Body style={{display:"block",textAlign:'center'}}>
                            <img style={{width:'50%'}} src={ApplicationIcons.Icons.signout}></img>
                        </Modal.Body>
                        <Modal.Body style={{display:"block",textAlign:'center'}}>
                            <h5 className="text-danger bold">{label.DoYouWantToLogoutFormThiApplication}</h5>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button size="sm" variant="success" onClick={event => {
                                if(logoutCancel){
                                    logoutCancel()
                                }
                                this.closeLogoutDialog()
                            }}>
                                <FontAwesomeIcon icon={faCheck} className="me-2" />  {label.Cancel}
                            </Button>
                            <Button size="sm" variant="danger" className="ms-auto"
                                    onClick={()=>{
                                        if(logoutOk){
                                            logoutOk()
                                        }
                                        this.closeLogoutDialog()
                                    }}>
                                {label.Continue}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </React.Fragment>

            </>
        );
    }

    showProgress(){
        let {showProcessing} = this.props
        if(showProcessing){
            showProcessing()
        }
    }
    closeProgress(){
        let {closeProcessing} = this.props
        if(closeProcessing){
            closeProcessing()
        }
    }

    fetchIndustries = (callback) => {
        let {industries} = this.state
        if(industries && industries.length>0){
            callback(industries)
        }
        this.get(Endpoint.API.INDUSTRIES, null, null, response=>{
            if(response.code===200){
                industries = response.data
                this.setState({
                    industries:industries
                }, () => {
                    if(callback){
                        callback(industries)
                    }
                })
            }
        }, false, false)
    }

    fetchCompanyTypes = (callback) => {
        let {companyTypes} = this.state
        if(companyTypes && companyTypes.length>0){
            callback(companyTypes)
        }
        this.get(Endpoint.API.COMPANY_TYPES, null, null, response=>{
            if(response.code===200){
                companyTypes = response.data
                this.setState({
                    companyTypes:companyTypes
                }, () => {
                    if(callback){
                        callback(companyTypes)
                    }
                })
            }
        }, false, false)
    }

    fetchGoodsCategories = (callback) => {
        if(this.state.provinces!=null && this.state.provinces.length>0){
            callback(this.state.provinces)
        }
        this.get(Endpoint.API.GOODS_CATEGORIES,
                {
                    params:{
                        ascending:true,
                        sortir:"c.id"
                    }
                }, null, response=>{
            if(response.code===200){
                let goodsCategories = response.data
                if(callback){
                    callback(goodsCategories)
                }
            }
        }, false, false)
    }


    fetchProvinces = (callback) => {
        if(this.state.provinces!=null && this.state.provinces.length>0){
            callback(this.state.provinces)
        }
        this.get(Endpoint.API.PROVINCES, null, null, response=>{
            if(response.code===200){
                this.setState({
                    provinces:response.data
                }, () => {
                    if(callback){
                        callback(this.state.provinces)
                    }
                })
            }
        }, false, false)
    }

    fetchCities = (provinceId, callback) => {
        this.get(Endpoint.API.CITIES, {
            params:{
                provinceId:provinceId
            }
        }, null, response=>{
            if(response.code===200){
                this.setState({
                    cities:response.data
                }, () => {
                    if(callback){
                        callback(this.state.cities)
                    }
                })
            }
        }, false, false)
    }

    fetchDistricts = (cityId, callback) => {
        this.get(Endpoint.API.DISTRICTS, {
            params:{
                cityId:cityId
            }
        }, null, response=>{
            if(response.code===200){
                this.setState({
                    districts:response.data
                }, () => {
                    if(callback){
                        callback(this.state.districts)
                    }
                })
            }
        }, false, false)
    }

    fetchVillages = (districtId, callback) => {
        this.get(Endpoint.API.VILLAGES, {
            params:{
                districtId:districtId
            }
        }, null, response=>{
            if(response.code===200){
                this.setState({
                    villages:response.data
                }, () => {
                    if(callback){
                        callback(this.state.villages)
                    }
                })
            }
        }, false, false)
    }

    fetchProducts(callback){
        this.get(Endpoint.API.PRODUCTS, null, null, (res)=>{
            if(isResponseOk(res)){
                callback(res.data)
            }
        }, false, false)
    }



    generateUsername(email,progressing, callback){
        this.get(Endpoint.API.USER_GENERATE_USERNAME, {params:{email:email}}, null, response=>{
            if(response.code===200){
                let username = response.data
                callback(username)
            }
        }, progressing, true)
    }

    async validateUsername(email, username, progressing){
        let response = await this.asyncGet(Endpoint.API.USER_VALIDATE_USERNAME, {params:{email:email, username:username}}, null, progressing, true)
        if(response && response.code===200){
            let valid = response.data
            return valid ;
        }

        // return false ;
    }

    async validateMobilePhone(email, mobilePhone, progressing){
        let response = await this.asyncGet(Endpoint.API.USER_VALIDATE_MOBILE_PHONE, {params:{email:email, mobilePhone:mobilePhone}}, null, progressing, true)
        if(response && response.code===200){
            let valid = response.data
            return valid ;
        }
        // return false ;
    }
    async validatePhoneNumber(email, phoneNumber, progressing){
        let response = await this.asyncGet(Endpoint.API.USER_VALIDATE_PHONE_NUMBER, {params:{email:email, phoneNumber:phoneNumber}}, null, progressing, true)
        if(response && response.code===200){
            let valid = response.data
            return valid ;
        }
        // return false ;
    }

    async validateCompanyName(email, fullName, progressing){
        let response = await this.asyncGet(Endpoint.API.COMPANY_VALIDATE_COMPANY_NAME, {params:{email:email, fullName:fullName}}, null, progressing, true)
        if(response && response.code===200){
            let valid = response.data
            return valid ;
        }
        // return false ;
    }

    async validateWebsite(email, website, progressing){
        let response = await this.asyncGet(Endpoint.API.COMPANY_VALIDATE_WEBSITE, {params:{email:email, website:website}}, null, progressing, true)
        if(response && response.code===200){
            let valid = response.data
            return valid ;
        }
        // return false ;
    }

    async validateCompanyPhoneNumber(email, companyPhoneNumber, progressing){
        let response = await this.asyncGet(Endpoint.API.COMPANY_VALIDATE_COMPANY_PHONE_NUMBER, {params:{email:email, companyPhoneNumber:companyPhoneNumber}}, null, progressing, true)
        if(response && response.code===200){
            let valid = response.data
            return valid ;
        }
        // return false ;
    }


    post(url, params, config, callback, progressing, alerting){
        // let [axiosRun, setAxiosRun] = useState(true)
        let {axiosRun} = this.state
        console.log("AXIOS RUN "+axiosRun)
        // alert("TES")

        if (axiosRun) {
            let component = this
            setTimeout(function(){
                component.post(url, params, config, callback, progressing, alerting)
            },50);
        } else {
            this.setState({axiosRun: true})
            // this.setState({axiosRun : true})
            if(progressing){
                this.showProgress()
            }
            console.log("MASUK")
            kitchen.post(url, params, config).then((res)=>{
                console.log(res)
                this.setState({axiosRun: false})
                let component = this
                setTimeout(function () {
                    if(progressing && !component.state.axiosRun){
                        component.closeProgress()
                    }
                    component.setState({
                        loading:false
                    }, () => {
                        if(res.data.code===200){
                            callback(res.data)
                        }else{
                            if(alerting){
                                toasting.error(res.data.message);
                                component.setState({
                                    failed:true,
                                    message:res.data.message
                                }, () => {
                                    callback(res.data)
                                })
                            }else{
                                callback(res.data)
                            }
                        }
                    })
                }, 100)
            }).catch(e=>{
                console.error(e)
                this.setState({axiosRun: false})
                let component = this
                if(progressing){
                    this.closeProgress()
                }
                if(alerting){
                    toasting.error(e.message);
                }
                if(e.response && (e.response.status===SC_UNAUTHORIZED)){
                    removeData(Global.USER)
                    removeData(Global.AUTHORIZATION)
                    window.location.href = SIGNIN_ENDPOINT+"?message="+e.response.data.message
                }else {
                    setTimeout(function () {
                        try {
                            component.closeProgress()
                            component.setState({
                                loading: false,
                                failed: true,
                                message: e.message
                            }, () => {
                            })
                        } catch (e) {

                        }
                    }, 100)
                }
            });
        }
    }

    get(url, params, config, callback, progressing, alerting){
        // let [axiosRun, setAxiosRun] = useState(true)
        let {axiosRun} = this.state

        if (axiosRun) {
            let component = this
            setTimeout(function(){
                component.get(url, params, config, callback, progressing, alerting)
            },50);
        } else {
            this.setState({axiosRun: true})
            if(progressing){
                this.showProgress()
            }
            kitchen.get(url, params, null).then(res=>{
                this.setState({axiosRun: false})
                let component = this
                setTimeout(function () {
                    if(progressing && !component.state.axiosRun){
                        component.closeProgress()
                    }
                    component.setState({
                        loading:false
                    }, () => {
                        if(res.data.code===200){
                            callback(res.data)
                        }else{
                            if(alerting){
                                toasting.error(res.data.message);
                                component.setState({
                                    failed:true,
                                    message:res.data.message
                                }, () => {
                                    callback(res.data)
                                })
                            }else{
                                callback(res.data)
                            }
                        }
                    })
                }, 100)
            }).catch(e=>{
                this.setState({axiosRun: false})
                let component = this
                if(progressing){
                    this.closeProgress()
                }
                if(alerting){
                    toasting.error(e.message);
                }
                if(e.response && (e.response.status===SC_UNAUTHORIZED)){
                    removeData(Global.USER)
                    removeData(Global.AUTHORIZATION)
                    window.location.href = SIGNIN_ENDPOINT+"?message="+e.response.data.message
                }else {
                    setTimeout(function () {
                        try {
                            component.closeProgress()
                            component.setState({
                                loading: false,
                                failed: true,
                                message: e.message
                            }, () => {
                            })
                        } catch (e) {

                        }
                    }, 100)
                }
            });
        }
    }

    async asyncPost(url, params, config, progressing, alerting){
        // let [failed, setFailed] = useState(true)
        // let [message, setMessage] = useState(true)

        if(progressing){
            await this.showProgress()
        }

        try{
            let res = await kitchen.post(url, params, config);
            if(progressing){
                await this.closeProgress()
            }

            if(res.data.code===200){
                return res.data
            }else{
                if(alerting){
                    toasting.error(res.data.message);
                    this.setState({failed: true, message:res.data.message})
                }
                return res.data
            }
        }catch (e) {
            if(progressing){
                this.closeProgress()
            }
            if(alerting){
                toasting.error(e.message);
            }
            if(e.response && (e.response.status===SC_UNAUTHORIZED)){
                removeData(Global.USER)
                removeData(Global.AUTHORIZATION)
                window.location.href = SIGNIN_ENDPOINT+"?message="+e.response.data.message
            }
        }

    }

    async asyncGet(url, params, config, progressing, alerting){
        // let [failed, setFailed] = useState(true)
        // let [message, setMessage] = useState(true)

        if(progressing){
            await this.showProgress()
        }
        try{
            let res = await kitchen.get(url, params);
            if(progressing){
                await this.closeProgress()
            }

            if(res.data.code===200){
                return res.data
            }else{
                if(alerting){
                    toasting.error(res.data.message);
                    this.setState({failed: true, message:res.data.message})
                }
                return res.data
            }
        }catch (e) {
            if(progressing){
                this.closeProgress()
            }
            if(alerting){
                toasting.error(e.message);
            }
            if(e.response && (e.response.status===SC_UNAUTHORIZED)){
                removeData(Global.USER)
                removeData(Global.AUTHORIZATION)
                window.location.href = SIGNIN_ENDPOINT+"?message="+e.response.data.message
            }

        }

    }


    downloadBlobFile = (urlOrObject, callback)=>{
        axios({
            url: urlOrObject,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            var fileBlob = response.data ;
            if(callback){
                callback(fileBlob)
            }
        }).catch(reason => {
            this.errorToast(reason)
            callback(null)
        })
    }

    multipartBlobFile = async (urlOrObject) =>{
        let multipartFile = await new Promise(resolve => {
            axios({
                url: urlOrObject,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                this.closeProgress()
                var file = response.data ;
                resolve(file)
            })
        })
        return multipartFile
    }


    // getUsers = async () => {
    //     let res = await axios.get("https://reqres.in/api/users?page=1");
    //     let { data } = res.data;
    //     this.setState({ users: data });
    // };

    async asyncDownloadGet(url, params, config, progressing, alerting){

        if(progressing){
            await this.showProgress()
        }

        let options = {
            responseType: 'blob',
            headers: {
                'Authorization': getData(Global.AUTHORIZATION),
            },
            ...params
        }

        let res = await kitchen.get(url, options);
        if(progressing){
            await this.closeProgress()
        }
        let filename = res.headers.filename

        const fileUrl = window.URL.createObjectURL(new Blob([res.data], {type:'application/vnd.ms-excel'}));
        const link = document.createElement('a');

        link.href = fileUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        return true
    }


    defaultImage(elm, image){
        elm.target.src = image
    }
    logout() {
        window.history.pushState(null, null, '/');
        window.location.href = SIGNIN_ENDPOINT
        removeData(Global.USER)
        removeData(Global.AUTHORIZATION)
        this.setState({
            isAuth: getUser()!=null,
            user: null
        })
    }

}